import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeActionDialog } from "../../../redux/slices/actionDialogSlice";
import {
  Box,
  Chip,
  DialogActions,
  DialogTitle,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  MenuItem,
  SvgIcon,
  Typography
} from "@mui/material";
import {
  ActionsGrid,
  ContentGrid,
  CustomCircularProgress,
  CustomDialogContent,
  CustomSelect,
  CustomTextField,
  TextFieldGrid,
  TitleGrid
} from "./Forms.styled";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { StyledButton } from "../../../App.styled";
import { Constants } from "../../../helpers/constants";
import { useEditBuildingMutation, useGetBuildingByIdQuery } from "../../../redux/metaDataApi";
import { openSnack } from "../../../redux/slices/snackSlice";
import { DateBox } from "devextreme-react";
import { format } from "date-fns";
import { t } from "i18next";
import { closeDetailsDialog } from "../../../redux/slices/detailsDialogSlice";
import { ModifiedConstants } from "../../../App";
import { useCurrencySymbol } from "../../../redux/customHooks/customHooks";

export default function EditBuildingForm(props) {
  const dispatch = useDispatch();
  const currencySymbol = useCurrencySymbol();
  const selectedBuilding = useSelector((state) => state.buildings.selectedBuilding);
  const { data: buildingMatch } = useGetBuildingByIdQuery(selectedBuilding.id);

  const [building, setBuilding] = useState();

  const [editBuilding, { isLoading }] = useEditBuildingMutation();

  const closeForm = () => {
    if (props.hideHeader) {
      dispatch(closeDetailsDialog());
    } else {
      dispatch(closeActionDialog());
    }
  };

  const handleBuildingChange = (e) => {
    const { name, value } = e.target;

    if (typeof value === "string") {
      setBuilding({ ...building, [name]: value });
    } else if (typeof value === "number") {
      setBuilding({ ...building, [name]: value });
    } else {
      setBuilding({ ...building, [name]: value.join(",") });
    }
  };

  const handleConfirm = async () => {
    await editBuilding(building)
      .unwrap()
      .then(() => {
        dispatch(openSnack({ message: t("toast.buildingUpdated"), type: "success" }));
        dispatch(closeActionDialog());
      })
      .catch((error) => {
        dispatch(openSnack({ message: t("toast.errorMessage"), type: "error" }));
        console.log(`Error:${error}`);
      });
  };

  useEffect(() => {
    if (buildingMatch) {
      setBuilding({
        ...buildingMatch,
        id: buildingMatch?.id,
        description: buildingMatch?.description,
        address1: buildingMatch?.address1,
        city: buildingMatch?.city,
        country: buildingMatch?.country,
        zip: buildingMatch?.zip,
        energyBaseline: buildingMatch?.energyBaseline,
        energyBaselineCost: buildingMatch?.energyBaselineCost,
        lightingEnergyBaseline: buildingMatch?.lightingEnergyBaseline,
        lightingEnergyBaselineCost: buildingMatch?.lightingEnergyBaselineCost,
        buildingAreaM2: buildingMatch?.buildingAreaM2,
        currency: buildingMatch?.currency || "EUR",
        gasBaseline: buildingMatch?.gasBaseline,
        gasBaselineCost: buildingMatch?.gasBaselineCost,
        waterBaseline: buildingMatch?.waterBaseline,
        waterBaselineCost: buildingMatch?.waterBaselineCost,
        workingDays: buildingMatch?.workingDays || "",
        workingStartHour: buildingMatch?.workingStartHour || "00:00",
        workingEndHour: buildingMatch?.workingEndHour || "00:00"
      });
    }
  }, [buildingMatch]);

  return (
    building && (
      <>
        {!props.hideHeader && (
          <DialogTitle>
            <TitleGrid container>
              <Grid item xs>
                <Grow in timeout={800}>
                  <Typography variant="h3">{t("form.editBuilding")}</Typography>
                </Grow>
              </Grid>
              <Grid item xs textAlign="right">
                <IconButton onClick={closeForm}>
                  <SvgIcon>
                    <XMarkIcon />
                  </SvgIcon>
                </IconButton>
              </Grid>
            </TitleGrid>
          </DialogTitle>
        )}
        <CustomDialogContent sx={{ maxHeight: "fit-content !important" }}>
          <ContentGrid container rowGap={1}>
            <TextFieldGrid item xs={12} md={6}>
              <Typography variant="p">{t("form.street")}</Typography>
              <CustomTextField
                size="small"
                name="address1"
                value={building.address1}
                onChange={handleBuildingChange}
              />
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={6}>
              <Typography variant="p">{t("form.city")}</Typography>
              <CustomTextField
                size="small"
                name="city"
                value={building.city}
                onChange={handleBuildingChange}
              />
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={6}>
              <Typography variant="p">{t("form.country")}</Typography>
              <CustomTextField
                size="small"
                name="country"
                value={building.country}
                onChange={handleBuildingChange}
              />
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={6}>
              <Typography variant="p">{t("form.zip")}</Typography>
              <CustomTextField
                size="small"
                name="zip"
                value={building.zip}
                onChange={handleBuildingChange}
              />
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={6}>
              <Typography variant="p">{t("form.totalBuildingArea")}</Typography>
              <CustomTextField
                size="small"
                name="buildingAreaM2"
                value={building.buildingAreaM2}
                onChange={handleBuildingChange}
                endAdornment={
                  <InputAdornment position="end">
                    <Typography variant="p" sx={{ opacity: "0.6" }}>
                      m²
                    </Typography>
                  </InputAdornment>
                }
              />
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={6}>
              <Typography variant="p">{t("form.currency")}</Typography>
              <CustomSelect
                size="small"
                name="currency"
                value={building.currency}
                onChange={handleBuildingChange}
              >
                {" "}
                {[...ModifiedConstants.CURRENCY]
                  ?.sort((a, b) => a.label.localeCompare(b.label))
                  .map((currency) => (
                    <MenuItem key={currency.value} value={currency.value}>
                      {currency.label}
                    </MenuItem>
                  ))}
              </CustomSelect>
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={6}>
              <Typography variant="p">{t("form.energyBaseline")}</Typography>
              <CustomTextField
                size="small"
                name="energyBaseline"
                value={building.energyBaseline}
                onChange={handleBuildingChange}
                endAdornment={
                  <InputAdornment position="end">
                    <Typography variant="p" sx={{ opacity: "0.6" }}>
                      kWh
                    </Typography>
                  </InputAdornment>
                }
              />
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={6}>
              <Typography variant="p">{t("form.energyBaselineCost")}</Typography>
              <CustomTextField
                size="small"
                name="energyBaselineCost"
                value={building.energyBaselineCost}
                onChange={handleBuildingChange}
                InputProps={{
                  inputProps: {
                    min: 0
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="p" sx={{ opacity: "0.6" }}>
                        {currencySymbol}
                      </Typography>
                    </InputAdornment>
                  )
                }}
              />
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={6}>
              <Typography variant="p">{t("form.lightingEnergyBaseline")}</Typography>
              <CustomTextField
                size="small"
                name="lightingEnergyBaseline"
                value={building.lightingEnergyBaseline}
                onChange={handleBuildingChange}
                endAdornment={
                  <InputAdornment position="end">
                    <Typography variant="p" sx={{ opacity: "0.6" }}>
                      kWh
                    </Typography>
                  </InputAdornment>
                }
              />
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={6}>
              <Typography variant="p">{t("form.lightingEnergyBaselineCost")}</Typography>
              <CustomTextField
                size="small"
                name="lightingEnergyBaselineCost"
                value={building.lightingEnergyBaselineCost}
                onChange={handleBuildingChange}
                InputProps={{
                  inputProps: {
                    min: 0
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="p" sx={{ opacity: "0.6" }}>
                        {currencySymbol}
                      </Typography>
                    </InputAdornment>
                  )
                }}
              />
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={6}>
              <Typography variant="p">{t("form.gasBaseline")}</Typography>
              <CustomTextField
                size="small"
                name="gasBaseline"
                value={building.gasBaseline}
                onChange={handleBuildingChange}
                endAdornment={
                  <InputAdornment position="end">
                    <Typography variant="p" sx={{ opacity: "0.6" }}>
                      m³
                    </Typography>
                  </InputAdornment>
                }
              />
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={6}>
              <Typography variant="p">{t("form.gasBaselineCost")}</Typography>
              <CustomTextField
                size="small"
                name="gasBaselineCost"
                value={building.gasBaselineCost}
                onChange={handleBuildingChange}
                InputProps={{
                  inputProps: {
                    min: 0
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="p" sx={{ opacity: "0.6" }}>
                        {currencySymbol}
                      </Typography>
                    </InputAdornment>
                  )
                }}
              />
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={6}>
              <Typography variant="p">{t("form.waterBaseline")}</Typography>
              <CustomTextField
                size="small"
                name="waterBaseline"
                value={building.waterBaseline}
                onChange={handleBuildingChange}
                endAdornment={
                  <InputAdornment position="end">
                    <Typography variant="p" sx={{ opacity: "0.6" }}>
                      m³
                    </Typography>
                  </InputAdornment>
                }
              />
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={6}>
              <Typography variant="p">{t("form.waterBaselineCost")}</Typography>
              <CustomTextField
                size="small"
                name="waterBaselineCost"
                value={building.waterBaselineCost}
                onChange={handleBuildingChange}
                InputProps={{
                  inputProps: {
                    min: 0
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="p" sx={{ opacity: "0.6" }}>
                        {currencySymbol}
                      </Typography>
                    </InputAdornment>
                  )
                }}
              />
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={12}>
              <Typography variant="p">{t("form.workingDays")}</Typography>
              <CustomSelect
                name="workingDays"
                multiple
                value={building.workingDays?.split(",")}
                onChange={handleBuildingChange}
                renderValue={(selected) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.5,
                      height: "34px",
                      alignContent: "center"
                    }}
                  >
                    {selected.map(
                      (value) =>
                        value !== "" && (
                          <Chip
                            key={value}
                            label={t(`calendarControl.${value}`).charAt(0)}
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                              height: "24px"
                            }}
                          />
                        )
                    )}
                  </Box>
                )}
              >
                {Constants.WEEK_DAYS.map((name) => {
                  return (
                    name !== "All" && (
                      <MenuItem key={name} value={name}>
                        {t(`calendarControl.${name}`)}
                      </MenuItem>
                    )
                  );
                })}
              </CustomSelect>
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={6}>
              <Typography variant="p">{t("form.workingStartHours")}</Typography>
              <DateBox
                name="workingStartHour"
                value={new Date(0, 0, 0, ...building.workingStartHour?.split(":"))}
                type="time"
                interval={60}
                displayFormat="HH:00"
                onValueChanged={(e) => {
                  handleBuildingChange({
                    target: {
                      name: "workingStartHour",
                      value: format(e.value, "HH:00")
                    }
                  });
                }}
              />
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={6}>
              <Typography variant="p">{t("form.workingEndHours")}</Typography>
              <DateBox
                name="workingEndHour"
                value={new Date(0, 0, 0, ...building.workingEndHour?.split(":"))}
                type="time"
                interval={60}
                displayFormat="HH:00"
                onValueChanged={(e) =>
                  handleBuildingChange({
                    target: {
                      name: "workingEndHour",
                      value: format(e.value, "HH:00")
                    }
                  })
                }
              />
            </TextFieldGrid>
          </ContentGrid>
        </CustomDialogContent>
        <DialogActions>
          <ActionsGrid container>
            <StyledButton onClick={closeForm}> {t("button.cancel")}</StyledButton>
            <Box sx={{ position: "relative" }}>
              <StyledButton selected onClick={handleConfirm}>
                {t("button.confirm")}
              </StyledButton>
              {isLoading && <CustomCircularProgress size={24} />}
            </Box>
          </ActionsGrid>
        </DialogActions>
      </>
    )
  );
}
