import { styled } from "@mui/material/styles";
import { AppBar, Grid, Select } from "@mui/material";

export const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  background: "#f1f5f8",
  color: "#000000",
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: "none",
  height: `80px`,
  width: `calc(100% - ${open ? 278 : 95}px)`,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export const StyledActionWrapper = styled(Grid)(() => ({
  justifyContent: "flex-end",
  alignItems: "center",
  display: "flex",
  height: "80px",
  width: "100%",
}));

export const StyledLogoWrapper = styled(Grid)(() => ({
  justifyContent: "flex-start",
  alignItems: "center",
  display: "flex",
  height: "100%",
}));

export const StyledHeaderWrapper = styled(Grid)(() => ({
  columnGap: "1",
  justifyContent: "space-between",
  alignContent: "center",
  alignItems: "center",
  flexWrap: "nowrap",
}));

export const StyledBuildingSelect = styled(Select)(() => ({
  fontSize: "14px !important",
  fieldset: {
    border: "1px solid #00000014",
    borderRadius: "25px",
    backgroundColor: "transparent",
  },
}));
