import { Drawer, List, ListItemButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Constants } from "../../helpers/constants";

const drawerOpenWidth = Constants.CSS.DRAWER_WIDTH.OPEN;

const openedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  width: drawerOpenWidth,
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "95px",
  overflowY: "hidden",
});

export const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerOpenWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(!open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      paddingTop: "2px",
      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    },
  }),
  ...(open && {
    ...closedMixin(theme),
    "&.MuiDrawer-paper": {
      ...closedMixin(theme),
    },
  }),
  ".MuiListItem-root": {
    minHeight: "44px",
    ".Mui-selected": {
      backgroundColor: "#1C7AD4 !important",
      borderRadius: "15px",
    },
  },
}));

export const DrawerHeader = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  display: "flex",
  padding: theme.spacing(1),
  alignItems: "center", // Add this to vertically center the logo
  justifyContent: open ? "space-between" : "center", // Add this to horizontally center the logo
  marginLeft: open ? "20px" : "0px", // Add this to horizontally center the logo
  ...theme.mixins.toolbar,
}));

export const StyledList = styled(List)(({ theme }) => ({
  padding: theme.spacing(0),
  color: "rgba(0, 0, 0, 0.54) !important",
}));

export const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "avatar",
})(({ open, avatar }) => ({
  display: "flex",
  alignItems: "center",
   paddingLeft: avatar ? "10px !important" :"auto",
  ".MuiListItemIcon-root": {
    minWidth: open ? "30px !important" : "22px !important",
    marginY: "14px",
    margin: "0px",
  },
  paddingTop: "8px !important",
  paddingBottom: "8px !important",
  "&:hover": {
    backgroundColor: "transparent !important",
  },
  "&.Mui-selected": {
    backgroundColor: "transparent ! important",
    color: "white !important",
    ".MuiListItemIcon-root": {
      color: "white !important",
    },
  },
}));
