import { add, format, startOfMonth } from 'date-fns';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetConditionalAlertsByBuildingQuery } from '../../redux/metaDataApi';
import { changeActionDialogType, openActionDialog } from '../../redux/slices/actionDialogSlice';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { StyledButton } from '../../App.styled';
import AlertCard from '../../components/AlertsNotification/AlertCard/AlertCard';

export default function AlertsMobile() {
    const dispatch = useDispatch();
    const currentDate = new Date();
    const navigate = useNavigate();
    const beginningOfLastMonth = startOfMonth(currentDate);
    const profile = useSelector((state) => state.session.selectedProfile);
    const [moduleArray, setModuleArray] = useState([]);
    const [filterAlertsBy, setFilterAlertsBy] = useState("DeviationAlert");
    const [filteredAlerts, setFlitteredAlerts] = useState();
    const selectedBuilding = useSelector(
      (state) => state.buildings.selectedBuilding
    );
  
    useEffect(() => {
      if (profile) {
        const modules = profile.profileModules?.map((item) => item.module) || [];
        setModuleArray(modules);
      }
    }, [profile]);
    const filter = {
      buildingId: selectedBuilding.id,
      from: format(beginningOfLastMonth, "yyyy-MM-dd"),
      to: format(add(currentDate, { days: 1 }), "yyyy-MM-dd"),
    };
    const { data: alerts } = useGetConditionalAlertsByBuildingQuery(filter, {
      skip: !selectedBuilding,
    });
  
    const handleChangeType = (type) => {
      if (type === "pro") {
        if (moduleArray.includes("MultisenseAlertsPro")) {
          setFilterAlertsBy("PerformanceAlert");
        } else {
          dispatch(changeActionDialogType("upgradeAlerts"));
          dispatch(openActionDialog());
        }
      } else {
        setFilterAlertsBy("DeviationAlert");
      }
    };
  
    useEffect(() => {
      setFlitteredAlerts(
        alerts?.filter(
          (item) =>
            item.conditionalAlertType === filterAlertsBy &&
            item.conditionalAlertStatus === "Active"
        )
      );
    }, [filterAlertsBy, alerts]);
  
    return (
      alerts && (
        <Grid
          container
          sx={{ height: "100%", width: "100%", marginLeft: "0.5%" }}
          flexDirection="column"
          spacing={2}
        >
          <Grid item>
            <Stack direction="row" spacing={2} sx={{ justifyContent: "center" }}>
              <StyledButton
                variant="contained"
                disableElevation
                selected={filterAlertsBy === "DeviationAlert"}
                onClick={() => handleChangeType("lite")}
              >
                LITE
              </StyledButton>
              <StyledButton
                variant="contained"
                disableElevation
                selected={filterAlertsBy === "PerformanceAlert"}
                onClick={() => handleChangeType("pro")}
              >
                PRO
              </StyledButton>
            </Stack>
          </Grid>
          {filteredAlerts?.length > 0 ? (
            <Box
              item
              container
              sx={{
                height: "400px",
                overflow: "auto",
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {filteredAlerts?.map((alert, index) => (
                <Grid item key={index} sx={{ marginTop: "8px" }}>
                  <AlertCard alert={alert} />
                </Grid>
              ))}
            </Box>
          ) : (
            <Grid
              item
              container
              sx={{
                height: "400px",
                overflowY: "auto",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid item>
                <Typography>{t("alerts.noFound")}</Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      )
    );
}
