import styled from "styled-components";
import { Typography, Card, Autocomplete, TextField, Tooltip, tooltipClasses } from "@mui/material";

export const AdditionalInfo = styled(Typography)(() => ({
  color: "#424242",
  textAlign: "center",
  fontSize: "20px",
  fontStyle: "bold",
  fontWeight: "400"
}));

export const CostCard = styled(Card)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "40vh",
  alignItems: "center",
  backgroundColor: "#ffffff",
  borderRadius: "24px !important",
  boxShadow: "none",
  padding: "16px"
}));

export const StyledNumbers = styled(Typography)(() => ({
  color: "#424242",
  textAlign: "center",
  fontSize: "25px",
  fontStyle: "bold",
  fontWeight: "400",
  lineHeight: "38px"
}));

export const CustomAutocomplete = styled(Autocomplete)(() => ({
  width: "100%",
  fontWeight: "400 !important",
  fontSize: "14px !important",
  lineHeight: "20px !important",
  textAlign: "center",
  fontFamily: "'Poppins', sans-serif !important",
  color: "#424242 !important",
  "& .MuiOutlinedInput-root": {
    height: "36px",
    "& .MuiInputBase-input": {
      height: "100%",
      padding: "0 9px"
    },
    "& fieldset": {
      border: "1px solid #00000014",
      borderRadius: "6px",
      backgroundColor: "transparent"
    }
  },
  "& .MuiAutocomplete-paper": {
    fontFamily: "'Poppins', sans-serif !important",
    fontSize: "14px !important",
    color: "#424242 !important",
    backgroundColor: "#f5f5f5 !important"
  },
  "& .MuiAutocomplete-option": {
    fontFamily: "'Poppins', sans-serif !important",
    minHeight: "36px !important",
    lineHeight: "20px !important",
    padding: "8px 16px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  backgroundColor: "#f5f5f5"
}));

export const CustomTextField = styled(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    height: "36px",
    "& input": {
      height: "100%",
      padding: "0 9px",
      fontWeight: "400 !important",
      fontSize: "14px ! important",
      lineHeight: "20px ! important",
      fontFamily: "'Poppins', sans-serif !important",
      color: "#424242 !important"
    },
    "& fieldset": {
      border: "1px solid #00000014",
      borderRadius: "6px",
      backgroundColor: "transparent"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #00000014"
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #00000014"
    },
    "&.Mui-focused": {
      "& label": {
        color: "#1c7ad4"
      }
    }
  }
}));

export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: "16px",
    border: "1px solid #dadde9"
  }
}));
