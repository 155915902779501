import {
  Grid,
  Typography,
} from "@mui/material";

import React from "react";
import { t } from "i18next";
import {
  NotificationCard,
  StyledLottieNotifications,
  StyledSnackbarContent,
} from "./Notifications.styled";
import new_icon from "../../resources/lotties/new_icon.json";
import maintenance from "../../resources/lotties/maintenance.json";
import service from "../../resources/lotties/service.json";

export const NewRelease = React.forwardRef((props, ref) => {
  return (
    <StyledSnackbarContent ref={ref}>
      <NotificationCard variant="outlined">
        <Grid
          item
          container
          alignItems="center"
          flexDirection="row"
          wrap="nowrap" // This ensures content stays in a single row
          style={{ overflow: "hidden" }} // Prevents content overflow
        >
          <Grid item>
            <StyledLottieNotifications animationData={new_icon} loop={false} />
          </Grid>
          <Grid item xs zeroMinWidth>
            <Typography variant="h3" sx={{ marginBottom: "2px" }}>
              {t("toast.NewRelease")}
            </Typography>
            <Typography variant="p">{t("toast.NewReleaseMessage")}</Typography>
          </Grid>
        </Grid>
      </NotificationCard>
    </StyledSnackbarContent>
  );
});

export const Maintenance = React.forwardRef((props, ref) => {
  return (
    <StyledSnackbarContent ref={ref}>
      <NotificationCard variant="outlined">
        <Grid
          item
          container
          alignItems="center"
          flexDirection="row"
          wrap="nowrap" // This ensures content stays in a single row
          style={{ overflow: "hidden" }} // Prevents content overflow
        >
          <Grid item>
            <StyledLottieNotifications
              animationData={maintenance}
              loop={true}
            />
          </Grid>
          <Grid item>
            <Typography variant="h3" sx={{ marginBottom: "2px" }}>
              {t("toast.Maintenance")}
            </Typography>
            <Typography variant="p">{t("toast.MaintenanceMessage")}</Typography>
          </Grid>
        </Grid>
      </NotificationCard>
    </StyledSnackbarContent>
  );
});

export const ServiceDown = React.forwardRef((props, ref) => {
  return (
    <StyledSnackbarContent ref={ref}>
      <NotificationCard variant="outlined">
        <Grid
          item
          container
          alignItems="center"
          flexDirection="row"
          wrap="nowrap" // This ensures content stays in a single row
          style={{ overflow: "hidden" }} // Prevents content overflow
        >
          <Grid item>
            <StyledLottieNotifications animationData={service} loop={true} />
          </Grid>
          <Grid item>
            <Typography variant="h3" sx={{ marginBottom: "2px" }}>
              {t("toast.ServiceDown")}
            </Typography>
            <Typography variant="p">{t("toast.ServiceDownMessage")}</Typography>
          </Grid>
        </Grid>
      </NotificationCard>
    </StyledSnackbarContent>
  );
});
