import { XMarkIcon } from '@heroicons/react/24/outline'
import { IconButton, MenuItem, Stack, SvgIcon, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CustomSelect } from '../../../ActionDialog/Forms/Forms.styled'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  changeAlertSettings,
  removeCondition,
} from '../../../../redux/slices/alertsProSettingsSlice'
import { ModifiedConstants } from '../../../../App'

export default function DataType({ conditionIndex }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { sensorTypes, selectedBuilding } = useSelector(
    (state) => state.buildings,
  )
  const { fullObject } = useSelector((state) => state.alertsProSettings)

  const [sensorType1, setSensorType1] = useState(
    fullObject?.conditionalAlertSettingConditions[conditionIndex]
      .conditionalAlertSettingParameters[0].sensorType1,
  )
  const [sensorType2, setSensorType2] = useState(
    fullObject?.conditionalAlertSettingConditions[conditionIndex]
      .conditionalAlertSettingParameters[1].sensorType1,
  )
  const [logical, setLogical] = useState(
    fullObject?.conditionalAlertSettingConditions[conditionIndex]
      .conditionalAlertComparisonOperator,
  )

  const handleChange1 = (value, key) => {
    setSensorType1(value)
  }
  const handleChange2 = (value, key) => {
    setSensorType2(value)
  }
  const handleLogicalOperatorChange = (value, key) => {
    setLogical(value)
  }

  useEffect(() => {
    if (sensorType1 && sensorType2 && logical) {
      const updatedConditions = fullObject.conditionalAlertSettingConditions?.map(
        (condition, index) => {
          if (index === conditionIndex) {
            // If it's the first condition
            return {
              ...condition,
              order: conditionIndex + 1,
              conditionalAlertComparisonOperator: logical,
              conditionalAlertSettingParameters: [
                {
                  ...condition.conditionalAlertSettingParameters[0],
                  order: 1,
                  conditionalAlertParameterType: 'SensorType',
                  sensorType1: sensorType1,
                },
                {
                  ...condition.conditionalAlertSettingParameters[1], // Assuming this is the second parameter
                  order: 2,
                  conditionalAlertParameterType: 'SensorType',
                  sensorType1: sensorType2,
                },
              ],
            }
          }
          return condition // Return other conditions as is
        },
      )
      dispatch(changeAlertSettings(updatedConditions))
    }
  }, [sensorType1, sensorType2, logical, dispatch])

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Typography variant="h3">{t("alerts.dataType")}</Typography>
        <IconButton
         onClick={() => dispatch(removeCondition({ index: conditionIndex }))}
        >
          <SvgIcon>
            <XMarkIcon />
          </SvgIcon>
        </IconButton>
      </div>
      <Stack direction="row" spacing={2.5} sx={{ marginTop: '16px' }}>
        <CustomSelect
          sx={{ minWidth: 120, flex: 1 }}
          key="sensor-select1"
          id="sensor-select1"
          value={sensorType1}
          fullWidth
          onChange={(e) => {
            e.stopPropagation()
            handleChange1(e.target.value)
          }}
        >
          {sensorTypes[selectedBuilding?.id]?.map((sensor) => (
            <MenuItem value={sensor} key={sensor}>
              {t(`sensor.${sensor}`)}
            </MenuItem>
          ))}
        </CustomSelect>
        <CustomSelect
          fullWidth
          sx={{ minWidth: 120, flex: 1 }}
          value={logical}
          onChange={(e) => {
            e.stopPropagation()
            handleLogicalOperatorChange(e.target.value)
          }}
        >
          {ModifiedConstants.LOGICAL_OPERATORS.map((operator) => (
            <MenuItem value={operator} key={operator}>
              {t(`logical.${operator}`)}
            </MenuItem>
          ))}
        </CustomSelect>
        <CustomSelect
          key="sensor-select2"
          id="sensor-select2"
          value={sensorType2}
          fullWidth
          sx={{ minWidth: 120, flex: 1 }}
          onChange={(e) => {
            e.stopPropagation()
            handleChange2(e.target.value)
          }}
        >
          {sensorTypes[selectedBuilding?.id]
            ?.filter((item) => item !== sensorType1)
            .map((sensor) => (
              <MenuItem value={sensor} key={sensor}>
                {t(`sensor.${sensor}`)}
              </MenuItem>
            ))}
        </CustomSelect>
      </Stack>
    </>
  )
}
