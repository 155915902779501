import React from 'react'
import {
  Document,
  Page,
  Text,
  StyleSheet,
  Image,
  View,
} from '@react-pdf/renderer'
import Enappgy from '../../../resources/images/Logo.png'
import Waves from '../../../resources/images/bg-wave.png'
import { useTranslation } from 'react-i18next'
import { formatNumber } from '../../../helpers/functions'
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 16,
    marginBottom: 12,
  },
  subtitle: {
    fontSize: 14,
    marginTop: 12,
    marginBottom: 12,
  },
  text: {
    fontSize: 10,
    color: '#424242',
    textAlign: 'justify',
  },
  
  image: {
    width: 125.501,
    height: 32,
    marginVertical: 15,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
  },
  table: {
    marginTop: 12,
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf', // Add different border color here
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: '#bfbfbf',
    backgroundColor: '#f0f0f0', // Background color for headers
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: '#bfbfbf',
  },
  tableCellHeader: {
    margin: 10,
    fontSize: 10,
    fontWeight: 700, // Bold header text
    height: 24, // Set height for header cells
  },
  tableCell: {
    margin: 10,
    fontSize: 10,
    height: 24, // Set height for all cells
  },
  footer: {
    position: 'absolute',
    fontSize: 8,
    fontWeight: 'bold',
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#424242',
  },
  formula1: { 
    marginVertical: "16px",
    fontSize: 10,
    color: '#424242',
    textAlign: 'justify',},
    formula2: { 
      marginBottom: "16px",
      fontSize: 10,
      color: '#424242',
      textAlign: 'justify',},

  backgroundImage: {
    position: 'absolute',
    minWidth: '100%',
    minHeight: '50%',
    height: '50%',
    top: '50%', // center vertically
    transform: 'translateY(-200%)', // shifts it up by half its height to center it vertically
    width: '100%',
    opacity: 0.5,
  },
})

export default function PdfReport({ report }) {
  const { t } = useTranslation()
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <Image style={styles.backgroundImage} src={Waves} />
        <Image src={Enappgy} style={styles.image} />
        <Text style={styles.title}>
          {t(`reports.pdf.title`)} - {report.buildingDescription}
        </Text>
        <Text style={styles.text}>
          {t(`reports.pdf.paragraph1`, {
            buildingDescription: report.buildingDescription,
          })}
        </Text>

        <Text style={styles.subtitle}>{t(`reports.pdf.subtitle`)}</Text>
        <Text style={styles.text}>{t(`reports.pdf.paragraph2`)}</Text>
        <View style={styles.table}>
          {/* Table Header */}
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>
                {' '}
                {t(`reports.pdf.categories`)}
              </Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>{t(`sensor.Energy`)}</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>{t(`sensor.Water`)}</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>{t(`sensor.Gas`)}</Text>
            </View>
          </View>

          {/* Table Content */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {t(`reports.pdf.categories1`)}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {formatNumber(report.lastQuarterEnergyConsumption)}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {formatNumber(report.lastQuarterWaterConsumption)}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {formatNumber(report.lastQuarterGasConsumption)}
              </Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {t(`reports.pdf.categories2`)}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {formatNumber(report.energyConsumptionPercentageChange)}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {formatNumber(report.waterConsumptionPercentageChange)}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {formatNumber(report.gasConsumptionPercentageChange)}
              </Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {t(`reports.pdf.categories3`)}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {formatNumber(report.lastQuarterEnergyConsumptionPerSquareMeter)}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {formatNumber(report.lastQuarterWaterConsumptionPerSquareMeter)}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {formatNumber(report.lastQuarterGasConsumptionPerSquareMeter)}
              </Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {t(`reports.pdf.categories4`)}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {formatNumber(report.energyWeightPercentage)}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {formatNumber(report.waterWeightPercentage)}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {formatNumber(report.gasWeightPercentage)}
              </Text>
            </View>
          </View>
        </View>

        <Text style={styles.subtitle}>{t(`reports.pdf.subtitle2`)}</Text>
        <Text style={styles.text}>{t(`reports.pdf.paragraph3`)}</Text>
        <Text style={styles.formula1}>
          {t(`reports.pdf.formula`)} = (1 - (
          {report.energyConsumptionPercentageChange} / 100)) *{' '}
          {report.energyWeightPercentage} + (1 - (
          {report.waterConsumptionPercentageChange}/ 100)) *{' '}
          {report.waterWeightPercentage / 100} + (1 - (
          {report.gasConsumptionPercentageChange}/ 100)) *{' '}
          {report.gasWeightPercentage}
        </Text>
        <Text style={styles.formula2}>{t(`reports.pdf.formula`)} = {report.sustainabilityScore}</Text>
        <Text style={styles.text}></Text>
        <Text style={styles.text}>{t(`reports.pdf.paragraph4`)}</Text>
        <Text style={styles.footer} fixed>
          ENAPPGY | Databankweg 26, 3821AL | Amersfoort | The Netherlands | kvk
          59171553 | www.enappgy.com
        </Text>
      </Page>
    </Document>
  )
}
