import {
  Box,
  DialogActions,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  ActionsGrid,
  ContentGrid,
  CustomCircularProgress,
  CustomDialogContent,
  CustomTextField,
  TextFieldGrid,

} from "./Forms.styled";
import { useDispatch, useSelector } from "react-redux";
import { closeActionDialog } from "../../../redux/slices/actionDialogSlice";

import { StyledButton } from "../../../App.styled";
import { useAddFavoriteMutation } from "../../../redux/metaDataApi";
import { openSnack } from "../../../redux/slices/snackSlice";
import { t } from "i18next";
import { addFavorites, applySelection, selectFavorites } from "../../../redux/slices/multisenseSlice";
import { closeDetailsDialog } from "../../../redux/slices/detailsDialogSlice";

export default function ConfirmForm() {
  const dispatch = useDispatch();
  const userId = useSelector((state)=>state.session.userId)
  const [addFavorite, {isLoading}] = useAddFavoriteMutation();
  const series = useSelector((state) => state.multisense.series);
  const { id: tenantId } = useSelector((state) => state.session.selectedTenant);
  const [name, setName] = useState('');

  const handleConfirm = async  () => {
 const payload = {
      userId: userId,
      tenantId: tenantId,
      description: name,
      series: JSON.stringify(series),
    };

    await addFavorite(payload)
      .unwrap()
      .then(() => {
        dispatch(openSnack({ message: `${t("snack.favSucces")}`, type: "success" }))
        dispatch(addFavorites(payload));
        dispatch(selectFavorites(series));
        dispatch(applySelection());
        dispatch(closeDetailsDialog())
        dispatch(closeActionDialog())})
      .catch((error) => {
        dispatch(openSnack({ message: `${t("snack.error")}`, type: "error" }))
        console.log(`Error:${error}`);
      });
  };

  return (
    <>
      <CustomDialogContent>
        <ContentGrid container rowGap={2} padding={2}>
          <Typography variant="h3">
            {t("favorites.confirmTitle")}
          </Typography>
          <TextFieldGrid item xs={12} md={12}>
            <Typography variant="p"> {t("favorites.description")}</Typography>
            <CustomTextField size="small" value={name} onChange={(e) => setName(e.target.value)}  inputProps={{ maxLength: 50 }} />
          </TextFieldGrid>
        </ContentGrid>
      </CustomDialogContent>
      <DialogActions>
        <ActionsGrid container>
          <StyledButton onClick={() => dispatch(closeActionDialog())}>
          {t("button.cancel")}
          </StyledButton>
          <Box sx={{ position: 'relative' }}>
            <StyledButton
              selected
              onClick={() => handleConfirm()}
              disabled={isLoading}
            >
            {t("button.confirm")}
            </StyledButton>
            {isLoading && <CustomCircularProgress size={24} />}
          </Box>
        </ActionsGrid>
      </DialogActions>
    </>
  );
}
