import React, { useCallback, useEffect, useRef, useState } from "react";
import { CustomCard, StyledButton } from "../../App.styled";
import { useSelector } from "react-redux";
import FloorPlanEngine from "@archilogic/floor-plan-sdk";
import { Box, Chip, CircularProgress, Grid, Stack } from "@mui/material";
import { useGetMeasurementsByFloorQuery } from "../../redux/dataApi";
import { getColorForArea, getFilter } from "../../helpers/functions";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";

export default function DigitalFloorPlan() {
  const mapContainerRef = useRef(null);
  const floorPlanRef = useRef(null);
  const activeTooltip = useRef(null);
  const { t } = useTranslation();
  const calendarSelection = useSelector((state) => state.calendar.calendarSelection);
  const publishableToken = process.env.REACT_APP_ARCHILOGIC;
  const { buildings, selectedBuilding } = useSelector((state) => state.buildings);
  const building = buildings.find((b) => b.id === selectedBuilding.id);
  const sortedFloors = [...building.floors]
    .filter((item) => item.description !== "Central Installations")
    .sort((a, b) => a.description.localeCompare(b.description));
  const { id: tenantId } = useSelector((state) => state.session.selectedTenant);
  const [selectedFloor, setSelectedFloor] = useState(sortedFloors[0]);

  const labels = [
    { text: "0%-19%", color: "#a9e48f" },
    { text: "20% - 39%", color: "#ffedac" },
    { text: "40% - 59%", color: "#ffd18f" },
    { text: "60% - 79%", color: "#ffb48d" },
    { text: "80% - 100%", color: "#ff9a8e" },
  ];

  const { data: utilization, isFetching } = useGetMeasurementsByFloorQuery(
    {
      filter: getFilter("Occupancy", calendarSelection, selectedFloor),
      locationId: selectedFloor.id,
      tenantId,
    },
    {
      skip: !selectedFloor,
    }
  );

  const colorizeArea = useCallback((spaceId, color) => {
    if (!floorPlanRef.current) return;
    if (floorPlanRef.current.resources.spaces.length > 0) {
      let node = null;
      floorPlanRef.current.resources.spaces.forEach((element) => {
        if (element.node.id !== undefined) {
          if (element.node?.id === spaceId) {
            node = element.node.id === spaceId ? element.node : null;
            const colorMap = {
              green: [169, 228, 144],
              yellow: [255, 237, 174],
              orange: [255, 209, 143],
              orangered: [255, 180, 141],
              red: [255, 155, 142],
              default: [229, 231, 233],
            };
            node.setHighlight({ fill: colorMap[color] || colorMap["default"] });
          }
        }
      });
    }
  }, []);

  const setInfoWindow = useCallback(
    (infoPos, space) => {
      if (space) {
        let utilizationValue = utilization?.areas.find((item) => item.spaceId === space.id);
        var usageName = space?.usageName;
        const html = `<h3>${space.name}</h3>
       <div style="display: flex;  flex-direction: column;">
       <div  style="flex-direction: row;">
       <b>${t("utilization.title")}:${
          utilizationValue?.dataBucket.avg.toFixed(2).replace(".", ",") || 0
        }%</b>
       </div>
       <div style="flex-direction: row;">
       <b>${t("utilization.roomtype")}:${t(`utilization.rooms.${usageName}`)}</b>
       </div>
       </div>`;
        if (activeTooltip.current) {
          activeTooltip.current.set({ pos: infoPos, html });
        } else {
          activeTooltip.current = floorPlanRef.current.addInfoWindow({
            pos: infoPos,
            html,
            height: 100,
            width: 250,
            closeButton: false,
          });
        }
      } else if (activeTooltip.current) {
        activeTooltip.current.remove();
        activeTooltip.current = null;
      }
    },
    [t, utilization]
  );

  const handleMouseHover = useCallback(
    debounce((event) => {
      const pos = event.pos;
      const infoPos = [pos[0], pos[1] - 0.5];
      let { spaces } = floorPlanRef.current.getResourcesFromPosition(pos);
      if (spaces.length > 0) {
        const space = spaces[0];
        setInfoWindow(infoPos, space);
      }
    }, 100),
    [setInfoWindow]
  );

  useEffect(() => {
    if (
      publishableToken &&
      mapContainerRef.current &&
      selectedFloor &&
      utilization &&
      !isFetching
    ) {
      const startupOptions = {
        theme: {
          background: {
            color: "#ffffff",
          },
          elements: {
            roomStamp: {
              roomStampDisplay: ["name", "area"],
            },
          },
        },
        accessToken: publishableToken,
      };
      floorPlanRef.current = new FloorPlanEngine(mapContainerRef.current, startupOptions);
      floorPlanRef.current
        .loadScene(selectedFloor.floorPlanId, {
          publishableToken,
        })
        .then(() => {
          if (utilization) {
            utilization.areas.forEach((area) => {
              colorizeArea(area.spaceId, getColorForArea(area.dataBucket.avg));
            });
          }
        });

      floorPlanRef.current.on("mousemove", handleMouseHover);
      return () => {
        // Clean up
        if (floorPlanRef.current) {
          floorPlanRef.current.off("mousemove", handleMouseHover);
          if (activeTooltip.current) {
            activeTooltip.current.remove();
            activeTooltip.current = null;
          }
        }
      };
    }
  }, [publishableToken, selectedFloor, utilization, handleMouseHover, isFetching, colorizeArea]);

  return (
    <CustomCard
      variant="outlined"
      sx={{
        height: "calc(100vh-200px) !important",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box style={{ width: "100%", height: "100%", padding: "18px" }}>
        <Grid container direction="column" sx={{ height: "100%" }}>
          <Grid item>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                padding: "18px",
              }}
            >
              <Stack useFlexGap spacing={1} direction="row">
                {labels?.map((label) => (
                  <Chip
                    variant="outlined"
                    label={label.text}
                    sx={{ backgroundColor: label.color }}
                    key={label.text}
                  />
                ))}
              </Stack>
              <Stack useFlexGap spacing={1} direction="row">
                {sortedFloors?.map((floor, index) => (
                  <StyledButton
                    key={index}
                    selected={selectedFloor.id === floor.id}
                    onClick={() => {
                      setSelectedFloor(floor);
                    }}
                  >
                    {floor.description}
                  </StyledButton>
                ))}
              </Stack>
            </div>
            <Grid item sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
              <Box
                ref={mapContainerRef}
                sx={{
                  width: "100%",
                  height: "600px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                {isFetching && (
                  <CircularProgress
                    sx={{
                      position: "absolute",
                      marginTop: "-400px"
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </CustomCard>
  );
}
