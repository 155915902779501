import { Box, DialogActions, DialogTitle, Grid, Grow, IconButton, SvgIcon, Typography } from "@mui/material";
import React, { useState } from "react";
import { ActionsGrid, ContentGrid, CustomCircularProgress, CustomDialogContent, CustomTextField, TextFieldGrid, TitleGrid } from "./Forms.styled";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { closeActionDialog } from "../../../redux/slices/actionDialogSlice";
import { StyledButton } from "../../../App.styled";
import { t } from "i18next";
import { openSnack } from "../../../redux/slices/snackSlice";
import { useUpdateTenantNameMutation } from "../../../redux/metaDataApi";
import { changeTenantSelection } from "../../../redux/slices/currentSessionSlice";

export default function ChangeTenantName() {
  const dispatch = useDispatch();
  const tenant = useSelector((state) => state.session.selectedTenant);
  const [updateDescription, { isLoading }] = useUpdateTenantNameMutation();
  const [name, setName] = useState(tenant.description);
  const closeForm = () => {
    dispatch(closeActionDialog());
  };

  const handleConfirm = async () => {
    const newDescription = {
      tenantId: tenant.id,
      description: name,
    };

    await updateDescription(newDescription)
      .unwrap()
      .then(() => {
        dispatch(openSnack({ message: "Tenant description successfully changed", type: "success" }));
        dispatch(changeTenantSelection({id: tenant.id, description: name}))
        closeForm();
      })
      .catch((error) => {
        dispatch(openSnack({ message: t("toast.errorMessage"), type: "error" }));
        console.log(`Error:${error}`);
      });
  };

  return (
    <>
      <DialogTitle>
        <TitleGrid container>
          <Grid item xs>
            <Grow in timeout={800}>
              <Typography variant="h3">Tenant Name</Typography>
            </Grow>
          </Grid>
          <Grid item xs textAlign="right">
            <IconButton onClick={closeForm}>
              <SvgIcon>
                <XMarkIcon />
              </SvgIcon>
            </IconButton>
          </Grid>
        </TitleGrid>
      </DialogTitle>
      <CustomDialogContent>
        <ContentGrid container rowGap={2}>
          <TextFieldGrid item xs={12} md={12}>
            <Typography variant="p">New description</Typography>
            <CustomTextField size="small" value={name} onChange={(e) => setName(e.target.value)} />
          </TextFieldGrid>
        </ContentGrid>
      </CustomDialogContent>
      <DialogActions>
        <ActionsGrid container>
          <StyledButton onClick={closeForm}> {t("button.cancel")}</StyledButton>
          <Box sx={{ position: "relative" }}>
            <StyledButton selected onClick={handleConfirm} disabled={isLoading}>
              {t("button.confirm")}
            </StyledButton>
            {isLoading && <CustomCircularProgress size={24} />}
          </Box>
        </ActionsGrid>
      </DialogActions>
    </>
  );
}
