import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { enappgyDataServiceApi } from './dataApi'
import { enappgyMetaDataServiceApi } from './metaDataApi'

import buildingsSlice from './slices/buildingsSlice'
import calendarSlice from './slices/calendarSlice'
import multisenseSlice from './slices/multisenseSlice'
import popoversSlice from './slices/popoversSlice'
import currentSessionSlice from './slices/currentSessionSlice'
import sideMenuSlice from './slices/sideMenuSlice'
import actionDialogSlice from './slices/actionDialogSlice'
import detailsDialogSlice  from './slices/detailsDialogSlice'
import adminPanelSlice from './slices/adminPanelSlice'
import snackSlice from './slices/snackSlice'
import alertsSlice from './slices/alertsSlice'
import  alertsSettingsSlice  from './slices/alertSettingsSlice'
import  alertsProSettingsSlice  from './slices/alertsProSettingsSlice'
import { enappgyRDMServiceApi } from './externalApi'
import { useDispatch, useSelector } from 'react-redux'
import  drawerSlice from './slices/mobileDrawer'

export const store = configureStore({
  reducer: {
    [enappgyDataServiceApi.reducerPath]: enappgyDataServiceApi.reducer,
    [enappgyRDMServiceApi.reducerPath]: enappgyRDMServiceApi.reducer,
    [enappgyMetaDataServiceApi.reducerPath]: enappgyMetaDataServiceApi.reducer,
    buildings: buildingsSlice,
    calendar: calendarSlice,
    multisense: multisenseSlice,
    popovers: popoversSlice,
    session: currentSessionSlice,
    sideMenu: sideMenuSlice,
    actionDialog: actionDialogSlice,
    detailsDialog: detailsDialogSlice,
    adminPanel: adminPanelSlice,
    snackBar: snackSlice,
    alerts: alertsSlice,
    alertsSettings: alertsSettingsSlice,
    alertsProSettings: alertsProSettingsSlice,
    drawer: drawerSlice,
  },

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      enappgyDataServiceApi.middleware,
      enappgyMetaDataServiceApi.middleware,
      enappgyRDMServiceApi.middleware,
    ),
})
// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

export  const useAppDispatch = ()=> useDispatch()
export const useAppSelector = ()=> useSelector()