import { Card, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DateBox } from "devextreme-react";

export const PartnerMainCard = styled(Card)(() => ({
  borderRadius: "24px !important",
  boxShadow: "none",
  marginBottom: "16px",
  backgroundColor: "transparent",
  height: "15vh !important",
}));

export const PartnerDataWrapper = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  columnGap: "0px",
  height: "100%",
  gap: theme.spacing(1),
  padding: theme.spacing(2),
}));

export const PartnerItemWrapper = styled(Grid)(() => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  height: "100%",
  flex: 1,
}));

export const PartnerCustomBox = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "flex-start",
  height: "100%",
  width: "100%",
  gap: theme.spacing(1),
  backgroundColor: "#ffffff",
  borderRadius: "15px",
  padding: theme.spacing(2),
  boxShadow: "inherit",
}));

export const PartnerCustomTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "center",
})(({ center }) => ({
  justifyContent: center ? "center" : "flex-start",
}));

export const StyledDateBox = styled(DateBox, {
  shouldForwardProp: (prop) => prop !== "error",
})(({ error }) => ({
  borderColor: error ? "red" : "red",
    color: error ? "red": "inherit",
  ".dx-texteditor-input-container": {
    borderColor: error ? "red" : "red",
    color: error ? "red": "inherit"
  },
}));
