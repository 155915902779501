import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppContainer, theme, RouterOutlet, CustomSnackBar, LoadingContainer, LottieStyled, LogoStyled } from "./App.styled";
import { useAuth0 } from "@auth0/auth0-react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Route, Routes } from "react-router-dom";
import { useGetBuildingByTenantQuery, useGetBuildingByUserIdQuery, useGetNotificationsQuery, useGetSensorTypesCatalogQuery, useGetUserMetaDataByIdQuery } from "./redux/metaDataApi";
import AppBar from "./components/AppBar/AppBar";
import SideMenu from "./components/SideMenu/SideMenu";
import { initCalendar } from "./redux/slices/calendarSlice";
import GuardedRoute from "./security/GuardedRoute";
import Settings from "./components/Settings/Settings";
import { initSession, setProfiles } from "./redux/slices/currentSessionSlice";
import ActionDialog from "./components/ActionDialog/ActionDialog";
import DetailsDialog from "./components/DetailsDialog/DetailsDialog";
import { IconButton, Slide, Snackbar } from "@mui/material";
import { closeSnack } from "./redux/slices/snackSlice";
import Account from "./components/Account/Account";
import { initBuildings } from "./redux/slices/buildingsSlice";
import Alerts from "./components/Alerts/Alerts";
import { filterNotifications, transformSensorTypes } from "./helpers/functions";
import { Constants } from "./helpers/constants";
import { openDrawer } from "./redux/slices/sideMenuSlice";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Cookies from "js-cookie";
import LogoTag from "./resources/images/Enappgy_Tag.png";
import ESGReports from "./components/Settings/ESGReports/ESGReports";
import AlertsSettings from "./components/Settings/AlertsSettings/AlertsSettings";
import AlertsLite from "./components/Alerts/AlertsLite/AlertsLite";
import AlertsPro from "./components/Alerts/AlertsPro/AlertsPro";
import { useSnackbar } from "notistack";
import { useWindowDimensions } from "./redux/customHooks/customHooks";
import MobileApp from "./mobileComponents/MobileApp";
import i18n from "./services/i18n";

let ModifiedConstants = { ...Constants };

function App() {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();
  const [localUserId, setLocalUserId] = useState(undefined);
  const { user, isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const open = useSelector((state) => state.snackBar.open);
  const message = useSelector((state) => state.snackBar.message);
  const type = useSelector((state) => state.snackBar.type);
  const profile = useSelector((state) => state.session.selectedProfile);
  const { sub: userId } = user || {};
  const { data: sensorCatalog } = useGetSensorTypesCatalogQuery();
  const openMenu = useSelector((state) => state.sideMenu.openDrawer);
  const openDialog = useSelector((state) => state.detailsDialog.open);
  const openActionDialog = useSelector((state) => state.actionDialog.open);
  const isAnyModalOpen = openMenu || openDialog || openActionDialog;

  const { data: userMetadata, isFetching: userMetadataFetch } = useGetUserMetaDataByIdQuery(localUserId, { skip: !localUserId });
  const { id: tenantId } = useSelector((state) => state.session.selectedTenant);
  const { data } = useGetBuildingByUserIdQuery({ userId: userMetadata?.id, tenantId: tenantId }, { skip: !tenantId });
  const { data: tenantBuildings, isFetching } = useGetBuildingByTenantQuery(tenantId, { skip: !tenantId });

  const { data: notifications } = useGetNotificationsQuery(tenantId, {
    skip: !tenantId,
  });

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.clear();
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [dispatch]);

  useEffect(() => {
    if (sensorCatalog) {
      ModifiedConstants = {
        ...ModifiedConstants,
        SENSOR_TYPE: transformSensorTypes(sensorCatalog),
      };
    }
  }, [sensorCatalog]);

  useEffect(() => {
    if (notifications && profile) {
      const { otherNotifications, newReleaseNotifications } = filterNotifications(notifications);
      const shownIds = Cookies.get("shownNewReleaseNotifications") ? JSON.parse(Cookies.get("shownNewReleaseNotifications")) : [];

      newReleaseNotifications?.forEach((item) => {
        enqueueSnackbar("", {
          variant: item.appNotificationType,
          preventDuplicate: true,
        });
        // Add the new ID to the list
        shownIds.push(item.id);
        // Update the cookie, including the new ID
        Cookies.set("shownNewReleaseNotifications", JSON.stringify(shownIds), {
          expires: 7,
        }); // Cookie expires in 7 days
      });
      otherNotifications?.forEach((item) => {
        enqueueSnackbar("", {
          variant: item.appNotificationType,
        });
      });
    }

  }, [enqueueSnackbar, notifications, profile]);

  useEffect(() => {
    if (userMetadata && !profile && !userMetadataFetch) {
      dispatch(initSession(userMetadata));
      i18n.changeLanguage(userMetadata.language);
    }
  }, [userMetadata, profile, userMetadataFetch]);

  useEffect(() => {
    if (data && data.length > 0 && tenantBuildings && !isFetching) {
      const buildings = data.map((structure) => ({
        ...structure.building,
        isDefault: structure.isDefault,
      }));
      const profile = data.map((structure) => ({
        ...structure.profile,
        isDefault: structure.isDefault,
      }));
      // Merge properties from buildings and tenantBuildings
      const mergedBuildings = tenantBuildings.map((tenantBuilding) => {
        // Find the corresponding building from 'buildings' and merge properties
        const buildingMatch = buildings.find((building) => building.id === tenantBuilding.id);
        if (buildingMatch) {
          return { ...tenantBuilding, isDefault: buildingMatch.isDefault };
        }
        return tenantBuilding;
      });

      // Filter the merged buildings to include only those in the buildings array
      const filteredTenantBuildings = mergedBuildings.filter((mergedBuilding) => buildings.some((building) => building.id === mergedBuilding.id));
      dispatch(setProfiles(profile));
      dispatch(initBuildings(filteredTenantBuildings));

      dispatch(initCalendar());
    }
  }, [data, dispatch, tenantBuildings, isFetching]);

  useEffect(() => {
    if (isAuthenticated) {
      setLocalUserId(userId);
    }
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
      Cookies.remove("drawerIndex");
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, dispatch, userId]);

  const handleDrawerOpen = () => {
    dispatch(openDrawer(true));
  };

  const handleCloseSnackBar = () => {
    dispatch(closeSnack());
  };

  if (isAuthenticated && width > 600) {
    return (
      <AppContainer container>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {profile ? (
            <>
              <SideMenu name={user.name} picture={user.picture} />
              {!openMenu && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerOpen}
                  sx={{
                    position: "fixed", // Changed from 'absolute' to 'fixed'
                    top: 25,
                    width: "20px",
                    height: "20px",
                    left: "105px",
                    backgroundColor: "white",
                    transform: "translateX(-40%)",
                    opacity: isAnyModalOpen ? 0 : 1,
                    transition: "opacity 0.5s ease-in-out",
                    zIndex: isAnyModalOpen ? -1 : 9999,
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                >
                  <ChevronRightIcon sx={{ fontSize: "16px" }} />
                </IconButton>
              )}
              <AppBar />
              <RouterOutlet open={openMenu}>
                <Routes>
                  {ModifiedConstants.MODULES.map((module, index) => (
                    <Route key={index} path={module.route} element={<GuardedRoute allowedModule={module.name}>{module.component}</GuardedRoute>}>
                      {module.subRoutes && (
                        <>
                          {module?.subRoutes?.map((subRoute, index) => {
                            if (index === 0) {
                              return <Route index key={subRoute.name} path={subRoute.route} element={<GuardedRoute allowedModule={subRoute.name}>{subRoute.component}</GuardedRoute>} />;
                            } else {
                              return <Route key={subRoute.name} path={subRoute.route} element={<GuardedRoute allowedModule={subRoute.name}>{subRoute.component}</GuardedRoute>} />;
                            }
                          })}
                        </>
                      )}
                    </Route>
                  ))}
                  <Route path="/account" element={<Account />} />
                  <Route path="/settings" element={<Settings />}>
                    <Route index path="esgReport" element={<GuardedRoute allowedModule={"ESGQuarterlyReports"}>{<ESGReports />}</GuardedRoute>} />
                    <Route path="alertsSettings" element={<GuardedRoute allowedModule={"MultisenseAlertsLite"}>{<AlertsSettings />}</GuardedRoute>} />
                  </Route>
                  <Route path="/alerts" element={<Alerts />}>
                    <Route index path="lite" element={<GuardedRoute allowedModule={"MultisenseAlertsLite"}>{<AlertsLite />}</GuardedRoute>} />
                    <Route path="pro" element={<GuardedRoute allowedModule={"MultisenseAlertsPro"}>{<AlertsPro />}</GuardedRoute>} />
                  </Route>
                </Routes>
              </RouterOutlet>
              <ActionDialog />
              <Snackbar
                open={open}
                autoHideDuration={1500}
                onClose={handleCloseSnackBar}
                TransitionComponent={(props) => <Slide {...props} direction="down" />}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <CustomSnackBar severity={type}>{message}</CustomSnackBar>
              </Snackbar>
              <DetailsDialog />
            </>
          ) : (
            <LoadingContainer>
              <LottieStyled animationData={ModifiedConstants.FIRST_LOADER.animationData} loop={true} />
              <LogoStyled src={LogoTag} alt="logo" />
            </LoadingContainer>
          )}
        </ThemeProvider>
      </AppContainer>
    );
  } else if (isAuthenticated && width < 600) {
    return (
      <>
        {profile ? (
          <>
            <MobileApp />
            <ActionDialog />
            <Snackbar
              open={open}
              autoHideDuration={1500}
              onClose={handleCloseSnackBar}
              TransitionComponent={(props) => <Slide {...props} direction="down" />}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <CustomSnackBar severity={type}>{message}</CustomSnackBar>
            </Snackbar>
            <DetailsDialog />
          </>
        ) : (
          <LoadingContainer>
            <LottieStyled animationData={ModifiedConstants.FIRST_LOADER.animationData} loop={true} />
            <LogoStyled src={LogoTag} alt="logo" />
          </LoadingContainer>
        )}
      </>
    );
  } else {
    return null;
  }
}

export default App;

export { ModifiedConstants };
