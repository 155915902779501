import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  type: null,
  size: "md",
  seriesIndex: null,
};

export const actionDialogSlice = createSlice({
  name: "actionDialog",
  initialState,
  reducers: {
    openActionDialog: (state) => {
      state.open = true;
    },
    changeActionDialogType: (state, action) => {
      state.type = action.payload;
    },
    openEditSeries: (state, action) => {
      state.open = true;
      state.type = "addSeries";
      state.seriesIndex = action.payload;
    },
    openNewSeries: (state) => {
      state.open = true;
      state.type = "addSeries";
      state.seriesIndex = undefined;
    },
    closeActionDialog: (state) => {
      state.open = false;
      state.type = null;
    },
  },
});

export const { openActionDialog, changeActionDialogType, closeActionDialog,openEditSeries, openNewSeries } = actionDialogSlice.actions;

export default actionDialogSlice.reducer;
