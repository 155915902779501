import React from "react";
import { AdditionalInfo, CostCard, StyledNumbers } from "../Costs.styled";
import { CardHeader, CircularProgress, Grid, Typography } from "@mui/material";
import { LinearGauge } from "devextreme-react";
import {
  MinorTick,
  Range,
  RangeContainer,
  ValueIndicator,
  SubvalueIndicator,
  Scale,
  Tooltip
} from "devextreme-react/linear-gauge";
import { formatNumberCustom, getSensorUnit } from "../../../helpers/functions";
import { useTranslation } from "react-i18next";
import { LoadingOverlay } from "../TotalSavings/TotalSavings.styled";
import { useCurrencySymbol } from "../../../redux/customHooks/customHooks";
import { t } from "i18next";

const calculateScale = (value) => {
  if (value === 0) {
    return { startValue: 0, endValue: 1, tickInterval: 0.2 };
  }
  const magnitude = Math.floor(Math.log10(value));
  const endValue = Math.pow(10, magnitude + 1);
  const tickInterval = endValue / 5;

  return { startValue: 0, endValue, tickInterval };
};

export default function CostBenchmark(props) {
  const { t } = useTranslation();
  const currency = useCurrencySymbol();
  const sensorType = props?.sensorType || "";
  const bucketTimeWindow = props?.bucketTimeWindow || "";
  const consumptionPerM2 = props?.consumptionPerM2 || 0;
  const costPerM2 = props?.costPerM2 || 0;
  const benchmarkConsumptionPerM2 = props?.benchmarkConsumptionPerM2 || 0;
  const benchmarkCostPerM2 = props?.benchmarkCostPerM2 || 0;
  const isFetching = props?.isFetching || false;

  const subValues = [0, benchmarkConsumptionPerM2];
  const scale = calculateScale(benchmarkConsumptionPerM2);

  function customizeTooltip(arg) {
    let result = `${formatNumberCustom(arg.value, false, 2, 4)} ${getSensorUnit(
      sensorType
    )}/m² per ${bucketTimeWindow.toLowerCase()}`;
    if (arg.index >= 0) {
      result = `${t("costs.benchmark")} ${result}`;
    } else {
      result = `${t("costs.consumption")}: ${result}`;
    }
    return {
      text: result
    };
  }

  return (
    <CostCard variant="outlined">
      <CardHeader title={t("costs.benchmark")} />
      {isFetching ? (
        <LoadingOverlay>
          <CircularProgress />
        </LoadingOverlay>
      ) : (
        <Grid
          container
          sx={{ justifyContent: "space-between", alignContent: "space-between", height: "100%" }}
        >
          <Grid item container>
            <Grid
              item
              xs
              sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
              gap={1}
            >
              <StyledNumbers>
                {currency}
                {formatNumberCustom(costPerM2, false, 2, 4)}
              </StyledNumbers>
              <Typography variant="p">
                {t("costs.perm2", { bucket: bucketTimeWindow.toLowerCase() })}
              </Typography>
              <AdditionalInfo>
                {formatNumberCustom(consumptionPerM2, false, 2, 4)} {getSensorUnit(sensorType)}
              </AdditionalInfo>
            </Grid>

            <Grid
              item
              xs
              sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
              gap={1}
            >
              <StyledNumbers>
                {currency}
                {formatNumberCustom(benchmarkCostPerM2, false, 2, 4)}
              </StyledNumbers>
              <Typography variant="p">
                {t("costs.permBenchmark", { bucket: bucketTimeWindow.toLowerCase() })}
              </Typography>
              <AdditionalInfo>
                {formatNumberCustom(benchmarkConsumptionPerM2, 2, 4)} {getSensorUnit(sensorType)}
              </AdditionalInfo>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid
              item
              xs
              sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
              gap={0.5}
            >
              <LinearGauge
                style={{ width: "100%" }}
                id="linearGauge"
                value={consumptionPerM2}
                subvalues={subValues}
              >
                <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
                <Scale
                  startValue={scale.startValue}
                  endValue={scale.endValue}
                  tickInterval={scale.tickInterval}
                >
                  <MinorTick visible={true} />
                </Scale>
                <SubvalueIndicator
                  type="rangeBar"
                  color="rgba(55, 180, 0, 0.2)"
                  size={48}
                  offset={0}
                />
                <ValueIndicator type="rangeBar" color="#28B4C8" size={28} />
                <RangeContainer offset={10} palette={"soft"} width={28}>
                  <Range
                    startValue={0}
                    endValue={scale.endValue}
                    color="rgba(40, 180, 200, 0.20)"
                  />
                </RangeContainer>
              </LinearGauge>
              <Typography variant="p" sx={{ alignSelf: "flex-end", textAlign: "right" }}>
                {t("costs.costsBenchmark", {
                  unit: getSensorUnit(sensorType),
                  bucket: bucketTimeWindow.toLowerCase()
                })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </CostCard>
  );
}
