import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AlertCard from "./AlertCard/AlertCard";
import { StyledButton } from "../../App.styled";
import { useGetConditionalAlertsByBuildingQuery } from "../../redux/metaDataApi";
import { add, format, startOfMonth } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { closePopover } from "../../redux/slices/popoversSlice";
import { t } from "i18next";
import {
  changeActionDialogType,
  openActionDialog,
} from "../../redux/slices/actionDialogSlice";

export default function AlertsNotification() {
  const dispatch = useDispatch();
  const currentDate = new Date();
  const navigate = useNavigate();
  const beginningOfLastMonth = startOfMonth(currentDate);
  const profile = useSelector((state) => state.session.selectedProfile);
  const [moduleArray, setModuleArray] = useState([]);
  const [filterAlertsBy, setFilterAlertsBy] = useState("DeviationAlert");
  const [filteredAlerts, setFlitteredAlerts] = useState();
  const selectedBuilding = useSelector(
    (state) => state.buildings.selectedBuilding
  );

  useEffect(() => {
    if (profile) {
      const modules = profile.profileModules?.map((item) => item.module) || [];
      setModuleArray(modules);
    }
  }, [profile]);
  const filter = {
    buildingId: selectedBuilding.id,
    from: format(beginningOfLastMonth, "yyyy-MM-dd"),
    to: format(add(currentDate, { days: 1 }), "yyyy-MM-dd"),
  };
  const { data: alerts } = useGetConditionalAlertsByBuildingQuery(filter, {
    skip: !selectedBuilding,
  });

  const handleChangeType = (type) => {
    if (type === "pro") {
      if (moduleArray.includes("MultisenseAlertsPro")) {
        setFilterAlertsBy("PerformanceAlert");
      } else {
        dispatch(changeActionDialogType("upgradeAlerts"));
        dispatch(openActionDialog());
      }
    } else {
      setFilterAlertsBy("DeviationAlert");
    }
  };

  useEffect(() => {
    setFlitteredAlerts(
      alerts?.filter(
        (item) =>
          item.conditionalAlertType === filterAlertsBy &&
          item.conditionalAlertStatus === "Active"
      )
    );
  }, [filterAlertsBy, alerts]);

  return (
    alerts && (
      <Grid
        container
        sx={{ height: "100%", width: "350px" }}
        flexDirection="column"
        spacing={2}
      >
        <Grid item>
          <Typography variant="h3">{t("alerts.alerts")}</Typography>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={2} sx={{ justifyContent: "center" }}>
            <StyledButton
              variant="contained"
              disableElevation
              selected={filterAlertsBy === "DeviationAlert"}
              onClick={() => handleChangeType("lite")}
            >
              LITE
            </StyledButton>
            <StyledButton
              variant="contained"
              disableElevation
              selected={filterAlertsBy === "PerformanceAlert"}
              onClick={() => handleChangeType("pro")}
            >
              PRO
            </StyledButton>
          </Stack>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        {filteredAlerts?.length > 0 ? (
          <Box
            item
            container
            sx={{
              height: "400px",
              overflow: "auto",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
            flexDirection={"column"}
          >
            {filteredAlerts?.map((alert, index) => (
              <Grid item key={index} sx={{ marginTop: "8px", marginLeft: "16px" }}>
                <AlertCard alert={alert} />
              </Grid>
            ))}
          </Box>
        ) : (
          <Grid
            item
            container
            sx={{
              height: "400px",
              overflowY: "auto",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item>
              <Typography>{t("alerts.noFound")}</Typography>
            </Grid>
          </Grid>
        )}
        <Grid item sx={{ display: "flex", justifyContent: "flex-end" }}>
          <StyledButton
            variant="contained"
            disableElevation
            selected
            onClick={() => {
              dispatch(closePopover());
              navigate("/alerts");
            }}
          >
            {t("button.viewAlerts")}
          </StyledButton>
        </Grid>
      </Grid>
    )
  );
}