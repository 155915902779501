import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { toggleDrawer } from "../../redux/slices/mobileDrawer";
import DataSelection from "./DataSelection";
import LocationSelection from "./LocationSelection";
import BuildingSelection from "./BuildingSelection";

export default function AnchorTemporaryDrawer() {
  const open = useSelector((state) => state.drawer.open);
  const type = useSelector((state) => state.drawer.type);
  const dispatch = useDispatch();

  const handleClose = () => dispatch(toggleDrawer());

  return (
      <Drawer anchor={"bottom"} open={open} onClose={handleClose} sx={{".MuiDrawer-paper":{
        maxHeight: "40vh !important"
      }}}>
        {
          {
            data: <DataSelection />,
            location: <LocationSelection/>,
            building: <BuildingSelection/>,
          }[type]
        }
      </Drawer>
  );
}
