import { Typography } from "@mui/material";
import React from "react";
import { format, getYear, parse, setYear } from "date-fns";

export const TooltipTemplate = (
  endDateSelected,
  info,
  t,
  prefix,
  suffix,
  series,
  formatNumberCustom,
  specificYear
) => {
  const formatArgumentText = (argumentText, specificYear) => {
    // Regex to match time-only format, e.g., "13:00"
    const timeOnlyRegex = /^\d{2}:\d{2}$/;
    // Regex to match day in the format "22"
    const dayOnlyRegex = /^\d{1,2}$/;
    // Regex to match date in the format "22 Jan"
    const dayMonthRegex = /^\d{1,2}\s\w{3}$/;
    // Regex to match date in the format "Jan 2024"
    const monthYearRegex = /^\w{3}\s\d{4}$/;
    // Regex to match date in the format "22 Jan 2024"
    const fullDateRegex = /^\d{1,2}\s\w{3}\s\d{4}$/;

    if (timeOnlyRegex.test(argumentText)) {
      if (endDateSelected) {
        let date = parse(endDateSelected, "yyyy-MM-dd", new Date());
        if (specificYear) {
          date = setYear(date, specificYear);
        }
        const dateFormatted = format(date, "MMM d, yyyy");
        return `${dateFormatted} ${argumentText}`;
      } else if (fullDateRegex.test(info.seriesName)) {
        const date = parse(info.seriesName, "dd MMM yyyy", new Date());
        const dateFormatted = format(date, "MMM d, yyyy");
        return `${dateFormatted} ${argumentText}`;
      }
    } else if (dayMonthRegex.test(argumentText)) {
      // Assuming the year is the current year for simplicity
      const year = specificYear ? specificYear : getYear(new Date());
      const date = parse(`${argumentText} ${year}`, "d MMM yyyy", new Date());
      return format(date, "MMM d, yyyy");
    } else if (dayOnlyRegex.test(argumentText) && monthYearRegex.test(info.seriesName)) {
      const date = parse(`${argumentText} ${info.seriesName}`, "dd MMM yyyy", new Date());
      return format(date, "MMM d, yyyy");
    }
    // If argumentText already includes the date or does not match known formats, return it unchanged
    return argumentText;
  };

  const formattedArgumentText = formatArgumentText(info.argumentText, specificYear);

  return info.seriesName !== "Alerts" && series === undefined ? (
    <div>
      <Typography variant="h2">{formattedArgumentText}</Typography>
      <div>
        <Typography variant="p">
          {info.seriesName}: {prefix && `${prefix} `}
          {info.value}
          {suffix && ` ${suffix}`}
        </Typography>
      </div>
    </div>
  ) : series !== undefined ? (
    <div>
      <Typography variant="h2">{formattedArgumentText}</Typography>
      <div>
        <Typography variant="p">
          {t(`sensor.${series.sensor}`)}: {prefix && `${prefix} `}
          {formatNumberCustom(info.value, false, 2, 4)}
          {suffix && ` ${suffix}`}
        </Typography>
      </div>
      <div>
        <Typography variant="p">
          {t(`chart.location`)}: {series.location}
        </Typography>
      </div>
    </div>
  ) : (
    <div>
      <div>
        <Typography variant="h2">{t("multisense.tooltip.alert")}</Typography>
      </div>
      <div>
        <Typography variant="p">{formattedArgumentText}</Typography>
        <Typography variant="p">
          {info.seriesName}: {formatNumberCustom(info.value, false, 2, 4)}{" "}
        </Typography>
      </div>
    </div>
  );
};
