import { useState, useEffect } from "react";
import { CustomCard, StyledSelect } from "../../../App.styled";
import { Grid, MenuItem, SvgIcon, Typography } from "@mui/material";
import {
  useGetESGReportsByBuildingIdQuery,
  useGetESGReportsQuery
} from "../../../redux/metaDataApi";
import { useSelector } from "react-redux";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfReport from "./PdfReport";
import { useTranslation } from "react-i18next";

export default function ESGReports() {
  const { t } = useTranslation();
  const selectedBuilding = useSelector((state) => state.buildings.selectedBuilding);
  const [year, setYear] = useState("");

  const buildingFilter = {
    buildingId: selectedBuilding.id
  };

  let buildingYearFilter = {
    buildingId: selectedBuilding.id,
    year: year
  };

  const { data: allReports } = useGetESGReportsByBuildingIdQuery(buildingFilter, {
    skip: !selectedBuilding
  });

  const { data: reports } = useGetESGReportsQuery(buildingYearFilter, {
    skip: !selectedBuilding && !buildingFilter.year
  });

  const sortArray = (reports) => {
    const options = [...reports];
    options.sort((a, b) => {
      if (a.year === b.year) {
        return a.quarter - b.quarter; // Sort by quarter if the year is the same
      }
      return a.year - b.year; // Otherwise, sort by year
    });
    return options;
  };

  const handleChange = (event) => {
    if (event.target.value !== "") {
      setYear(event.target.value);
      buildingYearFilter = {
        buildingId: selectedBuilding.id,
        year: event.target.value
      };
    }
  };

  useEffect(() => {
    const minYear = Math.min(
      ...(allReports?.length ? allReports.map((item) => item.year) : [Infinity])
    );
    if (minYear !== Infinity) {
      setYear(minYear.toString());
    }
  }, [allReports]);

  return (
    <CustomCard>
      <Grid
        container
        direction="column"
        sx={{ height: "100%" }}
        alignContent="flex-start"
        justifyContent="flex-start"
        padding={2}
        spacing={2}
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{ height: "auto" }}
        >
          <Grid item></Grid>
          <Grid item>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                {allReports && (
                  <StyledSelect
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={year}
                    onChange={handleChange}
                  >
                    {[...new Set(allReports?.map((item) => item.year))].map((uniqueYear, index) => (
                      <MenuItem key={index} value={uniqueYear}>
                        {uniqueYear}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {reports?.length > 0 ? (
          <Grid item container flexGrow={1} alignItems="flex-start">
            {sortArray(reports)?.map((report, index) => (
              <PDFDownloadLink
                key={index}
                document={<PdfReport report={report} />}
                fileName={`${report.year}- Quarter ${report.quarter}.pdf`}
                style={{
                  textDecoration: "none",
                  padding: "10px",
                  color: "#4a4a4a"
                }}
              >
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <SvgIcon>
                      <DocumentArrowDownIcon />
                    </SvgIcon>
                  </Grid>
                  <Grid item>
                    {report.year}- {t(`reports.quarter`)} {report.quarter}
                  </Grid>
                </Grid>
              </PDFDownloadLink>
            ))}
          </Grid>
        ) : (
          <Grid item container flexGrow={1} alignItems="center" justifyContent="center">
            <Grid item>
              <Typography variant="h2">{t(`reports.noReports`)}</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </CustomCard>
  );
}
