import React, { useState } from "react";
import { Box, Grid, Typography, IconButton, Badge, Menu, MenuItem, Tooltip, Switch } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { changeDetailsDialogType, openDetailsDialog } from "../../../redux/slices/detailsDialogSlice";
import Avatar from "@mui/material/Avatar";
import TuneIcon from "@mui/icons-material/Tune";
import AddchartIcon from "@mui/icons-material/Addchart";
import { Constants } from "../../../helpers/constants";
import { changeAxisDisplay, handleCustomBuckets } from "../../../redux/slices/multisenseSlice";
import Favorites from "../Favorites/Favorites";

export default function ChartTitle() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { calendarSelection } = useSelector((state) => state.calendar);
  const { selectedSeries } = useSelector((state) => state.multisense.selectedValues);
  const customBucket = useSelector((state) => state.multisense.customBucket);
  const displayAxis = useSelector((state) => state.multisense.displayAxis);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (e) => {
    if (e.target.checked) {
      dispatch(handleCustomBuckets(Constants.BUCKET_TIME.MINUTE));
    } else {
      dispatch(handleCustomBuckets(""));
    }
  };
  const toggleAxis = (e) => {
    dispatch(changeAxisDisplay(!displayAxis));
  };

  return (
    <Grid container padding={2} flexDirection="row" alignItems="center">
      <Grid item container flexDirection="row" xs={6} alignItems="flex-start">
        <Box display="flex" flexDirection="row">
          {selectedSeries?.map(
            (item, index, array) =>
              item.seriesName && (
                <Typography key={index} variant="h3" noWrap sx={{ width: "fit-content", paddingInline: "2px" }}>
                  {item.seriesName}
                  {item.seriesName && array[index + 1] ? ", " : " "}
                </Typography>
              )
          )}
          <Typography variant="p" noWrap sx={{ width: "fit-content", paddingInline: "2px" }}>
            {t(`multisense.sense-title.in`)}
          </Typography>
          <Typography variant="h3" noWrap sx={{ width: "fit-content", paddingInline: "2px" }}>
            {t(`multisense.sense-title.${calendarSelection.selectedPeriod}`)}
          </Typography>
        </Box>
      </Grid>
      <Grid item container xs={6} spacing={2} flexDirection="row" justifyContent="flex-end" alignItems="flex-end">
        <Grid item justifyContent={"center"} alignContent={"center"}>
          <div style={{ cursor: "pointer", width: "65px", backgroundColor: "#CCCCCC", borderRadius: "50px", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Favorites />
            <Badge badgeContent={selectedSeries?.length} color="primary">
              <Tooltip title={t("tooltip.viewSeries")}>
                <Avatar
                  onClick={() => {
                    dispatch(openDetailsDialog());
                    dispatch(changeDetailsDialogType("addSeries"));
                  }}
                >
                  <AddchartIcon />
                </Avatar>
              </Tooltip>
            </Badge>
          </div>
        </Grid>

        <Grid item justifyContent={"center"} alignContent={"center"}>
          <Tooltip title="Chart settings">
            <IconButton id="basic-button" aria-controls={open ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
              <TuneIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleClose}>
              <Typography variant="p">
                <Switch checked={customBucket === Constants.BUCKET_TIME.MINUTE ? true : false} onChange={handleChange} />
                {t("chart.showDetail")}
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Typography variant="p">
                <Switch checked={displayAxis} onChange={toggleAxis} />
                {t("multisense.sense-title.hideY")}
              </Typography>
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </Grid>
  );
}
