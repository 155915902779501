import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { differenceInDays } from "date-fns";
import { formatMultisenseSeries } from "../../../helpers/multisenseFunctions";
import { formatNumberCustom, getSensorUnit } from "../../../helpers/functions";
import { CustomChart } from "../../DataChart/DataChart.styled";
import {
  ArgumentAxis,
  CommonAxisSettings,
  Grid,
  Label,
  Legend,
  Point,
  Series,
  Tick,
  ValueAxis,
  Tooltip,
  Crosshair,
  HorizontalLine,
  VerticalLine
} from "devextreme-react/chart";
import { TooltipTemplate } from "../../DataChart/TooltipTemplate";
import { t } from "i18next";

const MultisenseChart = (props) => {
  let lastDisplayedDate = "";
  const [chartData, setChartData] = useState([]);
  const { indexSelected } = useSelector((state) => state.sideMenu);
  const calendarSelection = useSelector((state) => state.calendar.calendarSelection);
  const [isReady, setIsReady] = useState(false);
  const [architectureSources, setArchitectureSources] = useState([]);
  const [uniqueUnits, setUniqueUnits] = useState([]);
  const displayAxis = useSelector((state) => state.multisense.displayAxis);

  useLayoutEffect(() => {
    setIsReady(false);
    const fromDate = new Date(calendarSelection.dateStart);
    const toDate = new Date(calendarSelection.dateEnd);
    const daysDifference = differenceInDays(toDate, fromDate);
    const { unitToSensorMap, architectureSources, consolidatedData } = formatMultisenseSeries(
      props.bucket,
      daysDifference,
      props.selectedSensor,
      ...props.allSeries
    );

    setUniqueUnits(unitToSensorMap);
    setArchitectureSources(architectureSources);
    setChartData(consolidatedData);
    setIsReady(true);
  }, [props.allSeries, calendarSelection, props.bucket, props.selectedSensor]);

  const colorPalette = ["#1C7AD4", "#aa46bE", "#28b4c8", "#78d237", "#ffd246"];

  function onLegendClick(e) {
    e.target.isVisible() ? e.target.hide() : e.target.show();
  }

  let displayUnit = "";

  return (
    <>
      {isReady && (
        <CustomChart
          onLegendClick={onLegendClick}
          dataSource={chartData}
          id="chart"
          palette={colorPalette}
          resolveLabelOverlapping="hide"
          hideDetail={indexSelected !== "Multisense" ? true : false}
        >
          <CommonAxisSettings>
            <Grid visible={false} color="#F0F0F0" />
            <Tick visible={false} color="#F0F0F0" />
          </CommonAxisSettings>
          {uniqueUnits?.map((unit, index) => (
            <ValueAxis
              key={`valueAxis-${index}`}
              name={`valueAxis-${unit.unit}`}
              color="#F0F0F0"
              position={index % 2 === 0 ? "left" : "right"}
            >
              <Grid visible={true} color="#F0F0F0" />
              <Label
                visible={!displayAxis}
                customizeText={(arg) => {
                  displayUnit = unit.unit;
                  return `${formatNumberCustom(arg.value, false, 2, 4)} ${displayUnit}`;
                }}
              />
            </ValueAxis>
          ))}
          <ArgumentAxis color="#F0F0F0">
            <Label
              visible={true}
              customizeText={(arg) => {
                const dateParts = arg.value.split(" ");
                let currentDate = "";
                if (dateParts.length === 3) {
                  currentDate = `${dateParts[0]} ${dateParts[1]}`;
                } else if (dateParts.length === 2) {
                  currentDate = arg.value.includes(":")
                    ? `${dateParts[0]}:${dateParts[1]}`
                    : `${dateParts[0]} ${dateParts[1]}`;
                } else {
                  currentDate = arg.value;
                }

                // Check if the current date is different from the last displayed date
                if (currentDate !== lastDisplayedDate) {
                  lastDisplayedDate = currentDate;
                  return currentDate;
                } else {
                  return "";
                }
              }}
            />
            <Tick visible={false} color="#F0F0F0" />
          </ArgumentAxis>
          {architectureSources?.map((item, index) => {
            const unit = getSensorUnit(item.value);
            return (
              <Series
                key={index}
                valueField={item.name}
                axis={`valueAxis-${unit}`}
                name={item.name}
                color={item.color}
                argumentField="date"
                type={item.chartType}
              >
                <Point visible={false} />
              </Series>
            );
          })}
          {props.allSeries.length === 1 && (
            <Crosshair enabled={true} width={2} color="#C6C6C6" dashStyle="dash">
              <HorizontalLine>
                <Label
                  visible={true}
                  customizeText={(info) =>
                    `${formatNumberCustom(info.value, false, 2, 4)} ${displayUnit}`
                  }
                />
              </HorizontalLine>
              <VerticalLine>
                <Label visible={true} customizeText={(info) => info.valueText} />
              </VerticalLine>
            </Crosshair>
          )}
          <Tooltip
            enabled={true}
            contentRender={(info) => {
              const nameSeries = info.seriesName;
              let unitSensor;

              props.allSeries?.some((array) => {
                unitSensor = array?.find((item) => item.name === nameSeries);
                return unitSensor; // Exit the loop if unitSensor is found
              });

              return TooltipTemplate(
                calendarSelection.dateEnd,
                info,
                t,
                null,
                getSensorUnit(unitSensor?.sensor),
                unitSensor,
                formatNumberCustom,
                null
              );
            }}
          />
          <Legend verticalAlignment="bottom" horizontalAlignment="center" />
        </CustomChart>
      )}
    </>
  );
};

export default React.memo(MultisenseChart);
