import React from 'react'
import impact from '../../resources/images/Impact.png'
export default function Impact() {
  return (
    <div><img
    src={impact}
    alt="impact"
    style={{ width: "100%", height: "calc(100vh - 118px)", objectFit: "contain" }}
  /></div>
  )
}
