import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  open: false,
  type: null,
  size: 'md',
}

export const detailsDialogSlice = createSlice({
  name: 'detailsDialog',
  initialState,
  reducers: {
    openDetailsDialog: (state) => {
      state.open = true
    },
    changeDetailsDialogType: (state, action) => {
      state.type = action.payload
    },
    closeDetailsDialog: (state) => {
      state.open = false
      state.type = null
    },
  },
})

export const {
    openDetailsDialog,
    changeDetailsDialogType,
    closeDetailsDialog,
} = detailsDialogSlice.actions

export default detailsDialogSlice.reducer
