import {
    Box,
    DialogActions,
    DialogTitle,
    Grid,
    Grow,
    IconButton,
    SvgIcon,
    Typography,
    Autocomplete,
    TextField,
    Switch,
  } from '@mui/material'
  import React, { useState } from 'react'
  import {
    ActionsGrid,
    ContentGrid,
    CustomAutocomplete,
    CustomCircularProgress,
    CustomDialogContent,
    CustomTextField,
    TextFieldGrid,
    TitleGrid,
  } from './Forms.styled'
  import { StyledButton } from '../../../App.styled'
  import { XMarkIcon } from '@heroicons/react/24/outline'
  import { closeActionDialog } from '../../../redux/slices/actionDialogSlice'
  import {
    useGetProfilesByBuildingIdQuery,
    useUpdateUserBuildingMutation,
  } from '../../../redux/metaDataApi'
  import { useDispatch, useSelector } from 'react-redux'
import { openSnack } from '../../../redux/slices/snackSlice'
import { t } from 'i18next'
  
  export default function EditUserBuildingForm() {
    const dispatch = useDispatch()
    const userSelected = useSelector((state) => state.adminPanel.userSelected)
    const buildingSelected = useSelector((state) => state.adminPanel.buildingSelected)
    const [updateUserBuilding, { isLoading }] = useUpdateUserBuildingMutation()
    const [selectedProfile, setSelectedProfile] = useState(null)
    const [isDefault, setIsDefault] = useState(buildingSelected.isDefault)
    const { data: profiles } = useGetProfilesByBuildingIdQuery(
        buildingSelected?.id,
      {
        skip: !buildingSelected,
      },
    )
  
    const closeForm = () => {
      dispatch(closeActionDialog())
    }
  
    const handleSwitchChange = (event) => {
      setIsDefault(event.target.checked)
    }

    const handleConfirm  = async () => {
      const payload = {
        userId: userSelected.id,
        buildingId: buildingSelected.id,
        profileId: selectedProfile.id,
        isDefault:isDefault,
      }
  
      await updateUserBuilding(payload)
        .unwrap()
        .then(() => {
          dispatch(openSnack({ message: 'User buildings updated', type: 'success' }))
          dispatch(closeActionDialog())
        }
        )
        .catch((error) => {
          dispatch(
            openSnack({ message: 'User buildings could not be updated', type: 'error' }),
          )
          console.log(`Error:${error}`)
        })
    }
  
    const handleProfileSelection = (event, newValue) => {
      setSelectedProfile(newValue)
    }

    return (
        profiles && (
        <>
          <DialogTitle>
            <TitleGrid container>
              <Grid item xs>
                <Grow in timeout={800}>
                  <Typography variant="h3">Edit Building</Typography>
                </Grow>
              </Grid>
              <Grid item xs textAlign="right">
                <IconButton onClick={closeForm}>
                  <SvgIcon>
                    <XMarkIcon />
                  </SvgIcon>
                </IconButton>
              </Grid>
            </TitleGrid>
          </DialogTitle>
          <CustomDialogContent>
            <ContentGrid container rowGap={2}>
              <TextFieldGrid item xs={12} md={12}>
                <Typography variant="p">Buildings</Typography>
                <CustomTextField
                  disabled
                  value={buildingSelected.description}
                />
              </TextFieldGrid>
              <TextFieldGrid item xs={12} md={12}>
              <Typography variant="p">{t("form.setDefault")} </Typography>
              <Switch
                checked={isDefault} // Controlled component
                onChange={handleSwitchChange}
              />
            </TextFieldGrid>
              {profiles && (
                <TextFieldGrid item xs={12} md={12}>
                  <Typography variant="p">Profiles</Typography>
                  <CustomAutocomplete
                    options={profiles}
                    getOptionLabel={(option) => option.description}
                    value={selectedProfile}
                    onChange={handleProfileSelection}
                    renderInput={(params) => <CustomTextField {...params} />}
                  />
                </TextFieldGrid>
              )}
            </ContentGrid>
          </CustomDialogContent>
          <DialogActions>
            <ActionsGrid container>
              <StyledButton onClick={closeForm}> Cancel</StyledButton>
              <Box sx={{ position: 'relative' }}>
                <StyledButton
                  selected
                  onClick={handleConfirm }
                  disabled={isLoading}
                >
                  Apply
                </StyledButton>
                {isLoading && <CustomCircularProgress size={24} />}
              </Box>
            </ActionsGrid>
          </DialogActions>
        </>
      )
    )
  }
  