import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { Auth0Provider } from "@auth0/auth0-react";
import i18n from "./services/i18n";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { Maintenance, NewRelease, ServiceDown } from "./components/Notifications/NewRelease";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SnackbarProvider
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      Components={{
        NewRelease: NewRelease,
        Maintenance : Maintenance,
        ServiceDown: ServiceDown
      }}
      maxSnack={3}
    >
      <BrowserRouter>
        <Auth0Provider
          domain={process.env.REACT_APP_DOMAIN}
          clientId={process.env.REACT_APP_CLIENT_ID}
          authorizationParams={{
            redirect_uri: window.location.origin,
          }}
        >
          <Provider store={store}>
            <I18nextProvider i18n={i18n}>
              <App />
            </I18nextProvider>
          </Provider>
        </Auth0Provider>
      </BrowserRouter>
    </SnackbarProvider>
  </React.StrictMode>
);
