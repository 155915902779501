import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  useGetDataConsumptionByFilterQuery,
  useLazyGetDataByFilterQuery
} from "../../redux/dataApi";
import { useDispatch, useSelector } from "react-redux";
import { getBucketTimeDynamically, getFilter } from "../../helpers/functions";
import ChartTitle from "./ChartTitle/ChartTitle";
import { CustomCard } from "../../App.styled";
import ConsolidatedData from "./ConsolidatedData/ConsolidatedData";
import { initMultisense } from "../../redux/slices/multisenseSlice";
import { Box, CircularProgress, Grid } from "@mui/material";
import { getAverage, getMaxValue, getSum } from "../../helpers/multisenseFunctions";
import { t } from "i18next";
import MultisenseChart from "./MultisenseChart/MultisenseChart";

export default function Multisense() {
  const dispatch = useDispatch();
  const [loadingTimeExceeded, setLoadingTimeExceeded] = useState(false);
  const customBuckets = useSelector((state) => state.multisense.customBucket);
  const selectedBuilding = useSelector((state) => state.buildings.selectedBuilding);
  const { buildings } = useSelector((state) => state.buildings);
  const { id: tenantId } = useSelector((state) => state.session.selectedTenant);
  const calendarSelection = useSelector((state) => state.calendar.calendarSelection);
  const {
    selectedSensor,
    selectedLocation,
    selectedLocationIds,
    selectedLocationLevel,
    selectedSeries
  } = useSelector((state) => state.multisense.selectedValues);
  const [getData] = useLazyGetDataByFilterQuery();
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [seriesQueries, setSeriesQueries] = useState([]);
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    if (!initialRender) {
      const fetchData = async () => {
        setIsFetchingData(true);
        const queries = [];
        for (let i = 0; i < Math.min(selectedSeries?.length, 5); i++) {
          const query = await addNewSeries({
            filter: getFilter(
              selectedSeries[i]?.sensor,
              calendarSelection,
              selectedSeries[i]?.locationLevel,
              customBuckets
            ),
            locationId: selectedSeries[i]?.locationId,
            tenantId
          });
          queries.push(
            query?.map((item) => ({
              ...item,
              name: selectedSeries[i].seriesName,
              sensor: selectedSeries[i]?.sensor,
              location: selectedSeries[i]?.location,
              color: selectedSeries[i]?.color,
              type: selectedSeries[i]?.type
            }))
          );
        }

        setSeriesQueries(queries);
        setIsFetchingData(false);
      };

      if (selectedSeries && selectedSeries.length > 0) {
        fetchData();
      } else {
        setSeriesQueries([]);
      }
    }
  }, [selectedSeries, calendarSelection, tenantId, customBuckets, initialRender]);

  useEffect(() => {
    const firstData = async () => {
      setIsFetchingData(true);
      const queries = [];
      const query = await addNewSeries({
        filter: getFilter(
          selectedSeries[0]?.sensor,
          calendarSelection,
          selectedSeries[0]?.locationLevel,
          customBuckets,
          false
        ),
        locationId: selectedSeries[0]?.locationId,
        tenantId
      });
      queries.push(
        query?.map((item) => ({
          ...item,
          name: selectedSeries[0].seriesName,
          sensor: selectedSeries[0]?.sensor,
          location: selectedSeries[0]?.location,
          color: selectedSeries[0]?.color,
          type: selectedSeries[0]?.type
        }))
      );
      setSeriesQueries(queries);
      setIsFetchingData(false);
    };
  }, []);

  const addNewSeries = async (payload) => {
    try {
      const data = await getData(payload).unwrap();
      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const { data: ComparativeSeries } = useGetDataConsumptionByFilterQuery(
    {
      filter: getFilter(
        selectedSensor[0],
        calendarSelection,
        selectedLocationLevel,
        customBuckets,
        true
      ),
      locationId: selectedLocationIds[0],
      tenantId
    },
    {
      skip: !selectedLocationIds[0] || !calendarSelection || !selectedSensor[0] || !tenantId
    }
  );

  useLayoutEffect(() => {
    if (buildings.length > 0) {
      const index = buildings.findIndex((item) => item.id === selectedBuilding.id);

      if (index !== -1) {
        const newBuildings = [
          buildings[index],
          ...buildings.slice(0, index),
          ...buildings.slice(index + 1)
        ];
        dispatch(initMultisense(newBuildings));
      } else {
        dispatch(initMultisense(buildings));
      }
    }
  }, [buildings, dispatch, selectedBuilding]);

  useEffect(() => {
    let timer;
    if (isFetchingData) {
      // Set a timer for 40 seconds (40000 milliseconds)
      timer = setTimeout(() => {
        setLoadingTimeExceeded(true);
      }, 20000);
    } else {
      // If loading finishes before 40 seconds, clear the timer
      clearTimeout(timer);
      // Reset the loading time exceeded message
      if (loadingTimeExceeded) {
        setLoadingTimeExceeded(false);
      }
    }

    // Clear the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [isFetchingData, loadingTimeExceeded]);

  const bucket =
    customBuckets !== "" && customBuckets !== undefined
      ? customBuckets
      : getBucketTimeDynamically(calendarSelection.dateStart, calendarSelection.dateEnd);

  useEffect(() => {
    // Set initial render flag to false after first render
    if (initialRender) {
      setInitialRender(false);
    }
  }, [initialRender]);

  return (
    <Box sx={{ width: "100%", height: "90%", rowGap: "16px" }}>
      {selectedSeries.length < 2 ? (
        <ConsolidatedData
          average={getAverage(seriesQueries[0], selectedSensor[0])}
          peak={getMaxValue(seriesQueries[0], selectedSensor[0])}
          period={getSum(seriesQueries[0])}
          average2={getAverage(ComparativeSeries, selectedSensor[0])}
          peak2={getMaxValue(ComparativeSeries, selectedSensor[0])}
          period2={getSum(ComparativeSeries)}
        />
      ) : null}
      <CustomCard variant="outlined" smaller={selectedSeries.length < 2}>
        <ChartTitle />
        <Grid sx={{ height: "100%", width: "100%" }} paddingX={2}>
          {isFetchingData ? (
            <Box
              style={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: " center",
                gap: "18px"
              }}
            >
              <CircularProgress />
              {loadingTimeExceeded && <div> {t("chart.fetching")}</div>}
            </Box>
          ) : (
            <MultisenseChart
              bucket={bucket}
              allSeries={seriesQueries}
              seriesNames={
                selectedSeries
                  ? [
                      selectedSeries[0]?.seriesName,
                      selectedSeries[1]?.seriesName,
                      selectedSeries[2]?.seriesName
                    ]
                  : undefined
              }
              selectedSensor={selectedSensor}
              selectedLocation={selectedLocation}
              selectedPeriod={calendarSelection.selectedPeriod}
            />
          )}
        </Grid>
      </CustomCard>
    </Box>
  );
}
