export const isEmailValid = (email) => {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(String(email).toLowerCase());
};

export const isPasswordValid = (password) => {
  const re = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
  return re.test(password);
};

export const isConfirmPasswordValid = (password, confirmPassword) => {
  return password === confirmPassword;
};

export const validateFormFields = (formData) => {
  const newErrors = {};
  if (!formData.formTitle || formData.formTitle.trim() === "") {
    newErrors.alertTitle = "Alert title is required";
  }

  if (!formData.formLocation || formData.formLocation.description === undefined) {
    newErrors.location = "Location is required";
  }

  return newErrors;
};

export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};
