import { TabPanel } from "@mui/lab";
import { Grid, Card, Button, Popover, Box, Select, Alert } from "@mui/material";
import { createTheme, styled } from "@mui/material/styles";
import { DateBox } from "devextreme-react";
import Lottie from "lottie-react";

export const theme = createTheme({
  breakpoints: {
    values: {
      xxs: 0, // small phone
      xs: 300, // phone
      sm: 600, // tablets
      md: 900, // small laptop
      lg: 1200, // desktop
      xl: 1536, // large screens
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
    h1: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "1.5em !important",
    },
    h3: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "14px !important",
      fontWeight: "700 !important",
      fontStyle: "normal !important",
      lineHeight: "20px !important",
      display: "flex !important",
      alignItems: "center !important",
      color: "#000 !important",
      textAlign: "center",
    },
    menu: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "15px !important",
      fontStyle: "normal !important",
      lineHeight: "20px !important",
      display: "flex !important",
      alignItems: "center !important",
      marginBottom: 0,
      width: "100%",
      textAlign: "center",
    },
    p: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "14px !important",
      fontWeight: "400 !important",
      fontStyle: "normal !important",
      lineHeight: "20px !important",
      display: "flex !important",
      alignItems: "center !important",
      color: "#424242 !important",
      marginBottom: 0,
      textAlign: "center",
    },
    notification: { fontSize: "12px !important", color: "#424242 !important" },
    h2: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "14px !important",
      fontWeight: "600 !important",
      fontStyle: "normal !important",
      lineHeight: "18px !important",
      display: "flex !important",
      alignItems: "center !important",
      color: "#424242!important",
      width: "100%",
      textAlign: "center",
    },
    breadcrumb: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "12px !important",
      fontWeight: "400 !important",
      fontStyle: "normal !important",
      lineHeight: "19px !important",
    },
    dashboard: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "2rem !important",
      color: "#1c7ad4 !important",
    },
    consolidatedDashboard: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "1.618rem !important",
      color: "#424242 !important",
      textAlign: "flex-start",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: "'Poppins', sans-serif",
          backgroundColor: "#f1f5f8 !important",
        },
        overflowY: "none",
        scrollbarWidth: "thin",
        scrollbarColor: "transparent",
        "&::-webkit-scrollbar": {
          width: "6px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#f5f5f5",
          borderRadius: "3px",
        },
      },
    },
  },
});

export const CustomDateBox = styled(DateBox)(() => ({
  width: "100%",
  fontFamily: "'Poppins', sans-serif",
}));

export const StyledPopover = styled(Popover)(({ theme }) => ({
  ".MuiPopover-paper": {
    boxShadow: "none",
    borderRadius: "10px",
    padding: theme.spacing(2),
    border: "1px solid #e5e7eb",
  },
}));
export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "selected",
})(({ selected, disabled }) => ({
  fontWeight: "400 !important",
  fontSize: "14px ! important",
  lineHeight: "20px ! important",
  textAlign: "center",
  fontFamily: "'Poppins', sans-serif",
  textTransform: "none !important",
  justifyContent: "space-between",
  borderRadius: "8px !important",
  alignItems: "center",
  display: "flex",
  width: "fit-content",
  minWidth: "fit-content !important",
  padding: "5px 9px !important", // Adding padding here
  color: selected ? "#fff !important" : disabled ? "rgba(0,0,0,.04)"  : "#424242 !important",
  border: "1px solid #00000014 !important",
  background: selected
  ? "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.04) 99.48%, rgba(0,0,0,.04) 99.49%), #1c7ad4 !important"
  : "linear-gradient(180deg, rgba(0,0,0,0.000002) 0%, rgba(0,0,0,0.02) 100%), #f5f5f5 !important"
}));

export const DeleteButton= styled(Button, {
  shouldForwardProp: (prop) => prop !== "selected",
})(({ selected, disabled }) => ({
  fontWeight: "400 !important",
  fontSize: "14px ! important",
  lineHeight: "20px ! important",
  textAlign: "center",
  fontFamily: "'Poppins', sans-serif",
  textTransform: "none !important",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  width: "100% !important",
  color: selected ? "#fff !important" : disabled ? "rgba(0,0,0,.04)"  : "#424242 !important",
  border: "1px solid #00000014 !important",
  background: selected ? "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.04) 99.48%, rgba(0, 0, 0, 0.04) 99.49%), #d41717 !important"
  : "linear-gradient(180deg, rgba(0,0,0,0.000002) 0%, rgba(0,0,0,0.02) 100%), #f5f5f5 !important"
}));

export const ConfirmButton = styled(Button)(() => ({
  fontWeight: "400 !important",
  fontFamily: "'Poppins', sans-serif",
  fontSize: "14px ! important",
  lineHeight: "20px ! important",
  textAlign: "center",
  textTransform: "none !important",
  justifyContent: "space-between",
  borderRadius: "8px !important",
  alignItems: "center",
  display: "flex",
  width: "fit-content",
  minWidth: "fit-content !important",
  padding: "5px 9px !important", // Adding padding here
  color: "#fff !important",
  border: "1px solid #00000014 !important",
  background: "linear-gradient(180deg,rgba(0,0,0,0) 0%,rgba(0,0,0,.04) 99.48%,rgba(0,0,0,.04) 99.49%),#1c7ad4 !important",
}));

export const AppContainer = styled(Grid)(() => ({
  height: "calc(100vh - 65px)",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  width: "100%",
}));

export const CustomCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== "smaller",
})(({ theme, smaller }) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
  minHeight: "450px",
  height: smaller ? "75%" : "97%",
  alignItems: "center",
  backgroundColor: "#ffffff",
  borderRadius: "24px !important",
  boxShadow: "none",
  [theme.breakpoints.down("sm")]: {
    height: "70vh",
  },
}));

export const Offset = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
  ...theme.mixins.toolbar,
}));

export const RouterOutlet = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  marginLeft: open ? "270px" : "95px", // Adjust the margin based on the drawer width when open
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.down("sm")]: {
    marginLeft: "0px",
    width: "100%",
    height: "calc(100vh + 35%)",
    overflowY: "auto",
    marginBottom: "100px",
    marginTop: "85px",
  },
  ...(open && {
    marginLeft: "270px", // Again, adjust this value based on the drawer width
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  marginTop: "65px", // Adjust the margin based on the drawer width when open
  height: "100%",
  width: `calc(100% - ${open ? "270px" : "95px"})`, // Adjust width dynamically
  padding: theme.spacing(2),
}));

export const ActionsContainer = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}));
export const LeftActionWrapper = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "center",
}));

export const ApplyWrapper = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "flex-end",
}));

export const StyledSelect = styled(Select)(() => ({
  fontWeight: "400 !important",
  fontSize: "14px ! important",
  lineHeight: "20px ! important",
  textAlign: "center",
  textTransform: "none !important",
  justifyContent: "space-between",
  borderRadius: "8px !important",
  alignItems: "center",
  display: "flex",
  boxShadow: "none !important",
  width: "fit-content",
  minWidth: "fit-content !important",
  padding: "5px 9px !important", // Adding padding here
  color: "#424242 !important",
  border: "1px solid #00000014 !important",
  background: "linear-gradient(180deg, rgba(0,0,0,0.000002) 0%, rgba(0,0,0,0.02) 100%), #f5f5f5 !important",
  "&.MuiSelect-root": {
    height: "20px ! important",
    padding: "5px 9px !important",
    border: "1px solid #00000014 !important",
  },
  "&.MuiInputBase-root": {
    height: "35px ! important",
    padding: "5px 9px !important",
    border: "1px solid #00000014 !important",
  },
}));

export const CustomSnackBar = styled(Alert)(({ theme }) => ({
  backgroundColor: "#ffffff !important",
  color: "#424242 !important",
  boxShadow: "none",
  borderRadius: "10px",
  padding: theme.spacing(1.5),
  border: "1px solid #e5e7eb",
  fontFamily: "'Roboto !important', 'Helvetica', 'Arial', sans-serif",
  fontSize: "14px !important",
}));

export const LoadingContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  width: "100vw",
  background: "linear-gradient(to bottom, #f1f5f8, #dbe2e6)",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100vh",
  },
}));

export const LottieStyled = styled(Lottie)(({ theme }) => ({
  width: "25%",
  height: "auto",

  [theme.breakpoints.down("sm")]: {
    width: "50%",
  },
}));

export const LogoStyled = styled("img")(({ theme }) => ({
  width: "15%",
  marginTop: "20px",
  [theme.breakpoints.down("sm")]: {
    width: "45%",
  },
}));

export const TabPanelContainer = styled(TabPanel)(() => ({
  height: "calc(100vh - 150px)",
  padding: "8px",
}));
