import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedAlert: {} 
}

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    changeAlertSelection: (state, action) => {
      state.selectedAlert = action.payload
    },
  },
})

export const {
    changeAlertSelection,
} = alertsSlice.actions

export default alertsSlice.reducer