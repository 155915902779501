import {
  Box,
  Checkbox,
  DialogActions,
  DialogTitle,
  Grid,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Typography
} from "@mui/material";
import { useState } from "react";
import {
  ActionsGrid,
  ContentGrid,
  CustomCircularProgress,
  CustomDialogContent,
  TextFieldGrid,
  TitleGrid
} from "./Forms.styled";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { closeActionDialog } from "../../../redux/slices/actionDialogSlice";
import { useGetTenantsQuery, useAddUserTenantsMutation } from "../../../redux/metaDataApi";
import { useDispatch, useSelector } from "react-redux";
import { StyledButton } from "../../../App.styled";
import { openSnack } from "../../../redux/slices/snackSlice";
import { t } from "i18next";

export default function AddTenantForm() {
  const dispatch = useDispatch();
  const userSelected = useSelector((state) => state.adminPanel.userSelected);
  const { data: tenants } = useGetTenantsQuery();

  const [tenantsSelected, setTenantsSelected] = useState(
    userSelected.userTenants?.map((item) => item.tenant)
  );
  const [addUserTenants, { isLoading }] = useAddUserTenantsMutation();

  const closeForm = () => {
    dispatch(closeActionDialog());
  };

  const handleConfirm = async () => {
    const profileModules = tenantsSelected?.map((tenant) => ({
      userId: userSelected.id,
      tenantId: tenant.id,
      isDefault: false
    }));

    await addUserTenants(profileModules)
      .unwrap()
      .then(() => {
        dispatch(closeActionDialog());
        dispatch(openSnack({ message: "Tenants added to user", type: "success" }));
      })
      .catch((error) => {
        dispatch(openSnack({ message: "Error adding Tenants", type: "error" }));
        console.log(`Error:${error}`);
      });
  };

  const handleModuleClick = (newTenant) => {
    const newTenantsSelected = [...tenantsSelected];
    const moduleIndex = newTenantsSelected.findIndex((tenant) => tenant.id === newTenant.id);

    if (moduleIndex !== -1) {
      newTenantsSelected.splice(moduleIndex, 1);
    } else {
      newTenantsSelected.push(newTenant);
    }

    setTenantsSelected(newTenantsSelected);
  };

  return (
    <>
      <DialogTitle>
        <TitleGrid container>
          <Grid item xs>
            <Grow in timeout={800}>
              <Typography variant="h3">{t("form.addTenantUser")}</Typography>
            </Grow>
          </Grid>
          <Grid item xs textAlign="right">
            <IconButton onClick={closeForm}>
              <SvgIcon>
                <XMarkIcon />
              </SvgIcon>
            </IconButton>
          </Grid>
        </TitleGrid>
      </DialogTitle>
      <CustomDialogContent>
        <ContentGrid container rowGap={2}>
          <TextFieldGrid item xs={12} md={12}>
            {tenants && (
              <List>
                {[...tenants]
                  ?.sort((a, b) => a.description.localeCompare(b.description))
                  .map((tenant) => {
                    return (
                      <ListItem key={tenant.id} disablePadding>
                        <ListItemButton dense onClick={() => handleModuleClick(tenant)}>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={!!tenantsSelected.find((t) => t.id === tenant.id)}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText primary={tenant.description} />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
              </List>
            )}
          </TextFieldGrid>
        </ContentGrid>
      </CustomDialogContent>
      <DialogActions>
        <ActionsGrid container>
          <StyledButton onClick={() => dispatch(closeActionDialog())}>
            {" "}
            {t("button.cancel")}
          </StyledButton>
          <Box sx={{ position: "relative" }}>
            <StyledButton selected onClick={() => handleConfirm()} disabled={isLoading}>
              {t("button.apply")}
            </StyledButton>
            {isLoading && <CustomCircularProgress size={24} />}
          </Box>
        </ActionsGrid>
      </DialogActions>
    </>
  );
}
