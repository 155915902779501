import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  location: {},
  conditions: [''],
  fullObject: {
    id: null,
    name: null,
    description: null,
    conditionalAlertType: null,
    conditionalAlertLocation: null,
    buildingId: null,
    floorId: null,
    areaId: null,
    conditionalAlertSeverity: null,
    validationDays: ['All'],
    validationStartTime: '00:00',
    validationEndTime: '23:00',
    sendEmailNotification: true,
    enabled: true,
    creationUser:'',
    conditionalAlertSettingConditions: [
      {
        id: null,
        conditionalAlertSettingId: null,
        order: 1,
        conditionalAlertLogicalOperator: null,
        conditionalAlertComparisonOperator: null,
        conditionalAlertSettingParameters: [
          {
            id: null,
            conditionalAlertSettingConditionId: null,
            order: 1,
            conditionalAlertParameterType: null,
            sensorType1: null,
            setPoint: undefined,
            bandwidth: undefined,
            conditionalAlertArithmeticOperator: null,
            sensorType2: null,
            fixedValue: undefined,
          },
          {
            id: null,
            conditionalAlertSettingConditionId: null,
            order: 1,
            conditionalAlertParameterType: null,
            sensorType1: null,
            setPoint: undefined,
            bandwidth: undefined,
            conditionalAlertArithmeticOperator: null,
            sensorType2: null,
            fixedValue: undefined,
          },
        ],
      },
    ],
  },
}

export const alertsProSettingsSlice = createSlice({
  name: 'alertsPro',
  initialState,
  reducers: {
    cleanProAlertSettings: (state) => {
      state.fullObject = {
        id: null,
        name: null,
        description: null,
        conditionalAlertType: null,
        conditionalAlertLocation: null,
        buildingId: null,
        floorId: null,
        areaId: null,
        conditionalAlertSeverity: null,
        validationDays: ['All'],
        validationStartTime: '00:00',
        validationEndTime: '23:00',
        sendEmailNotification: true,
        enabled: true,
        creationUser:'',
        conditionalAlertSettingConditions: [
          {
            id: null,
            conditionalAlertSettingId: null,
            order: 1,
            conditionalAlertLogicalOperator: null,
            conditionalAlertComparisonOperator: null,
            conditionalAlertSettingParameters: [
              {
                id: null,
                conditionalAlertSettingConditionId: null,
                order: 1,
                conditionalAlertParameterType: null,
                sensorType1: null,
                setPoint: undefined,
                bandwidth: undefined,
                conditionalAlertArithmeticOperator: null,
                sensorType2: null,
                fixedValue: undefined,
              },
              {
                id: null,
                conditionalAlertSettingConditionId: null,
                order: 1,
                conditionalAlertParameterType: null,
                sensorType1: null,
                setPoint: undefined,
                bandwidth: undefined,
                conditionalAlertArithmeticOperator: null,
                sensorType2: null,
                fixedValue: undefined,
              },
            ],
          },
        ],
      }
      state.conditions = ['']
    },
    changeAlertSettingLocation: (state, action) => {
      state.location = action.payload
    },
    setConditions: (state, action) => {
      if (action.payload.value === '') {
        state.fullObject.conditionalAlertSettingConditions = state.fullObject.conditionalAlertSettingConditions?.map(
          (condition, idx) => {
            return {
              ...condition,
              conditionalAlertComparisonOperator: null,
              conditionalAlertSettingParameters: condition.conditionalAlertSettingParameters?.map(
                (param) => ({
                  ...param,
                  order: 1,
                  sensorType1: null,
                  setPoint: 0,
                  bandwidth: 0,
                  conditionalAlertArithmeticOperator: null,
                  sensorType2: null,
                  fixedValue: 0,
                }),
              ),
            }
          },
        )
      }

      // Update the conditions array based on the index and value
      state.conditions = state.conditions.map((cond, idx) =>
        idx === action.payload.index ? action.payload.value : cond,
      )
    },

    removeCondition: (state, action) => {
      const index = action.payload.index
      if (action.payload.index === 0) {
        state.conditions[0] = ''
        state.fullObject.conditionalAlertSettingConditions = state.fullObject.conditionalAlertSettingConditions?.map(
          (condition, idx) => {
            return {
              ...condition,
              conditionalAlertComparisonOperator: null,
              conditionalAlertSettingParameters: condition.conditionalAlertSettingParameters?.map(
                (param) => ({
                  ...param,
                  order: 1,
                  sensorType1: null,
                  setPoint: 0,
                  bandwidth: 0,
                  conditionalAlertArithmeticOperator: null,
                  sensorType2: null,
                  fixedValue: 0,
                }),
              ),
            }
          },
        )
      } else {
        state.conditions.splice(index, 1)
        state.fullObject.conditionalAlertSettingConditions.splice(index, 1)
      }
    },

    addCondition: (state, action) => {
      //include error message here or use forms 
      state.fullObject.conditionalAlertSettingConditions.push({
        id: null,
        conditionalAlertSettingId: null,
        order: 1,
        conditionalAlertLogicalOperator: action.payload.condition,
        conditionalAlertComparisonOperator: null,
        conditionalAlertSettingParameters: [
          {
            id: null,
            conditionalAlertSettingConditionId: null,
            order: 1,
            conditionalAlertParameterType: null,
            sensorType1: null,
            setPoint: undefined,
            bandwidth: undefined,
            conditionalAlertArithmeticOperator: null,
            sensorType2: null,
            fixedValue: undefined,
          },
          {
            id: null,
            conditionalAlertSettingConditionId: null,
            order: 2,
            conditionalAlertParameterType: null,
            sensorType1: null,
            setPoint: undefined,
            bandwidth: undefined,
            conditionalAlertArithmeticOperator: null,
            sensorType2: null,
            fixedValue: undefined,
          },
        ],
      })

      state.conditions.push('')
    },
    changeAlertSettingDaysOfWeek: (state, action) => {
      if (action.payload === 'All') {
        state.fullObject.validationDays = ['All']
      } else {
        if (state.fullObject.validationDays.includes('All')) {
          state.fullObject.validationDays = state.fullObject.validationDays.filter(
            (item) => item !== 'All',
          )
        }

        if (state.fullObject.validationDays.includes(action.payload)) {
          state.fullObject.validationDays = state.fullObject.validationDays.filter(
            (item) => item !== action.payload,
          )
        } else {
          state.fullObject.validationDays.push(action.payload)
        }
      }
    },
    changeAlertSettingTitle: (state, action) => {
      state.fullObject.name = action.payload
    },
    changeAlertSettingDescription: (state, action) => {
      state.fullObject.description = action.payload
    },
    changeAlertSettingChannel: (state, action) => {
      state.fullObject.channel = action.payload
    },
    changeAlertSettingSeverity: (state, action) => {
      state.fullObject.severity = action.payload
    },
    changeAlertSettingStartTime: (state, action) => {
      state.fullObject.validationStartTime = action.payload
    },
    changeAlertSettingEndTime: (state, action) => {
      state.fullObject.validationEndTime = action.payload
    },
    changeAlertSettings: (state, action) => {
      state.fullObject = {
        ...state.fullObject,
        conditionalAlertSettingConditions: action.payload,
      }
    },
    selectProAlertSettings: (state, action) => {
      state.fullObject = action.payload
      const allDaysOfWeek = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ]
      const selectedDaysArray = action.payload.validationDays
        ? action.payload.validationDays.split(',')
        : []
      // Check if all days are selected
      const allDaysSelected =
        selectedDaysArray.length === allDaysOfWeek.length &&
        allDaysOfWeek.every((day) => selectedDaysArray.includes(day))
      state.fullObject = {
        ...action.payload,
        validationDays: allDaysSelected ? ['All'] : selectedDaysArray,
      }

      // Handle location details
      if (action.payload.buildingId) {
        state.location = {
          id: action.payload.buildingId,
          description: `Building: ${action.payload.buildingDescription}`,
        }
      } else if (action.payload.floorId) {
        state.location = {
          id: action.payload.floorId,
          description: `Floor: ${action.payload.floorDescription}`,
        }
      } else if (action.payload.areaId) {
        state.location = {
          id: action.payload.areaId,
          description: `Area: ${action.payload.areaDescription}`,
        }
      }

      // Reset conditions before setting new ones
      state.conditions = []

      // Process conditions
      action.payload.conditionalAlertSettingConditions.forEach((condition) => {
        if (
          condition.conditionalAlertSettingParameters[0]
            .conditionalAlertParameterType === 'SensorType' &&
          condition.conditionalAlertSettingParameters[1]
            .conditionalAlertParameterType === 'SetPoint'
        ) {
          state.conditions.push('SetPoint')
        }

        if (
          condition.conditionalAlertSettingParameters[0]
            .conditionalAlertParameterType === 'SensorType' &&
          condition.conditionalAlertSettingParameters[1]
            .conditionalAlertParameterType === 'SensorType'
        ) {
          state.conditions.push('DataType')
        }

        if (
          condition.conditionalAlertSettingParameters[0]
            .conditionalAlertParameterType === 'Calculation' &&
          condition.conditionalAlertSettingParameters[1]
            .conditionalAlertParameterType === 'SetPoint'
        ) {
          state.conditions.push('SmartRule')
        }
      })
    },
  },
})

export const {
  addCondition,
  removeCondition,
  changeAlertSettings,
  selectProAlertSettings,
  cleanProAlertSettings,
  changeAlertSettingLocation,
  changeAlertSettingDaysOfWeek,
  changeAlertSettingSensor1,
  changeAlertSettingSensor2,
  changeAlertSettingSetPoint,
  changeAlertSettingBandwidth,
  changeAlertSettingTitle,
  changeAlertSettingDescription,
  changeAlertSettingChannel,
  changeAlertSettingSeverity,
  changeAlertSettingStartTime,
  changeAlertSettingEndTime,
  setConditions,
} = alertsProSettingsSlice.actions

export default alertsProSettingsSlice.reducer
