import React, { useEffect, useState } from "react";
import { StyledTree } from "../../components/MultiSense/ChartTitle/LocationFilter/LocationFilter.styled";

import { t } from "i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useDispatch, useSelector } from "react-redux";
import { Box, SvgIcon, Typography } from "@mui/material";
import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import { TreeItem, useTreeItem } from "@mui/x-tree-view";
import { applySelection, selectLocation, selectLocationId, selectMultisenseBuilding, setLocationLevel } from "../../redux/slices/multisenseSlice";
import clsx from "clsx";
import { toggleDrawer } from "../../redux/slices/mobileDrawer";

const CustomContent = React.forwardRef(({ classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon, buildingId, setAnchorEl, index }, ref) => {
  const { disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection } = useTreeItem(nodeId);
  const icon = iconProp || expansionIcon || displayIcon;
  const dispatch = useDispatch();

  return (
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={preventSelection}
      ref={ref}
    >
      <div onClick={handleExpansion} className={classes.iconContainer}>
        {icon}
      </div>
      <Typography
        component="div"
        className={classes.label}
        onClick={(event) => {
          handleSelection(event);
          dispatch(
            selectMultisenseBuilding({
              id: buildingId,
              description: label.props.description,
            })
          );
          dispatch(
            selectLocation({
              index: index,
              location: label.props.description,
            })
          );

          dispatch(setLocationLevel({ index: index, level: label.props.nodeLevel }));
          dispatch(
            selectLocationId({
              index: index,
              location: nodeId,
            })
          );
          dispatch(applySelection());
          setAnchorEl(null);
          dispatch(toggleDrawer());
        }}
      >
        {label}
      </Typography>
    </div>
  );
});

const CustomTreeItem = React.forwardRef(({ buildingId, setAnchorEl, index, ...props }, ref) => (
  <TreeItem ContentComponent={CustomContent} ContentProps={{ buildingId, setAnchorEl, index }} {...props} ref={ref} />
));

const BuildingLabel = ({ description, labelPrefix }) => (
  <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
    <Box color="inherit" sx={{ mr: 1 }} />
    <SvgIcon sx={{ alignContent: "baseline" }}>
      <BuildingOfficeIcon />
    </SvgIcon>
    <Typography variant="p" color="inherit">
      {`${labelPrefix}: ${description}`}
    </Typography>
  </Box>
);

export default function LocationSelection() {
  const { buildings } = useSelector((state) => state.multisense);
  const [anchorEl, setAnchorEl] = useState(null);


  return (
    <StyledTree defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
      {buildings &&
        buildings
          ?.slice()
          .sort((a, b) => a.description.localeCompare(b.description))
          .map((building) => (
            <CustomTreeItem
              buildingId={building.id}
              setAnchorEl={setAnchorEl}
              index={0}
              key={building.id}
              nodeId={building.id}
              label={<BuildingLabel description={building.description} labelPrefix={t("multisense.Building")} nodeLevel="Building" />}
            >
              {building.floors
                ?.slice()
                .sort((a, b) => a.description.localeCompare(b.description))
                .map((floor) => (
                  <CustomTreeItem
                    key={floor.id}
                    nodeId={floor.id}
                    index={0}
                    setAnchorEl={setAnchorEl}
                    label={<BuildingLabel description={floor.description} labelPrefix={t("multisense.Floor")} nodeLevel="Floor" />}
                    buildingId={building.id}
                  >
                    {floor.areas
                      ?.slice()
                      .sort((a, b) => a.description.localeCompare(b.description))
                      .map((area) => (
                        <CustomTreeItem
                          key={area.id}
                          setAnchorEl={setAnchorEl}
                          index={0}
                          nodeId={area.id}
                          label={<BuildingLabel description={area.description} labelPrefix={t("multisense.Area")} nodeLevel="Area" />}
                          buildingId={building.id}
                        />
                      ))}
                  </CustomTreeItem>
                ))}
            </CustomTreeItem>
          ))}
    </StyledTree>
  );
}
