import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userId: null,
  firstName: null,
  lastName: null,
  email: null,
  tenants: [{ id: null, description: null }],
  selectedTenant: {
    id: null,
    description: null,
  },
  profiles: null,
  selectedProfile: null,
  role: null,
  preferredLang: null,
  picture: ""
};

export const currentSessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    initSession: (state, action) => {
      const payload = action.payload;
      if (!payload) return;

      state.userId = payload?.id;
      state.firstName = payload?.firstName;
      state.lastName = payload?.lastName;
      state.role = payload?.role;
      state.email = payload?.email;
      state.preferredLang = payload?.language;
      state.picture = payload?.picture;
      state.tenants =
        payload?.userTenants?.map((item) => ({
          id: item?.tenant?.id,
          description: item?.tenant?.description, // assuming 'description' is an attribute
        })) || [];

      const defaultTenant = payload?.userTenants?.find((tenant) => tenant?.isDefault) || payload?.userTenants?.[0];

      state.selectedTenant = {
        id: defaultTenant?.tenant?.id,
        description: defaultTenant?.tenant?.description,
      };
    },
    changeTenantSelection: (state, action) => {
      state.selectedTenant.id = action.payload.id;
      state.selectedTenant.description = action.payload.description;
    },
    changePreferredLanguage: (state, action) => {
      state.preferredLang = action.payload;
    },
    setProfiles: (state, action) => {
      state.profiles = action.payload;
      state.selectedProfile = action.payload.find((profile) => profile.isDefault === true) || action.payload[0];
    },
    changeSelectedProfile: (state, action) => {
      state.selectedProfile = action.payload;
    },
    cleanTenantInfo: (state) => {
      state.tenants = [];
      state.selectedProfile = null;
    },
  },
});

export const { changePreferredLanguage, cleanTenantInfo, changeTenantSelection, initSession, setProfiles, changeSelectedProfile } = currentSessionSlice.actions;

export default currentSessionSlice.reducer;
