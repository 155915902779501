import { Box, Button, ButtonGroup, Divider, Typography } from "@mui/material";
import React from "react";
import { ModifiedConstants } from "../../App";
import { StyledButton } from "../../App.styled";
import { useDispatch, useSelector } from "react-redux";
import { selectPeriod } from "../../helpers/calendarFunctions";
import { t } from "i18next";
import { changeCalendarSelection } from "../../redux/slices/calendarSlice";

export default function CalendarMobile() {
  const periodOptions = ModifiedConstants.PERIOD;
  const dispatch = useDispatch();
  const selectedPeriod = useSelector((state) => state.calendar.selectedPeriod);

  const handleSelection = (option) => {
    selectPeriod(option, dispatch);
    dispatch(changeCalendarSelection());
  };
  return (
    <Box sx={{ width: "100%", justifyContent: "center", display: "flex" }}>
      <ButtonGroup variant="contained" aria-label="Basic button group" sx={{ height: "24px", borderRadius: "8px", boxShadow: "none", textTransform: "none", backgroundColor: "white" }}>
        {periodOptions
          .filter((item) => item !== "Custom")
          ?.map((option, index) => (
            <>
              {" "}
              <Button
                key={index}
                onClick={() => handleSelection(option)}
                sx={{
                  display: "flex", // Ensure the contents of the button are aligned in a row
                  alignItems: "center", // Center the button contents vertically
                  background:option === selectedPeriod? "linear-gradient(180deg,rgba(0,0,0,0) 0%,rgba(0,0,0,.04) 99.48%,rgba(0,0,0,.04) 99.49%),#1c7ad4 !important" :"white !important" ,
                  borderColor: "transparent !important",
                  textTransform: "none",
                  borderRadius: "6px !important",
                }}
              >
                <Typography variant="p" sx={{ color: option === selectedPeriod ? "#fff !important" : "#424242 !important"}}>{t(`calendarControl.${option}`)}</Typography>
              </Button>
              {index < periodOptions.length - 2 && ( // Do not add a divider after the last button
                <Box sx={{ display: "flex", paddingY: "6px", height: "100%" }}>
                  <Divider orientation="vertical" flexItem sx={{ backgroundColor: "white" }} />
                </Box>
              )}
            </>
          ))}
      </ButtonGroup>
    </Box>
  );
}
