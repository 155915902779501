import {
  Box,
  DialogActions,
  DialogTitle,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  SvgIcon,
  Typography
} from "@mui/material";
import React, { useLayoutEffect, useState } from "react";
import {
  ActionsGrid,
  ContentGrid,
  CustomCircularProgress,
  CustomDialogContent,
  CustomSelect,
  StyledNoDataLottie,
  TextFieldGrid,
  TitleGrid
} from "./Forms.styled";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { StyledButton } from "../../../App.styled";
import { useDispatch, useSelector } from "react-redux";
import { closeActionDialog } from "../../../redux/slices/actionDialogSlice";
import {
  useAddUserBuildingMutation,
  useGetAuth0UsersByTenantQuery,
  useGetProfilesByBuildingIdQuery
} from "../../../redux/metaDataApi";
import { openSnack } from "../../../redux/slices/snackSlice";
import { t } from "i18next";
import { ModifiedConstants } from "../../../App";
import axios from "axios";
import { Constants } from "../../../helpers/constants";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

export default function AddUserToBuildingForm() {
  const dispatch = useDispatch();
  const [request, setRequest] = useState(false);
  const [displayMessage, setDisplayMessage] = useState(false);
  const [userForm, setUserForm] = useState({ userId: "", profileId: "" });
  const [partnerProfiles, setPartnerProfiles] = useState(undefined);
  const [nonAdminUsers, setNonAdminUsers] = useState(undefined);
  const [addUser, { isLoading }] = useAddUserBuildingMutation();
  const selectedTenant = useSelector((state) => state.session.selectedTenant);
  const { firstName, lastName, email } = useSelector((state) => state.session);
  const selectedBuilding = useSelector((state) => state.buildings.selectedBuilding);
  const { data: users } = useGetAuth0UsersByTenantQuery(selectedTenant.id);
  const { data: profiles, isSuccess } = useGetProfilesByBuildingIdQuery(selectedBuilding.id);

  useLayoutEffect(() => {
    if (users && profiles) {
      setNonAdminUsers(users?.filter((user) => user.role !== "Admin"));
      setPartnerProfiles(profiles?.filter((profile) => profile.visibleToPartnerRole));
    }
  }, [profiles, users]);

  const handleSelectionChange = (e) => {
    const { name, value } = e.target;
    setUserForm({ ...userForm, [name]: value });
  };

  const closeForm = () => {
    dispatch(closeActionDialog());
  };

  const prepareChatTemplate = () => {
    const chatTemplate = JSON.parse(JSON.stringify(Constants.CHAT_TEMPLATE)); // Deep clone to avoid mutating the original
    chatTemplate.cardsV2[0].card.header.title = `${firstName} ${lastName}`;
    chatTemplate.cardsV2[0].card.header.subtitle = `Partner cannot add users to Building; add profiles for ${selectedBuilding.description}`;
    return chatTemplate;
  };

  const onUpgradeClick = async () => {
    const postData = prepareChatTemplate();
    setRequest(true);
    try {
      await axios.post(process.env.REACT_APP_GOOGLE_WEBHOOK, postData, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      setRequest(false);
      setDisplayMessage(true);
    } catch (error) {
      console.error("Error making post request:", error);
    }
  };

  const handleConfirm = async () => {
    const userToAdd = {
      userId: userForm.userId,
      buildingId: selectedBuilding.id,
      profileId: userForm.profileId,
      isDefault: false
    };

    await addUser(userToAdd)
      .unwrap()
      .then(() => {
        dispatch(openSnack({ message: t("toast.userAddedBuilding"), type: "success" }));
        closeForm();
      })
      .catch((error) => {
        dispatch(openSnack({ message: t("toast.errorMessage"), type: "error" }));
        console.log(`Error:${error}`);
      });
  };

  return (
    isSuccess &&
    (partnerProfiles?.length > 0 ? (
      <div style={{ width: 500 }}>
        <DialogTitle>
          <TitleGrid container>
            <Grid item xs>
              <Grow in timeout={800}>
                <Typography variant="h3">{t("form.addUserBuilding")}</Typography>
              </Grow>
            </Grid>
            <Grid item xs textAlign="right">
              <IconButton onClick={closeForm}>
                <SvgIcon>
                  <XMarkIcon />
                </SvgIcon>
              </IconButton>
            </Grid>
          </TitleGrid>
        </DialogTitle>
        <CustomDialogContent>
          <ContentGrid container rowGap={2}>
            <TextFieldGrid item xs={12} md={12}>
              <Typography variant="p">{t("form.users")}</Typography>
              <CustomSelect name="userId" value={userForm.userId} onChange={handleSelectionChange}>
                {nonAdminUsers?.map((user) => (
                  <MenuItem key={user.firstName} value={user.id}>
                    {user.firstName} {user.lastName}
                  </MenuItem>
                ))}
              </CustomSelect>
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={12}>
              <Typography variant="p">{t("form.profile")}</Typography>
              <CustomSelect
                name="profileId"
                value={userForm.profileId}
                onChange={handleSelectionChange}
              >
                {partnerProfiles?.map((profile) => (
                  <MenuItem key={profile.description} value={profile.id}>
                    {profile.description}
                  </MenuItem>
                ))}
              </CustomSelect>
            </TextFieldGrid>
          </ContentGrid>
        </CustomDialogContent>
        <DialogActions>
          <ActionsGrid container>
            <StyledButton onClick={closeForm}> {t("button.cancel")}</StyledButton>
            <Box sx={{ position: "relative" }}>
              <StyledButton selected onClick={handleConfirm}>
                {t("button.confirm")}
              </StyledButton>
              {isLoading && <CustomCircularProgress size={24} />}
            </Box>
          </ActionsGrid>
        </DialogActions>
      </div>
    ) : (
      partnerProfiles && (
        <>
          <CustomDialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "16px"
            }}
          >
            <Typography
              variant="dashboard"
              sx={{
                textAlign: "center",
                maxWidth: "100%",
                alignSelf: "center" // Ensure text aligns to left
              }}
            >
              {t("form.oops")}
            </Typography>

            <StyledNoDataLottie
              animationData={ModifiedConstants.NO_DATA_LOADER.animationData}
              loop={false}
            />

            <Typography variant="p" sx={{ alignSelf: "flex-start" }}>
              {t("form.sorry")}
            </Typography>
          </CustomDialogContent>

          <DialogActions>
            <ActionsGrid container justifyContent="center">
              {" "}
              {/* Center content */}
              <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                {displayMessage ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <SvgIcon
                      sx={{
                        width: "20px",
                        height: "20px",
                        color: "green !important",
                        borderRadius: "20px"
                      }}
                    >
                      <CheckCircleIcon sx={{ width: "20px", height: "20px" }} />
                    </SvgIcon>
                    <Typography variant="p">{t(`multisense.upgradealerts.request`)}</Typography>
                  </div>
                ) : (
                  <Box sx={{ position: "relative" }}>
                    <StyledButton selected onClick={onUpgradeClick}>
                      {t("button.upgrade")}
                    </StyledButton>
                    {request && <CustomCircularProgress size={24} />}
                  </Box>
                )}
                {isLoading && <CustomCircularProgress size={24} />}
              </Box>
            </ActionsGrid>
          </DialogActions>
        </>
      )
    ))
  );
}
