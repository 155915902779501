import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SvgIcon,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { PencilSquareIcon, PlusIcon } from "@heroicons/react/24/outline";
import { ContentGrid, TextFieldGrid, TitleGrid } from "../../ActionDialog/Forms/Forms.styled";
import { openActionDialog, changeActionDialogType } from "../../../redux/slices/actionDialogSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetBuildingModulesQuery,
  useGetBuildingSensorTypesQuery,
  useGetProfilesByBuildingIdQuery
} from "../../../redux/metaDataApi";
import { changeProfileSelection } from "../../../redux/slices/adminPanelSlice";
import { t } from "i18next";

export default function ModulesBuildingForm() {
  const dispatch = useDispatch();
  const { selectedBuilding } = useSelector((state) => state.buildings);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [selectedSensorTypes, setSelectedSensorTypes] = useState([]);
  const [translatedSensorTypes, setTranslatedSensorTypes] = useState([]);

  const { data: profiles } = useGetProfilesByBuildingIdQuery(selectedBuilding.id, {
    skip: !selectedBuilding
  });
  const { data: modules } = useGetBuildingModulesQuery(selectedBuilding.id, {
    skip: !selectedBuilding
  });
  const { data: sensors } = useGetBuildingSensorTypesQuery(selectedBuilding.id, {
    skip: !selectedBuilding
  });

  useEffect(() => {
    if (selectedBuilding && modules && sensors) {
      setModulesSelected(modules.map((item) => item.module));
      setSelectedSensorTypes(sensors.map((item) => item.sensorType));
    }
  }, [selectedBuilding, modules, sensors]);

  useEffect(() => {
    if (selectedSensorTypes) {
      setTranslatedSensorTypes(
        selectedSensorTypes.map((sensorType) => ({
          sensorType,
          translation: t(`sensor.${sensorType}`)
        }))
      );
    }
  }, [selectedSensorTypes]);

  return (
    profiles && (
      <>
        <ContentGrid container rowGap={2}>
          <TextFieldGrid item xs={12} md={4}>
            <TitleGrid container>
              <Grid item xs>
                <Typography variant="h3">Modules</Typography>
              </Grid>
              <Grid item xs textAlign="right">
                <IconButton
                  onClick={() => {
                    dispatch(openActionDialog());
                    dispatch(changeActionDialogType("modules"));
                  }}
                >
                  <SvgIcon>
                    <PencilSquareIcon />
                  </SvgIcon>
                </IconButton>
              </Grid>
            </TitleGrid>
            <List>
              {[...modulesSelected]
                ?.sort((a, b) => a.localeCompare(b))
                .map((module, index) => {
                  return (
                    <ListItem key={index} disablePadding>
                      <ListItemButton dense>
                        <ListItemText primary={module} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
            </List>
          </TextFieldGrid>
          <TextFieldGrid item xs={12} md={4}>
            <TitleGrid container>
              <Grid item xs>
                <Typography variant="h3">{t("form.profiles")}</Typography>
              </Grid>
              <Grid item xs textAlign="right">
                <IconButton
                  onClick={() => {
                    dispatch(openActionDialog());
                    dispatch(changeActionDialogType("profiles"));
                  }}
                >
                  <SvgIcon>
                    <PlusIcon />
                  </SvgIcon>
                </IconButton>
              </Grid>
            </TitleGrid>
            <List>
              {[...profiles]
                ?.sort((a, b) => a.description.localeCompare(b.description))
                .map((profile, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemButton
                      dense
                      onClick={() => {
                        dispatch(openActionDialog());
                        dispatch(changeProfileSelection(profile));
                        dispatch(changeActionDialogType("editProfile"));
                      }}
                    >
                      <ListItemText primary={profile.description} />
                      <SvgIcon>
                        <PencilSquareIcon />
                      </SvgIcon>
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
          </TextFieldGrid>
          <TextFieldGrid item xs={12} md={4}>
            <TitleGrid container>
              <Grid item xs>
                <Typography variant="h3">{t("form.sensorTypes")}</Typography>
              </Grid>
              <Grid item xs textAlign="right">
                <IconButton
                  onClick={() => {
                    dispatch(openActionDialog());
                    dispatch(changeActionDialogType("sensors"));
                  }}
                >
                  <SvgIcon>
                    <PencilSquareIcon />
                  </SvgIcon>
                </IconButton>
              </Grid>
            </TitleGrid>
            <List>
              {[...translatedSensorTypes]
                ?.sort((a, b) => a.translation.localeCompare(b.translation))
                .map((translatedSensorType, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemButton dense>
                      <ListItemText primary={translatedSensorType.translation} />
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
          </TextFieldGrid>
        </ContentGrid>
      </>
    )
  );
}
