import React, { useLayoutEffect, useState } from 'react'
import LocationDropdown from './LocationDropdown/LocationDropdown'
import { Grid, IconButton, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { StyledButton } from '../../../../App.styled'
import { useDispatch, useSelector } from 'react-redux'
import {
  addLocationPlaceholder,
  applySelection,
  resetLocation,
} from '../../../../redux/slices/multisenseSlice'
import { closePopover } from '../../../../redux/slices/popoversSlice'
import {
  CustomFilterContainer,
  CustomItemContainer,
  CustomTitleContainer,
} from './LocationFilter.styled'
import { useTranslation } from 'react-i18next'
import { changeActionDialogType, openActionDialog } from '../../../../redux/slices/actionDialogSlice'

export default function LocationFilter() {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const [shouldRenderButton, setShouldRenderButton] = useState(false)
  const { location, selectedValues } = useSelector((state) => state.multisense)
  const  profile  = useSelector((state) => state.session.selectedProfile);
  const modules = profile?.profileModules?.map((item) => item.module) || [];

  useLayoutEffect(() => {
    if (
      location.filter((s) => s !== null && s !== undefined).length < 5 &&
      selectedValues.selectedSensor.length === 1
    ) {
      setShouldRenderButton(true)
    } else {
      setShouldRenderButton(false)
    }
  }, [selectedValues, location])

  return (
    <CustomFilterContainer container>
       <Grid item container sx={{maxHeight: 200, overflowY: "auto"}}>
      {location?.map((item, index) => (
         item !== undefined &&(
        <CustomTitleContainer key={index}>
          <Typography variant="h3">{t(`multisense.sense-filter.location`)}</Typography>
          <LocationDropdown index={index} />
        </CustomTitleContainer>
      )))}
       </Grid>
        <CustomItemContainer item container>
          {shouldRenderButton  ? (
            <IconButton
            onClick={() => {
              if(modules.includes('MultisenseBuildingCrossReference')){
                dispatch(addLocationPlaceholder())
              } else {
                dispatch(changeActionDialogType("upgradeAlerts"));
                dispatch(openActionDialog());
              }
            }}
            >
              <AddIcon />
            </IconButton>
          ) : (
            <></>
          )}
        </CustomItemContainer>
      <CustomItemContainer container item xs={12} spacing={2}>
        <Grid item display="flex" justifyContent="center">
          <StyledButton
            onClick={() => {
              dispatch(resetLocation())
              dispatch(closePopover())
            }}
          >
            {t(`button.reset`)}
          </StyledButton>
        </Grid>
        <Grid item xs display="flex" justifyContent="flex-end">
          <StyledButton
            selected
            onClick={() => {
              dispatch(applySelection())
              dispatch(closePopover())
            }}
          >
              {t(`button.apply`)}
          </StyledButton>
        </Grid>
      </CustomItemContainer>
    </CustomFilterContainer>
  )
}
