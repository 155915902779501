import { format } from 'date-fns'
import { ModifiedConstants } from '../App'
import { formatNumber } from './functions'

export const processAndExtractAlertsData = (alerts) => {
    return alerts?.map((alert) => {
      let locationId = null;
      let locationDescription = null;
      let LabelPrefix = null;
      let processedSensorType = null;
      let processedSensorType2 = null;
      let alertValue = null;
      let bandwidth = null;
      let setPoint = null;
      let delta = null;
      let lastDetectionDate = null;
      let endDate = null;
      let startDate = null;
      let arithmeticOperator = null;
      let logicalOperator = null;
      let conditionType = null;
  
      // Extracting values from alert
      const {
        name,
        description,
        conditionalAlertSeverity,
        validationDays,
        validationStartTime,
        validationEndTime,
        conditionalAlertSettingConditions,
        enabled,
        conditionalAlertConditions,
        buildingId,
        floorId,
        areaId,
        id,
        conditionalAlertStatus,
        conditionalAlertType,
        creationUser
      } = alert;
  
      const selectedDaysOfWeek = validationDays.split(', ');
  
      if (conditionalAlertSettingConditions) {
        for (const condition of conditionalAlertSettingConditions) {
          logicalOperator = condition.conditionalAlertComparisonOperator
            const parameters = condition.conditionalAlertSettingParameters;
  
            if (parameters) {
              if(parameters[0].conditionalAlertParameterType==="SensorType" &&parameters[1].conditionalAlertParameterType==="SensorType"){
                processedSensorType = parameters[0].sensorType1;
                processedSensorType2 = parameters[1].sensorType1;
                conditionType = "DataType"
              } else {
                for (const parameter of parameters) {
                  if (parameter.sensorType1) {
                    processedSensorType = parameter.sensorType1;
                  }
                  if (parameter.bandwidth) {
                    bandwidth = parameter.bandwidth;
                  }
                  if (parameter.setPoint) {
                    setPoint = parameter.setPoint;
                  }
                  if (parameter.sensorType2) {
                    processedSensorType2 = parameter.sensorType2;
                  }
                  if (parameter.conditionalAlertArithmeticOperator) {
                    arithmeticOperator = parameter.conditionalAlertArithmeticOperator;
                  }
                }
              }
            }
  
            // Adjust setPoint based on alertValue and bandwidth
            let adjustedSetPoint = setPoint;
            if (alertValue !== null && setPoint !== null && bandwidth !== null) {
              if (alertValue > setPoint) {
                adjustedSetPoint = setPoint + bandwidth;
              } else {
                adjustedSetPoint = setPoint - bandwidth;
              }
              delta = formatNumber(alertValue - adjustedSetPoint);
            }
  
            if (processedSensorType && bandwidth && setPoint) {
              break;
            }
          }
      }
  
      // Process conditionalAlertConditions
      if (conditionalAlertConditions) {
        for (const condition of conditionalAlertConditions) {
          if (condition.conditionMet === true) {
            alertValue = condition.alertValue;
            lastDetectionDate = alert.lastDetectionDate;
            endDate = alert.endDate;
            startDate = alert.startDate;

            const parameters = condition.conditionalAlertParameters;
            if (parameters) {
              if(parameters[0].conditionalAlertParameterType==="SensorType" && parameters[1].conditionalAlertParameterType==="SensorType"){
                processedSensorType = parameters[0].sensorType1;
                processedSensorType2 = parameters[1].sensorType1;
                conditionType = "DataType"
              } else {
              for (const parameter of parameters) {
                if (parameter.sensorType1) {
                  processedSensorType = parameter.sensorType1;
                }
                if (parameter.bandwidth) {
                  bandwidth = parameter.bandwidth;
                }
                if (parameter.setPoint) {
                  setPoint = parameter.setPoint;
                }
                if (parameter.sensorType2) {
                  processedSensorType2 = parameter.sensorType2;
                }
                if (parameter.conditionalAlertArithmeticOperator) {
                  conditionType = "Calculation"
                  arithmeticOperator = parameter.conditionalAlertArithmeticOperator;
                }
              }}
            }
  
            // Adjust setPoint based on alertValue and bandwidth
            let adjustedSetPoint = setPoint;
            if (alertValue !== null && setPoint !== null && bandwidth !== null) {
              if (alertValue > setPoint) {
                adjustedSetPoint = setPoint + bandwidth;
              } else {
                adjustedSetPoint = setPoint - bandwidth;
              }
              delta = formatNumber(alertValue - adjustedSetPoint);
            }
  
            if (processedSensorType && bandwidth && setPoint) {
              break;
            }
          }
        } }
  
      // Determine the location type and related information
      if (buildingId) {
        LabelPrefix = 'Building';
        locationId = buildingId;
        locationDescription = alert.buildingDescription;
      } else if (floorId) {
        LabelPrefix = 'Floor';
        locationId = floorId;
        locationDescription = alert.floorDescription;
      } else if (areaId) {
        LabelPrefix = 'Area';
        locationId = areaId;
        locationDescription = alert.areaDescription;
      }
  
      return {
        id,
        logicalOperator,
        sensor: processedSensorType,
        sensor2: processedSensorType2,
        status: conditionalAlertStatus,
        severity: conditionalAlertSeverity,
        type: conditionalAlertType,
        arithmeticOperator,
        bandwidth,
        setPoint,
        alertValue,
        delta,
        conditionType,
        lastDetectionDate,
        endDate,
        startDate,
        selectedDaysOfWeek,
        title: name,
        startTime: validationStartTime,
        endTime: validationEndTime,
        description,
        locationDescription,
        locationId,
        LabelPrefix,
        enabled,
        creationUser
    }})
};

export function getAlertSeries(alert, seriesFormatted, bucketTimeWindow) {
  const alertSeries = []
  const tempSeries = JSON.parse(JSON.stringify(seriesFormatted))
  const startDate = new Date(alert.startDate).getTime()
  const endDate = Math.max(
    new Date(alert.lastDetectionDate).getTime(),
    new Date(alert.endDate).getTime(),
  )

  tempSeries?.forEach((element) => {
    const elementDate = new Date(element.to).getTime()
    if (startDate <= elementDate && elementDate <= endDate) {
      element.name = 'Alerts'
      alertSeries.push({
        ...element,
        date: format(new Date(element.to), dateFormat(bucketTimeWindow)),
      })
    }
  })
  return alertSeries
}

export const dateFormat = (bucketTimeWindow) => {
  switch (bucketTimeWindow) {
    case ModifiedConstants.BUCKET_TIME.TEN_MINUTES:
    case ModifiedConstants.BUCKET_TIME.MINUTE:
      return 'H:mm'
    case ModifiedConstants.BUCKET_TIME.HOUR:
      return 'H:00'
    case ModifiedConstants.BUCKET_TIME.DAY:
      return 'dd/MM/yy'
    default:
      return 'medium'
  }
}
