import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import LockedService from '../components/LockedService/LockedService'

const GuardedRoute = ({ children, allowedModule }) => {
  const { role } = useSelector((state) => state.session)
  const  profile  = useSelector((state) => state.session.selectedProfile);
  const [moduleArray, setModuleArray] = useState([])


  useEffect(() => {
    if (profile) {
      const modules = profile.profileModules?.map((item) => item.module) || [];
      setModuleArray(modules);
    }
  }, [profile]);
  

  if ((allowedModule !== 'AdminPanel' && allowedModule !== 'PartnerPanel') && !moduleArray?.includes(allowedModule)) {
    return <LockedService module={allowedModule} />
  } else if (allowedModule === 'AdminPanel' && role === 'Admin') {
    return children
  }  else if (allowedModule === 'PartnerPanel' && role === 'Partner') {
    return children
  } else {
    return children
  }
}

export default GuardedRoute
