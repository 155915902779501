import {  Button, Menu } from '@mui/material'
import styled from 'styled-components'


  export const StyledMenu = styled(Menu)(() => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: '8px', // adjusted from theme.spacing(1)
      minWidth: 180,
      width: '300px',
      color: 'rgb(55, 65, 81)', // removed conditional theme dependency
      boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: 'grey', // removed theme dependency, replace with a suitable color
          marginRight: '24px', // adjusted from theme.spacing(1.5)
        },
        '& .MuiButtonBase-root': {
          disableRipple: true, // disabling the ripple effect here
        },
        '&:hover, &:focus, &:active': {
          backgroundColor: 'transparent !important',
        },
      },
    },
  }));

  export const CalendarButton = styled(Button)(({ theme, selected })=>({
    fontWeight: '400 !important',
    fontSize: '14px  !important',
    lineHeight: '20px !important',
    textAlign: 'center',
    textTransform: 'none !important',
    justifyContent: 'space-between',
    borderRadius: '25px !important',
    alignItems: 'center',
    display: 'flex',
    height: 'auto',
    width: 'fit-content',
    padding: '15px 12px !important', // Adding padding here
    color: selected ? '#fff !important' : '#424242 !important',
    border: '1px solid #00000014 !important',
    background: selected
      ? 'linear-gradient(180deg,rgba(0,0,0,0) 0%,rgba(0,0,0,.04) 99.48%,rgba(0,0,0,.04) 99.49%),#1c7ad4 !important'
      : 'linear-gradient(180deg, rgba(0,0,0,0.000002) 0%, rgba(0,0,0,0.02) 100%), #f5f5f5 !important',
  }))

  