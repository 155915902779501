export function getBuildingDescription({ row, tenants }) {
  if (row.buildingDescription === undefined) {
    // Find tenant based on the selected tenantId in the row
    const tenantInfo = tenants?.find(
      (t) => t.description === row.userTenants?.[0]?.tenant?.description,
    )

    // Get the building descriptions
    const tenantBuildingOptions = tenantInfo
      ? tenantInfo.buildings?.map((b) => b)
      : []

    // Filter out buildings that are not assigned to the user
    const userBuildings = row.userBuildings?.map((t) => t?.building) || []

    const options = tenantBuildingOptions.filter((desc) =>
      userBuildings.some((ub) => ub?.id === desc.id),
    )

    return options[0]?.description || ''
  } else {
    return row.buildingDescription
  }
}

export function getBuildingOptions({ row, tenants }) {
  const tenantInfo = tenants?.find((t) => t.description === row.tenant)
  const tenantBuildingOptions = tenantInfo
    ? tenantInfo.buildings.map((b) => b)
    : []
  const userBuildings = row.userBuildings?.map((t) => t?.building) || []
  const options = tenantBuildingOptions.filter((desc) =>
    userBuildings.some((ub) => ub?.id === desc.id),
  )

  return options?.map((o) => o.description)
}

export function getProfileDescriptions({ row, tenants }) {
  // 1. Find the tenant that matches either the building description or the tenant description from the row.
  const tenantInfo = tenants?.find(
    (t) => t.description === (row.tenant || row.userTenants?.[0]?.tenant?.description),
  );
  // 2. Get all buildings related to that tenant.
  const tenantBuildingOptions = tenantInfo ? tenantInfo.buildings : [];
  // 3. Filter to include only the building that matches row.buildingDescription.
  const specificBuilding = tenantBuildingOptions.find(
    (building) => building.description === (row.buildingDescription || tenantBuildingOptions[0]?.description)
  );

  // 4. Get all profile IDs that the user is a part of.
  const userProfiles = row.userBuildings?.map((ub) => ub?.profileId) || [];
  // 5. Extract all profile information from the specific building.
  let allBuildingProfiles = specificBuilding ? specificBuilding.profiles : [];

  // 6. Filter to only include profiles that the user is part of.
  const filteredProfiles = allBuildingProfiles?.filter((profile) =>
    userProfiles.includes(profile.id)
  );

  // 7. Filter to only include profiles associated with the building specified in row.buildingDescription
  const profilesForBuilding = filteredProfiles.filter((profile) =>
    profile.buildingId === specificBuilding.id
  );

  return profilesForBuilding[0]?.description;
}



export function getRenderedProfileCell({ row, tenants }) {
  if (row.buildingDescription !== '') {
    const tenantBuildings = tenants?.flatMap((tenant) => tenant.buildings)
    const buildingInfo = tenantBuildings?.filter(
      (building) => building.description === row.buildingDescription,
    )

    const userProfiles = row.userBuildings?.map((ub) => ub?.profileId) || []

    let allBuildingProfiles = []

    if (Array.isArray(buildingInfo)) {
      for (const building of buildingInfo) {
        allBuildingProfiles = [
          ...allBuildingProfiles,
          ...(building.profiles || []),
        ]
      }
    }

    const filteredProfiles = allBuildingProfiles?.filter((profile) =>
      userProfiles.includes(profile.id),
    )

    return filteredProfiles?.map((profile) => profile.description).join(', ')
  }
  return ''
}

export function getBuildingsForUser(tenant, tenants, user) {
  // Find tenant based on the selected tenantId in the row
  const tenantInfo = tenants?.find((t) => t.description === tenant.description)

  // Get the building descriptions
  const tenantBuildingOptions = tenantInfo?.buildings.map((b) => b)

  // Filter out buildings that are not assigned to the user
  const userBuildings = user.userBuildings?.map((t) => t?.building)
  const options = tenantBuildingOptions?.filter((desc) =>
    userBuildings.some((ub) => ub?.id === desc.id),
  )

  // Map to add the 'isDefault' property from userBuildings
  const optionsWithDefault = options?.map((option) => {
    const userBuilding = user.userBuildings.find((ub) => ub?.buildingId === option.id);
    return {
      ...option,
      isDefault: userBuilding?.isDefault || false,
    };
  });

  return optionsWithDefault
}

export function getProfilesForUser(tenant, tenants, buildingSelected, user) {
  const tenantBuildings = tenants?.flatMap((tenant) => tenant?.buildings)
  const buildingInfo = tenantBuildings?.filter(
    (building) => building.description === buildingSelected?.description,
  )

  const userProfiles = user.userBuildings?.map((ub) => ub?.profileId) || []

  let allBuildingProfiles = []

  if (Array.isArray(buildingInfo)) {
    for (const building of buildingInfo) {
      allBuildingProfiles = [
        ...allBuildingProfiles,
        ...(building.profiles || []),
      ]
    }
  }

  const filteredProfiles = allBuildingProfiles?.filter((profile) =>
    userProfiles.includes(profile.id),
  )

  return filteredProfiles
}
