import { Grid, Select, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomSelect = styled(Select)(() => ({
  height: "34px",
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "1.5px solid #00000040",
  },
  fieldset: {
    border: "1px solid #00000014",
    borderRadius: "6px",
    backgroundColor: "transparent",
    height: "40px",
  },
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const HelpText = styled(Typography)(({ theme }) => ({
  alignSelf: "flex-start !important", // Aligns the component itself when in a flex container
  textAlign: "left !important", // Aligns the text inside the component to the left
  fontStyle: "italic !important",
}));

export const ContentGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "25%",
  borderRight: "solid 2px  rgba(0, 0, 0, 0.12)",
  padding: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    borderRight: "none",
  },
}));
