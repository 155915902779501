import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export  function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const useCurrencySymbol = () => {
  const { selectedBuilding, buildingCurrencies } = useSelector((state) => state.buildings);

  const currencyMap = {
    EUR: "€", // US Dollar
    MXN: "$", // MXN PESOS
    USD: "$", // US Dollar
    GBP: "£", // British Pound Sterling
  };

  return currencyMap[buildingCurrencies[selectedBuilding.id]] || "$"; // Default to US Dollar if locale not found
};
