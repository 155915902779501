import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  id: undefined,
  location: {},
  selectedDaysOfWeek: ['All'],
  startTime: '00:00',
  endTime: '23:00',
  sensor: null,
  setPoint: null,
  bandwidth: null,
  title: null,
  description: null,
  channel: null,
  severity: null,
  fullObject: null,
  creationUser:'',
}

export const alertsSettingsSlice = createSlice({
  name: 'alertsSettings',
  initialState,
  reducers: {
    cleanAlertSettings: (state) => {
      state.location = {}
      state.selectedDaysOfWeek = ['All']
      state.sensor = null
      state.setPoint = null
      state.bandwidth = null
      state.title = null
      state.description = null
      state.channel = null
      state.severity = null
      state.startTime = '00:00'
      state.endTime = '23:00'
    },
    changeAlertSettingLocation: (state, action) => {
      state.location = action.payload
    },
    changeAlertSettingDaysOfWeek: (state, action) => {
      if (action.payload === 'All') {
        state.selectedDaysOfWeek = ['All']
      } else {
        if (state.selectedDaysOfWeek.includes('All')) {
          state.selectedDaysOfWeek = state.selectedDaysOfWeek.filter(
            (item) => item !== 'All',
          )
        }

        if (state.selectedDaysOfWeek.includes(action.payload)) {
          state.selectedDaysOfWeek = state.selectedDaysOfWeek.filter(
            (item) => item !== action.payload,
          )
        } else {
          state.selectedDaysOfWeek.push(action.payload)
        }
      }
    },
    changeAlertSettingSensor: (state, action) => {
      state.sensor = action.payload
    },
    changeAlertSettingSetPoint: (state, action) => {
      state.setPoint = action.payload
    },
    changeAlertSettingBandwidth: (state, action) => {
      state.bandwidth = action.payload
    },
    changeAlertSettingTitle: (state, action) => {
      state.title = action.payload
    },
    changeAlertSettingDescription: (state, action) => {
      state.description = action.payload
    },
    changeAlertSettingChannel: (state, action) => {
      state.channel = action.payload
    },
    changeAlertSettingSeverity: (state, action) => {
      state.severity = action.payload
    },
    changeAlertSettingStartTime: (state, action) => {
      state.startTime = action.payload
    },
    changeAlertSettingEndTime: (state, action) => {
      state.endTime = action.payload
    },
    selectAlertSettings: (state, action) => {
      let locationDescription = null
      let LabelPrefix = null
      let locationId = null
      const allDaysOfWeek = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ]
      const {
        id,
        name,
        description,
        conditionalAlertSeverity,
        validationDays,
        validationStartTime,
        validationEndTime,
        conditionalAlertSettingConditions,
        creationUser,
      } = action.payload
      state.id = id
      if (validationDays === allDaysOfWeek.join(',')) {
        state.selectedDaysOfWeek = ['All']
      }
      state.fullObject = action.payload
      state.selectedDaysOfWeek = validationDays?.split(',')
      state.startTime = validationStartTime
      state.endTime = validationEndTime
      state.title = name
      state.description = description
      state.channel = 'Email'
      state.severity = conditionalAlertSeverity
      state.creationUser = creationUser

      // Iterate through conditionalAlertSettingConditions
      conditionalAlertSettingConditions?.forEach((condition) => {
        condition.conditionalAlertSettingParameters.forEach((param) => {
          if (param.conditionalAlertParameterType === 'SensorType') {
            state.sensor = param.sensorType1
          } else if (param.conditionalAlertParameterType === 'SetPoint') {
            state.setPoint = param.setPoint
            state.bandwidth = param.bandwidth
          }
        })
      })
      // Determine the location type and related information
      if (action.payload.buildingId) {
        LabelPrefix = 'Building'
        locationId = action.payload.buildingId
        locationDescription = action.payload.buildingDescription
      } else if (action.payload.floorId) {
        LabelPrefix = 'Floor'
        locationId = action.payload.floorId
        locationDescription = action.payload.floorDescription
        // Add floor description property here if available
      } else if (action.payload.areaId) {
        LabelPrefix = 'Area'
        locationId = action.payload.areaId
        locationDescription = action.payload.areaDescription
        // Add area description property here if available
      }
      state.location = {
        id: locationId,
        description: `${LabelPrefix}: ${locationDescription}`,
      } // Assuming you want a static value
    },
  },
})

export const {
  selectAlertSettings,
  cleanAlertSettings,
  changeAlertSettingLocation,
  changeAlertSettingDaysOfWeek,
  changeAlertSettingSensor,
  changeAlertSettingSetPoint,
  changeAlertSettingBandwidth,
  changeAlertSettingTitle,
  changeAlertSettingDescription,
  changeAlertSettingChannel,
  changeAlertSettingSeverity,
  changeAlertSettingStartTime,
  changeAlertSettingEndTime,
} = alertsSettingsSlice.actions

export default alertsSettingsSlice.reducer
