import React, { useState } from "react";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";

import {
  Avatar,
  Divider,
  Grow,
  IconButton,
  ListItemAvatar,
  ListItemText,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { DrawerHeader, StyledDrawer, StyledList, StyledListItemButton } from "./SideMenu.styled";
import { ArrowLeftOnRectangleIcon, WrenchIcon } from "@heroicons/react/24/outline";
import Enappgy from "../../resources/images/Logo.png";
import Enappgy2 from "../../resources/images/logo192.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeIndexSelected, openDrawer } from "../../redux/slices/sideMenuSlice";
import { ModifiedConstants } from "../../App";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export default function SideMenu(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  const open = useSelector((state) => state.sideMenu.openDrawer);
  const { firstName, lastName, role, email } = useSelector((state) => state.session);
  const { indexSelected } = useSelector((state) => state.sideMenu);
  const handleListItemClick = (event, name, route) => {
    dispatch(changeIndexSelected(name));
    navigate(route);
  };
  const modulesToShow = ModifiedConstants.MODULES.filter(
    (module) => !module.roles || module.roles.includes(role)
  );
  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } }); // Redirect user to home page after logout
  };

  const handleDrawerOpen = () => {
    dispatch(openDrawer(!open));
  };

  return (
    <StyledDrawer variant="permanent" open={open}>
      <DrawerHeader open={open}>
        <Grow in timeout={1000}>
          {open ? (
            <img
              src={Enappgy}
              alt={"enappgy"}
              style={{ width: 125.501, height: 32, objectFit: "contain" }}
            />
          ) : (
            <img
              src={Enappgy2}
              alt={"enappgy_consolidated"}
              style={{ width: 125.501, height: 32, objectFit: "contain" }}
            />
          )}
        </Grow>
        {open && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ width: "20px", height: "20px" }}
          >
            <ChevronLeftIcon sx={{ fontSize: "16px" }} />
          </IconButton>
        )}
      </DrawerHeader>
      <Divider />
      <Box sx={{ padding: "16px", height: "calc(100vh - 65px)" }}>
        <Box sx={{ flex: 1, height: "calc(100% - 170px)" }}>
          <StyledList>
            {modulesToShow?.map((module, index) => {
              if (
                (module === "AdminPanel" || module === "PartnerPanel") &&
                (role !== "Admin" || role === "Partner")
              ) {
                return null;
              } else {
                return (
                  <ListItem disablePadding key={index}>
                    <StyledListItemButton
                      open={open}
                      disableRipple
                      selected={indexSelected === module.name}
                      onClick={(event) => handleListItemClick(event, module.name, module.route)}
                      style={{
                        cursor: "pointer",
                        justifyContent: open ? "flex-start" : "center",
                      }}
                    >
                      {open ? (
                        <>
                          <ListItemIcon>
                            <SvgIcon sx={{ width: "22px", height: "22px" }}>{module.icon}</SvgIcon>
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="menu">{t(`Menu.${module.name}`)}</Typography>
                            }
                          />
                        </>
                      ) : (
                        <Tooltip title={t(`Menu.${module.name}`)} placement="right">
                          <ListItemIcon>
                            <SvgIcon sx={{ width: "22px", height: "22px" }}>{module.icon}</SvgIcon>
                          </ListItemIcon>
                        </Tooltip>
                      )}
                    </StyledListItemButton>
                  </ListItem>
                );
              }
            })}
          </StyledList>
        </Box>
        <Box sx={{ justifyContent: "flex-end" }}>
          <StyledList>
            <ListItem disablePadding>
              {role !== "User" && (
                <StyledListItemButton
                  open={open}
                  disableRipple
                  selected={indexSelected === "settings"}
                  onClick={(event) => handleListItemClick(event, "settings", "/settings")}
                  style={{
                    cursor: "pointer",
                    justifyContent: open ? "flex-start" : "center",
                  }}
                >
                  {open ? (
                    <>
                      <ListItemIcon sx={{ minWidth: open ? "auto" : "100%" }}>
                        <SvgIcon sx={{ width: "22px", height: "22px" }}>
                          <WrenchIcon />
                        </SvgIcon>
                      </ListItemIcon>
                      <ListItemText
                        primary={<Typography variant="menu"> {t(`Menu.Settings`)}</Typography>}
                      />
                    </>
                  ) : (
                    <Tooltip title={t(`Menu.Settings`)} placement="right">
                      <ListItemIcon>
                        <SvgIcon sx={{ width: "22px", height: "22px" }}>
                          <WrenchIcon />
                        </SvgIcon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                </StyledListItemButton>
              )}
            </ListItem>
            <ListItem disablePadding>
              <StyledListItemButton
                open={open}
                disableRipple
                onClick={handleLogout}
                style={{
                  cursor: "pointer",
                  justifyContent: open ? "flex-start" : "center",
                }}
              >
                {open ? (
                  <>
                    <ListItemIcon sx={{ minWidth: open ? "auto" : "100%" }}>
                      <SvgIcon sx={{ width: "22px", height: "22px" }}>
                        <ArrowLeftOnRectangleIcon />
                      </SvgIcon>
                    </ListItemIcon>
                    <ListItemText
                      primary={<Typography variant="menu">{t(`Menu.Logout`)}</Typography>}
                    />
                  </>
                ) : (
                  <Tooltip title={t(`Menu.Logout`)} placement="right">
                    <ListItemIcon>
                      <SvgIcon sx={{ width: "22px", height: "22px" }}>
                        <ArrowLeftOnRectangleIcon />
                      </SvgIcon>
                    </ListItemIcon>
                  </Tooltip>
                )}
              </StyledListItemButton>
            </ListItem>
            <Divider sx={{ marginTop: "6px" }} />
            <ListItem disablePadding>
              <StyledListItemButton
                open={open}
                avatar={true}
                disableRipple
                onClick={(event) => handleListItemClick(event, "account", "/account")}
                style={{ cursor: "pointer" }}
              >
                {open ? (
                  <>
                    <ListItemAvatar
                      style={{
                        justifyContent: open ? "flex-start" : "center !important",
                        display: "flex",
                        minWidth: open ? "56px !important" : "100% !important",
                      }}
                    >
                      <Avatar alt={props.name} src={props.picture} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          component="span"
                          variant="menu"
                          color="text.primary"
                          sx={{ display: "inline" }}
                        >
                          {firstName} {lastName}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant="menu"
                          sx={{
                            display: "inline",
                            fontSize: "12px !important",
                          }}
                        >
                          {email}
                        </Typography>
                      }
                    />
                  </>
                ) : (
                  <Tooltip title={t(`Menu.Account`)} placement="right">
                    <Avatar
                      sx={{ marginTop: "6px", marginRight: "12px" }}
                      alt={props.name}
                      src={props.picture}
                    />
                  </Tooltip>
                )}
              </StyledListItemButton>
            </ListItem>
          </StyledList>
        </Box>
      </Box>
    </StyledDrawer>
  );
}
