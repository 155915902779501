import {
  Box,
  Checkbox,
  DialogActions,
  DialogTitle,
  Grid,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Switch,
  Typography
} from "@mui/material";
import { useState } from "react";
import {
  ActionsGrid,
  ContentGrid,
  CustomCircularProgress,
  CustomDialogContent,
  CustomTextField,
  RequiredIndicator,
  TextFieldGrid,
  TitleGrid
} from "./Forms.styled";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { closeActionDialog } from "../../../redux/slices/actionDialogSlice";
import { useCreateProfileMutation, useGetBuildingModulesQuery } from "../../../redux/metaDataApi";
import { useDispatch, useSelector } from "react-redux";
import { StyledButton } from "../../../App.styled";
import { openSnack } from "../../../redux/slices/snackSlice";

export default function ProfileForm() {
  const dispatch = useDispatch();
  const selectedBuilding = useSelector((state) => state.adminPanel.buildingSelected);
  const { data: modules } = useGetBuildingModulesQuery(selectedBuilding, {
    skip: !selectedBuilding
  });
  const [modulesSelected, setModulesSelected] = useState([]);
  const [profileName, setProfileName] = useState("");
  const [profileVisibleToPartner, setProfileVisibleToPartner] = useState(false);
  const [createProfile, { isLoading }] = useCreateProfileMutation();

  const closeForm = () => {
    dispatch(closeActionDialog());
  };

  const handleConfirm = async () => {
    const profileModules = modulesSelected?.map((module) => ({
      buildingId: selectedBuilding,
      module
    }));

    const payload = {
      buildingId: selectedBuilding,
      description: profileName,
      visibleToPartnerRole: profileVisibleToPartner,
      profileModules
    };
    await createProfile(payload)
      .unwrap()
      .then(() => {
        dispatch(openSnack({ message: "Profiles updated", type: "success" }));
        closeForm();
      })
      .catch((error) => {
        dispatch(openSnack({ message: "Profiles could not be updated", type: "error" }));
        console.log(`Error:${error}`);
      });
  };

  const handleModuleClick = (module) => {
    const newModulesSelected = [...modulesSelected];
    const moduleIndex = newModulesSelected.indexOf(module);
    if (moduleIndex !== -1) {
      newModulesSelected.splice(moduleIndex, 1);
    } else {
      newModulesSelected.push(module);
    }
    setModulesSelected(newModulesSelected);
  };

  return (
    <>
      <DialogTitle>
        <TitleGrid container>
          <Grid item xs>
            <Grow in timeout={800}>
              <Typography variant="h3">New Profile For Building</Typography>
            </Grow>
          </Grid>
          <Grid item xs textAlign="right">
            <IconButton onClick={closeForm}>
              <SvgIcon>
                <XMarkIcon />
              </SvgIcon>
            </IconButton>
          </Grid>
        </TitleGrid>
      </DialogTitle>
      <CustomDialogContent>
        <ContentGrid container rowGap={2}>
          <TextFieldGrid item xs={12} md={12}>
            <Typography variant="p">
              Profile Name<RequiredIndicator>*</RequiredIndicator>
            </Typography>
            <CustomTextField
              size="small"
              fullWidth
              value={profileName}
              onChange={(e) => setProfileName(e.target.value)}
            ></CustomTextField>
          </TextFieldGrid>
          <TextFieldGrid item xs={12} md={12}>
            <Typography variant="p">Is profile visible to partner?</Typography>
            <Switch
              checked={profileVisibleToPartner}
              onChange={() => setProfileVisibleToPartner(!profileVisibleToPartner)}
            />
          </TextFieldGrid>
          <TextFieldGrid item xs={12} md={12}>
            <Typography variant="p">
              Modules<RequiredIndicator>*</RequiredIndicator>
            </Typography>
            <List>
              {[...modules]
                ?.sort((a, b) => a.module.localeCompare(b.module))
                .map((item, index) => {
                  return (
                    <ListItem key={index} disablePadding>
                      <ListItemButton dense onClick={() => handleModuleClick(item.module)}>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={modulesSelected?.includes(item.module)}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText primary={item.module} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
            </List>
          </TextFieldGrid>
        </ContentGrid>
      </CustomDialogContent>
      <DialogActions>
        <ActionsGrid container>
          <StyledButton onClick={() => dispatch(closeActionDialog())}> Cancel</StyledButton>
          <Box sx={{ position: "relative" }}>
            <StyledButton selected onClick={handleConfirm} disabled={isLoading}>
              Apply
            </StyledButton>
            {isLoading && <CustomCircularProgress size={24} />}
          </Box>
        </ActionsGrid>
      </DialogActions>
    </>
  );
}
