import {
  IconButton,
  SvgIcon,
  Switch,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import {
  ActionsWrapper,
  ContentWrapper,
  CustomDivider,
  CustomInsideCard,
  CustomSVGIcon,
  MainCard,
} from './AlertsSettingsCard.styled'
import {
  BellIcon,
  BuildingOfficeIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline'
import { getSensorUnit } from '../../../../helpers/functions'
import {
  changeActionDialogType,
  openActionDialog,
} from '../../../../redux/slices/actionDialogSlice'
import { useDispatch } from 'react-redux'
import { selectAlertSettings } from '../../../../redux/slices/alertSettingsSlice'
import { useUpdateConditionalAlertStatusMutation } from '../../../../redux/metaDataApi'
import { openSnack } from '../../../../redux/slices/snackSlice'
import { processAndExtractAlertsData } from '../../../../helpers/alertsFunctions'
import { useTranslation } from 'react-i18next'
import { selectProAlertSettings } from '../../../../redux/slices/alertsProSettingsSlice'

export default function AlertSettingsCard({ alert }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const processedAlert = processAndExtractAlertsData([alert])

  const [checked, setChecked] = useState(processedAlert[0].enabled)
  const [updateStatus] = useUpdateConditionalAlertStatusMutation()

  const handleCheckToggle = async () => {
    await updateStatus({ id: alert.id, enabled: !checked })
      .unwrap()
      .then(() => {
        if (!checked) {
          dispatch(openSnack({ message: 'Alert  enabled', type: 'success' }))
        } else {
          dispatch(openSnack({ message: 'Alert disabled', type: 'success' }))
        }
      })
      .catch((error) => {
        dispatch(
          openSnack({ message: 'Alert could not be disabled', type: 'error' }),
        )
        console.log(`Error:${error}`)
      })
    setChecked(!checked)
  }

  return (
    <MainCard>
      <ContentWrapper container>
        <Switch checked={checked} onChange={handleCheckToggle}></Switch>
        <CustomInsideCard>
          <CustomSVGIcon>
            <BuildingOfficeIcon />
          </CustomSVGIcon>
          <Typography variant="p" sx={{width: "100% !important"}}>
            {t("alerts.location")}<b>&nbsp;{processedAlert[0].locationDescription}</b>
          </Typography>
        </CustomInsideCard>
        <CustomDivider />
        <CustomInsideCard main>
          {processedAlert[0].type === 'DeviationAlert' ? (
             <Typography variant="p" sx={{width: "100% !important"}}>
               {t("alerts.when")}<b>&nbsp;{t(`sensor.${processedAlert[0].sensor}`)}&nbsp; </b>  {t("alerts.deviates")}
              <b>
                &nbsp;{processedAlert[0].bandwidth}
                {getSensorUnit(processedAlert[0].sensor)}&nbsp;
              </b>
              {t("alerts.from")}
              <b>
                &nbsp;{processedAlert[0].setPoint}
                {getSensorUnit(processedAlert[0].sensor)}
              </b>
            </Typography>
          ) : processedAlert[0].conditionType === 'DataType' ? (
            <Typography variant="p" sx={{width: "100% !important"}}>
              {t("alerts.when")}
              <b>
                &nbsp;{t(`sensor.${processedAlert[0].sensor}`)}
                &nbsp;
              </b>
              is&nbsp;
              {t(`logical.${processedAlert[0]?.logicalOperator}`).toLowerCase()}
              &nbsp;
              <b>{t(`sensor.${processedAlert[0].sensor2}`)}&nbsp;</b>
            </Typography>
          ) : (
            <Typography variant="p" sx={{width: "100% !important"}}>
              {t("alerts.when")}
              <b>
                &nbsp; {t(`sensor.${processedAlert[0].sensor}`)}{' '}
                {processedAlert[0]?.arithmeticOperator &&
                  t(`operator.${processedAlert[0]?.arithmeticOperator}`)}{' '}
                {processedAlert[0].sensor2}&nbsp;
              </b>{' '}
              is{' '}
              {t(`logical.${processedAlert[0]?.logicalOperator}`).toLowerCase()}
              <b>
                &nbsp;{processedAlert[0].setPoint}
                {getSensorUnit(processedAlert[0].sensor)}
              </b>
            </Typography>
          )}
        </CustomInsideCard>
        <CustomDivider />
        <CustomInsideCard>
          <CustomSVGIcon>
            <BellIcon />
          </CustomSVGIcon>
          <Typography variant="p" sx={{width: "100% !important"}}>
          {t("alerts.thenTitle")}<b>&nbsp;{t(`severities.${processedAlert[0].severity}`)}&nbsp;</b>{' '}
            {t("alerts.priority")}
          </Typography>
        </CustomInsideCard>
        <ActionsWrapper item>
          <IconButton
            onClick={() => {
              if (processedAlert[0].type === 'DeviationAlert') {
                dispatch(changeActionDialogType('editLiteAlert'))
                dispatch(selectAlertSettings(alert))
                dispatch(openActionDialog())
              } else {
                dispatch(changeActionDialogType('editProAlert'))
                dispatch(selectProAlertSettings(alert))
                dispatch(openActionDialog())
              }
            }}
          >
            <SvgIcon fontSize="small">
              <PencilSquareIcon />
            </SvgIcon>
          </IconButton>
        </ActionsWrapper>
      </ContentWrapper>
    </MainCard>
  )
}
