import { List, ListItem, ListItemButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Lottie from "lottie-react";

export const LottieUpgradeStyled = styled(Lottie)(({ theme }) => ({
  width: "100%",
  height: "150px",
}));

export const StyledList = styled(List)(({ theme }) => ({
  width: "100%",
  padding: "0px",
  height: "100%",
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: "0px",
  "&:hover": {
    backgroundColor: "transparent", // Keep the background the same on hover
  },
  "& .MuiListItemIcon-root": { 
    minWidth: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  "& .MuiListItemText-root": {
    // Correctly apply the font size using the `style` property
    style: { fontSize: "1rem" }, // Adjust as needed, e.g., '1rem' for font size
    marginTop: "0px",
    marginBottom: "0px",
  },
}));
export const StyledListItemButton = styled(ListItemButton)(() => ({
  cursor: "default",
  "&:hover": {
    backgroundColor: "transparent !important",
  },
}));