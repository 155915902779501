import { Box, Grid, Menu } from '@mui/material'
import { TreeView } from '@mui/x-tree-view'
import styled from '@emotion/styled'

export const StyledMenu = styled(Menu)(() => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: '8px', // adjusted from theme.spacing(1)
    minWidth: 200,
    width: '350px',
    color: 'rgb(55, 65, 81)', // removed conditional theme dependency
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: 'grey', // removed theme dependency, replace with a suitable color
        marginRight: '24px', // adjusted from theme.spacing(1.5)
      },
      '& .MuiButtonBase-root': {
        disableRipple: true, // disabling the ripple effect here
      },
      '&:hover, &:focus, &:active': {
        backgroundColor: 'transparent !important',
      },
    },
  },
}))

export const StyledTree = styled(TreeView)(({theme}) => ({
  maxHeight: 250,
  minHeight: 250,
  width: '100%',
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  overflowY: 'auto',
  overflowX: 'hidden',
}))

export const CustomFilterContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: 'column',
  width: '300px',
  rowGap: theme.spacing(2),
  paddingTop: theme.spacing(1), // Optional, adds a little padding at the top
  paddingBottom: theme.spacing(1), // Optional, adds a little padding at the bottom
}));


export const CustomTitleContainer = styled(Box)(() => ({
  justifyContent: 'flex-start',
  width: '100%',
}))

export const CustomItemContainer = styled(Grid)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
}))
