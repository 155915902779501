import React from 'react'
import asset from '../../resources/images/AssetTracking.png'
export default function AssetTracking() {
  return (
    <div>
    <img
      src={asset}
      alt="assetTracking"
      style={{ width: "100%", height: "calc(100vh - 118px)", objectFit: "contain" }}
    /></div>
  )
}
