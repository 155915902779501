import React from "react";
import { StyledButton } from "../../../App.styled";
import pro from "../../../resources/lotties/pro.json";
import { t } from "i18next";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import {
  Box,
  DialogActions,
  DialogTitle,
  Grid,
  Grow,
  IconButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Typography,
} from "@mui/material";
import {
  ActionsGrid,
  CustomCircularProgress,
  CustomDialogContent,
  TitleGrid,
} from "../Forms/Forms.styled";
import { useDispatch, useSelector } from "react-redux";
import { closeActionDialog } from "../../../redux/slices/actionDialogSlice";
import {
  LottieUpgradeStyled,
  StyledList,
  StyledListItem,
  StyledListItemButton,
} from "./Upgrade.styled";
import axios from "axios";
import { Constants } from "../../../helpers/constants";

export default function UpgradePro() {
  const dispatch = useDispatch();
  const [request, setRequest] = React.useState(false);
  const [displayMessage, setDisplayMessage] = React.useState(false);
  const { firstName, lastName, email } = useSelector((state) => state.session);

  const prepareChatTemplate = () => {
    const chatTemplate = JSON.parse(JSON.stringify(Constants.CHAT_TEMPLATE)); // Deep clone to avoid mutating the original
    chatTemplate.cardsV2[0].card.header.title = `${firstName} ${lastName}`;
    chatTemplate.cardsV2[0].card.header.subtitle = `User request to be upgraded to Pro`;
    chatTemplate.cardsV2[0].card.sections[0].widgets[0].decoratedText.text = `${email}`;
    return chatTemplate;
  };
  
  const onUpgradeClick = async () => {
    const postData = prepareChatTemplate();
    setRequest(true);
    try {
      await axios.post(process.env.REACT_APP_GOOGLE_WEBHOOK, postData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setRequest(false);
      setDisplayMessage(true);
    } catch (error) {
      console.error("Error making post request:", error);
    }
  };

  const closeForm = () => {
    dispatch(closeActionDialog());
  };

  return (
    <div style={{ maxWidth: "350px" }}>
      <DialogTitle>
        <TitleGrid container>
          <Grid item xs={10}>
            <Grow in timeout={800}>
              <Typography variant="h3">
                {t("multisense.upgradealerts.title1")}
              </Typography>
            </Grow>
          </Grid>
          <Grid item xs textAlign="right">
            <IconButton onClick={closeForm}>
              <SvgIcon>
                <XMarkIcon />
              </SvgIcon>
            </IconButton>
          </Grid>
        </TitleGrid>
      </DialogTitle>
      <CustomDialogContent>
        <Typography
          variant="p"
          sx={{
            alignSelf: "flex-start",
            textAlign: "left",
            maxWidth: "100",
            marginBottom: "16px",
            color: "#4c4c4c",
          }}
        >
          {t("multisense.upgradealerts.title2")}
        </Typography>

        <LottieUpgradeStyled animationData={pro} loop={true} />

        <Typography
          variant="p"
          sx={{ alignSelf: "flex-start", marginTop: "16px" }}
        >
          {t("multisense.upgradealerts.description")}
        </Typography>
        <StyledList>
          {[...Array(5)].map((_, index) => (
            <StyledListItem key={index} disablePadding>
              <StyledListItemButton>
                <ListItemIcon>
                  <SvgIcon
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: "#1c7ad4 !important",
                      borderRadius: "20px",
                    }}
                  >
                    <CheckCircleIcon sx={{ width: "20px", height: "20px" }} />
                  </SvgIcon>
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="p">
                      {t(`multisense.upgradealerts.feature${index + 1}`)}
                    </Typography>
                  }
                />
              </StyledListItemButton>
            </StyledListItem>
          ))}
        </StyledList>
      </CustomDialogContent>
      <DialogActions>
        <ActionsGrid container>
          {displayMessage ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <SvgIcon
                sx={{
                  width: "20px",
                  height: "20px",
                  color: "green !important",
                  borderRadius: "20px",
                }}
              >
                <CheckCircleIcon sx={{ width: "20px", height: "20px" }} />
              </SvgIcon>
              <Typography variant="p">
                {t(`multisense.upgradealerts.request`)}
              </Typography>
            </div>
          ) : (
            <Box sx={{ position: "relative" }}>
              <StyledButton selected onClick={onUpgradeClick}>
                {t("button.upgrade")}
              </StyledButton>
              {request && <CustomCircularProgress size={24} />}
            </Box>
          )}
        </ActionsGrid>
      </DialogActions>
    </div>
  );
}
