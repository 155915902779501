import { Card, Divider, Grid, SvgIcon } from '@mui/material'
import { styled } from '@mui/material/styles'

export const MainCard = styled(Card)(() => ({
  border: '1px solid #eee',
  boxShadow: 'none',
  padding: '16px',
  borderRadius: '12px',
  minHeight: '90px'

}))

export const ContentWrapper = styled(Grid)(() => ({
  width: '100%',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
}))

export const ActionsWrapper = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  flexGrow: 1,
}))

export const CustomDivider = styled(Divider)(() => ({
  alignSelf: 'center',
  flexGrow: 1,
}))

export const CustomInsideCard = styled(Card)(({ theme, main }) => ({
  flexGrow: 1,
  backgroundColor: 'rgb(245, 245, 244 , 1)',
  boxShadow: 'none',
  border: '1px solid #e5e7eb',
  justifyContent: 'space-evenly',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  width:  main? "360px": "250px"
}))
export const CustomSVGIcon = styled(SvgIcon)(({ theme }) => ({
  fontSize: 'large',
  marginRight: theme.spacing(1),
  flexGrow: 1,
}))
