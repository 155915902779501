import React, { useEffect, useLayoutEffect, useState } from "react";
import { getBucketTimeDynamically, getFilter } from "../../helpers/functions";
import { useDispatch, useSelector } from "react-redux";
import { useGetDataConsumptionByFilterQuery } from "../../redux/dataApi";
import { initMultisense } from "../../redux/slices/multisenseSlice";
import { Box,CircularProgress, Grid, IconButton, SvgIcon, Typography } from "@mui/material";
import { t } from "i18next";
import DataChart from "../../components/DataChart/DataChart";
import { openPopover } from "../../redux/slices/popoversSlice";
import { ArrowDownCircleIcon, ArrowDownIcon } from "@heroicons/react/24/solid";
import { toggleDrawer } from "../../redux/slices/mobileDrawer";
import CalendarMobile from "../CalendarMobile/CalendarMobile";
import { format, parseISO } from "date-fns";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { getAverage, getMaxValue, getSum } from "../../helpers/multisenseFunctions";
import ConsolidatedData from "../../components/MultiSense/ConsolidatedData/ConsolidatedData";

export default function MultisenseMobile() {
  const dispatch = useDispatch();
  const [loadingTimeExceeded, setLoadingTimeExceeded] = useState(false);
  const customBuckets = useSelector((state) => state.multisense.customBucket);
  const selectedBuilding = useSelector((state) => state.buildings.selectedBuilding);
  const { buildings } = useSelector((state) => state.buildings);
  const { id: tenantId } = useSelector((state) => state.session.selectedTenant);
  const calendarSelection = useSelector((state) => state.calendar.calendarSelection);
  const { selectedSensor, selectedLocation, selectedLocationIds, selectedLocationLevel } = useSelector((state) => state.multisense.selectedValues);
  const { selectedValues } = useSelector((state) => state.multisense);

  const {
    data: series1,
    isFetching: isLoading1,
    refetch: refetch1,
  } = useGetDataConsumptionByFilterQuery(
    {
      filter: getFilter(selectedSensor[0], calendarSelection, selectedLocationLevel, customBuckets),
      locationId: selectedLocationIds[0],
      tenantId,
    },
    {
      skip: !selectedLocationIds[0] || !calendarSelection || !selectedSensor[0] || !tenantId,
    }
  );

  const [formatDateStart, setFormatDateStart] = useState(null)
  const [formatDateEnd, setFormatDateEnd] = useState(null)

  useEffect(() => {
    try {
      if (calendarSelection.dateStart && calendarSelection.dateEnd) {
        setFormatDateStart(format(parseISO(calendarSelection.dateStart), 'dd MMM yy'));
        setFormatDateEnd(format(parseISO(calendarSelection.dateEnd), 'dd MMM yy'));
      }
    } catch (error) {
      console.error('Error parsing dates:', error);
    }
  }, [calendarSelection]);


  const isFetchingData = isLoading1 

  useLayoutEffect(() => {
    if (buildings.length > 0) {
      const index = buildings.findIndex((item) => item.id === selectedBuilding.id);

      if (index !== -1) {
        // Create a new array that includes the selected object at the beginning
        // without mutating the original `buildings` array.
        const newBuildings = [
          buildings[index], // Add the selected building to the beginning
          ...buildings.slice(0, index), // Add all items before the selected one
          ...buildings.slice(index + 1), // Add all items after the selected one
        ];
        dispatch(initMultisense(newBuildings));
      } else {
        // No changes needed, just use the original buildings array as is.
        dispatch(initMultisense(buildings));
      }
    }
  }, [buildings, dispatch, selectedBuilding]);

  useEffect(() => {
    if (customBuckets !== "" && customBuckets !== undefined) {
      refetch1();
    }
  }, [customBuckets, refetch1]);

  useEffect(() => {
    let timer;
    if (isFetchingData) {
      // Set a timer for 40 seconds (40000 milliseconds)
      timer = setTimeout(() => {
        setLoadingTimeExceeded(true);
      }, 20000);
    } else {
      // If loading finishes before 40 seconds, clear the timer
      clearTimeout(timer);
      // Reset the loading time exceeded message
      if (loadingTimeExceeded) {
        setLoadingTimeExceeded(false);
      }
    }

    // Clear the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [isFetchingData, loadingTimeExceeded]);

  const { data: ComparativeSeries } = useGetDataConsumptionByFilterQuery(
    {
      filter: getFilter(selectedSensor[0], calendarSelection, selectedLocationLevel, customBuckets, true),
      locationId: selectedLocationIds[0],
      tenantId,
    },
    {
      skip: !selectedLocationIds[0] || !calendarSelection || !selectedSensor[0] || !tenantId,
    }
  );

  const bucket = customBuckets !== "" && customBuckets !== undefined ? customBuckets : getBucketTimeDynamically(calendarSelection.dateStart, calendarSelection.dateEnd);

  return (
    <Box sx={{ width: "100%", height: "100%", rowGap: "16px", gap: 1 }} spacing={1}>
      <CalendarMobile/>
      <Box display="flex" flexDirection="row"  sx={{ width: "100%", paddingY: "8px" , justifyContent: "space-between"}}>
        <IconButton onClick={() => dispatch(toggleDrawer("data"))} sx={{padding: "0px" }}disableTouchRipple >
        <Typography variant="h2" noWrap>
            {t(`sensor.${selectedSensor[0]}`)}
            <SvgIcon sx={{ height: "20px", width: "14px", marginLeft: "2px" , marginTop: "3px" }}>
            <ChevronDownIcon />
          </SvgIcon>
          </Typography>
        </IconButton>

        <IconButton onClick={() => dispatch(toggleDrawer("location"))} sx={{display: "flex", alignItems: "center"}} disableTouchRipple>
        <Typography variant="h2" noWrap sx={{ 
      width: "fit-content", 
      display: 'flex', // Use flex to align items
      alignItems: 'center', // Center items vertically
      justifyContent: 'center' // Center items horizontally (if necessary)
    }}>
            {selectedLocation[0]}
             <SvgIcon sx={{ height: "20px", width: "14px", marginX: "2px", marginTop: "3px" }}>
            <ChevronDownIcon />
          </SvgIcon>
          </Typography>
        </IconButton>
      </Box>
      <Grid sx={{ height: "35%", width: "100%" }}>
        {isFetchingData ? (
          <Box style={{ display: "flex", height: "100%", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "18px" }}>
            <CircularProgress />
            {loadingTimeExceeded && <div>{t("chart.fetching")}</div>}
          </Box>
        ) : (
          <DataChart
            bucket={bucket}
            series1={series1}
            selectedSensor={selectedSensor}
            selectedLocation={selectedLocation}
            selectedPeriod={calendarSelection.selectedPeriod}
          />
        )}
      </Grid>
      <ConsolidatedData
        average={getAverage(series1, selectedSensor[0])}
        peak={getMaxValue(series1, selectedSensor[0])}
        period={getSum(series1)}
        average2={getAverage(ComparativeSeries, selectedSensor[0])}
        peak2={getMaxValue(ComparativeSeries, selectedSensor[0])}
        period2={getSum(ComparativeSeries)}
      />
    </Box>
  );
}
