import { CustomCard } from "../../../App.styled";
import {
  useDeleteUserMutation,
  useGetTenantsWithBuildingProfilesQuery
} from "../../../redux/metaDataApi";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { SvgIcon } from "@mui/material";
import {
  getBuildingDescription,
  getBuildingOptions,
  getProfileDescriptions
} from "../../../helpers/userSettingsFunctions";
import { changeUserSelection } from "../../../redux/slices/adminPanelSlice";
import {
  changeDetailsDialogType,
  openDetailsDialog
} from "../../../redux/slices/detailsDialogSlice";
import { openSnack } from "../../../redux/slices/snackSlice";

export default function UserSettings({ users }) {
  const dispatch = useDispatch();
  const { data: tenants } = useGetTenantsWithBuildingProfilesQuery();
  const [deleteUser] = useDeleteUserMutation();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (users) {
      setRows(users);
    }
  }, [users]);

  function generateBuildingColumn(tenants) {
    return {
      field: "buildingDescription",
      headerName: "Buildings",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueGetter: ({ row }) => getBuildingDescription({ row, tenants }),
      valueOptions: ({ row }) => getBuildingOptions({ row, tenants })
    };
  }

  function generateProfileColumn(tenants) {
    return {
      field: "profiles",
      headerName: "Profile",
      flex: 1,
      type: "singleSelect",
      valueGetter: ({ row }) => getProfileDescriptions({ row, tenants })
    };
  }

  function generateActionsColumn() {
    return {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      getActions: ({ row }) => [
        <GridActionsCellItem
          icon={
            <SvgIcon>
              <PencilSquareIcon />
            </SvgIcon>
          }
          label="Save"
          color="inherit"
          onClick={() => {
            dispatch(openDetailsDialog());
            dispatch(changeDetailsDialogType("user"));
            dispatch(changeUserSelection(row));
          }}
        />,
        <GridActionsCellItem
          icon={
            <SvgIcon>
              <TrashIcon />
            </SvgIcon>
          }
          label="Save"
          color="inherit"
          onClick={() => onDelete(row.id)}
        />
      ]
    };
  }

  const columns = [
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1
    },
    {
      field: "role",
      headerName: "Role",
      type: "singleSelect",
      valueOptions: ["Admin", "Manager", "User", "Partner"],
      flex: 1
    },
    generateTenantColumn(tenants),
    generateBuildingColumn(tenants),
    generateProfileColumn(tenants),
    generateActionsColumn()
  ];

  const onDelete = async (id) => {
    const confirmed = window.confirm("Are you sure you want to delete this user?");
    if (confirmed) {
      await deleteUser(id)
        .unwrap()
        .then(() => dispatch(openSnack({ message: "User deleted", type: "success" })))
        .catch((error) => {
          dispatch(openSnack({ message: "User cannot be deleted", type: "error" }));
          console.log(`Error:${error}`);
        });
    }
  };

  return (
    users && (
      <CustomCard sx={{ height: "500px" }}>
        {rows.length > 0 && (
          <DataGrid style={{ width: "100%" }} rows={rows} columns={columns} editMode="row" />
        )}
      </CustomCard>
    )
  );
}

function generateTenantColumn(tenants) {
  return {
    field: "tenant",
    headerName: "Tenants",
    editable: true,
    flex: 1,
    type: "singleSelect",
    valueOptions: (params) => params.row.userTenants?.map((t) => t.tenant?.description),
    valueGetter: (params) => {
      return params.row.tenant || params.row.userTenants?.[0]?.tenant?.description || "";
    }
  };
}
