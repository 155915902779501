import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Constants } from '../../helpers/constants';
import { t } from 'i18next';
import axios from 'axios';
import { CustomCard, StyledButton } from '../../App.styled';
import { Box, Grid, SvgIcon, Typography } from '@mui/material';
import { changeIndexSelected } from '../../redux/slices/sideMenuSlice';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { CustomCircularProgress } from '../../components/ActionDialog/Forms/Forms.styled';
import Locked from "../../resources/images/locked.png";

export default function LockedMobile(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [request, setRequest] = useState(false);
    const [displayMessage, setDisplayMessage] = useState(false);
    const { firstName, lastName, email } = useSelector((state) => state.session);
  
    const prepareChatTemplate = () => {
      const chatTemplate = JSON.parse(JSON.stringify(Constants.CHAT_TEMPLATE)); // Deep clone to avoid mutating the original
      chatTemplate.cardsV2[0].card.header.title = `${firstName} ${lastName}`;
      chatTemplate.cardsV2[0].card.header.subtitle = `User request to have new module; ${t(
        `Menu.${props.module}`
      )}`;
      chatTemplate.cardsV2[0].card.sections[0].widgets[0].decoratedText.text = `${email}`;
      return chatTemplate;
    };
  
    const onUpgradeClick = async () => {
      const postData = prepareChatTemplate();
      setRequest(true);
      try {
        await axios.post(process.env.REACT_APP_GOOGLE_WEBHOOK, postData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setRequest(false);
        setDisplayMessage(true);
      } catch (error) {
        console.error("Error making post request:", error);
      }
    };
  
    return (
      <CustomCard variant="outlined">
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          rowGap={4}
          flexDirection="column"
           sx={{
    padding: { xs: '20px', sm: '50px' }, // Adjust padding for small screens
  }}
        >
          <Grid item >
            <Typography variant="h1" sx={{fontWeight: "600 !important",   fontSize: { xs: '1.5rem', sm: '2rem', md: '3rem' }, marginBottom: "4px"}} >{t(`locked.message1`)}</Typography>
            <Typography variant="p" sx={{ fontSize: { xs: '0.8rem', sm: '1rem' }, textAlign: "left"}}>{t(`locked.message2`)}</Typography>
          </Grid>
          <Grid item>
            <img alt="unlock-service" src={Locked} style={{ maxWidth: '100%', height: 'auto' }} />
          </Grid>
          <Grid item>
            <Grid
              container
              flexDirection="row"
              spacing={2}
              justifyContent="space-between"
            >
              <Grid item>
                <StyledButton
                  onClick={() => {
                    dispatch(changeIndexSelected("Multisense"));
                    navigate("/");
                  }}
                >
                  {t(`locked.message4`)}
                </StyledButton>
              </Grid>
              <Grid item>
                {displayMessage ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <SvgIcon
                      sx={{
                        width: "20px",
                        height: "20px",
                        color: "green !important",
                        borderRadius: "20px",
                      }}
                    >
                      <CheckCircleIcon sx={{ width: "20px", height: "20px" }} />
                    </SvgIcon>
                    <Typography variant="p">
                      {t(`multisense.upgradealerts.request`)}
                    </Typography>
                  </div>
                ) : (
                  <Box sx={{ position: "relative" }}>
                    <StyledButton selected onClick={onUpgradeClick}>
                      {t(`locked.message3`)}
                    </StyledButton>
                    {request && <CustomCircularProgress size={24} />}
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CustomCard>
    );
  }
  
