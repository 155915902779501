import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  DialogActions,
  Divider,
  InputAdornment,
} from "@mui/material";
import { timeStringToDate } from "../../../helpers/calendarFunctions";
import { ActionsGrid, CustomDialogContent } from "../../ActionDialog/Forms/Forms.styled";
import { StyledButton } from "../../../App.styled";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { closeActionDialog } from "../../../redux/slices/actionDialogSlice";
import { format, startOfDay } from "date-fns";
import {
  useEditBuildingMutation,
  useGetBuildingByIdQuery,
  usePostMassiveFlexibleRatesMutation,
} from "../../../redux/metaDataApi";
import { generateRates, getSensorUnit } from "../../../helpers/functions";
import { StyledDateBox } from "../PartnerPanel.styled";
import { CustomTextField } from "../../Costs/Costs.styled";
import { openSnack } from "../../../redux/slices/snackSlice";
import { useCurrencySymbol } from "../../../redux/customHooks/customHooks";

export default function WaterFlexible({ rates }) {
  const dispatch = useDispatch();
  const currency = useCurrencySymbol()
  const [isFixed, setIsFixed] = useState(
    rates[0]?.isPeakHour && rates[0]?.isWeekendRate && rates[0]?.isWeekdayRate
  );
  const { selectedBuilding } = useSelector((state) => state.buildings);
  const [sendRates] = usePostMassiveFlexibleRatesMutation();
  const [editBuilding] = useEditBuildingMutation();
  const [valid, setValid] = useState(true);
  const peakRate = rates?.find((rate) => rate.isPeakHour);
  const weekendRate = rates?.find((rate) => rate.isWeekendRate);
  const offPeakRate = rates?.find((rate) => rate.isWeekdayRate && !rate.isPeakHour);
  const { data: buildingMatch } = useGetBuildingByIdQuery(selectedBuilding.id);
  const [benchmarkRate, setBenchmarkRate] = useState(buildingMatch.benchmarkWaterM2Year);
  const [benchmarkCost, setBenchmarkCost] = useState(buildingMatch.benchmarkWaterCost);
  const [waterRatesForm, setWaterRatesForm] = useState({
    peakRate: peakRate ? peakRate.rate : "",
    peakFromHour: timeStringToDate(peakRate?.fromHour || "00:00"),
    peakToHour: timeStringToDate(peakRate?.toHour || "00:00"),
    offPeakRate: offPeakRate ? offPeakRate?.rate : "",
    weekendRate: weekendRate ? weekendRate?.rate : "",
  });

  const handleChange = (field, value) => {
    setValid(true);
    const newWaterRatesForm = { ...waterRatesForm, [field]: value };
    setWaterRatesForm(newWaterRatesForm);
  };

  const onSend = async () => {
    let payload = {};
    let updatedBuilding = {
      ...buildingMatch,
      benchmarkWaterM2Year: benchmarkRate,
      benchmarkWaterCost: benchmarkCost,
    };
    if (isFixed) {
      payload = [
        {
          isPeakHour: true,
          buildingId: selectedBuilding?.id,
          sensorType: "Water",
          isWeekdayRate: true,
          isWeekendRate: true,
          fromHour: "00:00",
          toHour: "00:00",
          rate: waterRatesForm.peakRate,
        },
      ];
    } else {
      if (
        format(waterRatesForm.peakFromHour, "HH:mm") === "00:00" &&
        format(waterRatesForm.peakToHour, "HH:mm") === "00:00"
      ) {
        setValid(false);
        dispatch(
          openSnack({
            message:
              "Peak hours cannot take a full day. If this is the case, please use fixed rate",
            type: "error",
          })
        );
        return;
      }
      payload = generateRates(waterRatesForm, "Water", selectedBuilding);
    }

    await sendRates(payload)
      .unwrap()
      .then(async () => {
        await editBuilding(updatedBuilding)
          .unwrap()
          .then(() => dispatch(openSnack({ message: t("snack.costSucces"), type: "success" })))
          .catch((error) => {
            dispatch(openSnack({ message: t("toast.errorMessage"), type: "error" }));
            console.log(`Error:${error}`);
          });
      })
      .catch((error) => {
        dispatch(openSnack({ message: t("toast.errorMessage"), type: "error" }));
        console.log(`Error:${error}`);
      });
    dispatch(closeActionDialog());
  };

  return (
    <>
    <CustomDialogContent sx={{ p: "12px 16px", height: "480px", maxHeight: "480px !important", width: "100%" }}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Typography variant="h3">{t("flexibleRates.title")}</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          height: "fit-content",
          justifyContent: "center",
          alignContent: "center",
          width: "100%",
        }}
        rowSpacing={2}
      >
        <Grid item xs={12}>
          <Typography variant="p">
            {" "}
            {t("flexibleRates.benchmarkWaterYear", { unit: getSensorUnit("Water") })}{" "}
          </Typography>
          <CustomTextField
            fullWidth
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            type="number"
            value={benchmarkRate}
            onChange={(e) => setBenchmarkRate(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="p">
            {" "}
            {t("flexibleRates.benchmarkWaterCost", { unit: getSensorUnit("Water"), symbol: currency }) }{" "}
          </Typography>
          <CustomTextField
            fullWidth
            InputProps={{
              inputProps: {
                min: 0,
              },
              startAdornment: (
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="p" sx={{ opacity: "0.6" }}>
                    {currency}
                  </Typography>
                </InputAdornment>
              ),
            }}
            type="number"
            value={benchmarkCost}
            onChange={(e) => setBenchmarkCost(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Typography variant="h3">{t("flexibleRates.subTitle")}</Typography>
        </Grid>
        <Grid item xs sx={{ display: "flex", justifyContent: "flex-end" }}>
          <FormControlLabel
            control={
              <Switch
                checked={isFixed}
                onChange={(e) => {
                  setIsFixed(e.target.checked);
                  if (e.target.checked) {
                    const startHour = startOfDay(new Date());
                  }
                }}
              />
            }
            label={<Typography variant="p">{t("flexibleRates.toggle")}</Typography>}
            labelPlacement="start"
          />
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          height: "fit-content",
          justifyContent: "center",
          alignContent: "center",
          width: "100%",
        }}
        rowSpacing={2}
      >
        <Grid item xs={12}>
          <Typography variant="p">
            {isFixed
              ? t("flexibleRates.fixed", { unit: getSensorUnit("Water") })
              : t("flexibleRates.peak", { unit: getSensorUnit("Water") })}
          </Typography>
          <CustomTextField
            fullWidth
            InputProps={{
              inputProps: {
                min: 0,
              },
              startAdornment: (
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="p" sx={{ opacity: "0.6" }}>
                    {currency}
                  </Typography>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
            type="number"
            value={waterRatesForm.peakRate}
            onChange={(e) => handleChange("peakRate", parseFloat(e.target.value))}
          />
        </Grid>
        <Grid item container spacing={1} alignItems="center">
          <Divider flexItem />
          <Grid item xs>
            <Typography variant="p">{t("flexibleRates.fromTime")}</Typography>
            <StyledDateBox
              value={waterRatesForm.peakFromHour}
              type="time"
              interval={60}
              displayFormat="HH:mm"
              disabled={isFixed}
              isValid={valid}
              onValueChanged={(e) => handleChange("peakFromHour", e.value)}
            />
          </Grid>
          <Grid item xs>
            <Typography variant="p">{t("flexibleRates.toTime")}</Typography>
            <StyledDateBox
              value={waterRatesForm.peakToHour}
              type="time"
              interval={60}
              displayFormat="HH:mm"
              disabled={isFixed}
              isValid={valid}
              onValueChanged={(e) => handleChange("peakToHour", e.value)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="p">
            {t("flexibleRates.offPeak", { unit: getSensorUnit("Water") })}
          </Typography>
          <CustomTextField
            fullWidth
            variant="outlined"
            size="small"
            type="number"
            disabled={isFixed}
            value={isFixed ? waterRatesForm.peakRate : waterRatesForm.offPeakRate}
            onChange={(e) => handleChange("offPeakRate", parseFloat(e.target.value))}
            InputProps={{
              inputProps: {
                min: 0,
              },
              startAdornment: (
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="p" sx={{ opacity: "0.6" }}>
                    {currency}
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="p">
            {t("flexibleRates.weekend", { unit: getSensorUnit("Water") })}
          </Typography>
          <CustomTextField
            fullWidth
            variant="outlined"
            size="small"
            type="number"
            disabled={isFixed}
            value={isFixed ? waterRatesForm.peakRate : waterRatesForm.weekendRate}
            onChange={(e) => handleChange("weekendRate", parseFloat(e.target.value))}
            InputProps={{
              inputProps: {
                min: 0,
              },
              startAdornment: (
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="p" sx={{ opacity: "0.6" }}>
                    {currency}
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </CustomDialogContent>
    <DialogActions>
      <ActionsGrid container>
        <StyledButton
          onClick={() => {
            dispatch(closeActionDialog());
          }}
        >
          {t(`button.cancel`)}
        </StyledButton>
        <Box sx={{ position: "relative" }}>
          <StyledButton selected onClick={onSend}>
            {t(`button.apply`)}
          </StyledButton>
        </Box>
      </ActionsGrid>
    </DialogActions>
  </>
  );
}
