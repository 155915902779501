import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, SvgIcon, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import TrashIcon from "@mui/icons-material/Delete";
import { DeleteButton } from "../../../App.styled";

export default function Delete({ onDelete, item }) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton onClick={handleClickOpen} size="small">
        <SvgIcon>
          <TrashIcon />
        </SvgIcon>
      </IconButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"  maxWidth={"xs"}>
        <DialogTitle id="alert-dialog-title" variant="h3">
          <Typography variant="h3" sx={{ justifyContent: "center !important" }}>
            {t("form.title")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="p" sx={{ textAlign: "flex-start !important" }}>
              {" "}
              {t("form.deleteMessage")}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", flexDirection: "column", gap: "16px" ,padding:"20px"}}>
          <DeleteButton  selected onClick={() => onDelete(item)}>
            {t("button.delete")}
          </DeleteButton>
          <DeleteButton  onClick={handleClose}>{t("button.cancel")}</DeleteButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
