import { styled } from "@mui/material/styles";

import { TextField, Button, DialogContent, Grid, Autocomplete, CircularProgress, Card, Select } from "@mui/material";
import Lottie from "lottie-react";
import { TabList, TabPanel } from "@mui/lab";

export const CustomTextField = styled((props) => (
  <TextField
    {...props}
    InputProps={{
      ...props.InputProps, // Spread existing input props
      endAdornment: props.endAdornment, // Ensure endAdornment is passed correctly
    }}
  />
))(() => ({
  "& .MuiOutlinedInput-root": {
    height: "40px",
    "& input": {
      height: "100%",
      padding: "0 14px",
    },
    "& fieldset": {
      border: "1px solid #00000014",
      borderRadius: "6px",
      backgroundColor: "transparent",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1.5px solid #00000040",
    },
    "&.Mui-focused fieldset": {
      border: "2px solid #00000040",
    },
    "&.Mui-focused": {
      "& label": {
        color: "#1c7ad4",
      },
    },
  },
}));

export const CustomAutocomplete = styled(Autocomplete)(() => ({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    height: "40px", 
    "& .MuiInputBase-input": {
      height: "100%",
      padding: "0 9px",
      fontWeight: "400 !important",
      fontSize: "14px ! important",
      lineHeight: "20px ! important",
      fontFamily: "'Poppins', sans-serif !important",
      color: "#424242 !important",
    },
    "& fieldset": {
      border: "1px solid #00000014",
      borderRadius: "6px",
      backgroundColor: "transparent",
    },
  },
  backgroundColor: "#ffffff",
}));

export const CustomSelect = styled(Select)(() => ({
  height: "34px",
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "1.5px solid #00000040",
  },
  fieldset: {
    border: "1px solid #00000014",
    borderRadius: "6px",
    backgroundColor: "transparent",
    height: "40px",
  },
}));

export const StyledNoDataLottie = styled(Lottie)(() => ({
  width: "25%",
  height: "20%",
  alignSelf: "center",
}));
export const CustomDialogContent = styled(DialogContent)(() => ({
  maxHeight: "450px",
  paddingInline: "16px",
}));

export const DeleteButton = styled(Button)(() => ({
  backgroundColor: "#d92232",

  color: "#FFFFFF",

  textTransform: "none",

  borderRadius: "2px",

  paddingInline: "16px",

  "&:hover": {
    backgroundColor: "#ea3343",
  },
}));

export const AutocompleteGrid = styled(Grid)(() => ({
  paddingInline: "8px",
  paddingBlock: "2px",
  display: "flex",
  flexDirection: "column",
}));

export const TextFieldGrid = styled(Grid)(() => ({
  paddingInline: "8px",
  paddingBlock: "2px",
  display: "flex",
  flexDirection: "column",
}));

export const TitleGrid = styled(Grid)(() => ({
  flexWrap: "nowrap",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const ContentGrid = styled(Grid)(() => ({
  height: "100%",
}));

export const ActionsGrid = styled(Grid)(() => ({
  flexWrap: "nowrap",
  justifyContent: "space-between",
  paddingInline: "16px",
  paddingBottom: "16px",
}));

export const RequiredIndicator = styled("span")(() => ({
  color: "red",
}));

export const CustomCircularProgress = styled(CircularProgress)(() => ({
  color: "#FFFFFF",
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: "-12px",
  marginLeft: "-12px",
}));

export const CustomAlertCard = styled(Card)(({ theme }) => ({
  flexGrow: 1,
  width: "100%",
  backgroundColor: "rgb(245, 245, 244 , 1)",
  boxShadow: "none",
  border: "1px solid #e5e7eb",
  flexDirection: "column",
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2),
  rowGap: theme.spacing(2),
}));

export const StyledTabs = styled(TabList)(({ theme }) => ({
  fontSize: "12px",
  textTransform: "none",
  ".MuiButtonBase-root":{
    textTransform: "none",
  }
}));


export const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  padding: 0,
}));
