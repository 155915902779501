import React, { useLayoutEffect, useState } from "react";
import {
  Legend,
  Series,
  ValueAxis,
  Label,
  CommonAxisSettings,
  Grid,
  ArgumentAxis,
  Tick,
  Tooltip,
  ConstantLine,
  Strip,
  Point
} from "devextreme-react/chart";
import { CustomChart } from "./DataChart.styled";
import { formatNumber, getSensorUnit } from "../../helpers/functions";
import {
  formatSeries,
  generateSeriesData,
  getDominantType,
  getLabelUnit
} from "../../helpers/multisenseFunctions";
import { useTranslation } from "react-i18next";
import { TooltipTemplate } from "./TooltipTemplate";
import { useDispatch, useSelector } from "react-redux";
import { handleCustomBuckets } from "../../redux/slices/multisenseSlice";
import { Constants } from "../../helpers/constants";
import { Checkbox, Typography } from "@mui/material";
import { differenceInDays, parse } from "date-fns";
import { useWindowDimensions } from "../../redux/customHooks/customHooks";

export default function DataChart(props) {
  let lastDisplayedDate = "";
  const dispatch = useDispatch();
  const [chartData, setChartData] = useState([]);
  const [bandwidth, setBandwidth] = useState({});
  const [architectureSources, setArchitectureSources] = useState([]);
  const [uniqueUnits, setUniqueUnits] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const { t } = useTranslation();
  const [dominantType, setDominantType] = useState(null);
  const [labelUnit, setLabelUnit] = useState(null);
  const selectedAlert = useSelector((state) => state.alerts.selectedAlert[0]);
  const customBucket = useSelector((state) => state.multisense.customBucket);
  const { indexSelected } = useSelector((state) => state.sideMenu);
  const calendarSelection = useSelector((state) => state.calendar.calendarSelection);
  const { width } = useWindowDimensions();
  const handleChange = (e) => {
    if (e.target.checked) {
      dispatch(handleCustomBuckets(Constants.BUCKET_TIME.MINUTE));
    } else {
      dispatch(handleCustomBuckets(""));
    }
  };

  useLayoutEffect(() => {
    if (props.series1) {
      const allSeries = [];
      props.selectedSensor.forEach((sensor, index) => {
        if (props[`series${index + 1}`]) {
          allSeries.push(
            generateSeriesData(
              props[`series${index + 1}`],
              sensor,
              props.selectedLocation[index] //TODO: REMOVE this and use null
            )
          );
        }
      });

      props.selectedLocation.forEach((location, index) => {
        if (props[`series${index + 1}`]) {
          allSeries.push(generateSeriesData(props[`series${index + 1}`], null, location));
        }
      });
      if (props.showAlerts) {
        allSeries.push(props.series3, null, null);
      }

      const startDate = parse(calendarSelection.dateStart, "yyyy-MM-dd", new Date());
      const endDate = parse(calendarSelection.dateEnd, "yyyy-MM-dd", new Date());

      const daysDifference = differenceInDays(endDate, startDate);

      const mappingSensor = props.selectedSensor.length === 1 ? props.selectedSensor[0] : null;
      const { unitToSensorMap, architectureSources, consolidatedData } = formatSeries(
        props.bucket,
        daysDifference,
        mappingSensor,
        ...allSeries
      );

      const computedDominantType = getDominantType(props.selectedSensor, props.selectedLocation);
      setDominantType(computedDominantType);
      const computedLabelUnit = getLabelUnit(computedDominantType, architectureSources);
      setLabelUnit(computedLabelUnit);

      let filteredArchitectureSources = [];
      if (dominantType === null) {
        filteredArchitectureSources = architectureSources
          .filter((item) => item.type === "sensor")
          .slice(0, 1);
      } else {
        filteredArchitectureSources = architectureSources.filter(
          (item) => item.type === dominantType
        );
      }

      setUniqueUnits(Object.keys(unitToSensorMap));
      setArchitectureSources(filteredArchitectureSources);
      setChartData(consolidatedData);
      if (props.bandwidth && props.setPoint) {
        setBandwidth({
          start: props.setPoint - props.bandwidth,
          end: props.setPoint + props.bandwidth
        });
      }
      setIsReady(true);
    }
  }, [props, dominantType]);

  const colorPalette = ["#1C7AD4", "#aa46bE", "#28b4c8", "#78d237", "#ffd246"];
  const alertUnit = getSensorUnit(props.selectedSensor[0]);
  return (
    <>
      {indexSelected === "Multisense" && !props.showAlerts && width > 600 && (
        <Typography variant="p">
          <Checkbox
            checked={customBucket === Constants.BUCKET_TIME.MINUTE ? true : false}
            onChange={handleChange}
          />
          {t("chart.showDetail")}
        </Typography>
      )}
      {isReady && (
        <CustomChart
          dataSource={chartData}
          id="chart"
          palette={colorPalette}
          resolveLabelOverlapping="hide"
          hideDetail={indexSelected !== "Multisense" ? true : false}
        >
          <CommonAxisSettings>
            <Grid visible={false} color="#F0F0F0" />
            <Tick visible={false} color="#F0F0F0" />
            {props.setPoint && (
              <ConstantLine width={2} value={props?.setPoint} color="#C6C6C6" dashStyle="dash">
                <Label
                  text={`${t("chart.setpoint")} ${getSensorUnit(
                    props.selectedSensor === "Smart Rule"
                      ? selectedAlert?.sensor
                      : architectureSources[0]?.name
                  )}`}
                />
              </ConstantLine>
            )}
            {bandwidth && (
              <Strip
                startValue={bandwidth?.start}
                endValue={bandwidth?.end}
                color="rgba(120, 210, 55, 0.1)"
              ></Strip>
            )}
          </CommonAxisSettings>
          {uniqueUnits?.map((unit, index) => (
            <ValueAxis
              key={`valueAxis-${index}`}
              name={`valueAxis-${unit}`}
              color="#F0F0F0"
              position={dominantType === "location" ? "right" : index === 1 ? "left" : "right"}
            >
              <Grid visible={true} color="#F0F0F0" />
              <Label
                visible={true}
                customizeText={(arg) => {
                  const displayUnit = dominantType === "location" ? labelUnit : unit;
                  return `${formatNumber(arg.value)} ${displayUnit}`; // TODO: include alerts
                }}
              />
            </ValueAxis>
          ))}
          <ArgumentAxis color="#F0F0F0">
            <Label
              visible={true}
              customizeText={(arg) => {
                const dateParts = arg.value.split(" ");
                let currentDate = "";
                if (dateParts.length === 3) {
                  currentDate = `${dateParts[0]} ${dateParts[1]}`;
                } else if (dateParts.length === 2) {
                  currentDate = arg.value.includes(":")
                    ? `${dateParts[0]}:${dateParts[1]}`
                    : `${dateParts[0]} ${dateParts[1]}`;
                } else {
                  currentDate = arg.value;
                }

                // Check if the current date is different from the last displayed date
                if (currentDate !== lastDisplayedDate) {
                  lastDisplayedDate = currentDate;
                  return currentDate;
                } else {
                  return "";
                }
              }}
            />
            <Tick visible={false} color="#F0F0F0" />
          </ArgumentAxis>
          {architectureSources?.map((item, index) => {
            const unit = getSensorUnit(item.name);
            let seriesName;
            switch (item.type) {
              case "sensor":
                seriesName =
                  selectedAlert?.conditionType === "Calculation"
                    ? `${t(`sensor.${selectedAlert.sensor}`)}${t(
                        `operator.${selectedAlert.arithmeticOperator}`
                      )}${t(`sensor.${selectedAlert.sensor2}`)}`
                    : t(`sensor.${item.name}`);
                break;
              case "location":
                seriesName = item.name;
                break;
              case "alert":
                seriesName = "Alerts";
                break;
              default:
                seriesName = t(`sensor.${item.name}`);
                break;
            }
            return (
              <Series
                key={index}
                valueField={item.type === "sensor" ? item.value : item.name} // Conditional logic here
                axis={`valueAxis-${unit}`}
                name={seriesName}
                argumentField="date"
                type="spline"
              >
                <Point visible={false} />
              </Series>
            );
          })}
          {props.showAlerts && (
            <Series
              valueField={"Alerts"}
              axis={`valueAxis-${alertUnit}`}
              name={"Alerts"}
              argumentField="date"
              type={"scatter"}
              color={"#FF6358"}
            >
              <Point visible={true} />
            </Series>
          )}
          <Tooltip
            enabled={true}
            contentRender={(info) => {
              const unitSensor = props.selectedSensor?.find((option) => {
                // Splitting the seriesName by "+", "-", "*", or "/" and trimming whitespace
                const seriesNameFirstPart = info.seriesName.split(/[+\-*/]/)[0].trim();
                return t(`sensor.${option}`) === seriesNameFirstPart;
              });
              return TooltipTemplate(
                null,
                info,
                t,
                null,
                labelUnit ? labelUnit : getSensorUnit(unitSensor),
                undefined,
                formatNumber,
                null
              );
            }}
          />
          <Legend verticalAlignment="bottom" horizontalAlignment="center" />
        </CustomChart>
      )}
    </>
  );
}
