import React, { useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, IconButton, Typography } from '@mui/material'
import {
  addSensorPlaceholder,
  applySelection,
  resetSensors,
} from '../../../../redux/slices/multisenseSlice'
import { StyledButton } from '../../../../App.styled'
import DataDropdown from './DataDropdown/DataDropdown'
import AddIcon from '@mui/icons-material/Add'
import {
  CustomFilterContainer,
  CustomItemContainer,
  CustomTitleContainer,
} from './DataFilter.styled'
import { closePopover } from '../../../../redux/slices/popoversSlice'
import { useTranslation } from 'react-i18next'
import { changeActionDialogType, openActionDialog } from '../../../../redux/slices/actionDialogSlice'

export default function DataFilter() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [shouldRenderButton, setShouldRenderButton] = useState(false)
  const { sensor, selectedValues } = useSelector((state) => state.multisense)
  const  profile  = useSelector((state) => state.session.selectedProfile);
  const modules = profile?.profileModules?.map((item) => item.module) || [];


  useLayoutEffect(() => {
    if (
      sensor.filter((s) => s !== null && s !== undefined).length < 3 &&
      selectedValues.selectedLocation.length === 1
    ) {
      setShouldRenderButton(true)
    } else {
      setShouldRenderButton(false)
    }
  }, [selectedValues, sensor])

  return (
    <CustomFilterContainer container>
      {sensor?.map((sensor, index) => {
        return (
          sensor !== undefined && (
            <CustomTitleContainer key={index}>
              <Typography variant="h3">
                {t(`multisense.sense-filter.dataType`)}
              </Typography>
              <DataDropdown index={index} />
            </CustomTitleContainer>
          )
        )
      })}
      {shouldRenderButton ? (
          <CustomItemContainer item container>
            <IconButton
              style={{ visibility: shouldRenderButton ? 'visible' : 'hidden' }}
              onClick={() => {
                if(modules.includes('MultisenseDataCrossReference')){
                  dispatch(addSensorPlaceholder())
                } else {
                  dispatch(changeActionDialogType("upgradeAlerts"));
                  dispatch(openActionDialog());
                }
              }}
            >
              <AddIcon />
            </IconButton>
          </CustomItemContainer>
        ):(null)}
      <CustomItemContainer container item xs={12} spacing={2}>
        <Grid item display="flex" justifyContent="center">
          <StyledButton
            onClick={() => {
              dispatch(resetSensors())
              dispatch(closePopover())
            }}
          >
            {t(`button.reset`)}
          </StyledButton>
        </Grid>
        <Grid item xs display="flex" justifyContent="flex-end">
          <StyledButton
            selected
            onClick={() => {
              dispatch(applySelection())
              dispatch(closePopover())
            }}
          >
            {t(`button.apply`)}
          </StyledButton>
        </Grid>
      </CustomItemContainer>
    </CustomFilterContainer>
  )
}
