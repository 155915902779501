import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customBucket: "",
  selectedBuilding: {},
  displayAxis: false,
  location: [""],
  locationIds: [""],
  sensor: [""],
  initialSensor: [""],
  initialLocationId: [""],
  initialLocation: [""],
  selectedValues: {
    selectedSensor: [""],
    selectedLocationIds: [""],
    selectedLocation: [""],
    selectedLocationLevel: [],
    selectedSeries: [{ seriesName: "", sensor: "", location: "", locationId: "", color: "", type: "", locationLevel: "" }],
  },
  buildings: [],
  locationLevel: ["Building"],
  series: [{ seriesName: "", sensor: "", location: "", locationId: "", color: "", type: "", locationLevel: "" }],
  favorites: {
    id: '',
    userId: "",
    tenantId:"",
    description: "",
    series:[{ seriesName: "", sensor: "", location: "", locationId: "", color: "", type: "", locationLevel: "" }]},
};

export const multisenseSlice = createSlice({
  name: "multisense",
  initialState,
  reducers: {
    initMultisense: (state, action) => {
      const filteredBuildings = action.payload.filter((building) => building.buildingModules.some((module) => module.module === "Multisense"));
      state.selectedValues.selectedLocationLevel = ["Building"];
      state.buildings = filteredBuildings;
      state.selectedValues.selectedLocation = [filteredBuildings[0]?.description];
      state.selectedValues.selectedSensor = [filteredBuildings[0]?.buildingSensorTypes[0]?.sensorType];
      state.selectedBuilding = filteredBuildings[0];
      state.initialSensor = [filteredBuildings[0]?.buildingSensorTypes[0]?.sensorType];
      state.initialLocationId = [filteredBuildings[0]?.id];
      state.initialLocation = [filteredBuildings[0]?.description];
      state.selectedValues.selectedLocationIds = [filteredBuildings[0]?.id];
      state.selectedValues.selectedSeries = [
        {
          seriesName: filteredBuildings[0]?.buildingSensorTypes[0]?.sensorType,
          sensor: filteredBuildings[0]?.buildingSensorTypes[0]?.sensorType,
          location: filteredBuildings[0]?.description,
          locationId: filteredBuildings[0]?.id,
          color: "#1C7AD4",
          type: "spline",
          locationLevel: "Building",
        },
      ];
      state.sensor = [filteredBuildings[0]?.buildingSensorTypes[0]?.sensorType];
      state.location = [filteredBuildings[0]?.description];
      state.locationIds = [filteredBuildings[0]?.id];
      state.series = [
        {
          seriesName: filteredBuildings[0]?.buildingSensorTypes[0]?.sensorType,
          sensor: filteredBuildings[0]?.buildingSensorTypes[0]?.sensorType,
          location: filteredBuildings[0]?.description,
          locationId: filteredBuildings[0]?.id,
          color: "#1C7AD4",
          type: "spline",
          locationLevel: "Building",
        },
      ];
    },
    selectLocation: (state, action) => {
      state.location[action.payload.index] = action.payload.location;
      state.series = state.series.map((item, idx) => (idx === action.payload.index ? { ...item, location: action.payload.location } : item));
    },
    selectLocationId: (state, action) => {
      state.locationIds[action.payload.index] = action.payload.location;
      state.series = state.series.map((item, idx) => (idx === action.payload.index ? { ...item, locationIds: action.payload.location } : item));
    },
    changeAxisDisplay: (state,action)=>{
      state.displayAxis= action.payload
    },
    selectSensor: (state, action) => {
      state.sensor[action.payload.index] = action.payload.sensor;
      state.series = state.series.map((item, idx) => (idx === action.payload.index ? { ...item, sensor: action.payload.sensor } : item));
    },
    addLocationPlaceholder: (state) => {
      state.location.push("");
      state.locationLevel.push("");
      state.locationIds.push("")
    },
    handleCustomBuckets: (state, action) => {
      state.customBucket = action.payload;
    },
    addSensorPlaceholder: (state) => {
      state.sensor.push("");
    },
    modifyLocation: (state, action) => {
      const index = action.payload;
      // if (index > 0) {
      //   state.location = [...state.location.slice(0, index), ...state.location.slice(index + 1)];
      // } else if (index === 0) {
        state.location.splice(index, 1);
    },
    applySelection: (state) => {
      state.selectedValues.selectedLocation = state.location.filter((sensor) => sensor !== "");
      state.location = state.location.filter((location) => location !== "");
      state.selectedValues.selectedSensor = state.sensor.filter((sensor) => sensor !== "");
      state.sensor = state.sensor.filter((sensor) => sensor !== "");
      state.selectedValues.selectedLocationIds = state.locationIds;
      state.selectedValues.selectedLocationLevel = state.locationLevel;
      state.selectedValues.selectedSeries = state.series;
    },
    resetSensors: (state) => {
      state.sensor = state.initialSensor;
      state.selectedValues.selectedSensor = state.initialSensor;
    },
    resetLocation: (state) => {
      state.location = state.initialLocation;
      state.locationIds = state.initialLocationId;
      state.selectedValues.selectedLocationIds = state.initialLocationId;
      state.selectedValues.selectedLocation = state.initialLocation;
      state.selectedValues.selectedLocationLevel = "Building";
    },
    cancelSeries: (state) => {
      state.sensor= state.selectedValues.selectedSensor
      state.location= state.selectedValues.selectedLocation
      state.locationIds= state.selectedValues.selectedLocationIds
      state.series = state.selectedValues.selectedSeries;
    },
    deleteSensor: (state, action) => {
      const index = action.payload.index;
      if (index >= 0 && index < state.sensor.length) {
        const newSensorArray = [...state.sensor];
        newSensorArray.splice(index, 1);
        state.sensor = newSensorArray;
      }
    },
    selectMultisenseBuilding: (state, action) => {
      state.selectedBuilding = action.payload;
    },
    setLocationLevel: (state, action) => {
      state.locationLevel[action.payload.index] = action.payload.level;
      state.series = state.series.map((item, idx) => (idx === action.payload.index ? { ...item, locationLevel: action.payload.level } : item));
    },
    addFavorites: (state, action) => {
      state.favorites.id = action.payload.id;
      state.favorites.userId = action.payload.userId;
      state.favorites.tenantId = action.payload.tenantId;
      state.favorites.description = action.payload.description;
      state.favorites.series= JSON.parse(action.payload.series);
    },
    addSeries: (state, action) => {
      state.series.push(action.payload);
    },
    cleanFavorites: (state) => {
      state.favorites.id ='';
      state.favorites.userId = "";
      state.favorites.tenantId = "";
      state.favorites.description = "";
      state.favorites.series= [{ seriesName: "", sensor: "", location: "", locationId: "", color: "", type: "", locationLevel: "" }];
    },
    selectFavorites: (state, action) => {
      const favorites = action.payload;

      // Assuming favorites is an array of objects with {sensor, location, locationId, locationLevel} properties
      state.sensor = favorites.map(fav => fav.sensor);
      state.location = favorites.map(fav => fav.location);
      state.locationIds = favorites.map(fav => fav.locationId);
      state.locationLevel = favorites.map(fav => fav.locationLevel || "Building");
      state.series= favorites;
  
    },
    editSeries: (state, action) => {
      const { index, ...updatedSeries } = action.payload;
      state.series[index] = {...updatedSeries };
    },
    deleteSeries: (state, action) => {
      const index = action.payload;
      if (index >= 0 && index < state.series.length) {
        state.series.splice(index, 1);
      }
    },
  },
});

export const {
  cleanFavorites,
  addFavorites,
  deleteSeries,
  selectFavorites,
  changeAxisDisplay,
  cancelSeries,
  editSeries,
  addSeries,
  deleteSensor,
  confirmFetchData,
  setLocationLevel,
  selectMultisenseBuilding,
  resetLocation,
  modifyLocation,
  selectSensor,
  selectLocation,
  resetSensors,
  addLocationPlaceholder,
  addSensorPlaceholder,
  initMultisense,
  applySelection,
  selectLocationId,
  handleCustomBuckets,
} = multisenseSlice.actions;

export default multisenseSlice.reducer;
