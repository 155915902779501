import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  open: false,
  message: '',
  type: '',
}

export const snackSlice = createSlice({
  name: 'popovers',
  initialState,
  reducers: {
    openSnack: (state, action) => {
      state.open = true
      state.message = action.payload.message
      state.type = action.payload.type
      
    },
    closeSnack: (state, action) => {
      state.open = false
      state.type = ''
      state.message = ''
    },
  },
})

export const { openSnack, closeSnack } = snackSlice.actions

export default snackSlice.reducer
