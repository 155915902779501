import {  Card, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const MainCard = styled(Card)(({theme}) => ({
  borderRadius: '24px !important',
  boxShadow: 'none',
  marginBottom: "16px",
  backgroundColor: "transparent",
  height: "180px",
  [theme.breakpoints.down("sm")]: {
    height: "auto",
  },
}))
export const DataWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  columnGap: "0px",
  height: "auto",
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  flexDirection: "row",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: theme.spacing(1),
    padding: theme.spacing(.5),
  },
}))

export const ItemContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  flexDirection: 'row',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  height: "100%",
}))

export const ItemWrapper = styled(Grid)(() => ({
  display: 'flex',
  flex: 1,
  width: '100%',
  alignItems: 'flex-start',
  justifyContent: 'center',
  height: "100%",
}))

export const CustomBox = styled(Card)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  height: '100%',
  width: "100%",
  gap:theme.spacing(1),
  backgroundColor: '#ffffff',
  borderRadius:"15px",
  padding: theme.spacing(2),
  boxShadow:"inherit"
}))

export const CustomTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "center",
})(({ center}) => ({
  justifyContent: center ? 'center':'flex-start',
}))
