import { Box, Divider, Grid, IconButton, SvgIcon, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { CustomBox, CustomContainer, CustomContent, CustomDataPanel, HeaderCard } from "./AdminPanel.styled";
import { useDispatch, useSelector } from "react-redux";
import { StyledButton, TabPanelContainer } from "../../App.styled";
import { useGetAuth0UsersQuery, useGetBuildingMetadataByTenantIdQuery } from "../../redux/metaDataApi";
import BuildingSettings from "./BuildingSettings/BuildingSettings";
import { TabContext, TabPanel } from "@mui/lab";
import UserSettings from "./UsersSettings/UserSettings";
import { changeActionDialogType, openActionDialog } from "../../redux/slices/actionDialogSlice";
import TenantMenu from "./TenantMenu/TenantMenu";
import { PencilSquareIcon, XMarkIcon, PlusIcon } from "@heroicons/react/24/outline";

export default function AdminPanel() {
  const dispatch = useDispatch();
  const tenant = useSelector((state) => state.session.selectedTenant);
  const name = useSelector((state) => state.session.firstName);
  const [value, setValue] = React.useState("1");

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data: users } = useGetAuth0UsersQuery();

  const { data: buildings, refetch } = useGetBuildingMetadataByTenantIdQuery(tenant.id, {
    skip: !tenant,
  });

  useEffect(() => {
    if (tenant) {
      refetch();
    }
  }, [tenant, refetch]);

  return (
    <CustomContainer spacing={2}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleTabChange} centered>
            <Tab label="Buildings" value="1" />
            <Tab label="Users" value="2" />
          </Tabs>
        </Box>
        <TabPanelContainer value="1">
          <CustomContainer spacing={2}>
            <Grid item container sx={{ justifyContent: "flex-end" }}>
              <TenantMenu />
            </Grid>
            <CustomDataPanel container item spacing={2}>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h1">Good day, {name}!</Typography>
              </Grid>
              <Grid item xs>
                <HeaderCard>
                  <Grid item container sx={{ flexDirection: "row", justifyContent: "space-between", alignItems:"center"}}>
                    <Typography variant="h1">Tenant</Typography>
                    <IconButton    onClick={() => {
                      dispatch(openActionDialog())
                      dispatch(changeActionDialogType('changeTenant'))
                    }}>
                      <SvgIcon>
                        <PencilSquareIcon />
                      </SvgIcon>
                    </IconButton>
                  </Grid>
                  <Divider flexItem sx={{ marginTop: "2px" }} />
                  <CustomBox>
                    <Typography variant="dashboard">{tenant.description}</Typography>
                  </CustomBox>
                </HeaderCard>
              </Grid>
              <Grid item xs>
                <HeaderCard>
                  <Typography variant="h1">Buildings</Typography>
                  <Divider flexItem sx={{ marginTop: "2px" }} />
                  <CustomBox>
                    <Typography variant="dashboard">{buildings?.length}</Typography>
                  </CustomBox>
                </HeaderCard>
              </Grid>
            </CustomDataPanel>
            <CustomContent container item>
              <BuildingSettings buildings={buildings} />
            </CustomContent>
          </CustomContainer>
        </TabPanelContainer>
        <TabPanelContainer value="2">
          <CustomContainer spacing={2}>
            <Grid item container sx={{ justifyContent: "flex-end" }}>
              <StyledButton
                onClick={() => {
                  dispatch(openActionDialog());
                  dispatch(changeActionDialogType("user"));
                }}
                startIcon={
                  <SvgIcon>
                    <PlusIcon />
                  </SvgIcon>
                }
              >
                Add User
              </StyledButton>
            </Grid>
            <CustomDataPanel container item spacing={2}>
              <Grid
                item
                xs={9}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h1">Good day, {name}!</Typography>
              </Grid>
              <Grid item xs>
                <HeaderCard>
                  <Typography variant="h1">Users</Typography>
                  <Divider flexItem sx={{ marginTop: "2px" }} />
                  <CustomBox>
                    <Typography variant="dashboard">{users?.length}</Typography>
                  </CustomBox>
                </HeaderCard>
              </Grid>
            </CustomDataPanel>
            <CustomContent container item>
              <UserSettings users={users} />
            </CustomContent>
          </CustomContainer>
        </TabPanelContainer>
      </TabContext>
    </CustomContainer>
  );
}
