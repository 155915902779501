import {
  BottomNavigation,
  Box,
  Card,
  Grid,
  Stack,
} from '@mui/material'
import { styled } from '@mui/material/styles'

export const CustomContainer = styled(Box)(() => ({
  height: "100%",
  width:"100%"
}))

export const CustomDataPanel = styled(Grid)(() => ({
  height: '25%',
  display: 'flex',
  flexDirection: 'row',
  padding: "16px",
}))

export const CustomContent = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: "65%"
}))

export const HeaderCard = styled(Card)(({ theme }) => ({
  height: '100%',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
}))

export const CustomBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}))

export const CustomStack = styled(Stack)(() => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}))

export const StyledBottomNavigation = styled(BottomNavigation)(() => ({
  ".MuiSvgIcon-root":{
    fontSize: "20px"
  }

}))