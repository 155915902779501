import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userSelected: null,
  buildingSelected: null,
  tenantSelected: null,
  forceUpdate: false, 
  profileSelected: null,
}

export const adminPanelSlice = createSlice({
  name: 'adminPanel',
  initialState,
  reducers: {
    changeUserSelection: (state, action) => {
      state.userSelected = action.payload
    },
    changeBuildingSelection: (state, action) => {
      state.buildingSelected = action.payload
    },
    changeAdminPanelTenantSelection: (state, action) => {
      state.tenantSelected = action.payload
    },
    changeProfileSelection: (state, action) => {
      state.profileSelected = action.payload
    },
    toggleForceUpdate: (state) => {
      state.forceUpdate = !state.forceUpdate;
    },
  },
})

export const {
  changeProfileSelection,
  toggleForceUpdate,
  changeAdminPanelTenantSelection,
  changeUserSelection,
  changeBuildingSelection
} = adminPanelSlice.actions

export default adminPanelSlice.reducer
