import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  type: '', 
  id: '',
};

export const popoversSlice = createSlice({
  name: 'popovers',
  initialState,
  reducers: {
    openPopover: (state, action) => {
      state.open = true;
      state.type = action.payload;
      state.id = action.payload
    },
   closePopover: (state, action) => {
        state.open = false;
        state.type = '';
      },
    },
});

export const {
    openPopover,
    closePopover
} = popoversSlice.actions;

export default popoversSlice.reducer;