import React, { useEffect, useState } from 'react'
import { useGetNormalDataConsumptionByFilterQuery } from '../../../redux/dataApi'
import {
  getBucketTimeDynamically,
  getNormalFilter,
  processChartData,
} from '../../../helpers/functions'
import { useSelector } from 'react-redux'
import DataChart from '../../DataChart/DataChart'
import { Box, CircularProgress, Typography } from '@mui/material'
import { getAlertSeries } from '../../../helpers/alertsFunctions'
import { t } from 'i18next'

export default function AlertsChart() {
  const selectedAlert = useSelector((state) => state.alerts.selectedAlert[0])
  const tenant = useSelector((state) => state.session.selectedTenant)
  const [alertsSeries, setAlertsSeries] = useState([])
  const [testSeries, setTestSeries] = useState([])
  const [sensors, setSensors] = useState([])
  const tenantId = tenant?.id
  const locationId = selectedAlert?.locationId
  const {
    data: series1,
    isFetching,
  } = useGetNormalDataConsumptionByFilterQuery(
    {
      filter: getNormalFilter(
        selectedAlert?.sensor,
        selectedAlert?.startDate,
        selectedAlert?.endDate,
        `${selectedAlert?.conditionalAlertLocation}:`,
      ),
      locationId,
      tenantId,
    },
    { skip: !selectedAlert },
  )

  const { data: series3 } = useGetNormalDataConsumptionByFilterQuery(
    {
      filter: getNormalFilter(
        selectedAlert?.sensor2,
        selectedAlert?.startDate,
        selectedAlert?.endDate,
        `${selectedAlert?.conditionalAlertLocation}:`,
      ),
      locationId,
      tenantId,
    },
    { skip: !selectedAlert.sensor2 },
  )

  const bucket = getBucketTimeDynamically(
    selectedAlert?.startDate,
    selectedAlert?.endDate,
  )

  useEffect(() => {
    if (series1) {
      if (selectedAlert.type === 'DeviationAlert') {
        const series2 = getAlertSeries(
          selectedAlert,
          series1,
          getBucketTimeDynamically(
            selectedAlert.startDate,
            selectedAlert.endDate,
          ),
        )
        setAlertsSeries(series2)
        setTestSeries(series1)
        setSensors([selectedAlert?.sensor])
      } else {
        if (
          selectedAlert.conditionType === 'DataType' &&
          selectedAlert?.sensor &&
          selectedAlert?.sensor2
        ) {
          const series2 = getAlertSeries(
            selectedAlert,
            series1,
            getBucketTimeDynamically(
              selectedAlert.startDate,
              selectedAlert.endDate,
            ),
          )
          setAlertsSeries(series2)
          setSensors([selectedAlert?.sensor, selectedAlert?.sensor2])
        } else if (
          selectedAlert.conditionType === 'Calculation' &&
          selectedAlert?.sensor
        ) {
          setSensors([selectedAlert?.sensor])
          const combinedSeries = processChartData(
            series1,
            series3,
            selectedAlert.arithmeticOperator, // Assuming this is how you get the operator (add or subtract)
          )
          setTestSeries(combinedSeries)
          const series2 = getAlertSeries(
            selectedAlert,
            combinedSeries,
            getBucketTimeDynamically(
              selectedAlert.startDate,
              selectedAlert.endDate,
            ),
          )
          setAlertsSeries(series2)
        } else {
          const series2 = getAlertSeries(
            selectedAlert,
            series1,
            getBucketTimeDynamically(
              selectedAlert.startDate,
              selectedAlert.endDate,
            ),
          )
          setAlertsSeries(series2)
          setTestSeries(series1)
          setSensors([selectedAlert?.sensor])
        }
      }
    }
  }, [series1, selectedAlert, series3])

  return isFetching ? (
    <Box
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: ' center',
      }}
    >
      <CircularProgress />
    </Box>
  ) : selectedAlert ? (
    <Box
      container
      sx={{
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "#FAFAFA"
      }}
    >
      <Typography variant="h3" sx={{marginTop: "16px"}}>
        {t("alerts.alertIn")} {selectedAlert.locationDescription}
      </Typography>
      <DataChart
        bucket={bucket}
        setPoint={selectedAlert.setPoint}
        bandwidth={selectedAlert.bandwidth}
        showAlerts
        series1={selectedAlert.conditionType === 'DataType' ? series1 : testSeries}
        series2={series3}
        series3={alertsSeries}
        selectedSensor={sensors}
        selectedLocation={[`${selectedAlert.locationDescription}`]}
        selectedPeriod={'Day'}
      />
    </Box>
  ) : null
}
