import { format, parseISO } from "date-fns";
import { calculateMinMax, getMeasurementType, getSensorUnit } from "./functions";
import { ModifiedConstants } from "../App";

export const formatCostsSeries = (bucket, timeDif, ...seriesList) => {
  const dateFormat = getFormatFromBucket(bucket, timeDif);
  const architectureSources = [];
  const consolidatedData = [];
  const unitToSensorMap = [];

  seriesList.forEach((series, index) => {
    if (series && series.length > 0) {
      const seriesName = series[index]?.name || undefined;
      let sensorValue = series[index]?.sensor || undefined;
      let type = "sensor";

      architectureSources.push({
        value: sensorValue,
        name: seriesName,
        type: type,
        color: series[index]?.color,
        chartType: series[index]?.type
      });

      const unit = getSensorUnit(series[index].sensor);

      const { min, max } = calculateMinMax(series);
      unitToSensorMap.push({
        value: sensorValue,
        name: seriesName,
        unit,
        min,
        max
      });

      series.forEach(({ to, avg, sum, name }) => {
        const date = format(parseISO(to), dateFormat ? dateFormat : "d MMM");
        const measurementType = getMeasurementType(sensorValue);
        const measurement = measurementType === ModifiedConstants.MEASUREMENT_TYPE.SUM ? sum : avg;

        let existingEntry = consolidatedData.find((entry) => entry.date === date);

        if (existingEntry) {
          existingEntry[name] = measurement;
        } else {
          let dayHourChart = date;
          if (seriesList.length > 2 && dateFormat === "d MMM") {
            dayHourChart = format(parseISO(to), "d");
          }
          const newEntry = { date: dayHourChart };
          newEntry[name] = measurement;
          consolidatedData.push(newEntry);
        }
      });
    }
  });

  return { architectureSources, consolidatedData, unitToSensorMap };
};

export const getFormatFromBucket = (bucket, timeDif) => {
  if (timeDif === 0) {
    switch (bucket) {
      case ModifiedConstants.BUCKET_TIME.HOUR:
        return "HH:00";
      case ModifiedConstants.BUCKET_TIME.MINUTE:
        return "HH:mm";
      case ModifiedConstants.BUCKET_TIME.DAY:
        return "d MMM";
      default:
        return "HH:00";
    }
  } else {
    switch (bucket) {
      case ModifiedConstants.BUCKET_TIME.HOUR:
        return "HH:00";
      case ModifiedConstants.BUCKET_TIME.MINUTE:
        return "d MMM HH:mm";
      default:
        return "d MMM";
    }
  }
};
