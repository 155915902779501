import React, { useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import { Grid } from "@mui/material";
import FullCalendar from "./FullCalendar/FullCalendar";
import { useDispatch, useSelector } from "react-redux";
import { format, parseISO } from "date-fns";
import { toggleCalendar } from "../../redux/slices/calendarSlice";
import { CalendarButton } from "./Calendar.styled";

export default function Calendar() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = useSelector((state) => state.calendar.open);
  const calendarSelection = useSelector(
    (state) => state.calendar.calendarSelection
  );
  const [formatDateStart, setFormatDateStart] = useState(null);
  const [formatDateEnd, setFormatDateEnd] = useState(null);

  useEffect(() => {
    try {
      if (calendarSelection.dateStart && calendarSelection.dateEnd) {
        setFormatDateStart(
          format(parseISO(calendarSelection.dateStart), "dd MMM yy")
        );
        setFormatDateEnd(
          format(parseISO(calendarSelection.dateEnd), "dd MMM yy")
        );
      }
    } catch (error) {
      console.error("Error parsing dates:", error);
    }
  }, [calendarSelection]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    dispatch(toggleCalendar());
  };

  const handleClose = () => {
    dispatch(toggleCalendar());
    setAnchorEl(null);
  };

  const id = open ? "simple-popover" : undefined;

  return (
    formatDateStart && (
      <div key={calendarSelection.dateStart}>
        <CalendarButton
          id="demo-customized-button"
          variant="contained"
          disableElevation
          onClick={handleClick}
        >
          {formatDateStart} - {formatDateEnd}
        </CalendarButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Grid
            container
            sx={{ width: "fit-content", height: "auto" }}
            padding={2}
          >
            <FullCalendar />
          </Grid>
        </Popover>
      </div>
    )
  );
}
