import {
  Box,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  SvgIcon,
  Switch,
  Tab,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  ActionsGrid,
  ContentGrid,
  CustomCircularProgress,
  CustomDialogContent,
  CustomSelect,
  CustomTextField,
  StyledTabPanel,
  StyledTabs,
  TextFieldGrid,
  TitleGrid,
} from "./Forms.styled";
import { t } from "i18next";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { StyledButton } from "../../../App.styled";
import {
  useEditBuildingMutation,
  useGetBuildingByIdQuery,
  useGetFlexibleRatesQuery,
} from "../../../redux/metaDataApi";
import { useDispatch, useSelector } from "react-redux";
import { closeActionDialog } from "../../../redux/slices/actionDialogSlice";
import { openSnack } from "../../../redux/slices/snackSlice";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import EnergyFlexible from "../../PartnerPanel/FlexibleRates/EnergyFlexible";
import WaterFlexible from "../../PartnerPanel/FlexibleRates/WaterFlexible";
import GasFlexible from "../../PartnerPanel/FlexibleRates/GasFlexible";
import NoSensor from "../../PartnerPanel/FlexibleRates/NoSensor";

export default function EditTariffFrom() {
  const dispatch = useDispatch();
  const { sensorTypes, selectedBuilding } = useSelector((state) => state.buildings);
  const [value, setValue] = React.useState("1");
  const { data: flexibleRates } = useGetFlexibleRatesQuery(selectedBuilding.id, {
    skip: !selectedBuilding || !selectedBuilding.id,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const closeForm = () => {
    dispatch(closeActionDialog());
  };
  const buildingSensorTypes = sensorTypes[selectedBuilding?.id] || [];

  return (
    flexibleRates && (
      <>
        <TabContext value={value}>
          <DialogTitle sx={{ padding: "4px 24px" }}>
            <TitleGrid container>
              <Grid item xs>
                <Grow in timeout={800}>
                  <Typography variant="h3">{t("form.tariff")}</Typography>
                </Grow>
              </Grid>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <StyledTabs centered onChange={handleChange}>
                  <Tab
                    label={t("sensor.Energy")}
                    value="1"
                    disabled={
                      !buildingSensorTypes.includes("LightingEnergy") &&
                      !buildingSensorTypes.includes("Energy")
                    }
                  />
                  <Tab
                    label={t("sensor.Water")}
                    value="2"
                    disabled={!buildingSensorTypes.includes("Water")}
                  />
                  <Tab
                    label={t("sensor.Gas")}
                    value="3"
                    disabled={!buildingSensorTypes.includes("Gas")}
                  />
                </StyledTabs>
              </Box>
              <Grid item xs textAlign="right">
                <IconButton onClick={closeForm}>
                  <SvgIcon>
                    <XMarkIcon />
                  </SvgIcon>
                </IconButton>
              </Grid>
            </TitleGrid>
          </DialogTitle>
            <StyledTabPanel value="1">
              {!buildingSensorTypes.includes("LightingEnergy") &&
              !buildingSensorTypes.includes("Energy") ? (
                <NoSensor />
              ) : (
                <EnergyFlexible
                  rates={flexibleRates.filter(
                    (rate) => rate.sensorType === "LightingEnergy" || rate.sensorType === "Energy"
                  )}
                />
              )}
            </StyledTabPanel>
            <StyledTabPanel value="2">
              <WaterFlexible rates={flexibleRates.filter((rate) => rate.sensorType === "Water")} />
            </StyledTabPanel>
            <StyledTabPanel value="3">
              <GasFlexible rates={flexibleRates.filter((rate) => rate.sensorType === "Gas")} />
            </StyledTabPanel>
        </TabContext>
      </>
    )
  );
}
