import { Grid, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import DateRangeBox from 'devextreme-react/date-range-box'

export const StyledStack = styled(Stack)(() => ({
  flexDirection: 'row',
  flexWrap: 'nowrap',
}))

export const StyledGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const StyledDateRange = styled(DateRangeBox)(() => ({
  fontFamily: 'Roboto !important',
  '.dx-field-value':{
    fontFamily: 'Roboto !important',
  }
}))
