import { TabContext, TabList } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { TabPanelContainer } from "../../App.styled";
import { Outlet, useNavigate } from "react-router-dom";

export default function Utilization() {
  const [value, setValue] = useState("map");
  const navigate = useNavigate();
  const basePath = "/utilization";

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`${basePath}/${newValue}`);
  };
  useEffect(() => {
    navigate(`${basePath}/map`);
  }, []);

  return (
    <Box sx={{ width: "100%", typography: "body1", height: "100%" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "transparent" }}>
          <TabList onChange={handleChange}>
            <Tab label={t("utilization.digital")} value="map" />
            <Tab label={t("utilization.insights")} value="insights" />
          </TabList>
        </Box>
        <TabPanelContainer value="map">
          <Outlet />
        </TabPanelContainer>
        <TabPanelContainer value="insights">
          <Outlet />
        </TabPanelContainer>
      </TabContext>
    </Box>
  );
}
