import React from "react";
import { CostCard, StyledNumbers } from "../Costs.styled";
import { CardHeader, CircularProgress, Grid, SvgIcon, Typography } from "@mui/material";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";
import { analyzeSeries, formatNumberCustom } from "../../../helpers/functions";
import { getDifference } from "../../../helpers/multisenseFunctions";
import { t } from "i18next";
import { LoadingOverlay } from "../TotalSavings/TotalSavings.styled";
import { useCurrencySymbol } from "../../../redux/customHooks/customHooks";

export default function Trend(props) {
  const currency = useCurrencySymbol();
  const result = props?.allSeries[0] ? analyzeSeries(props?.allSeries[0]) : null;
  const resultSecond = props?.allSeries[1] ? analyzeSeries(props?.allSeries[1]) : null;

  return (
    <CostCard variant="outlined">
      <CardHeader title={t("costs.trendTitle")} />
      {props.isFetching ? (
        <LoadingOverlay>
          <CircularProgress />
        </LoadingOverlay>
      ) : (
        <>
          <Grid
            container
            sx={{
              justifyContent: "space-between",
              alignContent: "space-between",
              height: "100%"
            }}
          >
            <Grid item container>
              <Grid
                item
                xs
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
                gap={0.5}
              >
                <Typography variant="p">{t("costs.average")}</Typography>
                <StyledNumbers>
                  {" "}
                  {currency} {formatNumberCustom(result?.average, false, 2, 4)}
                </StyledNumbers>
                {getDifference(result?.total, resultSecond?.total) > 0 ? (
                  <Typography variant="p" sx={{ color: "red !important" }}>
                    <SvgIcon sx={{ width: "14px", height: "14px" }}>
                      <ArrowUpIcon />
                    </SvgIcon>
                    {formatNumberCustom(
                      getDifference(result?.average, resultSecond?.average),
                      false,
                      0,
                      4
                    )}{" "}
                    %
                  </Typography>
                ) : (
                  <Typography variant="p" sx={{ color: "green !important" }}>
                    <SvgIcon sx={{ width: "14px", height: "14px" }}>
                      <ArrowDownIcon />
                    </SvgIcon>
                    {formatNumberCustom(
                      Math.abs(getDifference(result?.average, resultSecond?.average)),
                      false,
                      0,
                      4
                    )}{" "}
                    %
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
                gap={0.5}
              >
                <Typography variant="p">{t("costs.total")}</Typography>
                <StyledNumbers>
                  {currency} {formatNumberCustom(result?.total, false, 2, 4)}
                </StyledNumbers>
                {getDifference(result?.total, resultSecond?.total) > 0 ? (
                  <Typography variant="p" sx={{ color: "red !important" }}>
                    <SvgIcon sx={{ width: "14px", height: "14px" }}>
                      <ArrowUpIcon />
                    </SvgIcon>
                    {formatNumberCustom(
                      getDifference(result?.average, resultSecond?.average),
                      false,
                      0,
                      4
                    )}{" "}
                    %
                  </Typography>
                ) : (
                  <Typography variant="p" sx={{ color: "green !important" }}>
                    <SvgIcon sx={{ width: "14px", height: "14px" }}>
                      <ArrowDownIcon />
                    </SvgIcon>
                    {formatNumberCustom(
                      Math.abs(getDifference(result?.average, resultSecond?.average)),
                      false,
                      0,
                      4
                    )}{" "}
                    %
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item container>
              <Grid
                item
                xs
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingBottom: "10px"
                }}
                gap={0.5}
              >
                <Typography variant="p">{t("costs.peak")}</Typography>
                <StyledNumbers>
                  {currency} {formatNumberCustom(result?.overallPeak.sum, false, 2, 4)}
                </StyledNumbers>
                {getDifference(result?.total, resultSecond?.total) > 0 ? (
                  <Typography variant="p" sx={{ color: "red !important" }}>
                    <SvgIcon sx={{ width: "14px", height: "14px" }}>
                      <ArrowUpIcon />
                    </SvgIcon>
                    {formatNumberCustom(
                      getDifference(result?.average, resultSecond?.average),
                      false,
                      0,
                      4
                    )}{" "}
                    %
                  </Typography>
                ) : (
                  <Typography variant="p" sx={{ color: "green !important" }}>
                    <SvgIcon sx={{ width: "14px", height: "14px" }}>
                      <ArrowDownIcon />
                    </SvgIcon>
                    {formatNumberCustom(
                      Math.abs(getDifference(result?.average, resultSecond?.average)),
                      false,
                      0,
                      4
                    )}{" "}
                    %
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
                gap={0.5}
              >
                <Typography variant="p">{t("costs.valley")}</Typography>
                <StyledNumbers>
                  {currency} {formatNumberCustom(result?.overallValley.sum, false, 2, 4)}
                </StyledNumbers>
                {getDifference(result?.total, resultSecond?.total) > 0 ? (
                  <Typography variant="p" sx={{ color: "red !important" }}>
                    <SvgIcon sx={{ width: "14px", height: "14px" }}>
                      <ArrowUpIcon />
                    </SvgIcon>
                    {formatNumberCustom(
                      getDifference(result?.average, resultSecond?.average),
                      false,
                      0,
                      4
                    )}{" "}
                    %
                  </Typography>
                ) : (
                  <Typography variant="p" sx={{ color: "green !important" }}>
                    <SvgIcon sx={{ width: "14px", height: "14px" }}>
                      <ArrowDownIcon />
                    </SvgIcon>
                    {formatNumberCustom(
                      Math.abs(getDifference(result?.average, resultSecond?.average)),
                      false,
                      0,
                      4
                    )}{" "}
                    %
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </CostCard>
  );
}
