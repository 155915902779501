import { Breadcrumbs, Card, Grid, Typography } from '@mui/material'
import { format } from 'date-fns'
import React from 'react'
import { StyledBox, TitleGrid } from './AlertCard.styled'
import { processAndExtractAlertsData } from '../../../helpers/alertsFunctions'
import { formatNumber, getSensorUnit } from '../../../helpers/functions'
import { useTranslation } from 'react-i18next'

export default function AlertCard({ alert }) {
  const { t } = useTranslation()
  const currentDate = new Date(alert.lastDetectionDate)
  const processedAlerts = processAndExtractAlertsData([alert])
  const breadcrumbs = [
    <Typography key="1" variant="breadcrumb">
      {t(`multisense.${processedAlerts[0].LabelPrefix}`)}
    </Typography>,
    <Typography key="2" variant="breadcrumb">
      {processedAlerts[0].locationDescription}
    </Typography>,
  ]
  const description = () => {
    if (processedAlerts[0].alertValue > processedAlerts[0].setPoint) {
      return t('alerts.rise')
    } else {
      return  t('alerts.dropped')
    }
  }
  return ( processedAlerts && (
    <Card
      sx={{
        border: '1px solid var(--component-border, rgba(0, 0, 0, 0.08))',
        boxShadow: 'none',
        borderRadius: '12px',
        padding: '0px',
        width: '100%',
      }}
    >
      <StyledBox status={alert.conditionalAlertStatus}>
        <Grid container padding={1} rowGap={1}>
          <TitleGrid item container>
            <Grid item>
              <Typography variant="h3">{alert.name} {t("alerts.alerts")}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="p">
                {format(currentDate, 'd MMM yyyy')}
              </Typography>
            </Grid>
          </TitleGrid>
          <Grid item xs={12}>
            {processedAlerts[0].conditionType === 'DataType' ? (
              <Typography variant="notification">
                {t(`sensor.${processedAlerts[0].sensor}`) }
                &nbsp;vs.&nbsp;
                {t(`sensor.${processedAlerts[0].sensor2}`)}
              </Typography>
            ) : processedAlerts[0].conditionType === 'Calculation' ? (
              <Typography variant="notification">
                {t(`sensor.${processedAlerts[0].sensor}`)}
                {t(`operator.${processedAlerts[0].arithmeticOperator}`)}
                {t(`sensor.${processedAlerts[0].sensor2}`)} {description()} {t("alerts.to")}{' '}
                {formatNumber(processedAlerts[0].alertValue)}
                {getSensorUnit(processedAlerts[0].sensor)}
              </Typography>
            ) : (
              <Typography variant="notification">
                {t(`sensor.${processedAlerts[0].sensor}`)} {description()} {t("alerts.to")}{' '}
                {formatNumber(processedAlerts[0].alertValue)}
                {getSensorUnit(processedAlerts[0].sensor)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Breadcrumbs
              separator="›"
              aria-label="breadcrumb"
              sx={{ color: '#1C7AD4' }}
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Grid>
        </Grid>
      </StyledBox>
    </Card>
  ))
}
