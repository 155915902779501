import {
  Box,
  Checkbox,
  DialogActions,
  DialogTitle,
  Grid,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Switch,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  ActionsGrid,
  ContentGrid,
  CustomCircularProgress,
  CustomDialogContent,
  CustomTextField,
  RequiredIndicator,
  TextFieldGrid,
  TitleGrid
} from "./Forms.styled";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { closeActionDialog } from "../../../redux/slices/actionDialogSlice";
import {
  useGetBuildingModulesQuery,
  useGetProfileModulesByProfileIdQuery,
  useUpdateModulesForProfileMutation,
  useUpdateProfileMutation
} from "../../../redux/metaDataApi";
import { useDispatch, useSelector } from "react-redux";
import { StyledButton } from "../../../App.styled";
import { openSnack } from "../../../redux/slices/snackSlice";

export default function EditProfileForm() {
  const dispatch = useDispatch();
  const selectedBuilding = useSelector((state) => state.adminPanel.buildingSelected);
  const selectedProfile = useSelector((state) => state.adminPanel.profileSelected);
  const { data: buildingModules } = useGetBuildingModulesQuery(selectedBuilding, {
    skip: !selectedBuilding
  });
  const [modulesSelected, setModulesSelected] = useState([]);
  const [profileName, setProfileName] = useState("");
  const [profileVisibleToPartner, setProfileVisibleToPartner] = useState(false);
  const { data: modules } = useGetProfileModulesByProfileIdQuery(selectedProfile?.id, {
    skip: !selectedProfile
  });
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();
  const [updateModulesForProfile] = useUpdateModulesForProfileMutation();
  const closeForm = () => {
    dispatch(closeActionDialog());
  };

  const handleConfirm = async () => {
    if (modulesSelected.length === 0) {
      return dispatch(
        openSnack({
          message: "Profile needs to have at least one module",
          type: "error"
        })
      );
    }
    const profileModules = modulesSelected?.map((module) => ({
      profileId: selectedProfile.id,
      buildingId: selectedBuilding,
      module
    }));

    const namePayload = {
      id: selectedProfile.id,
      buildingId: selectedBuilding,
      description: profileName,
      visibleToPartnerRole: profileVisibleToPartner
    };

    await updateProfile(namePayload)
      .unwrap()
      .then(() => {})
      .catch((error) => {
        dispatch(
          openSnack({
            message: "Profile name could not be updated",
            type: "error"
          })
        );
        console.log(`Error:${error}`);
      });
    await updateModulesForProfile(profileModules)
      .unwrap()
      .then(() => {
        dispatch(openSnack({ message: "Profile updated", type: "success" }));
        closeForm();
      })
      .catch((error) => {
        dispatch(
          openSnack({
            message: "Modules could not be updated",
            type: "error"
          })
        );
        console.log(`Error:${error}`);
      });
  };

  const handleModuleClick = (module) => {
    const newModulesSelected = [...modulesSelected];
    const moduleIndex = newModulesSelected.indexOf(module);
    if (moduleIndex !== -1) {
      newModulesSelected.splice(moduleIndex, 1);
    } else {
      newModulesSelected.push(module);
    }
    setModulesSelected(newModulesSelected);
  };

  useEffect(() => {
    if (selectedProfile && modules) {
      const moduleArray = modules?.map((item) => item.module);
      setProfileName(selectedProfile.description);
      setModulesSelected(moduleArray);
      setProfileVisibleToPartner(selectedProfile.visibleToPartnerRole);
    }
  }, [selectedProfile, modules]);

  return (
    modulesSelected.length >= 0 && (
      <>
        <DialogTitle>
          <TitleGrid container>
            <Grid item xs>
              <Grow in timeout={800}>
                <Typography variant="h3">Update Profile</Typography>
              </Grow>
            </Grid>
            <Grid item xs textAlign="right">
              <IconButton onClick={closeForm}>
                <SvgIcon>
                  <XMarkIcon />
                </SvgIcon>
              </IconButton>
            </Grid>
          </TitleGrid>
        </DialogTitle>
        <CustomDialogContent>
          <ContentGrid container rowGap={2}>
            <TextFieldGrid item xs={12} md={12}>
              <Typography variant="p">
                Profile Name<RequiredIndicator>*</RequiredIndicator>
              </Typography>
              <CustomTextField
                size="small"
                fullWidth
                value={profileName}
                onChange={(e) => setProfileName(e.target.value)}
              ></CustomTextField>
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={12}>
              <Typography variant="p">Is profile visible to partner?</Typography>
              <Switch
                checked={profileVisibleToPartner}
                onChange={() => setProfileVisibleToPartner(!profileVisibleToPartner)}
              />
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={12}>
              <Typography variant="p">
                Modules<RequiredIndicator>*</RequiredIndicator>
              </Typography>
              <List>
                {[...buildingModules]
                  ?.sort((a, b) => a.module.localeCompare(b.module))
                  .map((item, index) => {
                    return (
                      <ListItem key={index} disablePadding>
                        <ListItemButton dense onClick={() => handleModuleClick(item.module)}>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={modulesSelected?.includes(item.module)}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText primary={item.module} />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
              </List>
            </TextFieldGrid>
          </ContentGrid>
        </CustomDialogContent>
        <DialogActions>
          <ActionsGrid container>
            <StyledButton onClick={() => dispatch(closeActionDialog())}> Cancel</StyledButton>
            <Box sx={{ position: "relative" }}>
              <StyledButton selected onClick={handleConfirm} disabled={isLoading}>
                Apply
              </StyledButton>
              {isLoading && <CustomCircularProgress size={24} />}
            </Box>
          </ActionsGrid>
        </DialogActions>
      </>
    )
  );
}
