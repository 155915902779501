import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from '@mui/material'
import React from 'react'
import {
  ArrowLeftOnRectangleIcon,
  UserIcon,
  WrenchIcon,
} from '@heroicons/react/24/outline'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { closePopover } from '../../../redux/slices/popoversSlice'
import { changeIndexSelected } from '../../../redux/slices/sideMenuSlice'

export default function AvatarMenu() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { logout } = useAuth0()
  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } }) // Redirect user to home page after logout
  }
  const handleSelection = (option) => {
    switch (option) {
      case 'settings':
        navigate('/settings')
        break
        case 'account':
          navigate('/account')
          break
      default:
        return
    }
    dispatch(changeIndexSelected(null))
    dispatch(closePopover())
  }
  return (
    <List>
      <ListItem
        onClick={() => handleSelection('settings')}
        style={{ cursor: 'pointer' }}
      >
        <ListItemIcon>
          <SvgIcon>
            <WrenchIcon />
          </SvgIcon>
        </ListItemIcon>
        <ListItemText primary={'Settings'} />
      </ListItem>
      <ListItem
        onClick={() => handleSelection("account")}
        style={{ cursor: 'pointer' }}
      >
        <ListItemIcon>
          <SvgIcon>
            <UserIcon />
          </SvgIcon>
        </ListItemIcon>
        <ListItemText primary={'Account'} />
      </ListItem>
      <ListItem onClick={handleLogout} style={{ cursor: 'pointer' }}>
        <ListItemIcon>
          <SvgIcon>
            <ArrowLeftOnRectangleIcon />
          </SvgIcon>
        </ListItemIcon>
        <ListItemText primary={'Log out'} />
      </ListItem>
    </List>
  )
}
