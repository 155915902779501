import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useGetConditionalAlertsByBuildingQuery } from '../../../redux/metaDataApi'
import {  processAndExtractAlertsData } from '../../../helpers/alertsFunctions'
import { CustomCard } from '../../../App.styled'
import { ContentGrid, StyledBox, StyledChip } from '../AlertsLite/AlertsLite.styled'
import AlertsGrid from '../AlertsGrid/AlertsGrid'
import AlertsChart from '../AlertsChart/AlertsChart'
import { Grid, Typography } from '@mui/material'
import Chart from '../../../resources/images/graph.png'
import { t } from 'i18next'

export default function AlertsPro() {
  const [processedAlerts, setProcessedAlerts] = useState([])
  const selectedAlert = useSelector((state) => state.alerts.selectedAlert[0])
  const selectedBuilding = useSelector((state) => state.buildings.selectedBuilding)
  const calendarSelection = useSelector((state) => state.calendar.calendarSelection);

  const filter = {
    buildingId: selectedBuilding?.id,
    from: calendarSelection.dateStart,
    to: calendarSelection.timeEnd,
    conditionalAlertTypes: 'PerformanceAlert',
  };

  const { data: alerts } = useGetConditionalAlertsByBuildingQuery(filter, {
    skip: !selectedBuilding,
  })

  useEffect(() => {
    if (alerts) {
      const data = processAndExtractAlertsData(alerts.filter((item) =>item.conditionalAlertType === 'PerformanceAlert'))
      setProcessedAlerts(data)
    }
  }, [alerts])

  const columns = [
    { field: 'startDate', headerName: t('alerts.Date'), flex: 1 },
    {
      field: "creationUser",
      headerName: t("alerts.created"),
      flex: 1,
    },
    {
      field: "status",
      headerName: t("alerts.Status"),
      flex: 1,
      renderCell: (params) => {
        return <StyledChip label={params.row.status} type={params.row.status} t={t} />;
      },
    },
    {
      field: "severity",
      headerName: t("alerts.Severity"),
      flex: 1,
      renderCell: (params) => {
        return <StyledChip label={params.row.severity} type={params.row.severity} t={t} />;
      },
    },
    { field: 'sensor', headerName:  t('alerts.Value1'), flex: 1 },
    { field: 'sensor2', headerName:  t('alerts.Value2'), flex: 1 },
    { field: 'setPoint', headerName:  t('alerts.Value3'), flex: 1 },
  ]

  return (
    <CustomCard key={'alerts_pro'}>
      <ContentGrid container>
        <Grid item container sx={{ height: '50%' }}>
           {processedAlerts.length >0 ? (
            <AlertsGrid processedAlerts={processedAlerts} columns={columns} />
          ):(
            <StyledBox>
              <Typography variant="h1">{t("alerts.noData")}</Typography>
            </StyledBox>
          )}
        </Grid>
        <Grid item container sx={{ height: '50%', width: '100%' }}>
          {selectedAlert ? (
            <AlertsChart />
          ) : (
            <StyledBox>
              <img
                src={Chart}
                alt={'enappgy'}
                style={{ width: 125.501, height: 'auto', marginBottom: '16px' }}
                loading="lazy"
              ></img>
              <Typography variant="h1">{t("alerts.noSelect")}</Typography>
            </StyledBox>
          )}
        </Grid>
      </ContentGrid>
    </CustomCard>
  )
}
