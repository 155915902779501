import { Box, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const CustomTitle = styled(Typography)(() => ({
  textAlign: 'center',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const CustomAddBox = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: theme.spacing(1),
}))

export const CustomAlertsBox = styled(Box)(({theme}) => ({
    width: '100%',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    height: '50%',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    rowGap: theme.spacing(1),
    overflow:'auto'
  }))

export const AddWrapper = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '70%',
  padding: '16px',
}))

export const NoAlertsContainer = styled(Grid)(() => ({
    height: '50vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }))
