import { TabContext, TabList } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { TabPanelContainer } from "../../App.styled";
import { Outlet, useNavigate } from "react-router-dom";

export default function Settings() {
  const [value, setValue] = useState("esgReport");
  const navigate = useNavigate();
  const basePath = "/settings";

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`${basePath}/${newValue}`);
  };
  useEffect(() => {
    navigate(`${basePath}/esgReport`);
  }, []);
  
  return (
    <Box sx={{ width: "100%", typography: "body1", height: "100%" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "transparent" }}>
          <TabList onChange={handleChange}>
            <Tab label={t("reports.title")} value="esgReport" />
            <Tab label={t("alerts.alerts")} value="alertsSettings" />
          </TabList>
        </Box>
        <TabPanelContainer value="esgReport">
          <Outlet />
        </TabPanelContainer>
        <TabPanelContainer value="alertsSettings">
          <Outlet />
        </TabPanelContainer>
      </TabContext>
    </Box>
  );
}
