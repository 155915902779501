import React, { useEffect, useState } from "react";
import { CustomCard } from "../../App.styled";
import { Grid, MenuItem, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CustomSelect } from "../ActionDialog/Forms/Forms.styled";
import { useUpdateUserLangMutation } from "../../redux/metaDataApi";
import { openSnack } from "../../redux/slices/snackSlice";
import { changePreferredLanguage } from "../../redux/slices/currentSessionSlice";

export default function Account() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { firstName, lastName, role, email, userId } = useSelector((state) => state.session);
  const profile = useSelector((state) => state.session.selectedProfile);
  const preferredLang = useSelector((state) => state.session.preferredLang);
  const [updateLang] = useUpdateUserLangMutation();
  const languages = ["en", "nl", "es"];
  const handleLanguageChange = async (event) => {
    const selectedLanguage = event.target.value;
    const payload = {
      userId: userId,
      language: selectedLanguage
    };
    await updateLang(payload)
      .unwrap()
      .then(() => {
        dispatch(openSnack({ message: "Language has been Updated", type: "success" }));
        i18n.changeLanguage(selectedLanguage);
        dispatch(changePreferredLanguage(selectedLanguage));
      })
      .catch((error) => {
        dispatch(
          openSnack({ message: "Language could not update. Please try Again", type: "error" })
        );
        console.log(`Error:${error}`);
      });
  };

  const translatedLanguages = languages.map((language) => ({
    language,
    translation: t(`lang.${language}`)
  }));

  return (
    <CustomCard>
      <Grid
        container
        direction="column"
        sx={{ height: "100%" }}
        alignContent="flex-start"
        justifyContent="flex-start"
        padding={4}
        rowGap={2}
      >
        <Typography variant="h3">{t("account.name")}</Typography>
        <Typography variant="p">
          {firstName} {lastName}
        </Typography>
        <Typography variant="h3">{t("account.email")}</Typography>
        <Typography variant="p">{email}</Typography>
        <Typography variant="h3">{t("account.language")}</Typography>
        <CustomSelect sx={{ width: "200px" }} onChange={handleLanguageChange} value={preferredLang}>
          {[...translatedLanguages]
            ?.sort((a, b) => a.translation.localeCompare(b.translation))
            .map((translatedLanguage) => (
              <MenuItem value={translatedLanguage.language} key={translatedLanguage.language}>
                {translatedLanguage.translation}
              </MenuItem>
            ))}
        </CustomSelect>
        <Typography variant="h3">{t("account.role")}</Typography>
        <Typography variant="p">{t(`role.${role}`)}</Typography>
        <Typography variant="h3">{t("account.profile")}</Typography>
        <Typography variant="p">{profile.description}</Typography>
      </Grid>
    </CustomCard>
  );
}
