import { Box, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => !['status'].includes(prop),
})(({ status }) => ({
  width: '100%',
  height: '90px',
  borderRadius: '6px 0px 0px 6px',

  ...(status === 'Active' && {
    borderLeft: 'solid 5px red',
  }),
  ...(status === 'Resolved' && {
    borderLeft: 'solid 5px green',
  }),
}))

export const TitleGrid = styled(Grid)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
}))
