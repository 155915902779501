import { MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeTenantSelection } from "../../../redux/slices/currentSessionSlice";
import {
  useGetTenantsWithBuildingProfilesQuery,
  useGetUserTenantsByIdQuery
} from "../../../redux/metaDataApi";
import { StyledSelect } from "../../../App.styled";

export default function TenantMenu() {
  const dispatch = useDispatch();
  const [tenant, setTenant] = useState("");
  const userId = useSelector((state) => state.session.userId);
  const { data: tenants } = useGetUserTenantsByIdQuery(userId, {
    skip: !userId
  });
  const { data: tenantsCatalog } = useGetTenantsWithBuildingProfilesQuery();
  const selectedTenant = useSelector((state) => state.session.selectedTenant);
  let filteredTenantsCatalog = []; // Initialize it outside the conditional block

  // Workaround for backend issues Needs to be removed
  if (tenantsCatalog && tenants) {
    const tenantIds = tenants.map((tenant) => tenant.tenantId);
    filteredTenantsCatalog = tenantsCatalog
      .filter((tenant) => tenantIds.includes(tenant.id))
      .map(({ id, description }) => ({ id, description }));
  }

  const handleChange = (e) => {
    const value = e.target.value;
    const tenant = filteredTenantsCatalog.find((b) => b.id === value);
    dispatch(changeTenantSelection(tenant));
  };

  useEffect(() => {
    if (selectedTenant) {
      setTenant(selectedTenant.id);
    }
  }, [selectedTenant]);

  return (
    <StyledSelect
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={tenant}
      onChange={handleChange}
      sx={{}}
    >
      {[...filteredTenantsCatalog]
        ?.sort((a, b) => a.description.localeCompare(b.description))
        .map((tenant) => (
          <MenuItem key={tenant.id} value={tenant.id}>
            {tenant.description}
          </MenuItem>
        ))}
    </StyledSelect>
  );
}
