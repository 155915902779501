import { Card } from "@mui/material";
import { styled } from "@mui/material/styles";
import Lottie from "lottie-react";
import { SnackbarContent } from "notistack";

export const StyledLottieNotifications = styled(Lottie)(({ theme }) => ({
    width: "60px", 
    height: "60px",
    margin: "16px",
    minWidth: "50px", // Ensures it never gets smaller than this width
    minHeight: "50px", // Ensures it never gets smaller than this height
    [theme.breakpoints.down("lg")]: {
      width: "50px", // Adjusted size as needed, also corrected typo from "50x" to "50px"
      height: "50px",
      margin: "14px",
    },
}));

export const NotificationCard = styled(Card)(() => ({
  paddingRight: "16px",
}));

export const StyledSnackbarContent = styled(SnackbarContent)(() => ({
  maxWidth: "700px",
}));
