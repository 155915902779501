import { Dialog } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AddUserForm from "./Forms/AddUserForm";
import { closeActionDialog } from "../../redux/slices/actionDialogSlice";
import ModulesForm from "./Forms/ModulesForm";
import ProfileForm from "./Forms/ProfileForm";
import SensorTypesForm from "./Forms/SensorTypesForm";
import AddUserBuildingForm from "./Forms/AddUserBuildingForm";
import AddTenantForm from "./Forms/AddTenantForm";
import EditUserBuildingForm from "./Forms/EditUserBuildingForm";
import CreateLiteAlertForm from "./Forms/CreateLiteAlertForm";
import EditProfileForm from "./Forms/EditProfileForm";
import EditLiteAlertForm from "./Forms/EditLiteAlertForm";
import CreateProAlertForm from "./Forms/CreateProAlertForm";
import EditProAlertForm from "./Forms/EditProAlertForm";
import ConfirmForm from "./Forms/ConfirmForm";
import EditBuildingForm from "./Forms/EditBuildingForm";
import AddUserToBuildingForm from "./Forms/AddUserToBuildingForm";
import UpgradePro from "./Upgrade/UpgradePro";
import ChangeTenantName from "./Forms/ChangeTenantName";
import EditTariffFrom from "./Forms/EditTariffFrom";
import AddNewSeriesForm from "./Forms/AddNewSeriesForm";
import { cancelSeries } from "../../redux/slices/multisenseSlice";

export default function ActionDialog() {
  const dispatch = useDispatch();

  const open = useSelector((state) => state.actionDialog.open);
  const type = useSelector((state) => state.actionDialog.type);
  const seriesIndex = useSelector((state) => state.actionDialog.seriesIndex);
  const size = type === "upgradeAlerts" ? "xs" :"sm";
  const handleClose = () => {
    dispatch(closeActionDialog());
    dispatch(cancelSeries());
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { maxHeight: "fit-content" } }}
      maxWidth={size}
      open={open}
      onClose={handleClose}
      transitionDuration={500}
    >
      {
        {
          user: <AddUserForm />,
          modules: <ModulesForm />,
          profiles: <ProfileForm />,
          editProfile: <EditProfileForm />,
          sensors: <SensorTypesForm />,
          buildings: <AddUserBuildingForm />,
          editBuilding: <EditBuildingForm />,
          tenants: <AddTenantForm />,
          changeTenant: <ChangeTenantName />,
          editBuildings: <EditUserBuildingForm />,
          createLiteAlert: <CreateLiteAlertForm />,
          editLiteAlert: <EditLiteAlertForm />,
          createProAlert: <CreateProAlertForm />,
          editProAlert: <EditProAlertForm />,
          confirmFavorite: <ConfirmForm />,
          addUserToBuilding: <AddUserToBuildingForm />,
          upgradeAlerts: <UpgradePro />,
          tariff: <EditTariffFrom />,
          addSeries: <AddNewSeriesForm index={seriesIndex} />,
        }[type]
      }
    </Dialog>
  );
}
