import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { Constants } from "../../helpers/constants";
import { t } from "i18next";
import { Paper } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function LabelBottomNavigation() {
  const [value, setValue] = useState("/");
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  return (
    <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0, padding: 2, marginTop: 2 }} elevation={3}>
      <BottomNavigation sx={{ width: "100%"}} value={value} onChange={handleChange}>
        {Constants.MOBILE.MODULES.map((item) => (
          <BottomNavigationAction  disableRipple key={item.name} label={t(`Menu.${item.name}`)} value={item.route} icon={item.icon} />
        ))}
      </BottomNavigation>
    </Paper>
  );
}
