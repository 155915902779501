import React from 'react'
import { StyledPopover } from '../../App.styled'
import { useDispatch, useSelector } from 'react-redux'
import { closePopover } from '../../redux/slices/popoversSlice'
import AlertsNotification from '../AlertsNotification/AlertsNotification'
import DataFilter from '../MultiSense/ChartTitle/DataFilter/DataFilter'
import LocationFilter from '../MultiSense/ChartTitle/LocationFilter/LocationFilter'
import TenantMenu from '../AdminPanel/TenantMenu/TenantMenu'
import AvatarMenu from '../AppBar/AvatarMenu/AvatarMenu'
import BuildingMenu from '../AppBar/BuildingMenu/BuildingMenu'

export default function Popovers(props) {
  const dispatch = useDispatch()
  const type = useSelector((state) => state.popovers.type)
  const open =useSelector((state) => state.popovers.open)
  const id = useSelector((state) => state.popovers.id)
  const handleClose = () => dispatch(closePopover())
  return (
    <StyledPopover
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open && type != null && id === props.id}
      onClose={handleClose}
    >
      {
        {
          ALERTS_NOTIFICATION: <AlertsNotification />,
          DATA_FILTER: <DataFilter />,
          LOCATION_FILTER: <LocationFilter />,
          TENANT_DROPDOWN: <TenantMenu/>,
          AVATAR_MENU: <AvatarMenu/>,
          BUILDING_MENU:<BuildingMenu/>,
        }[type]
      }
    </StyledPopover>
  )
}
