import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  Checkbox,
  DialogActions,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import {
  ActionsGrid,
  ContentGrid,
  CustomAlertCard,
  CustomCircularProgress,
  CustomDialogContent,
  CustomSelect,
  CustomTextField,
  TitleGrid,
} from "./Forms.styled";
import { XMarkIcon } from "@heroicons/react/24/outline";
import border from "../../../resources/images/Border.svg";
import { useDispatch, useSelector } from "react-redux";
import { closeActionDialog } from "../../../redux/slices/actionDialogSlice";
import { CustomDateBox, StyledButton } from "../../../App.styled";
import {
  useCreateConditionalAlertSettingsMutation,
  useLazyGetAreasByFloorQuery,
} from "../../../redux/metaDataApi";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ModifiedConstants } from "../../../App";
import {
  StyledMenu,
  StyledTree,
} from "../../MultiSense/ChartTitle/LocationFilter/LocationFilter.styled";
import {
  addCondition,
  changeAlertSettingDaysOfWeek,
  changeAlertSettingEndTime,
  cleanProAlertSettings,
  removeCondition,
} from "../../../redux/slices/alertsProSettingsSlice";
import {
  cleanAlertSettings,
  changeAlertSettingStartTime,
} from "../../../redux/slices/alertSettingsSlice";
import { format } from "date-fns";
import { timeStringToDate } from "../../../helpers/calendarFunctions";
import { StyledStack } from "../../Calendar/FullCalendar/FullCalendar.styled";
import { openSnack } from "../../../redux/slices/snackSlice";
import { isEmpty, validateFormFields } from "./validators";
import { BuildingLabel, CustomTreeItem } from "./CreateLiteAlertForm";
import SetPoint from "../../Alerts/AlertsPro/SetPoint/SetPoint";
import SmartRule from "../../Alerts/AlertsPro/SmartRule/SmartRule";
import DataType from "../../Alerts/AlertsPro/DataType/DataType";
import { setConditions } from "../../../redux/slices/alertsProSettingsSlice";

export default function CreateProAlertForm() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentDate = new Date();
  const [errors, setErrors] = useState({
    location: "",
    alertTitle: "",
  });
  const [formData, setFormData] = useState({
    formSelectedDaysOfWeek: [],
    formStartTime: format(new Date(currentDate.setHours(0, 0, 0, 0)), "HH:mm"),
    formEndTime: format(new Date(currentDate.setHours(23, 59, 0, 0)), "HH:mm"),
    formTitle: "",
    formDescription: "",
    formChannel: "Email",
    formSeverity: "",
    formAllDay: true,
  });
  const { firstName, lastName } = useSelector((state) => state.session);
  const textFiledRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [childNodes, setChildNodes] = useState(null);
  const [buttonWidth, setButtonWidth] = useState(528);
  const [expanded, setExpanded] = useState([]);
  const [fetchingNodeId, setFetchingNodeId] = useState(null);

  const open = Boolean(anchorEl);
  const weekdays = ModifiedConstants.WEEK_DAYS;
  const { fullObject, conditions } = useSelector((state) => state.alertsProSettings);
  const { endTime, startTime, location } = useSelector((state) => state.alertsSettings);
  const { buildings, selectedBuilding } = useSelector((state) => state.buildings);

  const [addAlertSettings, { isLoading }] = useCreateConditionalAlertSettingsMutation();
  const [getAreas, { isFetching }] = useLazyGetAreasByFloorQuery();
  const handleChange = (value, key) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const closeForm = () => {
    dispatch(cleanAlertSettings());
    dispatch(closeActionDialog());
    dispatch(cleanProAlertSettings());
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const {
      formTitle: title,
      formDescription: description,
      formLocation: location,
      formSeverity: severity,
      formSelectedDaysOfWeek: selectedDaysOfWeek,
    } = formData;

    const newErrors = validateFormFields(formData);

    if (!isEmpty(newErrors)) {
      setErrors(newErrors);
      return;
    }

    //TODO needs to improve of backend lots of logic on frontend
    const getLocationId = (location) => {
      switch (location?.description?.split(":")[0]) {
        case "Building":
          return { buildingId: location.id };
        case "Floor":
          return { floorId: location.id };
        case "Area":
          return { areaId: location.id };
        default:
          return {};
      }
    };

    const locationIdObject = getLocationId(location);

    const payloadAlertSettings = {
      ...fullObject,
      name: title,
      description: description,
      conditionalAlertType: "PerformanceAlert",
      creationUser: `${firstName} ${lastName}`,
      creationDate: new Date(),
      conditionalAlertLocation: location?.description?.split(":")[0],
      ...locationIdObject,
      conditionalAlertSeverity: severity,
      validationDays:
        selectedDaysOfWeek[0] === "All"
          ? ModifiedConstants.ALL_DAYS.join(",")
          : selectedDaysOfWeek.join(","),
      sendEmailNotification: true,
      validationStartTime: startTime,
      validationEndTime: endTime,
      enabled: true,
    };

    await addAlertSettings(payloadAlertSettings)
      .unwrap()
      .then(() => {
        dispatch(openSnack({ message: "Alert Created", type: "success" }));
        dispatch(closeActionDialog());
      })
      .catch((error) => {
        dispatch(openSnack({ message: error.title, type: "error" }));
        console.log(`Error:${error}`);
      });
    dispatch(cleanAlertSettings());
    dispatch(cleanProAlertSettings());
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCheckBoxChange = () => {
    if (!formData.formAllDay) {
      let newTimeStart, newTimeEnd;
      newTimeStart = format(new Date(currentDate.setHours(0, 0, 0, 0)), "HH:mm");
      newTimeEnd = format(new Date(currentDate.setHours(23, 59, 0, 0)), "HH:mm");
      setFormData({
        ...formData, // spread existing formData to preserve other values
        formAllDay: !formData.formAllDay,
      });
      dispatch(changeAlertSettingStartTime(newTimeStart));
      dispatch(changeAlertSettingEndTime(newTimeEnd));
    }
    setFormData({
      ...formData, // spread existing formData to preserve other values
      formAllDay: !formData.formAllDay,
    });
  };
  const handleLocationChange = async (event, nodeIds) => {
    // Get the ids of all buildings
    const buildingIds = buildings.map((b) => b.id);

    // Identify the expanded building and floor
    const expandedBuilding = nodeIds.find((nodeId) => buildingIds.includes(nodeId));
    const expandedFloor = nodeIds.find((nodeId) => !buildingIds.includes(nodeId));

    // Ensure only one building and one floor are expanded at a time
    const newExpandedNodes = [];
    if (expandedBuilding) {
      newExpandedNodes.push(expandedBuilding);
      if (expandedFloor) {
        newExpandedNodes.push(expandedFloor);
      }
    }

    // Update the state for expanded nodes
    setExpanded(newExpandedNodes);
    setFetchingNodeId(expandedFloor);

    // Fetch and display areas for the expanded floor
    if (expandedFloor) {
      const childId = expandedFloor;
      const nodeData = buildings.find((b) => b.floors.find((f) => f.id === childId));

      if (nodeData) {
        try {
          const areas = await getAreas(childId).unwrap();
          const areaItems = [...areas].sort((a, b) => a.description.localeCompare(b.description)).map((area) =>  (
            <CustomTreeItem
              key={area.id}
              nodeId={area.id}
              setAnchorEl={setAnchorEl}
              label={
                <BuildingLabel
                  description={area.description}
                  labelPrefix={t("multisense.Area")}
                  nodeLevel="Area"
                />
              }
              buildingId={nodeData.id}
            />
          ));
          setChildNodes(areaItems);
        } catch (error) {
          console.error("Failed to load areas for floor ID:", childId, error);
        } finally {
          setFetchingNodeId(null);
        }
      }
    } else {
      setChildNodes([]);
    }
  };

  useEffect(() => {
    setFormData({
      ...formData, // spread existing formData to preserve other values
      formLocation: location,
      formSelectedDaysOfWeek: fullObject.validationDays,
    });
  }, [location, fullObject.validationDays]);

  useEffect(() => {
    const newStartTime = timeStringToDate(startTime);
    const newEndTime = timeStringToDate(endTime);
    setFormData((prevState) => ({
      ...prevState,
      formStartTime: newStartTime,
      formEndTime: newEndTime,
    }));
  }, [startTime, endTime]);

  useEffect(() => {
    if (textFiledRef.current) {
      setButtonWidth(textFiledRef.current.offsetWidth);
    }
  }, [textFiledRef]);

  return (
    selectedBuilding && (
      <div>
        <DialogTitle>
          <TitleGrid container>
            <Grid item xs>
              <Grow in timeout={800}>
                <Typography variant="h3">{t("alerts.createAlert")}</Typography>
              </Grow>
            </Grid>
            <Grid item xs textAlign="right">
              <IconButton onClick={closeForm}>
                <SvgIcon>
                  <XMarkIcon />
                </SvgIcon>
              </IconButton>
            </Grid>
          </TitleGrid>
        </DialogTitle>
        <CustomDialogContent>
          <ContentGrid container rowGap={2}>
            <CustomAlertCard>
              <TitleGrid container>
                <Typography variant="h3">{t("alerts.location")}</Typography>
              </TitleGrid>
              <CustomTextField
                value={
                  location?.description
                    ? `${t(`multisense.${location?.description?.split(":")[0]}`)}:${
                        formData.formLocation?.description?.split(":")[1]
                      }`
                    : ""
                }
                fullWidth
                onClick={handleClick}
                required
                error={!!errors.location}
                inputRef={textFiledRef}
                helperText={errors.location}
                onChange={(e) => handleChange(e.target.value, "formLocation")}
              />
              <StyledMenu
                sx={{ "& .MuiPaper-root": { width: `${buttonWidth}px !important` } }}
                id="simple-menu"
                anchorEl={anchorEl}
                open={open}
                fullWidth
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <MenuItem disableRipple>
                  <StyledTree
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    onNodeToggle={handleLocationChange}
                    expanded={expanded}
                  >
                    {buildings &&
                      buildings
                        ?.slice()
                        .sort((a, b) => a.description.localeCompare(b.description))
                        .map((building) => (
                          <CustomTreeItem
                            buildingId={building.id}
                            setAnchorEl={setAnchorEl}
                            itemId={building.id}
                            key={building.id}
                            nodeId={building.id}
                            label={
                              <BuildingLabel
                                description={building.description}
                                labelPrefix={t("multisense.Building")}
                                nodeLevel="Building"
                              />
                            }
                          >
                            {building.floors
                              ?.slice()
                              .sort((a, b) => a.description.localeCompare(b.description))
                              .map((floor) => (
                                <CustomTreeItem
                                  key={floor.id}
                                  nodeId={floor.id}
                                  itemId={floor.id}
                                  setAnchorEl={setAnchorEl}
                                  label={
                                    <BuildingLabel
                                      description={floor.description}
                                      labelPrefix={t("multisense.Floor")}
                                      nodeLevel="Floor"
                                      isFetching={isFetching && floor.id === fetchingNodeId}
                                    />
                                  }
                                  buildingId={building.id}
                                >
                                  <>{childNodes || [<div key="stub" />]}</>
                                </CustomTreeItem>
                              ))}
                          </CustomTreeItem>
                        ))}
                  </StyledTree>
                </MenuItem>
              </StyledMenu>
            </CustomAlertCard>
            <CustomAlertCard>
              <TitleGrid container>
                <Typography variant="h3">{t("alerts.time")}</Typography>
              </TitleGrid>
              <Typography variant="p" sx={{ width: "100% !important" }}>
                {t("calendarControl.title2")}
              </Typography>
              <Grid
                item
                container
                sx={{
                  justifyContent: "flex-start",
                  alignContent: "flex-start",
                  display: "flex",
                }}
                xs={12}
              >
                <StyledStack useFlexGap spacing={1}>
                  {weekdays?.map((option, index) => (
                    <StyledButton
                      key={index}
                      onClick={() => dispatch(changeAlertSettingDaysOfWeek(option))}
                      selected={formData.formSelectedDaysOfWeek.includes(option)}
                    >
                      {option === "All"
                        ? t(`calendarControl.${option}`)
                        : t(`calendarControl.${option}`).charAt(0)}{" "}
                    </StyledButton>
                  ))}
                </StyledStack>
                <Typography variant="p" sx={{ width: "100% !important" }}>
                  {" "}
                  {t("calendarControl.subtitleTime")}
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox checked={formData.formAllDay} onChange={handleCheckBoxChange} />
                  }
                  label={
                    <Typography variant="p" sx={{ width: "100% !important" }}>
                      {" "}
                      {t("calendarControl.checkBoxLegend")}
                    </Typography>
                  }
                />
                <StyledStack useFlexGap spacing={2} xs={12} sx={{ width: "100%" }}>
                  <CustomDateBox
                    disabled={formData.formAllDay}
                    type="time"
                    value={formData.formStartTime}
                    interval={60}
                    displayFormat="HH:00"
                    onValueChanged={(e) =>
                      dispatch(changeAlertSettingStartTime(format(e.value, "HH:00")))
                    }
                  />
                  <CustomDateBox
                    disabled={formData.formAllDay}
                    fullWidth
                    type="time"
                    interval={60}
                    value={formData.formEndTime}
                    onValueChanged={(e) =>
                      dispatch(changeAlertSettingEndTime(format(e.value, "HH:00")))
                    }
                    displayFormat="HH:00"
                  />
                </StyledStack>
              </Grid>
            </CustomAlertCard>
            <CustomAlertCard>
              <TitleGrid container>
                <Typography variant="h3">{t("alerts.when")}</Typography>
              </TitleGrid>
              {conditions.map((item, index) => {
                return (
                  <Card
                    sx={{
                      width: "100%",
                      padding: "16px",
                      boxShadow: "none",
                      borderRadius: "12px",
                      border: "1px solid #00000014",
                    }}
                  >
                    {item === "" ? (
                      <React.Fragment key={index}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Typography variant="p" sx={{ width: "100% !important" }}>
                            {t("alerts.select")}
                          </Typography>
                          {index > 0 && (
                            <IconButton onClick={() => dispatch(removeCondition({ index: index }))}>
                              <SvgIcon>
                                <XMarkIcon />
                              </SvgIcon>
                            </IconButton>
                          )}
                        </div>
                        <Stack direction="row" spacing={2.5} sx={{ marginTop: "6px" }}>
                          <StyledButton
                            onClick={() =>
                              dispatch(
                                setConditions({
                                  index: index,
                                  value: "DataType",
                                })
                              )
                            }
                          >
                            {t("alerts.dataType")}
                          </StyledButton>
                          <StyledButton
                            onClick={() =>
                              dispatch(
                                setConditions({
                                  index: index,
                                  value: "SetPoint",
                                })
                              )
                            }
                          >
                            {t("alerts.SetPoint")}
                          </StyledButton>
                          <StyledButton
                            onClick={() =>
                              dispatch(
                                setConditions({
                                  index: index,
                                  value: "SmartRule",
                                })
                              )
                            }
                          >
                            {t("alerts.smartRule")}
                          </StyledButton>
                        </Stack>
                      </React.Fragment>
                    ) : (
                      <React.Fragment key={index}>
                        {
                          {
                            DataType: <DataType key={index} conditionIndex={index} />,
                            SetPoint: <SetPoint key={index} conditionIndex={index} />,
                            SmartRule: <SmartRule key={index} conditionIndex={index} />,
                          }[item]
                        }
                      </React.Fragment>
                    )}
                  </Card>
                );
              })}
              {conditions.length < 2 && (
                <Stack direction="row" sx={{ width: "90%", marginTop: "-16px" }}>
                  <img src={border} alt="border" width="24" height="24" />
                  <StyledButton
                    sx={{ marginTop: "8px" }}
                    onClick={() => dispatch(addCondition({ condition: "And" }))}
                  >
                    {t("alerts.And")}
                  </StyledButton>
                  <Divider
                    sx={{
                      alignSelf: "center",
                      width: "16px",
                      marginTop: "8px",
                    }}
                  />
                  <StyledButton
                    sx={{ marginTop: "8px" }}
                    onClick={() => dispatch(addCondition({ condition: "Or" }))}
                  >
                    {t("alerts.Or")}
                  </StyledButton>
                </Stack>
              )}
            </CustomAlertCard>
            <CustomAlertCard>
              <TitleGrid container>
                <Typography variant="h3">{t("alerts.thenSend")}</Typography>
              </TitleGrid>
              <CustomTextField
                fullWidth
                value={formData.formTitle}
                placeholder={t("alerts.title")}
                required
                error={!!errors.alertTitle}
                helperText={errors.alertTitle}
                onChange={(e) => handleChange(e.target.value, "formTitle")}
              />
              <CustomTextField
                fullWidth
                value={formData.formDescription}
                placeholder={t("alerts.description")}
                onChange={(e) => handleChange(e.target.value, "formDescription")}
              />
              <Grid container flexDirection="row" columnGap={2}>
                <Grid item flexGrow={1}>
                  <Typography variant="p" sx={{ width: "100% !important" }}>
                    {t("alerts.Severity")}
                  </Typography>
                  <CustomSelect
                    fullWidth
                    value={formData.formSeverity}
                    onChange={(e) => handleChange(e.target.value, "formSeverity")}
                  >
                    {ModifiedConstants.SEVERITY.map((severity) => (
                      <MenuItem value={severity}> {t(`severities.${severity}`)}</MenuItem>
                    ))}
                  </CustomSelect>
                </Grid>
                <Grid item flexGrow={1}>
                  <Typography variant="p" sx={{ width: "100% !important" }}>
                    {t("alerts.Channel")}
                  </Typography>
                  <CustomSelect
                    fullWidth
                    value={formData.formChannel}
                    onChange={(e) => handleChange(e.target.value, "formChannel")}
                  >
                    {ModifiedConstants.CHANNEL.map((channel) => (
                      <MenuItem value={channel}> {t(`alerts.${channel}`)}</MenuItem>
                    ))}
                  </CustomSelect>
                </Grid>
              </Grid>
            </CustomAlertCard>
          </ContentGrid>
        </CustomDialogContent>
        <DialogActions>
          <ActionsGrid container>
            <StyledButton onClick={() => dispatch(closeActionDialog())}>
              {" "}
              {t("button.cancel")}
            </StyledButton>
            <Box sx={{ position: "relative" }}>
              <StyledButton selected onClick={(e) => handleSubmit(e)} disabled={isLoading}>
                {t("button.apply")}
              </StyledButton>
              {isLoading && <CustomCircularProgress size={24} />}
            </Box>
          </ActionsGrid>
        </DialogActions>
      </div>
    )
  );
}
