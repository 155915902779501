import React from "react";
import { CustomCard } from "../../../App.styled";
import { Avatar, AvatarGroup, Box, Grid, IconButton, SvgIcon, Typography } from "@mui/material";
import Alerts from "../../../resources/images/noAlerts.png";
import AddIcon from "@mui/icons-material/Add";
import { AddWrapper, CustomAddBox, CustomAlertsBox, CustomTitle } from "./AlertSettings.styled";
import AlertSettingsCard from "./AlertSettingsCard/AlertSettingsCard";
import { useGetConditionalAlertsSettingsQuery } from "../../../redux/metaDataApi";
import { useDispatch, useSelector } from "react-redux";
import { changeActionDialogType, openActionDialog } from "../../../redux/slices/actionDialogSlice";
import { t } from "i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { format, parseISO } from "date-fns";

export default function AlertsSettings() {
  const { user, isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const dispatch = useDispatch();
  const selectedBuilding = useSelector((state) => state.buildings.selectedBuilding);

  const { data: alertSettings } = useGetConditionalAlertsSettingsQuery(
    { buildingId: selectedBuilding?.id },
    { skip: !selectedBuilding }
  );

  const profile = useSelector((state) => state.session.selectedProfile);
  const modules = profile?.profileModules?.map((item) => item.module) || [];


  return (
    alertSettings &&
    (alertSettings?.length > 0 ? (
      <CustomCard sx={{ paddingY: "16px" }}>
        <Typography variant="h3">{t("alerts.lite")}</Typography>
        <CustomAlertsBox sx={{ flex: 1 }}>
          {alertSettings
            ?.filter((item) => item.conditionalAlertType === "DeviationAlert")
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((alert) => (
              <Box key={alert.id} sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="p" sx={{ fontWeight: "bold !important" }}>
                    {alert.name}
                  </Typography>
                </Box>
                {alert.lastUpdateUser && (
                  <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                    <AvatarGroup
                      max={2}
                      sx={{ ".MuiAvatarGroup-avatar": { width: 24, height: 24, fontSize: "10px" } }}
                    >
                      <Avatar alt={user.name} src={user.picture} sx={{ width: 24, height: 24 }} />
                      <Avatar alt={user.name} src={user.picture} sx={{ width: 24, height: 24 }} />
                      <Avatar alt={user.name} src={user.picture} sx={{ width: 24, height: 24 }} />
                    </AvatarGroup>

                    <Typography variant="p" sx={{ opacity: 0.5, fontSize: 12 }}>
                      Last Updated: {format(new Date(alert.lastUpdateDate), 'dd MMM yy')} by {alert.lastUpdateUser}
                    </Typography>
                  </Box>
                )}
                <AlertSettingsCard alert={alert} />
              </Box>
            ))}
          <Grid sx={{ justifyContent: "center", display: "flex" }}>
            <IconButton
              onClick={() => {
                dispatch(changeActionDialogType("createLiteAlert"));
                dispatch(openActionDialog());
              }}
            >
              <SvgIcon>
                <AddIcon />
              </SvgIcon>
            </IconButton>
          </Grid>
        </CustomAlertsBox>
        {modules.includes("MultisenseAlertsPro") && (
          <>
            <Typography variant="h3">{t("alerts.pro")}</Typography>
            <CustomAlertsBox>
              {alertSettings
                ?.filter((item) => item.conditionalAlertType === "PerformanceAlert")
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((alert, index) => (
                  <Box key={alert.id} sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="p" sx={{ fontWeight: "bold !important" }}>
                      {alert.name}
                    </Typography>
                  </Box>
                  {alert.lastUpdateUser && (
                    <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                      <AvatarGroup
                        max={2}
                        sx={{ ".MuiAvatarGroup-avatar": { width: 24, height: 24, fontSize: "10px" } }}
                      >
                        <Avatar alt={user.name} src={user.picture} sx={{ width: 24, height: 24 }} />
                        <Avatar alt={user.name} src={user.picture} sx={{ width: 24, height: 24 }} />
                        <Avatar alt={user.name} src={user.picture} sx={{ width: 24, height: 24 }} />
                      </AvatarGroup>
  
                      <Typography variant="p" sx={{ opacity: 0.5, fontSize: 12 }}>
                        Last Updated: {format(new Date(alert.lastUpdateDate), 'dd MMM yy')} by {alert.lastUpdateUser}
                      </Typography>
                    </Box>
                  )}
                  <AlertSettingsCard alert={alert} />
                </Box>
                ))}
              <Grid sx={{ justifyContent: "center", display: "flex" }}>
                <IconButton
                  onClick={() => {
                    dispatch(changeActionDialogType("createProAlert"));
                    dispatch(openActionDialog());
                  }}
                >
                  <SvgIcon>
                    <AddIcon />
                  </SvgIcon>
                </IconButton>
              </Grid>
            </CustomAlertsBox>
          </>
        )}
      </CustomCard>
    ) : (
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <img
          src={Alerts}
          alt={"enappgy"}
          style={{ height: "auto", marginBottom: "16px" }}
          loading="lazy"
        ></img>
        <Grid item>
          <Typography variant="h3">{t("alerts.noAlerts")}</Typography>
        </Grid>
        <AddWrapper item>
          <CustomAddBox>
            <CustomTitle variant="h2">{t("alerts.lite")}</CustomTitle>
            <Typography variant="p">{t("alerts.click")}</Typography>
            <IconButton
              onClick={() => {
                dispatch(changeActionDialogType("createLiteAlert"));
                dispatch(openActionDialog());
              }}
            >
              <SvgIcon>
                <AddIcon />
              </SvgIcon>
            </IconButton>
          </CustomAddBox>
          <CustomAddBox>
            <CustomTitle variant="h2">{t("alerts.pro")}</CustomTitle>
            <Typography variant="p">{t("alerts.click")}</Typography>
            <IconButton
              onClick={() => {
                if (modules.includes("MultisenseAlertsPro")) {
                  dispatch(changeActionDialogType("createProAlert"));
                } else {
                  dispatch(changeActionDialogType("upgradeAlerts"));
                }
                dispatch(openActionDialog());
              }}
            >
              <SvgIcon>
                <AddIcon />
              </SvgIcon>
            </IconButton>
          </CustomAddBox>
        </AddWrapper>
      </Box>
    ))
  );
}
