import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  Popover,
  SvgIcon,
  Typography,
} from "@mui/material";
import {
  ActionsGrid,
  ContentGrid,
  CustomAlertCard,
  CustomCircularProgress,
  CustomDialogContent,
  CustomSelect,
  CustomTextField,
  TitleGrid,
} from "./Forms.styled";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { TreeItem, useTreeItem } from "@mui/x-tree-view";
import { useDispatch, useSelector } from "react-redux";
import { closeActionDialog } from "../../../redux/slices/actionDialogSlice";
import { CustomDateBox, StyledButton } from "../../../App.styled";
import {
  useDeleteConditionalAlertSettingsMutation,
  useLazyGetAreasByFloorQuery,
  useUpdateConditionalAlertSettingsMutation,
} from "../../../redux/metaDataApi";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ModifiedConstants } from "../../../App";
import clsx from "clsx";
import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import { StyledMenu, StyledTree } from "../../MultiSense/ChartTitle/LocationFilter/LocationFilter.styled";
import {
  changeAlertSettingDaysOfWeek,
  changeAlertSettingEndTime,
  changeAlertSettingLocation,
  changeAlertSettingStartTime,
  cleanAlertSettings,
} from "../../../redux/slices/alertSettingsSlice";
import { format } from "date-fns";
import { timeStringToDate } from "../../../helpers/calendarFunctions";
import { StyledStack } from "../../Calendar/FullCalendar/FullCalendar.styled";
import { openSnack } from "../../../redux/slices/snackSlice";
import { validateDays } from "../../../helpers/functions";
import { t } from "i18next";

const CustomContent = React.forwardRef(
  (
    { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon, setAnchorEl },
    ref
  ) => {
    const {
      disabled,
      expanded,
      selected,
      focused,
      handleExpansion,
      handleSelection,
      preventSelection,
    } = useTreeItem(nodeId);
    const icon = iconProp || expansionIcon || displayIcon;
    const dispatch = useDispatch();

    return (
      <div
        className={clsx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.selected]: selected,
          [classes.focused]: focused,
          [classes.disabled]: disabled,
        })}
        onMouseDown={preventSelection}
        ref={ref}
      >
        <div onClick={handleExpansion} className={classes.iconContainer}>
          {icon}
        </div>
        <Typography
          component="div"
          className={classes.label}
          onClick={(event) => {
            handleSelection(event);
            setAnchorEl(null);
            dispatch(
              changeAlertSettingLocation({
                id: nodeId,
                description: `${label.props.labelPrefix}: ${label.props.description}`,
              })
            );
          }}
        >
          {label}
        </Typography>
      </div>
    );
  }
);

const CustomTreeItem = React.forwardRef(({ buildingId, setAnchorEl, index, ...props }, ref) => (
  <TreeItem
    ContentComponent={CustomContent}
    ContentProps={{ buildingId, setAnchorEl, index }}
    {...props}
    ref={ref}
  />
));

export const BuildingLabel = ({ description, labelPrefix, isFetching }) => (
  <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
    <Box color="inherit" sx={{ mr: 1 }} />
    <SvgIcon sx={{ alignContent: "baseline" }}>
      <BuildingOfficeIcon />
    </SvgIcon>
    <Typography variant="p" sx={{ width: "100% !important" }} color="inherit">
    {`${labelPrefix}: ${description}`}
    </Typography>
    {isFetching && <CircularProgress size={20} sx={{ marginLeft: "auto" }} />}
  </Box>
);
export default function EditLiteAlertForm() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentDate = new Date();
  const {
    id,
    severity,
    channel,
    description,
    title,
    bandwidth,
    setPoint,
    endTime,
    selectedDaysOfWeek,
    startTime,
    location,
    sensor,
    fullObject,
    creationUser,
  } = useSelector((state) => state.alertsSettings);
  const initialState = {
    formSensor: "",
    formLocation: {},
    formSelectedDaysOfWeek: [],
    formStartTime: format(new Date(currentDate.setHours(0, 0, 0, 0)), "HH:mm:ss"),
    formEndTime: format(new Date(currentDate.setHours(23, 59, 0, 0)), "HH:mm:ss"),
    formSetPoint: "",
    formBandwidth: "",
    formTitle: "",
    formDescription: "",
    formChannel: "",
    formSeverity: "",
    formAllDay: true,
  };
  const [formState, setFormState] = useState(initialState);
  const textFiledRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [childNodes, setChildNodes] = useState(null);
  const [buttonWidth, setButtonWidth] = useState(528);
  const [expanded, setExpanded] = useState([]);
  const [fetchingNodeId, setFetchingNodeId] = useState(null);

  const open = Boolean(anchorEl);
  const weekdays = ModifiedConstants.WEEK_DAYS;
  const { sensorTypes, buildings, selectedBuilding } = useSelector((state) => state.buildings);
  const { firstName, lastName} = useSelector((state) => state.session);
  const [updateAlertSettings, { isLoading }] = useUpdateConditionalAlertSettingsMutation();
  const [getAreas, { isFetching }] = useLazyGetAreasByFloorQuery();

  const [deleteAlertSettings, { isLoading: deleteLoading }] =
    useDeleteConditionalAlertSettingsMutation();

  const handleChange = (value, key) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const closeForm = () => {
    dispatch(cleanAlertSettings());
    dispatch(closeActionDialog());
  };

  const handelDelete = async () => {
    await deleteAlertSettings(id)
      .unwrap()
      .then(() => {
        dispatch(openSnack({ message: "Alert Deleted", type: "success" }));
        dispatch(closeActionDialog());
      })
      .catch((error) => {
        dispatch(openSnack({ message: `Error updating Alert`, type: "error" }));
        console.log(`Error:${error}`);
      });
    dispatch(cleanAlertSettings());
  };

  const handleConfirm = async () => {
    //TODO needs to improve of backend lots of logic on frontend
    const getLocationId = (location) => {
      switch (location.description?.split(":")[0]) {
        case "Building":
          return { buildingId: location.id };
        case "Floor":
          return { floorId: location.id };
        case "Area":
          return { areaId: location.id };
        default:
          return {};
      }
    };

    const locationIdObject = getLocationId(location);

    const payloadAlertSettings = {
      id: id,
      name: formState.formTitle,
      description: formState.formDescription,
      conditionalAlertType: "DeviationAlert",
      conditionalAlertLocation: location.description?.split(":")[0],
      ...locationIdObject,
      conditionalAlertSeverity: formState.formSeverity,
      validationDays:
        formState.formSelectedDaysOfWeek[0] === "All"
          ? ModifiedConstants.ALL_DAYS.join(",")
          : formState.formSelectedDaysOfWeek.join(","),
      validationStartTime: startTime,
      validationEndTime: endTime,
      sendEmailNotification: true,
      creationUser,
      lastUpdateUser:  `${firstName} ${lastName}`,
      enabled: true,
      LastUpdateDate: new Date(),
      conditionalAlertSettingConditions: [
        {
          id: fullObject.conditionalAlertSettingConditions[0].id,
          conditionalAlertSettingId: fullObject.id,
          order: 1,
          conditionalAlertComparisonOperator: "LowerThan",
          conditionalAlertSettingParameters: [
            {
              conditionalAlertSettingConditionId:
                fullObject.conditionalAlertSettingConditions[0].id,
              id: fullObject.conditionalAlertSettingConditions[0]
                .conditionalAlertSettingParameters[0].id,
              order: 1,
              conditionalAlertParameterType: "SensorType",
              sensorType1: formState.formSensor,
            },
            {
              conditionalAlertSettingConditionId:
                fullObject.conditionalAlertSettingConditions[0].id,
              id: fullObject.conditionalAlertSettingConditions[0]
                .conditionalAlertSettingParameters[1].id,
              order: 2,
              conditionalAlertParameterType: "SetPoint",
              setPoint: formState.formSetPoint,
              bandwidth: formState.formBandwidth,
            },
          ],
        },
        {
          id: fullObject.conditionalAlertSettingConditions[1].id,
          order: 2,
          conditionalAlertSettingId: fullObject.id,
          conditionalAlertComparisonOperator: "HigherThan",
          conditionalAlertLogicalOperator: "Or",
          conditionalAlertSettingParameters: [
            {
              conditionalAlertSettingConditionId:
                fullObject.conditionalAlertSettingConditions[1].id,
              id: fullObject.conditionalAlertSettingConditions[1]
                .conditionalAlertSettingParameters[0].id,
              order: 1,
              conditionalAlertParameterType: "SensorType",
              sensorType1: formState.formSensor,
            },
            {
              conditionalAlertSettingConditionId:
                fullObject.conditionalAlertSettingConditions[1].id,
              id: fullObject.conditionalAlertSettingConditions[1]
                .conditionalAlertSettingParameters[1].id,
              order: 2,
              conditionalAlertParameterType: "SetPoint",
              setPoint: formState.formSetPoint,
              bandwidth: formState.formBandwidth,
            },
          ],
        },
      ],
    };

    await updateAlertSettings(payloadAlertSettings)
      .unwrap()
      .then(() => {
        dispatch(openSnack({ message: "Alert Updated", type: "success" }));
        dispatch(closeActionDialog());
      })
      .catch((error) => {
        dispatch(openSnack({ message: `Error updating Alert.`, type: "error" }));
        console.log(`Error:${error}`);
      });
    dispatch(cleanAlertSettings());
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCheckBoxChange = () => {
    if (!formState.formAllDay) {
      let newTimeStart, newTimeEnd;
      newTimeStart = format(new Date(currentDate.setHours(0, 0, 0, 0)), "HH:mm:ss");
      newTimeEnd = format(new Date(currentDate.setHours(23, 59, 0, 0)), "HH:mm:ss");
      setFormState({
        ...formState, // spread existing formData to preserve other values
        formAllDay: !formState.formAllDay,
      });
      dispatch(changeAlertSettingStartTime(newTimeStart));
      dispatch(changeAlertSettingEndTime(newTimeEnd));
    }
    setFormState({
      ...formState, // spread existing formData to preserve other values
      formAllDay: !formState.formAllDay,
    });
  };

  const handleLocationChange = async (event, nodeIds) => {
    // Get the ids of all buildings
    const buildingIds = buildings.map((b) => b.id);
  
    // Identify the expanded building and floor
    const expandedBuilding = nodeIds.find((nodeId) => buildingIds.includes(nodeId));
    const expandedFloor = nodeIds.find((nodeId) => !buildingIds.includes(nodeId));
  
    // Ensure only one building and one floor are expanded at a time
    const newExpandedNodes = [];
    if (expandedBuilding) {
      newExpandedNodes.push(expandedBuilding);
      if (expandedFloor) {
        newExpandedNodes.push(expandedFloor);
      }
    }
  
    // Update the state for expanded nodes
    setExpanded(newExpandedNodes);
    setFetchingNodeId(expandedFloor);
  
    // Fetch and display areas for the expanded floor
    if (expandedFloor) {
      const childId = expandedFloor;
      const nodeData = buildings.find((b) => b.floors.find((f) => f.id === childId));
  
      if (nodeData) {
        try {
          const areas = await getAreas(childId).unwrap();
          const areaItems = [...areas].sort((a, b) => a.description.localeCompare(b.description)).map((area) =>  (
            <CustomTreeItem
              key={area.id}
              nodeId={area.id}
              setAnchorEl={setAnchorEl}
              label={
                <BuildingLabel
                  description={area.description}
                  labelPrefix={t("multisense.Area")}
                  nodeLevel="Area"
                />
              }
              buildingId={nodeData.id}
            />
          ));
          setChildNodes(areaItems);
        } catch (error) {
          console.error("Failed to load areas for floor ID:", childId, error);
        } finally {
          setFetchingNodeId(null);
        }
      }
    } else {
      setChildNodes([]);
    }
  };

  useEffect(() => {
    setFormState({
      ...formState,
      formSensor: sensor,
      formLocation: location,
      formSelectedDaysOfWeek: selectedDaysOfWeek,
      formStartTime: timeStringToDate(startTime), // Ensure startTime is in the desired format
      formEndTime: timeStringToDate(endTime), // Ensure endTime is in the desired format
      formSetPoint: setPoint,
      formBandwidth: bandwidth,
      formTitle: title,
      formDescription: description,
      formChannel: channel,
      formSeverity: severity,
    });
  }, [
    sensor,
    location,
    selectedDaysOfWeek,
    startTime,
    endTime,
    setPoint,
    bandwidth,
    title,
    description,
    channel,
    severity,
  ]);

  useEffect(() => {
    validateDays(selectedDaysOfWeek, dispatch);
  }, [selectedDaysOfWeek, dispatch]);

  useEffect(() => {
    const newStartTime = timeStringToDate(startTime);
    const newEndTime = timeStringToDate(endTime);
    setFormState((prevState) => ({
      ...prevState,
      formStartTime: newStartTime,
      formEndTime: newEndTime,
    }));
  }, [startTime, endTime]);

  useEffect(() => {
    if (textFiledRef.current) {
      setButtonWidth(textFiledRef.current.offsetWidth);
    }
  }, [textFiledRef]);

  return (
    <div>
      <DialogTitle>
        <TitleGrid container>
          <Grid item xs>
            <Grow in timeout={800}>
              <Typography variant="h3">{t("alerts.editAlert")}</Typography>
            </Grow>
          </Grid>
          <Grid item xs textAlign="right">
            <IconButton onClick={closeForm}>
              <SvgIcon>
                <XMarkIcon />
              </SvgIcon>
            </IconButton>
          </Grid>
        </TitleGrid>
      </DialogTitle>
      <CustomDialogContent>
        <ContentGrid container rowGap={2}>
          <CustomAlertCard>
            <TitleGrid container>
              <Typography variant="h3">{t("alerts.location")}</Typography>
            </TitleGrid>
            <CustomTextField
              value={`${t(`multisense.${location.description?.split(":")[0]}`)}:${
                formState.formLocation.description?.split(":")[1]
              }`}
              fullWidth
              onClick={handleClick}
              inputRef={textFiledRef}
            />
              <StyledMenu
                sx={{ "& .MuiPaper-root": { width: `${buttonWidth}px !important` } }}
                id="simple-menu"
                anchorEl={anchorEl}
                open={open}
                fullWidth
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <MenuItem disableRipple>
                  <StyledTree
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    onNodeToggle={handleLocationChange}
                    expanded={expanded}
                  >
                    {buildings &&
                      buildings
                        ?.slice()
                        .sort((a, b) => a.description.localeCompare(b.description))
                        .map((building) => (
                          <CustomTreeItem
                            buildingId={building.id}
                            setAnchorEl={setAnchorEl}
                            itemId={building.id}
                            key={building.id}
                            nodeId={building.id}
                            label={
                              <BuildingLabel
                                description={building.description}
                                labelPrefix={t("multisense.Building")}
                                nodeLevel="Building"
                              />
                            }
                          >
                            {building.floors
                              ?.slice()
                              .sort((a, b) => a.description.localeCompare(b.description))
                              .map((floor) => (
                                <CustomTreeItem
                                  key={floor.id}
                                  nodeId={floor.id}
                                  itemId={floor.id}
                                  setAnchorEl={setAnchorEl}
                                  label={
                                    <BuildingLabel
                                      description={floor.description}
                                      labelPrefix={t("multisense.Floor")}
                                      nodeLevel="Floor"
                                      isFetching={isFetching && floor.id === fetchingNodeId}
                                    />
                                  }
                                  buildingId={building.id}
                                >
                                  <>{childNodes || [<div key="stub" />]}</>
                                </CustomTreeItem>
                              ))}
                          </CustomTreeItem>
                        ))}
                  </StyledTree>
                </MenuItem>
              </StyledMenu>
          </CustomAlertCard>
          <CustomAlertCard>
            <TitleGrid container>
              <Typography variant="h3">{t("alerts.time")}</Typography>
            </TitleGrid>
            <Typography variant="p" sx={{ width: "100% !important" }}>
              {t("calendarControl.title2")}
            </Typography>
            <Grid
              item
              container
              sx={{
                justifyContent: "flex-start",
                alignContent: "flex-start",
                display: "flex",
              }}
              xs={12}
            >
              <StyledStack useFlexGap spacing={1}>
                {weekdays?.map((option, index) => (
                  <StyledButton
                    key={index}
                    onClick={() => dispatch(changeAlertSettingDaysOfWeek(option))}
                    selected={formState.formSelectedDaysOfWeek.includes(option)}
                  >
                    {option === "All"
                      ? t(`calendarControl.${option}`)
                      : t(`calendarControl.${option}`).charAt(0)}{" "}
                  </StyledButton>
                ))}
              </StyledStack>
              <Typography variant="p" sx={{ width: "100% !important" }}>
                {t("calendarControl.subtitleTime")}
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox checked={formState.formAllDay} onChange={handleCheckBoxChange} />
                  }
                  label={
                    <Typography variant="p" sx={{ width: "100% !important" }}>
                      {t("calendarControl.checkBoxLegend")}
                    </Typography>
                  }
                />
              </FormGroup>
              <StyledStack useFlexGap spacing={2} xs={12} sx={{ width: "100%" }}>
                <CustomDateBox
                  disabled={formState.formAllDay}
                  type="time"
                  value={formState.formStartTime}
                  interval={60}
                  displayFormat="HH:00"
                  onValueChanged={(e) =>
                    dispatch(changeAlertSettingStartTime(format(e.value, "HH:00")))
                  }
                />
                <CustomDateBox
                  disabled={formState.formAllDay}
                  fullWidth
                  type="time"
                  interval={60}
                  value={formState.formEndTime}
                  onValueChanged={(e) =>
                    dispatch(changeAlertSettingEndTime(format(e.value, "HH:00")))
                  }
                  displayFormat="HH:00"
                />
              </StyledStack>
            </Grid>
          </CustomAlertCard>
          <CustomAlertCard>
            <TitleGrid container>
              <Typography variant="h3">{t("alerts.when")}</Typography>
            </TitleGrid>
            <CustomSelect
              value={formState.formSensor}
              fullWidth
              onChange={(e) => handleChange(e.target.value, "formSensor")}
            >
              {sensorTypes[selectedBuilding?.id]?.map((sensor) => (
                <MenuItem value={sensor} key={sensor}>
                  {t(`sensor.${sensor}`)}
                </MenuItem>
              ))}
            </CustomSelect>
            <CustomTextField
              fullWidth
              placeholder={t("alerts.SetPoint")}
              value={formState.formSetPoint}
              onChange={(e) => handleChange(e.target.value, "formSetPoint")}
            />
            <CustomTextField
              fullWidth
              value={formState.formBandwidth}
              placeholder={t("alerts.Bandwidth")}
              onChange={(e) => handleChange(e.target.value, "formBandwidth")}
            />
          </CustomAlertCard>
          <CustomAlertCard>
            <TitleGrid container>
              <Typography variant="h3">{t("alerts.thenSend")}</Typography>
            </TitleGrid>
            <CustomTextField
              fullWidth
              value={formState.formTitle}
              placeholder={t("alerts.title")}
              onChange={(e) => handleChange(e.target.value, "formTitle")}
            />
            <CustomTextField
              fullWidth
              value={formState.formDescription}
              placeholder={t("alerts.description")}
              onChange={(e) => handleChange(e.target.value, "formDescription")}
            />
            <Grid container flexDirection="row" columnGap={2}>
              <Grid item flexGrow={1}>
                <Typography variant="p" sx={{ width: "100% !important" }}>
                  {t("alerts.Severity")}
                </Typography>
                <CustomSelect
                  fullWidth
                  value={formState.formSeverity}
                  onChange={(e) => handleChange(e.target.value, "formSeverity")}
                >
                  {ModifiedConstants.SEVERITY.map((severity) => (
                    <MenuItem value={severity}> {t(`severities.${severity}`)}</MenuItem>
                  ))}
                </CustomSelect>
              </Grid>
              <Grid item flexGrow={1}>
                <Typography variant="p" sx={{ width: "100% !important" }}>
                  {t("alerts.Channel")}
                </Typography>
                <CustomSelect
                  fullWidth
                  value={formState.formChannel}
                  onChange={(e) => handleChange(e.target.value, "formChannel")}
                >
                  {ModifiedConstants.CHANNEL.map((channel) => (
                    <MenuItem value={channel}> {t(`alerts.${channel}`)}</MenuItem>
                  ))}
                </CustomSelect>
              </Grid>
            </Grid>
          </CustomAlertCard>
          <CustomAlertCard>
            <TitleGrid container>
              <Typography variant="h3">{t("alerts.Danger")}</Typography>
            </TitleGrid>
            <ActionsGrid container>
              <Grid item>
                <Typography variant="p" sx={{ width: "100% !important" }}>
                  <b>{t("alerts.delete")}</b>
                </Typography>
                <Typography variant="p" sx={{ width: "100% !important" }}>
                  {t("alerts.deleteSubtitle")}
                </Typography>
              </Grid>
              <Box sx={{ position: "relative" }}>
                <StyledButton
                  sx={{ color: "red !important" }}
                  onClick={() => handelDelete()}
                  disabled={deleteLoading}
                >
                  {t("button.delete")}
                </StyledButton>
                {deleteLoading && <CustomCircularProgress size={24} />}
              </Box>
            </ActionsGrid>
          </CustomAlertCard>
        </ContentGrid>
      </CustomDialogContent>
      <DialogActions>
        <ActionsGrid container>
          <StyledButton onClick={() => dispatch(closeActionDialog())}>
            {" "}
            {t("button.cancel")}
          </StyledButton>
          <Box sx={{ position: "relative" }}>
            <StyledButton selected onClick={() => handleConfirm()} disabled={isLoading}>
              {t("button.apply")}
            </StyledButton>
            {isLoading && <CustomCircularProgress size={24} />}
          </Box>
        </ActionsGrid>
      </DialogActions>
    </div>
  );
}
