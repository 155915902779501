import {  Autocomplete, Box, Menu, TextField} from '@mui/material'
import styled from 'styled-components'


  export const StyledMenu = styled(Menu)(() => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: '8px', // adjusted from theme.spacing(1)
      minWidth: 180,
      width: '250px',
      color: 'rgb(55, 65, 81)', // removed conditional theme dependency
      boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: 'grey', // removed theme dependency, replace with a suitable color
          marginRight: '24px', // adjusted from theme.spacing(1.5)
        },
        '& .MuiButtonBase-root': {
          disableRipple: true, // disabling the ripple effect here
        },
        '&:hover, &:focus, &:active': {
          backgroundColor: 'transparent !important',
        },
      },
    },
  }));

 export const StyledAutocomplete = styled(Autocomplete)(()=>({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    height: '40px', 
    '& .MuiInputBase-input': {
      height: "100%",
      padding: "0 9px",
      fontWeight: "400 !important",
      fontSize: "14px ! important",
      lineHeight: "20px ! important",
      fontFamily: "'Poppins', sans-serif !important",
      color: "#424242 !important",
    },
    '& fieldset': {
      border: '1px solid #00000014',
      borderRadius: '6px',
      backgroundColor: 'transparent',
    },
  },
  backgroundColor: '#ffffff',
 }))

 export const CustomTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    height: '40px', // Asegúrate de que esto coincida con la altura deseada
    '& input': {
      height: '100%', // Ajusta la altura del input al 100% del contenedor
      padding: '0 14px', // Ajusta el padding según sea necesario
    },
    '& fieldset': {
      border: '1px solid #00000014',
      borderRadius: '6px',
      backgroundColor: 'transparent',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '1.5px solid #00000040',
    },
    '&.Mui-focused fieldset': {
      border: '2px solid #00000040',
    },
    '&.Mui-focused': {
      '& label': {
        color: '#1c7ad4',
      },
    },
  },
}));


  export const ExitWrapper = styled(Box)(() => ({  
    display:"flex", 
    alignItems: "center",
  }));


  

  