import React from "react";
import { StyledActionWrapper, StyledHeaderWrapper } from "../../components/AppBar/AppBar.styled";
import { Badge, Box, IconButton, Stack, SvgIcon, Toolbar, Typography } from "@mui/material";
import { BellIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { StyledMobileAppBar } from "./HeaderMobile.styled";
import { useDispatch, useSelector } from "react-redux";
import { toggleDrawer } from "../../redux/slices/mobileDrawer";
import { useNavigate } from "react-router-dom";
import { useGetConditionalAlertsByBuildingQuery } from "../../redux/metaDataApi";
import { add, format, startOfMonth } from "date-fns";

export default function HeaderMobile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedBuilding } = useSelector((state) => state.buildings);
  const currentDate = new Date();
  const beginningOfLastMonth = startOfMonth(currentDate);
  const filter = {
    buildingId: selectedBuilding.id,
    from: format(beginningOfLastMonth, "yyyy-MM-dd"),
    to: format(add(currentDate, { days: 1 }), "yyyy-MM-dd"),
  };
  const { data: alerts } = useGetConditionalAlertsByBuildingQuery(filter, {
    skip: !selectedBuilding,
  });
  return (
    <StyledMobileAppBar position="fixed">
      <Toolbar>
        <StyledHeaderWrapper container>
          <IconButton onClick={() => dispatch(toggleDrawer("building"))} sx={{ padding: "0px" }} disableTouchRipple>
            <Typography variant="h2" noWrap>
              {selectedBuilding.description}
              <SvgIcon sx={{ height: "20px", width: "14px", marginLeft: "2px", marginTop: "3px" }}>
                <ChevronDownIcon />
              </SvgIcon>
            </Typography>
          </IconButton>
          <StyledActionWrapper item>
            <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1} alignItems="center">
              <Box sx={{ flexGrow: 1, justifyContent: "center" }}>
                <IconButton sx={{ height: "48px", width: "48px" }} onClick={() => navigate("alertsMobile")}>
                  <Badge badgeContent={ alerts?.filter(
                          (item) => item.conditionalAlertStatus === "Active"
                        )?.length} color="error">
                    <SvgIcon sx={{ height: "26px", width: "26px" }}>
                      <BellIcon />
                    </SvgIcon>
                  </Badge>
                </IconButton>
              </Box>
            </Stack>
          </StyledActionWrapper>
        </StyledHeaderWrapper>
      </Toolbar>
    </StyledMobileAppBar>
  );
}
