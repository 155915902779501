import {
  Box,
  Checkbox,
  DialogActions,
  DialogTitle,
  Grid,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  ActionsGrid,
  ContentGrid,
  CustomCircularProgress,
  CustomDialogContent,
  TextFieldGrid,
  TitleGrid
} from "./Forms.styled";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { closeActionDialog } from "../../../redux/slices/actionDialogSlice";
import {
  useAddBuildingModuleMutation,
  useGetBuildingModulesQuery
} from "../../../redux/metaDataApi";
import { useDispatch, useSelector } from "react-redux";
import { StyledButton } from "../../../App.styled";
import { ModifiedConstants } from "../../../App";
import { openSnack } from "../../../redux/slices/snackSlice";

export default function ModulesForm() {
  const dispatch = useDispatch();
  const selectedBuilding = useSelector((state) => state.adminPanel.buildingSelected);
  const { data: modules } = useGetBuildingModulesQuery(selectedBuilding, {
    skip: !selectedBuilding
  });
  const [modulesSelected, setModulesSelected] = useState([]);
  const [addModules, { isLoading }] = useAddBuildingModuleMutation();

  const closeForm = () => {
    dispatch(closeActionDialog());
  };

  const handleConfirm = async () => {
    const buildingModules = modulesSelected?.map((module) => ({
      buildingId: selectedBuilding,
      module
    }));
    await addModules(buildingModules)
      .unwrap()
      .then(() => {
        dispatch(openSnack({ message: "Building modules updated", type: "success" }));
        closeForm();
      })
      .catch((error) => {
        dispatch(openSnack({ message: "Building modules could not be updated", type: "error" }));
        console.log(`Error:${error}`);
      });
  };

  const handleModuleClick = (module) => {
    const newModulesSelected = [...modulesSelected];
    const moduleIndex = newModulesSelected.indexOf(module);
    if (moduleIndex !== -1) {
      newModulesSelected.splice(moduleIndex, 1);
    } else {
      newModulesSelected.push(module);
    }
    setModulesSelected(newModulesSelected);
  };

  useEffect(() => {
    setModulesSelected(modules?.map((item) => item.module));
  }, [modules]);

  return (
    <>
      <DialogTitle>
        <TitleGrid container>
          <Grid item xs>
            <Grow in timeout={800}>
              <Typography variant="h3">Add Module to Building</Typography>
            </Grow>
          </Grid>
          <Grid item xs textAlign="right">
            <IconButton onClick={closeForm}>
              <SvgIcon>
                <XMarkIcon />
              </SvgIcon>
            </IconButton>
          </Grid>
        </TitleGrid>
      </DialogTitle>
      <CustomDialogContent>
        <ContentGrid container rowGap={2}>
          <TextFieldGrid item xs={12} md={12}>
            <List>
              {[...ModifiedConstants.MODULE_CATALOG]
                ?.sort((a, b) => a.localeCompare(b))
                .map((module, index) => {
                  return (
                    <ListItem key={index} disablePadding>
                      <ListItemButton dense onClick={() => handleModuleClick(module)}>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={modulesSelected?.includes(module)}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText primary={module} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
            </List>
          </TextFieldGrid>
        </ContentGrid>
      </CustomDialogContent>
      <DialogActions>
        <ActionsGrid container>
          <StyledButton onClick={() => dispatch(closeActionDialog())}> Cancel</StyledButton>
          <Box sx={{ position: "relative" }}>
            <StyledButton selected onClick={handleConfirm} disabled={isLoading}>
              Apply
            </StyledButton>
            {isLoading && <CustomCircularProgress size={24} />}
          </Box>
        </ActionsGrid>
      </DialogActions>
    </>
  );
}
