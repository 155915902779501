import React, { useMemo } from "react";
import { GlobeEuropeAfricaIcon, BoltIcon } from "@heroicons/react/24/solid";
import { CloudIcon } from "@heroicons/react/24/outline";
import { CardHeader, CircularProgress, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  CustomAvatar,
  CustomLabel,
  CustomNumber,
  IconContainer,
  IconWrapper,
  LoadingOverlay,
  SavingsContainer,
  SavingsNumber
} from "./TotalSavings.styled";
import { AdditionalInfo, CostCard } from "../Costs.styled";
import { useGetSavingsQuery } from "../../../redux/dataApi";
import { formatNumberCustom, getSensorUnit } from "../../../helpers/functions";
import { useCurrencySymbol } from "../../../redux/customHooks/customHooks";

export default function TotalSavings({ sensor }) {
  const { t } = useTranslation();
  const currency = useCurrencySymbol();
  const calendarSelection = useSelector((state) => state.calendar.calendarSelection);
  const filter = useMemo(
    () => ({
      sensorType: sensor?.value,
      startDate: calendarSelection.dateStart,
      endDate: calendarSelection.dateEnd,
      startTime: calendarSelection.timeStart,
      endTime: calendarSelection.timeEnd,
      weekDays:
        calendarSelection.weekDays[0] === "All"
          ? ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].join(",")
          : calendarSelection.weekDays.join(",")
    }),
    [calendarSelection, sensor]
  );
  const selectedBuilding = useSelector((state) => state.buildings.selectedBuilding);
  const { data: savings, isFetching } = useGetSavingsQuery(
    { buildingId: selectedBuilding.id, filter },
    { skip: !selectedBuilding && !sensor }
  );

  const icons = useMemo(
    () => [
      {
        icon: CloudIcon,
        savings: formatNumberCustom(savings?.cO2KgFootprintReduction, false, 0, 2),
        label: () => (
          <>
            {t("costs.CO2")}
            <sub>2</sub>
          </>
        )
      },
      {
        icon: GlobeEuropeAfricaIcon,
        savings: formatNumberCustom(savings?.treeSeedlings, false, 0, 0),
        label: t("costs.tree")
      },
      {
        icon: BoltIcon,
        savings: formatNumberCustom(savings?.smartphonesCharged, false, 0, 0),
        label: t("costs.phones")
      }
    ],
    [savings, t]
  );

  return (
    <CostCard variant="outlined">
      <CardHeader title={t("costs.savingsTitle")} />
      {isFetching ? (
        <LoadingOverlay>
          <CircularProgress />
        </LoadingOverlay>
      ) : (
        <Grid container sx={{ alignContent: "space-between", height: "100%" }}>
          <Grid
            item
            container
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
            gap={1}
          >
            <SavingsNumber>
              {formatNumberCustom(savings.savings, false, 2, 4)} {getSensorUnit(sensor?.value)}
            </SavingsNumber>
            <Typography variant="p" sx={{ alignSelf: "center !important" }}>
              {currency} {formatNumberCustom(savings.moneySavings, false, 2, 4)} {t("costs.saved")}
            </Typography>
            <AdditionalInfo>
              {formatNumberCustom(savings.percentageSavings, false, 2, 4)} %
            </AdditionalInfo>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
            gap={1}
          >
            <IconWrapper>
              {icons.map((item, index) => (
                <SavingsContainer key={index}>
                  <CustomAvatar>
                    <IconContainer>
                      <item.icon />
                    </IconContainer>
                  </CustomAvatar>
                  <CustomNumber>{item.savings}</CustomNumber>
                  <CustomLabel>
                    {typeof item.label === "function" ? item.label() : item.label}
                  </CustomLabel>
                </SavingsContainer>
              ))}
            </IconWrapper>
          </Grid>
        </Grid>
      )}
    </CostCard>
  );
}
