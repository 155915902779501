import { Box, Grid, Chip } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledBox = styled(Box)(() => ({
  height: "100%",
  display: "flex",
  width: "100%",
  flexDirection: "column",
  border: "1px solid #eee",
  backgroundColor: "#fafafa",
  justifyContent: "center",
  alignItems: "center",
}));

export const ContentGrid = styled(Grid)(({ theme }) => ({
  justifyContent: "flex-start",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
}));

export const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "type" || prop !== "t",
})(({ type, t }) => ({
  color: "white",
  borderRadius: "6px",
  ...(t("alerts.Active") === type && {
    backgroundColor: "#CB2B1A",
  }),
  ...(t("alerts.Resolved") === type && {
    backgroundColor: "#666666",
  }),
  ...(t("severities.Low") === type && {
    backgroundColor: "#37B400",
  }),
  ...(t("severities.Medium") === type && {
    backgroundColor: "#FFC000",
    color: "inherit",
  }),
  ...(t("severities.High") === type && {
    backgroundColor: "#CB2B1A",
  }),
}));
