import {
  Box,
  DialogActions,
  DialogTitle,
  Grid,
  Grow,
  IconButton,
  SvgIcon,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import { StyledButton } from "../../../App.styled";
import {
  ActionsGrid,
  ContentGrid,
  CustomAutocomplete,
  CustomCircularProgress,
  CustomDialogContent,
  CustomTextField,
  RequiredIndicator,
  TextFieldGrid,
  TitleGrid
} from "./Forms.styled";

import { XMarkIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { closeActionDialog } from "../../../redux/slices/actionDialogSlice";
import { isConfirmPasswordValid, isEmailValid, isPasswordValid } from "./validators";
import {
  useCreateUserMutation,
  useGetProfilesByBuildingIdQuery,
  useGetTenantsQuery
} from "../../../redux/metaDataApi";
import { openSnack } from "../../../redux/slices/snackSlice";
import { t } from "i18next";

export default function AddUserForm() {
  const dispatch = useDispatch();
  const selectedBuilding = useSelector((state) => state.buildings.selectedBuilding);
  const { data: profiles } = useGetProfilesByBuildingIdQuery(selectedBuilding.id, {
    skip: !selectedBuilding
  });
  const { data: tenants } = useGetTenantsQuery();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [tenantSelected, setTenantSelected] = useState(null);

  const [createUser, { isLoading }] = useCreateUserMutation();

  const closeForm = () => {
    dispatch(closeActionDialog());
  };
  const handleTenantSelection = (tenant) => {
    setTenantSelected(tenant);
  };

  const handleConfirm = async () => {
    const payload = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      password: password,
      role: role,
      userTenants: [
        {
          tenantId: tenantSelected.id,
          isDefault: true
        }
      ]
    };
    await createUser(payload)
      .unwrap()
      .then(() => {
        dispatch(openSnack({ message: "User created", type: "success" }));
        dispatch(closeActionDialog());
      })
      .catch((error) => {
        dispatch(openSnack({ message: "User could not be created", type: "error" }));
        console.log(`Error:${error}`);
      });
  };

  return (
    profiles && (
      <>
        <DialogTitle>
          <TitleGrid container>
            <Grid item xs>
              <Grow in timeout={800}>
                <Typography variant="h3">{t("form.addUser")}</Typography>
              </Grow>
            </Grid>
            <Grid item xs textAlign="right">
              <IconButton onClick={closeForm}>
                <SvgIcon>
                  <XMarkIcon />
                </SvgIcon>
              </IconButton>
            </Grid>
          </TitleGrid>
        </DialogTitle>
        <CustomDialogContent>
          <ContentGrid container rowGap={2}>
            <TextFieldGrid item xs={12} md={6}>
              <Typography variant="p">
                {t("form.firstName")}
                <RequiredIndicator>*</RequiredIndicator>
              </Typography>
              <CustomTextField
                size="small"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              ></CustomTextField>
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={6}>
              <Typography variant="p">{t("form.lastName")}</Typography>
              <CustomTextField
                size="small"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              ></CustomTextField>
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={6}>
              <Typography variant="p">
                {t("form.email")} <RequiredIndicator>*</RequiredIndicator>
              </Typography>
              <CustomTextField
                size="small"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!isEmailValid(email)}
                helperText={!isEmailValid(email) && "Invalid email address"}
              ></CustomTextField>
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={6}>
              <Typography variant="p">
                {t("form.role")} <RequiredIndicator>*</RequiredIndicator>
              </Typography>
              <CustomAutocomplete
                size="small"
                disableClearable
                value={role}
                onChange={(_, value) => setRole(value)} // Using value directly
                options={["Admin", "User", "Manager", "Partner"]}
                renderInput={(params) => <CustomTextField {...params} />}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    <Typography variant="p">{option}</Typography>
                  </li>
                )}
              ></CustomAutocomplete>
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={6}>
              <Typography variant="p">
                {t("form.password")} <RequiredIndicator>*</RequiredIndicator>
              </Typography>
              <CustomTextField
                size="small"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={!isPasswordValid(password)}
                helperText={
                  !isPasswordValid(password) &&
                  "Invalid password. It must have at least 8 characters including an upper-case letter, a lower-case letter, a number, a special character (such as !@#$%^&*)."
                }
              ></CustomTextField>
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={6}>
              <Typography variant="p">
                {t("form.confirmPassword")} <RequiredIndicator>*</RequiredIndicator>
              </Typography>
              <CustomTextField
                size="small"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                error={!isConfirmPasswordValid(password, confirmPassword)}
                helperText={
                  !isConfirmPasswordValid(password, confirmPassword) && "Passwords do not match"
                }
              ></CustomTextField>
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={12}>
              <Typography variant="p">
                {t("form.selectDefaultTenant")} <RequiredIndicator>*</RequiredIndicator>
              </Typography>
              <CustomAutocomplete
                options={
                  Array.isArray(tenants)
                    ? [...tenants].sort((a, b) => a.description.localeCompare(b.description))
                    : []
                }
                getOptionLabel={(option) => option.description}
                value={tenantSelected}
                onChange={(e, newValue) => handleTenantSelection(newValue)}
                renderInput={(params) => <CustomTextField {...params} />}
              />
            </TextFieldGrid>
          </ContentGrid>
        </CustomDialogContent>
        <DialogActions>
          <ActionsGrid container>
            <StyledButton onClick={() => dispatch(closeActionDialog())}>
              {" "}
              {t("button.cancel")}
            </StyledButton>
            <Box sx={{ position: "relative" }}>
              <StyledButton
                selected
                onClick={handleConfirm}
                disabled={
                  isLoading ||
                  !isEmailValid(email) ||
                  !isConfirmPasswordValid(password, confirmPassword) ||
                  !isPasswordValid(password)
                }
              >
                {" "}
                {t("button.apply")}
              </StyledButton>
              {isLoading && <CustomCircularProgress size={24} />}
            </Box>
          </ActionsGrid>
        </DialogActions>
      </>
    )
  );
}
