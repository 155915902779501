import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { Grid, SvgIcon, Typography } from "@mui/material";
import {
  CustomBox,
  CustomTitle,
  DataWrapper,
  ItemWrapper,
  MainCard
} from "./ConsolidatedData.styled";
import {
  useGetConsolidatedMeasurementByBuildingQuery,
  useGetConsolidatedMeasurementByFloorQuery
} from "../../../redux/dataApi";
import { formatNumberCustom, getSensorUnit } from "../../../helpers/functions";
import { useTranslation } from "react-i18next";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";
import { getDifference } from "../../../helpers/multisenseFunctions";

export default function ConsolidatedData(props) {
  const { t } = useTranslation();
  const { selectedSensor, selectedLocation, selectedLocationIds, selectedLocationLevel } =
    useSelector((state) => state.multisense.selectedValues);
  const tenant = useSelector((state) => state.session.selectedTenant);
  const locationId = selectedLocationIds[0];
  const tenantId = tenant?.id;
  const [consolidatedData, setConsolidatedData] = useState(null);
  const [consolidatedDataValues, setConsolidatedDataValues] = useState({
    average: 0,
    peak: 0,
    period: 0
  });
  const buildingDataQuery = useGetConsolidatedMeasurementByBuildingQuery(
    {
      filter: {
        sensorType: selectedSensor[0],
        endDate: format(new Date(), "yyyy-MM-dd")
      },
      locationId,
      tenantId
    },
    { skip: !selectedSensor || !tenant || !locationId }
  );

  const floorDataQuery = useGetConsolidatedMeasurementByFloorQuery(
    {
      filter: {
        sensorType: selectedSensor[0],
        endDate: format(new Date(), "yyyy-MM-dd")
      },
      locationId,
      tenantId
    },
    { skip: !selectedSensor || !tenant || !locationId }
  );

  useEffect(() => {
    if (selectedLocationLevel[0] === "Building") {
      setConsolidatedData(buildingDataQuery.data);
    } else if (selectedLocationLevel[0] === "Floor") {
      setConsolidatedData(floorDataQuery.data);
    }
    setConsolidatedDataValues({
      average: getDifference(props.average, props.average2),
      peak: getDifference(props.peak, props.peak2),
      period: getDifference(props.period, props.period2)
    });
  }, [selectedLocation, buildingDataQuery.data, floorDataQuery.data, selectedLocationLevel, props]);

  const showPeriodInfoPanel = props.period > 0;
  const showTotalConsumptionInfoPanel = consolidatedData?.sum > 0;

  return (
    <MainCard>
      <Grid container sx={{ height: "100%" }} gap={0}>
        <DataWrapper item container>
          <ItemWrapper item>
            <CustomBox variant="outlined">
              <CustomTitle variant="p">{t(`multisense.info-panel.Average`)}</CustomTitle>{" "}
              <CustomTitle variant="consolidatedDashboard">
                {formatNumberCustom(props.average, false, 2, 4)} {getSensorUnit(selectedSensor[0])}
              </CustomTitle>
              {consolidatedDataValues.average > 0 ? (
                <CustomTitle variant="p" sx={{ color: "green !important" }}>
                  <SvgIcon sx={{ width: "14px", height: "14px" }}>
                    <ArrowUpIcon />
                  </SvgIcon>
                  {formatNumberCustom(consolidatedDataValues.average, false, 0, 4)}%
                  <Typography variant="p"> &nbsp; {t(`multisense.info-panel.vsPeriod`)}</Typography>
                </CustomTitle>
              ) : (
                <CustomTitle variant="p" sx={{ color: "red !important" }}>
                  <SvgIcon sx={{ width: "14px", height: "14px" }}>
                    <ArrowDownIcon />
                  </SvgIcon>
                  {formatNumberCustom(consolidatedDataValues.average, false, 0, 4)}%
                  <Typography variant="p"> &nbsp; {t(`multisense.info-panel.vsPeriod`)}</Typography>
                </CustomTitle>
              )}
            </CustomBox>
          </ItemWrapper>
          <ItemWrapper item>
            <CustomBox variant="outlined">
              <CustomTitle variant="p">{t(`multisense.info-panel.Peak`)}</CustomTitle>{" "}
              <CustomTitle variant="consolidatedDashboard">
                {formatNumberCustom(props.peak, false, 2, 4)} {getSensorUnit(selectedSensor[0])}
              </CustomTitle>
              {consolidatedDataValues.peak > 0 ? (
                <CustomTitle variant="p" sx={{ color: "green !important" }}>
                  <SvgIcon sx={{ width: "14px", height: "14px" }}>
                    <ArrowUpIcon />
                  </SvgIcon>
                  {formatNumberCustom(consolidatedDataValues.peak, false, 0, 4)}%
                  <Typography variant="p"> &nbsp; {t(`multisense.info-panel.vsPeriod`)}</Typography>
                </CustomTitle>
              ) : (
                <CustomTitle variant="p" sx={{ color: "red !important" }}>
                  <SvgIcon sx={{ width: "14px", height: "14px" }}>
                    <ArrowDownIcon />
                  </SvgIcon>
                  {formatNumberCustom(consolidatedDataValues.peak, false, 0, 4)}%
                  <Typography variant="p"> &nbsp; {t(`multisense.info-panel.vsPeriod`)}</Typography>
                </CustomTitle>
              )}
            </CustomBox>
          </ItemWrapper>
          {showPeriodInfoPanel && (
            <ItemWrapper item>
              <>
                <CustomBox variant="outlined">
                  <CustomTitle variant="p">{t(`multisense.info-panel.Period`)}</CustomTitle>
                  {props.period === 0 ? (
                    <CustomTitle variant="h3">{t(`multisense.info-panel.NoData`)}</CustomTitle>
                  ) : (
                    <CustomTitle variant="consolidatedDashboard">
                      {formatNumberCustom(props.period, false, 2, 4)}{" "}
                      {getSensorUnit(selectedSensor[0])}
                    </CustomTitle>
                  )}
                  {consolidatedDataValues.period > 0 && (
                    <>
                      {consolidatedDataValues.period > 0 ? (
                        <CustomTitle variant="p" sx={{ color: "green !important" }}>
                          <SvgIcon sx={{ width: "14px", height: "14px" }}>
                            <ArrowUpIcon />
                          </SvgIcon>
                          {formatNumberCustom(consolidatedDataValues.period, false, 0, 4)}%
                          <Typography variant="p">
                            {" "}
                            &nbsp; {t(`multisense.info-panel.vsPeriod`)}
                          </Typography>
                        </CustomTitle>
                      ) : (
                        <CustomTitle variant="p" sx={{ color: "red !important" }}>
                          <SvgIcon sx={{ width: "14px", height: "14px" }}>
                            <ArrowDownIcon />
                          </SvgIcon>
                          &nbsp;
                          {formatNumberCustom(consolidatedDataValues.period, false, 0, 4)}%
                          <Typography variant="p">
                            &nbsp; {t(`multisense.info-panel.vsPeriod`)}
                          </Typography>
                        </CustomTitle>
                      )}
                    </>
                  )}
                </CustomBox>
              </>
            </ItemWrapper>
          )}
          {showTotalConsumptionInfoPanel && (
            <ItemWrapper item>
              <CustomBox variant="outlined">
                <CustomTitle variant="p">
                  {t(`multisense.info-panel.Total_Consumption`)}
                </CustomTitle>
                {consolidatedData?.sum === 0 ? (
                  <CustomTitle variant="h3">{t(`multisense.info-panel.NoData`)}</CustomTitle>
                ) : (
                  <CustomTitle variant="consolidatedDashboard">
                    {formatNumberCustom(consolidatedData?.sum, false, 2, 4)}{" "}
                    {getSensorUnit(selectedSensor[0])}
                  </CustomTitle>
                )}
              </CustomBox>
            </ItemWrapper>
          )}
        </DataWrapper>
      </Grid>
    </MainCard>
  );
}
