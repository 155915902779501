import {
  Box,
  Checkbox,
  DialogActions,
  DialogTitle,
  Grid,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  ActionsGrid,
  ContentGrid,
  CustomCircularProgress,
  CustomDialogContent,
  TextFieldGrid,
  TitleGrid
} from "./Forms.styled";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { closeActionDialog } from "../../../redux/slices/actionDialogSlice";
import {
  useAddBuildingSensorTypeMutation,
  useGetBuildingSensorTypesQuery
} from "../../../redux/metaDataApi";
import { useDispatch, useSelector } from "react-redux";
import { StyledButton } from "../../../App.styled";
import { ModifiedConstants } from "../../../App";
import { openSnack } from "../../../redux/slices/snackSlice";
import { useTranslation } from "react-i18next";

export default function SensorTypesForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedBuilding = useSelector((state) => state.adminPanel.buildingSelected);
  const { data: sensors } = useGetBuildingSensorTypesQuery(selectedBuilding, {
    skip: !selectedBuilding
  });
  const [sensorTypesSelected, setSensorTypesSelected] = useState([]);

  const [addSensors, { isLoading }] = useAddBuildingSensorTypeMutation();

  const closeForm = () => {
    dispatch(closeActionDialog());
  };

  useEffect(() => {
    if (selectedBuilding && sensors) {
      setSensorTypesSelected(sensors.map((item) => item.sensorType));
    }
  }, [selectedBuilding, sensors]);

  const handleConfirm = async () => {
    const buildingModules = sensorTypesSelected?.map((sensorType) => ({
      buildingId: selectedBuilding,
      sensorType
    }));
    await addSensors(buildingModules)
      .unwrap()
      .then(() => {
        dispatch(openSnack({ message: "Sensor types updated", type: "success" }));
        closeForm();
      })
      .catch((error) => {
        dispatch(openSnack({ message: "Sensor types could not be updated", type: "error" }));
        console.log(`Error:${error}`);
      });
  };

  const handleSensorClick = (sensor) => {
    const newSensorsSelected = [...sensorTypesSelected];
    const sensorIndex = newSensorsSelected.indexOf(sensor);
    if (sensorIndex !== -1) {
      newSensorsSelected.splice(sensorIndex, 1);
    } else {
      newSensorsSelected.push(sensor);
    }
    setSensorTypesSelected(newSensorsSelected);
  };

  const translatedSensorTypes = Object.values(ModifiedConstants.SENSOR_TYPE).map((sensorType) => ({
    sensorType: sensorType.description,
    translation: t(`sensor.${sensorType.description}`)
  }));

  return (
    <>
      <DialogTitle>
        <TitleGrid container>
          <Grid item xs>
            <Grow in timeout={800}>
              <Typography variant="h3">Add Sensor Types to Building</Typography>
            </Grow>
          </Grid>
          <Grid item xs textAlign="right">
            <IconButton onClick={closeForm}>
              <SvgIcon>
                <XMarkIcon />
              </SvgIcon>
            </IconButton>
          </Grid>
        </TitleGrid>
      </DialogTitle>
      <CustomDialogContent>
        <ContentGrid container rowGap={2}>
          <TextFieldGrid item xs={12} md={12}>
            <List>
              {[...translatedSensorTypes]
                .sort((a, b) => a.translation.localeCompare(b.translation))
                .map((translatedSensorType, index) => {
                  return (
                    <ListItem key={index} disablePadding>
                      <ListItemButton
                        dense
                        onClick={() => handleSensorClick(translatedSensorType.sensorType)}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={sensorTypesSelected?.includes(translatedSensorType.sensorType)}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText primary={translatedSensorType.translation} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
            </List>
          </TextFieldGrid>
        </ContentGrid>
      </CustomDialogContent>
      <DialogActions>
        <ActionsGrid container>
          <StyledButton onClick={() => dispatch(closeActionDialog())}> Cancel</StyledButton>
          <Box sx={{ position: "relative" }}>
            <StyledButton selected onClick={handleConfirm} disabled={isLoading}>
              Apply
            </StyledButton>
            {isLoading && <CustomCircularProgress size={24} />}
          </Box>
        </ActionsGrid>
      </DialogActions>
    </>
  );
}
