import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomCard, StyledButton } from "../../App.styled";
import FloorPlanEngine from "@archilogic/floor-plan-sdk";
import { Box, Grid, MenuItem, Stack } from "@mui/material";
import { ContentGrid, CustomSelect, HelpText, SubTitle } from "./LigthnShades.styled";
import { useControlAreaLightsMutation, useLazyGetPowerLevelQuery } from "../../redux/externalApi";
import { colorizeAreaByPowerLevel } from "../../helpers/functions";
import { t } from "i18next";
import { CustomCircularProgress } from "../ActionDialog/Forms/Forms.styled";
import { openSnack } from "../../redux/slices/snackSlice";
import { useWindowDimensions } from "../../redux/customHooks/customHooks";
import { useGetAreasByFloorQuery } from "../../redux/metaDataApi";

export default function DigitalFloorPlanLight() {
  const dispatch = useDispatch();
  const mapContainerRef = useRef(null);
  const floorPlanRef = useRef(null);
  const [controlLights] = useControlAreaLightsMutation();
  const [powerLevels, setPowerLevels] = useState([]);
  const publishableToken = process.env.REACT_APP_ARCHILOGIC;
  const buildingRedux = useSelector((state) => state.buildings.buildings);
  const selectedBuildingRedux = useSelector((state) => state.buildings.selectedBuilding);
  const building = buildingRedux.find((building) => building.id === selectedBuildingRedux.id);
  const { width } = useWindowDimensions();
  const sortedFloors = [...building.floors].sort((a, b) =>
    a.description.localeCompare(b.description)
  );

  const [selectedFloor, setSelectedFloor] = useState(sortedFloors[0]);
  const [lights, setLights] = useState("Off");
  const [selectedBuilding, setSelectedBuilding] = useState(building.description);
  const [selectedArea, setSelectedArea] = useState(undefined);
  const [getPowerLevel] = useLazyGetPowerLevelQuery();
  const lightingOptions = [
    { text: "Off", powerLevelValue: "0.0" },
    { text: "Low", powerLevelValue: "25.0" },
    { text: "Medium", powerLevelValue: "50.0" },
    { text: "High", powerLevelValue: "100.0" },
  ];
  const shadesOptions = [{ text: "Low" }, { text: "High" }];
  const [isFetching, setIsFetching] = useState(false);

  const { data: areas, isSuccess: isAreasSuccess } = useGetAreasByFloorQuery(selectedFloor.id, {
    skip: !selectedFloor,
  });

  const fetchPowerLevels = async () => {
    setIsFetching(true);
    try {
      const powerLevelResults = await Promise.all(
        [...areas].map((area) => getPowerLevel({ areaId: area.id }).unwrap())
      );
      setPowerLevels(powerLevelResults);
    } catch (error) {
      console.error("Error fetching power levels:", error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (isAreasSuccess && areas.length > 0) {
      fetchPowerLevels();
    }
  }, [isAreasSuccess, areas]);

  const handleLightSelect = (option, index) => {
    changeLights(option.powerLevelValue);
  };

  const changeLights = async (powerLevel) => {
    await controlLights({
      areaId: selectedArea.id,
      powerLevel: Number(powerLevel),
    })
      .unwrap()
      .then(() => fetchPowerLevels())
      .catch((error) => {
        dispatch(openSnack({ message: t("toast.errorMessage"), type: "error" }));
        console.log(`Error:${error}`);
      });
  };

  const handleAreaSelect = (area) => {
    setSelectedArea(area);
  };

  useEffect(() => {
    if (
      publishableToken &&
      mapContainerRef.current &&
      selectedFloor &&
      isAreasSuccess
    ) {
      const startupOptions = {
        theme: {
          background: {
            color: "#ffffff",
          },
          elements: {
            roomStamp: {
              roomStampDisplay: ["name", "area"],
            },
          },
        },
        accessToken: publishableToken,
      };
      floorPlanRef.current = new FloorPlanEngine(mapContainerRef.current, startupOptions);
      floorPlanRef.current.loadScene(selectedFloor.floorPlanId, {
        publishableToken,
      });
    }
  }, [publishableToken, selectedFloor, isAreasSuccess]);

  useEffect(() => {
    if (areas && powerLevels) {
      const initialLights = {};
      [...areas].forEach((area, index) => {
        initialLights[area.id] = TransformPowerLevelToText(powerLevels[index]?.value);
        if (area.spaceId && powerLevels[index] !== undefined) {
          colorizeAreaByPowerLevel(area.spaceId, powerLevels[index].value, floorPlanRef.current);
        }
      });
      setLights(initialLights);
    }
  }, [areas, powerLevels]);

  const TransformPowerLevelToText = (powerLevelValue) => {
    if (powerLevelValue === "100.0") return "High";
    if (powerLevelValue === "50.0") return "Medium";
    if (powerLevelValue === "25.0") return "Low";
    return "Off";
  };

  return (
    building &&
    powerLevels &&
    areas && (
      <CustomCard>
        <Grid container direction="row" sx={{ height: "100%" }}>
          <ContentGrid item container>
            <Box sx={{ height: "fit-content" }}>
              <SubTitle variant="p">{t("lights.Building")} </SubTitle>
              <CustomSelect
                fullWidth
                value={selectedBuilding}
                onChange={(e) => setSelectedBuilding(e.target.value)}
              >
                {[building]?.map((building) => (
                  <MenuItem key={"key" + building.description} value={building.description}>
                    {building.description}
                  </MenuItem>
                ))}
              </CustomSelect>
              <SubTitle variant="p">{t("lights.Floors")}</SubTitle>
              <Stack useFlexGap spacing={1} direction="row">
                {sortedFloors?.map((floor, index) => (
                  <StyledButton
                    key={index}
                    selected={selectedFloor.id === floor.id}
                    onClick={() => {
                      setSelectedFloor(floor);
                    }}
                  >
                    {floor.description}
                  </StyledButton>
                ))}
              </Stack>
              <SubTitle variant="p">{t("lights.Area")}</SubTitle>
              <CustomSelect
                fullWidth
                value={selectedArea ? selectedArea : ""}
                onChange={(e) => {
                  handleAreaSelect(e.target.value);
                }}
              >
                {[...areas]
                  ?.sort((a, b) => a.description.localeCompare(b.description))
                  .map((area, index) => (
                    <MenuItem key={index} value={area}>
                      {area.description}
                    </MenuItem>
                  ))}
              </CustomSelect>
              <HelpText variant="p">{t("lights.helper")}</HelpText>
            </Box>
            {selectedArea && (
              <Box
                sx={{
                  height: "fit-content",
                  rowGap: "6px",
                  alignSelf: "flex-end",
                  borderTop: "solid 2px  rgba(0, 0, 0, 0.12)",
                  width: "100%",
                  marginTop: "auto",
                }}
              >
                <SubTitle variant="h3">{selectedArea.description}</SubTitle>
                <SubTitle variant="p">{t("lights.Lighting")}</SubTitle>
                <Stack useFlexGap spacing={1} direction="row">
                  {lightingOptions?.map((option, index) => (
                    <Box sx={{ position: "relative" }} key={index}>
                      <StyledButton
                        selected={lights[selectedArea.id] === option.text}
                        onClick={() => handleLightSelect(option, index)}
                      >
                        {t(`lights.${option.text}`)}
                      </StyledButton>
                      {isFetching && lights[selectedArea.id] === option.text && (
                        <CustomCircularProgress size={24} />
                      )}
                    </Box>
                  ))}
                </Stack>
                <SubTitle variant="p">{t("lights.Shades")}</SubTitle>
                <Stack useFlexGap spacing={1} direction="row">
                  {shadesOptions?.map((option, index) => (
                    <StyledButton key={index}>{t(`lights.${option.text}`)}</StyledButton>
                  ))}
                </Stack>
              </Box>
            )}
          </ContentGrid>
          {width > 600 && (
            <Grid item xs={9}>
              <div
                ref={mapContainerRef}
                id="map"
                style={{ width: "100%", height: "100%", padding: "18px" }}
              ></div>
            </Grid>
          )}
        </Grid>
      </CustomCard>
    )
  );
}
