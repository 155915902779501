import { AppBar } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledMobileAppBar = styled(AppBar)(({ theme}) => ({
    background: "#f1f5f8",
    color: "#000000",
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "none",
    borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
    height: `80px`,
    width: `100%`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }));