import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LockedMobile from "../mobileComponents/LockedMobile/LockedMobile";

export default function GuardedMobile({ children, allowedModule }) {
  const profile = useSelector((state) => state.session.selectedProfile);
  const [moduleArray, setModuleArray] = useState([]);

  useEffect(() => {
    if (profile) {
      const modules = profile.profileModules?.map((item) => item.module) || [];
      setModuleArray(modules);
    }
  }, [profile]);

  if (allowedModule !== "Account" && !moduleArray?.includes(allowedModule)) {
    return <LockedMobile module={allowedModule} />;
  } else {
    return children;
  }
}
