import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  open: false,
  type: ''
}

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    toggleDrawer: (state, action) => {
      state.open = !state.open
      state.type = action.payload
    },
  },
})

export const {
    toggleDrawer
} = drawerSlice.actions

export default drawerSlice.reducer
