import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, SvgIcon, Box, Typography, CircularProgress } from "@mui/material";
import { StyledMenu, StyledTree } from "./LocationDropdown.styled";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import { TreeItem, useTreeItem } from "@mui/x-tree-view";
import { StyledButton } from "../../../../../App.styled";
import clsx from "clsx";
import {
  selectLocation,
  modifyLocation,
  selectLocationId,
  selectMultisenseBuilding,
  setLocationLevel,
} from "../../../../../redux/slices/multisenseSlice";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useLazyGetAreasByFloorQuery } from "../../../../../redux/metaDataApi";
import { t } from "i18next";

export const CustomContent = React.forwardRef(
  (
    {
      classes,
      className,
      label,
      nodeId,
      icon: iconProp,
      expansionIcon,
      displayIcon,
      buildingId,
      setAnchorEl,
      index,
      isFetching,
    },
    ref
  ) => {
    const {
      disabled,
      expanded,
      selected,
      focused,
      handleExpansion,
      handleSelection,
      preventSelection,
    } = useTreeItem(nodeId);
    const icon = iconProp || expansionIcon || displayIcon;
    const dispatch = useDispatch();

    return (
      <div
        className={clsx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.selected]: selected,
          [classes.focused]: focused,
          [classes.disabled]: disabled,
        })}
        onMouseDown={preventSelection}
        ref={ref}
      >
        <div onClick={handleExpansion} className={classes.iconContainer}>
          {icon}
        </div>
        <Typography
          component="div"
          className={classes.label}
          onClick={(event) => {
            handleSelection(event);
            dispatch(
              selectMultisenseBuilding({
                id: buildingId,
                description: label.props.description,
              })
            );
            dispatch(
              selectLocation({
                index: index,
                location: label.props.description,
              })
            );

            dispatch(setLocationLevel({ index: index, level: label.props.nodeLevel }));
            dispatch(
              selectLocationId({
                index: index,
                location: nodeId,
              })
            );
            setAnchorEl(null);
          }}
        >
          {label}
        </Typography>
      </div>
    );
  }
);

export const CustomTreeItem = React.forwardRef(
  ({ buildingId, setAnchorEl, index, ...props }, ref) => (
    <TreeItem
      sx={{ width: "100%" }}
      ContentComponent={CustomContent}
      ContentProps={{ buildingId, setAnchorEl, index }}
      {...props}
      ref={ref}
    />
  )
);

export const BuildingLabel = ({ description, labelPrefix,isFetching }) => (
  <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
    <Box color="inherit" sx={{ mr: 1 }} />
    <SvgIcon sx={{ alignContent: "baseline" }}>
      <BuildingOfficeIcon />
    </SvgIcon>
    <Typography variant="p" color="inherit">
    {`${labelPrefix}: ${description}`}
    </Typography>
    {isFetching && <CircularProgress size={20} sx={{ marginLeft: 'auto' }} />}
  </Box>
);

export default function LocationDropdown(props) {
  const { t } = useTranslation();
  const buttonRef = useRef(null);
  const dispatch = useDispatch();
  const selectedBuilding = useSelector((state) => state.buildings.selectedBuilding);
  const { location, buildings, sensor } = useSelector((state) => state.multisense);
  const { centralSensorTypes } = useSelector((state) => state.buildings);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [buttonWidth, setButtonWidth] = useState(0);
  const [childNodes, setChildNodes] = useState(null);
  const [expanded, setExpanded] = useState([]);
  const [fetchingNodeId, setFetchingNodeId] = useState(null);
  const [getAreas, { isFetching }] = useLazyGetAreasByFloorQuery();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDelete = () => {
    dispatch(modifyLocation(props.index));
    setAnchorEl(null);
  };

  useEffect(() => {
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth);
    }
  }, []);

  const filter = centralSensorTypes[selectedBuilding.id]?.includes(sensor[props.index]);


  const handleChange = async (event, nodeIds) => {
    // Get the ids of all buildings
    const buildingIds = buildings.map((b) => b.id);

    // Separate expanded building and floor nodes
    const expandedBuilding = nodeIds.find((nodeId) => buildingIds.includes(nodeId));
    const expandedFloor = nodeIds.find((nodeId) => !buildingIds.includes(nodeId));

    // Ensure only one building and one floor is expanded
    const newExpandedNodes = [];
    if (expandedBuilding) {
      newExpandedNodes.push(expandedBuilding);
      if (expandedFloor) {
        newExpandedNodes.push(expandedFloor);
      }
    }

    // Update the state for expanded buildings and floors
    setExpanded(newExpandedNodes);
    setFetchingNodeId(expandedFloor);

    // Fetch and display areas for the expanded floor
    if (expandedFloor && !props.hideAreas) {
      const childId = expandedFloor;
      const nodeData = buildings.find((b) => b.floors.find((f) => f.id === childId));

      if (nodeData) {
        try {
          const areas = await getAreas(childId).unwrap();
          const areaItems = [...areas].sort((a, b) => a.description.localeCompare(b.description)).map((area) =>  (
            <CustomTreeItem
              key={area.id}
              nodeId={area.id}
              index={props.index}
              setAnchorEl={setAnchorEl}
              label={
                <BuildingLabel
                  description={area.description}
                  labelPrefix={t("multisense.Area")}
                  nodeLevel="Area"
                />
              }
              buildingId={nodeData.id}
            />
          ));
          setChildNodes(areaItems);
        } catch (error) {
          console.error("Failed to load areas for floor ID:", childId, error);
        } finally {
          setFetchingNodeId(null);
        }
      }
    } else {
      setChildNodes([]);
    }
  };
  return (
    <>
      <StyledButton
        id="demo-customized-button"
        variant="contained"
        disableElevation
        ref={buttonRef}
        onClick={handleClick}
        sx={{
          width: props.width ? props.width : "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="p">{location[props.index]}</Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {location[props.index] !== "" ? (
            <CloseIcon
              fontSize="small"
              onClick={(e) => {
                e.stopPropagation();
                handleDelete();
              }}
            />
          ) : (
            <></>
          )}
          <KeyboardArrowDownIcon />
        </Box>
      </StyledButton>
      <StyledMenu
        sx={{ "& .MuiPaper-root": { width: buttonWidth } }}
        id="simple-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem disableRipple>
          <StyledTree
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            onNodeToggle={handleChange}
            expanded={expanded}
          >
            {buildings &&
              buildings
                ?.slice()
                .sort((a, b) => a.description.localeCompare(b.description))
                .map((building) => (
                  <CustomTreeItem
                    buildingId={building.id}
                    setAnchorEl={setAnchorEl}
                    index={props.index}
                    itemId={building.id}
                    key={building.id}
                    nodeId={building.id}
                    label={
                      <BuildingLabel
                        description={building.description}
                        labelPrefix={t("multisense.Building")}
                        nodeLevel="Building"
                      />
                    }
                  >
                    {building.floors
                      ?.slice()
                      .sort((a, b) => a.description.localeCompare(b.description))
                      .filter((floor) => (filter ? floor.isCentralInstallations : true))
                      .map((floor) => (
                        <CustomTreeItem
                          key={floor.id}
                          nodeId={floor.id}
                          itemId={floor.id}
                          index={props.index}
                          setAnchorEl={setAnchorEl}
                          label={
                            <BuildingLabel
                              description={floor.description}
                              labelPrefix={t("multisense.Floor")}
                              nodeLevel="Floor"
                              isFetching={isFetching && floor.id === fetchingNodeId}
                            />
                          }
                          buildingId={building.id}
                        >
                          {props.hideAreas ? null : (
                            <>
                              {childNodes || [<div key="stub" />]}
                            </>
                          )}
                        </CustomTreeItem>
                      ))}
                  </CustomTreeItem>
                ))}
          </StyledTree>
        </MenuItem>
      </StyledMenu>
    </>
  );
}
