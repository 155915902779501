import { Dialog } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDetailsDialog } from "../../redux/slices/detailsDialogSlice";
import BuildingForm from "./Content/BuildingForm";
import EditUserForm from "./Content/EditUserForm";
import MultisenseForm from "./Content/MultisenseForm";
import { cancelSeries } from "../../redux/slices/multisenseSlice";

export default function DetailsDialog() {
  const dispatch = useDispatch();

  const open = useSelector((state) => state.detailsDialog.open);
  const type = useSelector((state) => state.detailsDialog.type);
  const size = "md";

  const handleClose = () => {
    dispatch(closeDetailsDialog());
    dispatch(cancelSeries());
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { maxHeight: "fit-content", height: "fit-content" } }}
      maxWidth={size}
      fullWidth
      open={open}
      onClose={handleClose}
      transitionDuration={500}
    >
      {
        {
          building: <BuildingForm />,
          user: <EditUserForm />,
          addSeries: <MultisenseForm />,
          editSeries: <MultisenseForm isEdit={true} />,
        }[type]
      }
    </Dialog>
  );
}
