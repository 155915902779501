import Toolbar from "@mui/material/Toolbar";
import {
  StyledAppBar,
  StyledActionWrapper,
  StyledHeaderWrapper,
} from "./AppBar.styled";
import { Badge, IconButton, Stack, SvgIcon, Box } from "@mui/material";

import { BellIcon } from "@heroicons/react/24/outline";
import Calendar from "../Calendar/Calendar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openPopover } from "../../redux/slices/popoversSlice";
import Popovers from "../Popovers/Popovers";
import { add, format, startOfMonth } from "date-fns";
import { useGetConditionalAlertsByBuildingQuery } from "../../redux/metaDataApi";
import BuildingMenu from "./BuildingMenu/BuildingMenu";
import lrcLogo from "../../resources/images/lrc.logo.png";

export default function AppBar(props) {
  const dispatch = useDispatch();
  const { role, selectedTenant } = useSelector((state) => state.session);
  const profile = useSelector((state) => state.session.selectedProfile);

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [moduleArray, setModuleArray] = useState([]);
  const openDrawer = useSelector((state) => state.sideMenu.openDrawer);
  const selectedBuilding = useSelector(
    (state) => state.buildings.selectedBuilding
  );

  const currentDate = new Date();
  const beginningOfLastMonth = startOfMonth(currentDate);

  const filter = {
    buildingId: selectedBuilding.id,
    from: format(beginningOfLastMonth, "yyyy-MM-dd"),
    to: format(add(currentDate, { days: 1 }), "yyyy-MM-dd"),
  };

  const { data: alerts } = useGetConditionalAlertsByBuildingQuery(filter, {
    skip: !selectedBuilding,
  });

  const handleOpenBellMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    dispatch(openPopover("ALERTS_NOTIFICATION"));
  };

  useEffect(() => {
    if (profile) {
      const modules = profile.profileModules?.map((item) => item.module) || [];
      setModuleArray(modules);
    }
  }, [profile]);

  return (
    <StyledAppBar position="fixed" open={openDrawer}>
      <Toolbar>
        <StyledHeaderWrapper container>
          {selectedTenant.id === "001" && (
            <img src={lrcLogo} alt="logo" style={{ maxHeight: "60px" }} />
          )}
          <StyledActionWrapper item>
            <Stack
              direction="row"
              useFlexGap
              flexWrap="wrap"
              spacing={1}
              alignItems="center"
            >
              <Calendar />
              {role !== "User" && role !== null && <BuildingMenu />}

              {moduleArray?.includes("MultisenseAlertsLite") && (
                <Box sx={{ flexGrow: 1, justifyContent: "center" }}>
                  <IconButton
                    onClick={handleOpenBellMenu}
                    sx={{ height: "48px", width: "48px" }}
                  >
                    <Badge
                      badgeContent={
                        alerts?.filter(
                          (item) => item.conditionalAlertStatus === "Active"
                        )?.length
                      }
                      color="error"
                    >
                      <SvgIcon sx={{ height: "26px", width: "26px" }}>
                        <BellIcon />
                      </SvgIcon>
                    </Badge>
                  </IconButton>
                </Box>
              )}
              <Popovers anchorEl={anchorElNav} id={"ALERTS_NOTIFICATION"} />
            </Stack>
          </StyledActionWrapper>
        </StyledHeaderWrapper>
      </Toolbar>
    </StyledAppBar>
  );
}
