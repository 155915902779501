import React, { useState, useEffect } from "react";
import { Divider, Typography } from "@mui/material";
import { ModifiedConstants } from "../../../App";
import {
  ActionsContainer,
  ApplyWrapper,
  LeftActionWrapper,
  StyledButton,
} from "../../../App.styled";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelCalendar,
  changeCalendarSelection,
  changeDate,
  changePeriod,
  changeTime,
  changeWeekDay,
  resetCalendar,
  toggleCalendar,
} from "../../../redux/slices/calendarSlice";
import {
  selectPeriod,
  timeStringToDate,
} from "../../../helpers/calendarFunctions";
import { format } from "date-fns";
import {
  StyledStack,
  StyledGrid,
  StyledDateRange,
} from "./FullCalendar.styled";
import { DateBox } from "devextreme-react";
import { useTranslation } from "react-i18next";

export default function FullCalendar() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedPeriod = useSelector((state) => state.calendar.selectedPeriod);
  const selectedWeekDay = useSelector(
    (state) => state.calendar.selectedWeekDay
  );
  const { indexSelected } = useSelector((state) => state.sideMenu);
  const dateStart = useSelector((state) => state.calendar.dateStart);
  const dateEnd = useSelector((state) => state.calendar.dateEnd);
  const timeStart = useSelector((state) => state.calendar.timeStart);
  const timeEnd = useSelector((state) => state.calendar.timeEnd);
  const [checked, setChecked] = useState(true);
  const [timeStartDate, setTimeStartDate] = useState(new Date());
  const [timeEndDate, setTimeEndDate] = useState(new Date());
  const currentDate = new Date();
  const periodOptions = ModifiedConstants.PERIOD;
  const weekdays = ModifiedConstants.WEEK_DAYS;

  useEffect(() => {
    setChecked(timeStart === "00:00:00" && timeEnd === "00:00:00");
    setTimeStartDate(timeStringToDate(timeStart));
    setTimeEndDate(timeStringToDate(timeEnd));
  }, [timeStart, timeEnd]);

  const handleCheckBoxChange = () => {
    dispatch(changePeriod("Custom"));

    let newTimeEnd;

    let newTimeStart = format(
      new Date(currentDate.setHours(0, 0, 0, 0)),
      "HH:mm:ss"
    );

    if (!checked) {
      newTimeEnd = format(
        new Date(currentDate.setHours(0, 0, 0, 0)),
        "HH:mm:ss"
      );
    } else {
      newTimeEnd = format(
        new Date(currentDate.setHours(23, 0, 0, 0)),
        "HH:mm:ss"
      );
    }

    dispatch(
      changeTime({
        timeEnd: newTimeEnd,
        timeStart: newTimeStart,
      })
    );
    setChecked(!checked);
  };

  const handleCustomSelectionChange = (dateRange) => {
    if (Array.isArray(dateRange) && dateRange.length === 2) {
      const [startDate, endDate] = dateRange;

      if (startDate && endDate) {
        dispatch(
          changeDate({
            dateStart: startDate,
            dateEnd: endDate,
          })
        );
      }
    }
  };

  return (
    <StyledGrid container rowGap={2}>
      <StyledGrid item container>
        <Typography variant="h3">
          {t(`calendarControl.subtitlePeriod`)}
        </Typography>
        <StyledStack useFlexGap spacing={1}>
          {periodOptions?.map((option, index) => (
            <StyledButton
              disabled={
                option === "Custom" ||
                (option === "Current" && indexSelected === "EnergyCosts")
              }
              key={index}
              selected={option === selectedPeriod ? true : null}
              onClick={() => {
                setChecked(true);
                selectPeriod(option, dispatch);
              }}
            >
              {t(`calendarControl.${option}`)}
            </StyledButton>
          ))}
        </StyledStack>
      </StyledGrid>
      <StyledGrid item container>
        <Typography variant="h3">
          {t(`calendarControl.subtitleDays`)}
        </Typography>
        <StyledStack useFlexGap spacing={1}>
          {weekdays?.map((option, index) => (
            <StyledButton
              key={index}
              selected={selectedWeekDay.includes(option)}
              onClick={() => {
                dispatch(changeWeekDay(option));
              }}
            >
              {option === "All"
                ? t(`calendarControl.${option}`)
                : t(`calendarControl.${option}`).charAt(0)}{" "}
            </StyledButton>
          ))}
        </StyledStack>
      </StyledGrid>
      <StyledGrid item container>
        <Typography variant="h3">
          {t(`calendarControl.subtitleTime`)}
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked && selectedPeriod !== "Current"}
                onChange={handleCheckBoxChange}
              />
            }
            label={
              <Typography variant="p">
                {t(`calendarControl.checkBoxLegend`)}
              </Typography>
            }
          />
        </FormGroup>
        <StyledStack useFlexGap spacing={2}>
          <DateBox
            disabled={checked}
            value={timeStartDate}
            type="time"
            interval={60}
            displayFormat="HH:00"
            onValueChanged={(e) =>
              dispatch(
                changeTime({
                  timeStart: format(e.value, "HH:00:00"),
                  timeEnd: timeEnd,
                })
              )
            }
          />
          <DateBox
            disabled={checked}
            value={timeEndDate}
            type="time"
            interval={60}
            displayFormat="HH:00"
            onValueChanged={(e) =>
              dispatch(
                changeTime({
                  timeStart: timeStart,
                  timeEnd: format(e.value, "HH:00:00"),
                })
              )
            }
          />
        </StyledStack>
      </StyledGrid>
      <Divider />
      <StyledGrid item container>
        <StyledDateRange
          displayFormat=" d  MMM, yy"
          startDateLabel=""
          endDateLabel=""
          startDate={dateStart}
          endDate={dateEnd}
          multiView={true}
          onOpened={() => dispatch(changePeriod("Custom"))}
          onValueChanged={(e) => {
            handleCustomSelectionChange(e.value);
          }}
        />
      </StyledGrid>
      <StyledGrid item container>
        <ActionsContainer container item spacing={2}>
          <LeftActionWrapper item>
            <StyledButton
              onClick={() => {
                dispatch(
                  resetCalendar({
                    currentModule: indexSelected,
                  })
                );
                dispatch(toggleCalendar());
              }}
            >
              {t(`button.reset`)}
            </StyledButton>
          </LeftActionWrapper>
          <LeftActionWrapper item>
            <StyledButton
              onClick={() => {
                dispatch(cancelCalendar());
                dispatch(toggleCalendar());
              }}
            >
              {t(`button.cancel`)}
            </StyledButton>
          </LeftActionWrapper>
          <ApplyWrapper item xs>
            <StyledButton
              selected
              onClick={() => {
                dispatch(changeCalendarSelection());
                dispatch(toggleCalendar());
              }}
            >
              {t(`button.apply`)}
            </StyledButton>
          </ApplyWrapper>
        </ActionsContainer>
      </StyledGrid>
    </StyledGrid>
  );
}
