import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  buildings: [],
  sensorTypes: {},
  centralSensorTypes:{},
  modules: {},
  isCentralInstallation: [],
  energyBaseline: {},
  selectedBuilding: {},
  buildingCurrencies: {},
  buildingsWithCentralInstallations: [],
}

export const buildingsSlice = createSlice({
  name: 'buildings',
  initialState,
  reducers: {
    initBuildings: (state, action) => {
      state.buildings = action.payload;
      state.sensorTypes = {}; // Reset sensor types
      state.centralSensorTypes = {}; // Reset centralized sensor types
      state.isCentralInstallation = []; // Clear central installations
      state.buildingsWithCentralInstallations = []; // Clear buildings with central installations
      state.modules = {}; // Reset modules
      state.energyBaseline = {}; // Reset energy baseline
      state.buildingCurrencies= {};

      const defaultBuilding = action.payload.find(building => building.isDefault === true) || action.payload[0];
      state.selectedBuilding ={id: defaultBuilding?.id, description: defaultBuilding?.description}
      action.payload.forEach((building) => {
        state.sensorTypes[building.id] = building.buildingSensorTypes?.map(
          (sensorTypeObj) => sensorTypeObj.sensorType,
        )
      })
      action.payload.forEach((building) => {
        state.centralSensorTypes[building.id] = building.buildingCentralizedSensorTypes?.map(
          (sensorTypeObj) => sensorTypeObj.sensorType,
        )
      })
      state.isCentralInstallation=[]
      action.payload.forEach((building) => {
        building.floors.forEach((floor) => {
          if (floor.isCentralInstallations) {
            state.isCentralInstallation.push(floor.id);
          }
        });
      });
      state.buildingsWithCentralInstallations=[]
      action.payload.forEach((building) => {
        let hasCentralInstallations = building.floors.some(floor => floor.isCentralInstallations);
        if (hasCentralInstallations && !state.buildingsWithCentralInstallations.includes(building.id)) {
          state.buildingsWithCentralInstallations.push(building.id);
        }
      });
      action.payload.forEach((building) => {
        state.modules[building.id] = building?.buildingModules?.map(
          (modulesObj) => modulesObj.module,
        )
      })
      
      action.payload.forEach((building) => {
        state.energyBaseline[building.id] = building.energyBaseline
      })

      action.payload.forEach((building) => {
        state.buildingCurrencies[building.id] = building.currency
      })
    },
    updateBuilding: (state, action) => {
      const index = state.buildings.findIndex(
        (building) => building.id === action.payload.id,
      );
      state.buildings[index] = action.payload;
    },
    selectBuilding: (state, action) => {
      state.selectedBuilding = action.payload
    },
  },
})

export const {
  updateBuilding,
  initBuildings,
  selectBuilding,
} = buildingsSlice.actions

export default buildingsSlice.reducer
