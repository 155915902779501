import { TabContext } from "@mui/lab";
import {
  Box,
  DialogTitle,
  Grid,
  IconButton,
  SvgIcon,
  Tab,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomDialogContent,
  StyledTabPanel,
  StyledTabs,
} from "../../ActionDialog/Forms/Forms.styled";
import ModulesBuildingForm from "./ModulesBuildingForm";
import EditBuildingForm from "../../ActionDialog/Forms/EditBuildingForm";
import CostAdmin from "../../AdminPanel/CostsAdmin/CostsAdmin";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { closeDetailsDialog } from "../../../redux/slices/detailsDialogSlice";

export default function BuildingForm() {
  const [value, setValue] = React.useState("1");
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const closeForm = () => {
    dispatch(closeDetailsDialog());
  };

  return (
    <>
      <TabContext value={value}>
        <DialogTitle sx={{ padding: "4px 24px" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <StyledTabs centered onChange={handleChange}>
              <Grid item xs textAlign="left"></Grid>
              <Tab label={"Building"} value="1" />
              <Tab label={"Modules & Profiles"} value="2" />
              <Tab label={"Costs"} value="3" />
              <Grid item xs textAlign="right">
                <IconButton onClick={closeForm}>
                  <SvgIcon>
                    <XMarkIcon />
                  </SvgIcon>
                </IconButton>
              </Grid>
            </StyledTabs>
          </Box>
        </DialogTitle>
        <CustomDialogContent
          sx={{
            p: 0,
            height: "740px",
            maxHeight: "740px !important",
            width: "100%",
          }}
        >
          <StyledTabPanel value="1">
            <EditBuildingForm hideHeader={true} />
          </StyledTabPanel>
          <StyledTabPanel value="2">
            <ModulesBuildingForm />
          </StyledTabPanel>
          <StyledTabPanel value="3">
            <CostAdmin />
          </StyledTabPanel>
        </CustomDialogContent>
      </TabContext>
    </>
  );
}
