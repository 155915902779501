import {
  Box,
  DialogActions,
  DialogTitle,
  Grid,
  Grow,
  IconButton,
  SvgIcon,
  Typography,
  Autocomplete,
  TextField,
  Switch,
} from '@mui/material'
import React,{ useState } from 'react'
import {
  ActionsGrid,
  ContentGrid,
  CustomAutocomplete,
  CustomCircularProgress,
  CustomDialogContent,
  CustomTextField,
  TextFieldGrid,
  TitleGrid,
} from './Forms.styled'
import { StyledButton } from '../../../App.styled'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { closeActionDialog } from '../../../redux/slices/actionDialogSlice'
import {
  useAddUserBuildingMutation,
  useGetBuildingMetadataByTenantIdQuery,
  useGetProfilesByBuildingIdQuery,
} from '../../../redux/metaDataApi'
import { useDispatch, useSelector } from 'react-redux'
import { openSnack } from '../../../redux/slices/snackSlice'
import { t } from 'i18next'

export default function AddUserBuildingForm() {
  const dispatch = useDispatch()
  const userSelected = useSelector((state) => state.adminPanel.userSelected)
  const tenantSelected = useSelector((state) => state.adminPanel.tenantSelected)
  const [addUserBuilding, { isLoading }] = useAddUserBuildingMutation()
  const [selectedBuilding, setSelectedBuilding] = useState(null)
  const [selectedProfile, setSelectedProfile] = useState(null)
  const [isDefault, setIsDefault] = useState(false)

  const { data: building } = useGetBuildingMetadataByTenantIdQuery(
    tenantSelected.current.id,
    {
      skip: !tenantSelected,
    },
  )
  const { data: profiles } = useGetProfilesByBuildingIdQuery(
    selectedBuilding?.id,
    {
      skip: !selectedBuilding,
    },
  )

  const closeForm = () => {
    dispatch(closeActionDialog())
  }

  const handleConfirm = async () => {
    const payload = {
      userId: userSelected.id,
      buildingId: selectedBuilding.id,
      profileId: selectedProfile?.id,
      isDefault: isDefault,
    }

    await addUserBuilding(payload)
      .unwrap()
      .then(() =>{
        dispatch(openSnack({ message: 'Building added to user', type: 'success' }))
        dispatch(closeActionDialog())
      }
      )
      .catch((error) => {
        dispatch(
          openSnack({ message: 'Building could not be added to user', type: 'error' }),
        )
        console.log(`Error:${error}`)
      })
  }

  const handleSwitchChange = (event) => {
    setIsDefault(event.target.checked)
  }
  
  const handleBuildingSelection = (event, newValue) => {
    setSelectedBuilding(newValue)
  }
  const handleProfileSelection = (event, newValue) => {
    setSelectedProfile(newValue)
  }
  return (
    building && (
      <>
        <DialogTitle>
          <TitleGrid container>
            <Grid item xs>
              <Grow in timeout={800}>
                <Typography variant="h3">{t("form.addBuildingUser")}</Typography>
              </Grow>
            </Grid>
            <Grid item xs textAlign="right">
              <IconButton onClick={closeForm}>
                <SvgIcon>
                  <XMarkIcon />
                </SvgIcon>
              </IconButton>
            </Grid>
          </TitleGrid>
        </DialogTitle>
        <CustomDialogContent>
          <ContentGrid container rowGap={2}>
            <TextFieldGrid item xs={12} md={12}>
              <Typography variant="p">{t("form.buildings")}</Typography>
              <CustomAutocomplete
                options={building}
                getOptionLabel={(option) => option.description}
                value={selectedBuilding}
                onChange={handleBuildingSelection}
                renderInput={(params) => <CustomTextField {...params} />}
              />
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={12}>
              <Typography variant="p">{t("form.setDefault")} </Typography>
              <Switch
                checked={isDefault} // Controlled component
                onChange={handleSwitchChange}
              />
            </TextFieldGrid>
            {profiles && (
              <TextFieldGrid item xs={12} md={12}>
                <Typography variant="p">{t("form.profiles")}</Typography>
                <CustomAutocomplete
                  options={profiles}
                  getOptionLabel={(option) => option.description}
                  value={selectedProfile}
                  onChange={handleProfileSelection}
                  renderInput={(params) => <CustomTextField {...params} />}
                />
              </TextFieldGrid>
            )}
          </ContentGrid>
        </CustomDialogContent>
        <DialogActions>
          <ActionsGrid container>
            <StyledButton onClick={closeForm}> {t("button.cancel")}</StyledButton>
            <Box sx={{ position: 'relative' }}>
              <StyledButton
                selected
                onClick={handleConfirm}
                disabled={isLoading}
              >
                {t("button.confirm")}
              </StyledButton>
              {isLoading && <CustomCircularProgress size={24} />}
            </Box>
          </ActionsGrid>
        </DialogActions>
      </>
    )
  )
}
