import {
  Card,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  CustomSelect,
  CustomTextField,
} from '../../../ActionDialog/Forms/Forms.styled'
import border from '../../../../resources/images/Border.svg'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { XMarkIcon } from '@heroicons/react/24/outline'
import {
  changeAlertSettings,
  removeCondition,
} from '../../../../redux/slices/alertsProSettingsSlice'
import { ModifiedConstants } from '../../../../App'
import { getSensorUnit } from '../../../../helpers/functions'

export default function SmartRule({ conditionIndex }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { sensorTypes, selectedBuilding } = useSelector(
    (state) => state.buildings,
  )
  const { fullObject } = useSelector((state) => state.alertsProSettings)
  const [sensorType1, setSensorType1] = useState(
    fullObject.conditionalAlertSettingConditions[conditionIndex]
      .conditionalAlertSettingParameters[0].sensorType1,
  )
  const [sensorType2, setSensorType2] = useState(
    fullObject.conditionalAlertSettingConditions[conditionIndex]
      .conditionalAlertSettingParameters[0].sensorType2,
  )
  const [arithmetic, setArithmetic] = useState(
    fullObject.conditionalAlertSettingConditions[conditionIndex]
      .conditionalAlertSettingParameters[0].conditionalAlertArithmeticOperator,
  )
  const [logical, setLogical] = useState(
    fullObject.conditionalAlertSettingConditions[conditionIndex]
      .conditionalAlertComparisonOperator,
  )
  const [setPoint, setSetPoint] = useState(
    fullObject.conditionalAlertSettingConditions[conditionIndex]
      .conditionalAlertSettingParameters[1].setPoint,
  )
  const handleChange1 = (value, key) => {
    setSensorType1(value)
  }
  const handleChange2 = (value, key) => {
    setSensorType2(value)
  }
  const handleChangeSetPoint = (value, key) => {
    setSetPoint(value)
  }
  const handleArithmeticOperatorChange = (value, key) => {
    setArithmetic(value)
  }
  const handleLogicalOperatorChange = (value, key) => {
    setLogical(value)
  }

  useEffect(() => {
    if (sensorType1 && sensorType2 && setPoint && arithmetic && logical) {
      const updatedConditions = fullObject.conditionalAlertSettingConditions?.map(
        (condition, index) => {
          if (index === conditionIndex) {
            return {
              ...condition,
              order: conditionIndex + 1,
              conditionalAlertComparisonOperator: logical,
              conditionalAlertSettingParameters: [
                {
                  ...condition.conditionalAlertSettingParameters[0],
                  order: 1,
                  conditionalAlertParameterType: 'Calculation',
                  sensorType1: sensorType1,
                  sensorType2: sensorType2,
                  conditionalAlertArithmeticOperator: arithmetic,
                },
                {
                  ...condition.conditionalAlertSettingParameters[1], // Assuming this is the second parameter
                  order: 2,
                  conditionalAlertParameterType: 'SetPoint',
                  setPoint: setPoint,
                },
              ],
            }
          }
          return condition // Return other conditions as is
        },
      )

      dispatch(changeAlertSettings(updatedConditions))
    }
  }, [sensorType1, sensorType2, setPoint, dispatch, logical, arithmetic])

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Typography variant="h3">{t('alerts.smartRule')}</Typography>
        <IconButton
          onClick={() => dispatch(removeCondition({ index: conditionIndex }))}
        >
          <SvgIcon>
            <XMarkIcon />
          </SvgIcon>
        </IconButton>
      </div>
      <Card
        sx={{
          border: '1px solid #eee',
          boxShadow: 'none',
          padding: '16px',
          borderRadius: '12px',
          minHeight: '90px',
          marginTop: '16px',
        }}
      >
        <Stack direction="row" spacing={2.5} sx={{ maxWidth: '400px' }}>
          <CustomSelect
            sx={{ maxWidth: '150px', minWidth: '150px' }}
            key="sensor-select"
            id="sensor-select"
            value={sensorType1}
            placeholder="Data Type..."
            onChange={(e) => {
              e.stopPropagation()
              handleChange1(e.target.value)
            }}
          >
            {sensorTypes[selectedBuilding?.id]?.map((sensor) => (
              <MenuItem
                sx={{
                  maxWidth: '180px', // Set max-width slightly less than the CustomSelect width
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                value={sensor}
                key={sensor}
              >
                {t(`sensor.${sensor}`)}
              </MenuItem>
            ))}
          </CustomSelect>
          <CustomSelect
            sx={{ marginTop: '8px',maxWidth: '130px', minWidth: '130px' }}
            value={arithmetic}
            onChange={(e) => {
              e.stopPropagation()
              handleArithmeticOperatorChange(e.target.value)
            }}
          >
            {ModifiedConstants.ARITHMETIC_OPERATORS.map((operator) => (
              <MenuItem
                sx={{
                  maxWidth: '180px', // Set max-width slightly less than the CustomSelect width
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                value={operator}
                key={operator}
              >
                {t(`arithmetic.${operator}`)}
              </MenuItem>
            ))}
          </CustomSelect>
          <CustomSelect
            sx={{maxWidth: '140px', minWidth: '140px'}}
            key="sensor-select"
            id="sensor-select"
            value={sensorType2}
            fullWidth
            onChange={(e) => {
              e.stopPropagation()
              handleChange2(e.target.value)
            }}
          >
            {sensorTypes[selectedBuilding?.id]
              ?.filter(
                (item) =>
                  getSensorUnit(sensorType1) === getSensorUnit(item) &&
                  item !== sensorType1,
              )
              .map((sensor) => (
                <MenuItem
                  sx={{
                    maxWidth: '180px', // Set max-width slightly less than the CustomSelect width
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  value={sensor}
                  key={sensor}
                >
                  {t(`sensor.${sensor}`)}
                </MenuItem>
              ))}
          </CustomSelect>
        </Stack>
      </Card>
      <Stack direction="row" sx={{ marginLeft: '18px' }}>
        <img src={border} alt="border" width="28" height="28" />
        <CustomSelect
          sx={{ marginTop: '8px', maxWidth: '250px', minWidth: '250px'}}
          value={logical}
          onChange={(e) => {
            e.stopPropagation()
            handleLogicalOperatorChange(e.target.value)
          }}
        >
          {ModifiedConstants.LOGICAL_OPERATORS.map((operator) => (
            <MenuItem
              sx={{
                maxWidth: '250px', // Set max-width slightly less than the CustomSelect width
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              value={operator}
              key={operator}
            >
              {t(`logical.${operator}`)}
            </MenuItem>
          ))}
        </CustomSelect>
        <Divider
          sx={{ alignSelf: 'center', width: '16px', marginTop: '8px' }}
        />
        <CustomTextField
          fullWidth
          type="number"
          sx={{ marginTop: '8px' }}
          value={setPoint}
          placeholder={t('alerts.fixedLabel')}
          onChange={(e) => handleChangeSetPoint(e.target.value)}
          endAdornment={
            <InputAdornment position="start">
              <Typography variant="p" sx={{ opacity: "0.6" }}>
                {getSensorUnit(sensorType1)}
              </Typography>
            </InputAdornment>
          }
        />
      </Stack>
    </>
  )
}
