import { Box, Grid, SvgIcon, Typography } from "@mui/material";
import React from "react";
import Locked from "../../resources/images/locked.png";
import { CustomCard, StyledButton } from "../../App.styled";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeIndexSelected } from "../../redux/slices/sideMenuSlice";
import { useTranslation } from "react-i18next";
import { Constants } from "../../helpers/constants";
import axios from "axios";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { CustomCircularProgress } from "../ActionDialog/Forms/Forms.styled";

export default function LockedService(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [request, setRequest] = React.useState(false);
  const [displayMessage, setDisplayMessage] = React.useState(false);
  const { firstName, lastName, email } = useSelector((state) => state.session);

  const prepareChatTemplate = () => {
    const chatTemplate = JSON.parse(JSON.stringify(Constants.CHAT_TEMPLATE)); // Deep clone to avoid mutating the original
    chatTemplate.cardsV2[0].card.header.title = `${firstName} ${lastName}`;
    chatTemplate.cardsV2[0].card.header.subtitle = `User request to have new module; ${t(
      `Menu.${props.module}`
    )}`;
    chatTemplate.cardsV2[0].card.sections[0].widgets[0].decoratedText.text = `${email}`;
    return chatTemplate;
  };

  const onUpgradeClick = async () => {
    const postData = prepareChatTemplate();
    setRequest(true);
    try {
      await axios.post(process.env.REACT_APP_GOOGLE_WEBHOOK, postData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setRequest(false);
      setDisplayMessage(true);
    } catch (error) {
      console.error("Error making post request:", error);
    }
  };

  return (
    <CustomCard variant="outlined">
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        rowGap={4}
        flexDirection="column"
      >
        <Grid item >
          <Typography variant="h1" sx={{fontWeight: "600 !important"}}>{t(`locked.message1`)}</Typography>
          <Typography variant="p">{t(`locked.message2`)}</Typography>
        </Grid>
        <Grid item>
          <img alt="unlock-service" src={Locked} />
        </Grid>
        <Grid item>
          <Grid
            container
            flexDirection="row"
            spacing={2}
            justifyContent="space-between"
          >
            <Grid item>
              <StyledButton
                onClick={() => {
                  dispatch(changeIndexSelected("Multisense"));
                  navigate("/");
                }}
              >
                {t(`locked.message4`)}
              </StyledButton>
            </Grid>
            <Grid item>
              {displayMessage ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <SvgIcon
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: "green !important",
                      borderRadius: "20px",
                    }}
                  >
                    <CheckCircleIcon sx={{ width: "20px", height: "20px" }} />
                  </SvgIcon>
                  <Typography variant="p">
                    {t(`multisense.upgradealerts.request`)}
                  </Typography>
                </div>
              ) : (
                <Box sx={{ position: "relative" }}>
                  <StyledButton selected onClick={onUpgradeClick}>
                    {t(`locked.message3`)}
                  </StyledButton>
                  {request && <CustomCircularProgress size={24} />}
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomCard>
  );
}
