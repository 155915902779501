import { TabContext, TabList } from "@mui/lab";
import React, { useEffect, useState } from "react";
import { Box, Tab } from "@mui/material";
import { useDispatch } from "react-redux";
import { changeAlertSelection } from "../../redux/slices/alertsSlice";
import { TabPanelContainer } from "../../App.styled";
import { Outlet, useNavigate } from "react-router-dom";

export default function Alerts() {
  const dispatch = useDispatch();
  const [value, setValue] = useState("lite");
  const navigate = useNavigate();
  const basePath = "/alerts";

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`${basePath}/${newValue}`);
    dispatch(changeAlertSelection([]));
  };
  
  useEffect(() => {
    navigate(`${basePath}/lite`);
    dispatch(changeAlertSelection([]));
  }, []);

  return (
    <Box sx={{ width: "100%", typography: "body1", height: "100%" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "transparent" }}>
          <TabList onChange={handleChange}>
            <Tab label="Lite" value="lite" />
            <Tab label="Pro" value="pro" />
          </TabList>
        </Box>
        <TabPanelContainer value="lite">
          <Outlet />
        </TabPanelContainer>
        <TabPanelContainer value="pro">
          <Outlet />
        </TabPanelContainer>
      </TabContext>
    </Box>
  );
}
