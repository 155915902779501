import React, { useState, useEffect } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Avatar, Box, Grid, IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import PencilSquareIcon from "@mui/icons-material/Edit";
import TrashIcon from "@mui/icons-material/Delete";
// import IPData from "ipdata"; // Assuming 'ipdata' is the library you are using
import {
  useDeleteUserBuildingMutation,
  useGetAuth0UsersByBuildingQuery,
  useGetBuildingByIdQuery
} from "../../redux/metaDataApi";
import { CustomCard, StyledButton } from "../../App.styled";
import { useDispatch, useSelector } from "react-redux";
import { format, parseISO } from "date-fns";
import {
  PartnerCustomBox,
  PartnerDataWrapper,
  PartnerItemWrapper,
  PartnerMainCard
} from "./PartnerPanel.styled";
import { changeDetailsDialogType, openDetailsDialog } from "../../redux/slices/detailsDialogSlice";
import { changeUserSelection } from "../../redux/slices/adminPanelSlice";
import { openSnack } from "../../redux/slices/snackSlice";
import { changeActionDialogType, openActionDialog } from "../../redux/slices/actionDialogSlice";
import { t } from "i18next";
import { CurrencyDollarIcon } from "@heroicons/react/24/outline";

export default function PartnerPanel() {
  const dispatch = useDispatch();
  const selectedBuilding = useSelector((state) => state.buildings.selectedBuilding);
  const { data: building } = useGetBuildingByIdQuery(selectedBuilding.id);
  const [deleteUserBuilding] = useDeleteUserBuildingMutation();
  const { data: users } = useGetAuth0UsersByBuildingQuery(selectedBuilding.id);
  const [enrichedUsers, setEnrichedUsers] = useState([]);
  const address = `${building?.address1}, ${building?.zip} ${building?.city}, ${building?.country}`;

  // const ipdata = new IPData(process.env.REACT_APP_IP_DATA, {
  //   max: 1000,
  //   maxAge: 10 * 60 * 1000
  // });

  const enrichUsersWithIPData = async (users) => {
    const nonAdminUsers = users.filter((user) => user.role !== "Admin");
    // const promises = nonAdminUsers.map(async (user) => {
    //   try {
    //     const info = await ipdata.lookup(user.lastIp);
    //     return {
    //       ...user,
    //       country: t(`country.${info.country_name}`),
    //       flag: info.flag
    //     };
    //   } catch (error) {
    //     console.error("Error fetching IP data", error);
    //     return user; // Return original user data in case of an error
    //   }
    // });

    // const enrichedData = await Promise.all(promises);
    // setEnrichedUsers(enrichedData);
    setEnrichedUsers(nonAdminUsers);
  };

  const deleteUser = async (user) => {
    const payload = {
      userId: user.id,
      buildingId: selectedBuilding.id
    };

    await deleteUserBuilding(payload)
      .unwrap()
      .then(() => {
        dispatch(
          openSnack({
            message: t("toast.userRemovedBuilding"),
            type: "success"
          })
        );
      })
      .catch((error) => {
        dispatch(openSnack({ message: t("toast.errorMessage"), type: "error" }));
        console.log(`Error:${error}`);
      });
  };

  useEffect(() => {
    if (users?.length > 0) {
      enrichUsersWithIPData(users);
    }
  }, [users]);

  const generateActionsColumn = () => {
    return {
      field: "actions",
      type: "actions",
      getActions: ({ row }) => [
        <GridActionsCellItem
          icon={
            <SvgIcon>
              <PencilSquareIcon />
            </SvgIcon>
          }
          label="Save"
          color="inherit"
          onClick={() => {
            dispatch(openDetailsDialog());
            dispatch(changeDetailsDialogType("user"));
            dispatch(changeUserSelection(row));
          }}
        />,
        <GridActionsCellItem
          icon={
            <SvgIcon>
              <TrashIcon />
            </SvgIcon>
          }
          label="Save"
          color="inherit"
          disabled={row.role === "Partner"}
          onClick={() => deleteUser(row)}
        />
      ]
    };
  };

  const columns = [
    {
      field: "picture",
      headerName: t("partner.columns.name"),
      renderCell: (params) => (
        <Stack direction="row" spacing={2}>
          <Avatar src={params.row.picture} alt={"avatar"} />
          <Typography
            sx={{
              alignSelf: "center",
              display: "flex",
              verticalAlign: "middle"
            }}
          >
            {` ${params.row.firstName} ${params.row.lastName}`}
          </Typography>
        </Stack>
      ),
      flex: 1
    },
    {
      field: "email",
      headerName: t("partner.columns.email"),
      flex: 1
    },
    {
      field: "role",
      headerName: t("partner.columns.role"),
      flex: 1,
      renderCell: (params) => <>{t(`role.${params.row.role}`)}</>
    },
    {
      field: "lastLogin",
      headerName: t("partner.columns.lastdate"),
      flex: 1,
      renderCell: (params) => {
        const parsedDate = parseISO(params.row.lastLogin);
        const europeanDate = format(parsedDate, "dd MMM yy, HH:mm");
        return <>{europeanDate}</>;
      }
    },
    // {
    //   field: "country",
    //   headerName: t("partner.columns.lastcountry"),
    //   renderCell: (params) => (
    //     <>
    //       {" "}
    //       {params.row.country && (
    //         <>
    //           <img
    //             src={params.row.flag}
    //             alt={`${params.row.country} flag`}
    //             style={{ width: "20px", marginRight: "5px" }}
    //           />
    //           {params.row.country}
    //         </>
    //       )}
    //     </>
    //   ),
    //   flex: 1
    // },
    generateActionsColumn()
  ];

  return (
    enrichedUsers.length > 0 &&
    building && (
      <div>
        <PartnerMainCard>
          <Grid container gap={0}>
            <PartnerDataWrapper item container>
              <PartnerItemWrapper item xs={12}>
                <PartnerCustomBox variant="outlined">
                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      padding: "8px",
                      rowGap: "8px"
                    }}
                  >
                    <Typography variant="h1">{building?.description}</Typography>
                    <Typography variant="p">{address}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "8px",
                      gap: "5px"
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        dispatch(openActionDialog());
                        dispatch(changeActionDialogType("tariff"));
                      }}
                    >
                      <SvgIcon>
                        <CurrencyDollarIcon />
                      </SvgIcon>
                    </IconButton>
                    <StyledButton
                      onClick={() => {
                        dispatch(openActionDialog());
                        dispatch(changeActionDialogType("editBuilding"));
                      }}
                    >
                      {t("button.edit")}
                    </StyledButton>
                  </Box>
                </PartnerCustomBox>
              </PartnerItemWrapper>
            </PartnerDataWrapper>
          </Grid>
        </PartnerMainCard>
        <CustomCard sx={{ height: "60vh !important" }}>
          <Grid container gap={0} sx={{ height: "10vh" }}>
            <PartnerDataWrapper item container>
              <PartnerItemWrapper item xs={12}>
                <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      padding: "8px",
                      rowGap: "8px"
                    }}
                  >
                    <Typography variant="h1">{t("partner.title")}</Typography>
                    <Typography variant="p">{t("partner.subtitle")}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "8px",
                      rowGap: "16px"
                    }}
                  >
                    <StyledButton
                      onClick={() => {
                        dispatch(openActionDialog());
                        dispatch(changeActionDialogType("addUserToBuilding"));
                      }}
                      startIcon={
                        <SvgIcon>
                          <AddIcon />
                        </SvgIcon>
                      }
                    >
                      {t("button.addUser")}
                    </StyledButton>
                  </Box>
                </Box>
              </PartnerItemWrapper>
            </PartnerDataWrapper>
          </Grid>
          <DataGrid
            style={{
              width: "100%",
              justifyContent: "center",
              border: "none",
              padding: "8px"
            }}
            rows={enrichedUsers}
            columns={columns}
            editMode="row"
            autoPageSize
          />
        </CustomCard>
      </div>
    )
  );
}
