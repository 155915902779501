import React from "react";
import top from '../../resources/images/LightsBot.png'
import bottom from '../../resources/images/LightsTop.png'
export default function LightsInsights() {
  return (
    <div>
            <img
              src={top}
              alt="Lights_top"
              style={{ width: "100%", height: "40vh", objectFit: "contain" }}
            />
            <img
              src={bottom}
              alt="Lights_bottom"
              style={{ width: "100%", height: "40vh", objectFit: "contain" }}
            />
    </div>
  );
}
