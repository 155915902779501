import * as React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useDispatch, useSelector } from "react-redux";
import { useGetFlexibleRatesQuery } from "../../../redux/metaDataApi";
import EnergyFlexible from "../../PartnerPanel/FlexibleRates/EnergyFlexible";
import WaterFlexible from "../../PartnerPanel/FlexibleRates/WaterFlexible";
import GasFlexible from "../../PartnerPanel/FlexibleRates/GasFlexible";
import GasMeterIcon from "@mui/icons-material/GasMeter";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import BoltIcon from "@mui/icons-material/Bolt";
import { StyledBottomNavigation } from "../AdminPanel.styled";

export default function CostAdmin() {
  const [value, setValue] = React.useState(0);
  const { selectedBuilding } = useSelector((state) => state.buildings);
  const { data: flexibleRates } = useGetFlexibleRatesQuery(selectedBuilding.id, {
    skip: !selectedBuilding || !selectedBuilding.id,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    flexibleRates && (
      <>
        <Box sx={{ width: "100%" }}>
          <StyledBottomNavigation showLabels value={value} onChange={handleChange}>
            <BottomNavigationAction label="Energy" icon={<BoltIcon />} />
            <BottomNavigationAction label="Water" icon={<WaterDropIcon />} />
            <BottomNavigationAction label="Gas" icon={<GasMeterIcon />} />
          </StyledBottomNavigation>
        </Box>
        <Box sx={{ width: "100%", height: "100%" }}>
          {
            {
              0: (
                <EnergyFlexible
                  rates={flexibleRates?.filter((rate) => rate.sensorType === "Energy")}
                />
              ),
              1: (
                <WaterFlexible
                  rates={flexibleRates.filter((rate) => rate.sensorType === "Water")}
                />
              ),
              2: <GasFlexible rates={flexibleRates.filter((rate) => rate.sensorType === "Gas")} />,
            }[value]
          }
        </Box>
      </>
    )
  );
}
