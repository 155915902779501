import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { applySelection, selectSensor } from '../../redux/slices/multisenseSlice';
import { toggleDrawer } from '../../redux/slices/mobileDrawer';
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { t } from 'i18next';

export default function DataSelection() {
    const dispatch = useDispatch()
    const sensorTypes = useSelector((state) => state.buildings.sensorTypes);
    const selectedBuilding = useSelector((state) => state.multisense.selectedBuilding);
  
  
    const handleSensorSelection = (value) => {
     
      dispatch(selectSensor({ sensor: value, index: 0 }));
      dispatch(applySelection());
      dispatch(toggleDrawer());
    };
  return (
    <List>
    {sensorTypes[selectedBuilding?.id].map((sensor) => (
      <ListItem key={sensor} disablePadding onClick={()=>handleSensorSelection(sensor)}>
        <ListItemButton>
          <ListItemText primary={t(`sensor.${sensor}`)} />
        </ListItemButton>
      </ListItem>
    ))}
  </List>
  )
}
