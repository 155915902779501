import styled from "styled-components";
import { Avatar, Box, Typography, Grid } from "@mui/material";

export const CustomAvatar = styled(Avatar)(({ theme }) => ({
  padding: "16px",
  backgroundColor: "#c1e477 !important",
  color: "#0f172a !important",
  height: "70px",
  width: "70px"
}));

export const IconContainer = styled("div")(({ theme }) => ({
  height: "24px",
  width: "24px",
  color: "#739C1D"
}));

export const SavingsContainer = styled(Box)(({ theme }) => ({
  color: "#0f172a !important",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 20px !important"
}));

export const CustomNumber = styled(Typography)(({ theme }) => ({
  color: "#424242",
  textAlign: "center",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "25.27px"
}));

export const CustomLabel = styled(Box)(({ theme }) => ({
  textAlign: "center",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "20px",
  color: "#8f8f8f"
}));

export const SavingsNumber = styled(Typography)(() => ({
  color: "#424242",
  textAlign: "center",
  fontSize: "25px",
  fontStyle: "bold",
  fontWeight: "400",
  lineHeight: "38px"
}));

export const IconWrapper = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "80%",
  justifyContent: "space-between",
  marginTop: "20px"
}));

export const LoadingOverlay = styled(Grid)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(255, 255, 255, 0.7)", // Semi-transparent white background to obscure the content slightly
  zIndex: 10 // Higher z-index than other content within CostCard
}));
