import { MenuItem } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledBuildingSelect } from "../AppBar.styled";
import { selectBuilding } from "../../../redux/slices/buildingsSlice";
import { changeSelectedProfile } from "../../../redux/slices/currentSessionSlice";

export default function BuildingMenu() {
  const dispatch = useDispatch();
  const profiles = useSelector((state) => state.session.profiles);
  const { buildings, selectedBuilding } = useSelector((state) => state.buildings);
  const [building, setBuilding] = React.useState("");

  const handleBuildingSelection = (e) => {
    const buildingId = e.target.value;
    const building = buildings.find((b) => b.id === buildingId);
    setBuilding(buildingId);
    const selectedProfile = profiles.find((obj) => obj.buildingId === buildingId);
    dispatch(selectBuilding({ id: building.id, description: building.description }));
    dispatch(changeSelectedProfile(selectedProfile));
  };

  useEffect(() => {
    if (selectedBuilding) {
      setBuilding(selectedBuilding.id);
    }
  }, [selectedBuilding]);

  return (
    selectedBuilding && (
      <StyledBuildingSelect
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={building}
        onChange={handleBuildingSelection}
      >
        {[...buildings]
          ?.sort((a, b) => a.description.localeCompare(b.description))
          .map((building) => {
            return (
              <MenuItem key={building.id} value={building.id}>
                {building.description}
              </MenuItem>
            );
          })}
      </StyledBuildingSelect>
    )
  );
}
