import { IconButton, InputAdornment, MenuItem, Stack, SvgIcon, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  CustomSelect,
  CustomTextField,
} from '../../../ActionDialog/Forms/Forms.styled'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { XMarkIcon } from '@heroicons/react/24/outline'
import {
  changeAlertSettings,
  removeCondition,
} from '../../../../redux/slices/alertsProSettingsSlice'
import { ModifiedConstants } from '../../../../App'
import { getSensorUnit } from '../../../../helpers/functions'

export default function SetPoint({ conditionIndex }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { sensorTypes, selectedBuilding } = useSelector(
    (state) => state.buildings,
  )
  const { fullObject } = useSelector((state) => state.alertsProSettings)
  const [sensorType1, setSensorType1] = useState(
    fullObject.conditionalAlertSettingConditions[conditionIndex]
      .conditionalAlertSettingParameters[0].sensorType1,
  )
  const [setPoint, setSetPoint] = useState(
    fullObject.conditionalAlertSettingConditions[conditionIndex]
      .conditionalAlertSettingParameters[1].setPoint,
  )
  const [logical, setLogical] = useState(
    fullObject.conditionalAlertSettingConditions[conditionIndex]
      .conditionalAlertComparisonOperator,
  )
  const handleChange1 = (value, key) => {
    setSensorType1(value)
  }
  const handleChangeSetPoint = (value, key) => {
    setSetPoint(value)
  }
  const handleLogicalOperatorChange = (value, key) => {
    setLogical(value)
  }

  useEffect(() => {
    if (sensorType1 && setPoint && logical) {
      const updatedConditions = fullObject.conditionalAlertSettingConditions?.map(
        (condition, index) => {
          if (index === conditionIndex) {
            return {
              ...condition,
              order: conditionIndex + 1,
              conditionalAlertComparisonOperator: logical,
              conditionalAlertSettingParameters: [
                {
                  ...condition.conditionalAlertSettingParameters[0],
                  order: 1,
                  conditionalAlertParameterType: 'SensorType',
                  sensorType1: sensorType1,
                },
                {
                  ...condition.conditionalAlertSettingParameters[1], // Assuming this is the second parameter
                  order: 2,
                  conditionalAlertParameterType: 'SetPoint',
                  setPoint: setPoint,
                },
              ],
            }
          }
          return condition // Return other conditions as is
        },
      )
      
      dispatch(changeAlertSettings(updatedConditions))
    }
  }, [sensorType1, setPoint, logical, dispatch])

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Typography variant="h3">{t("alerts.SetPoint")}</Typography>
        <IconButton
          onClick={() => dispatch(removeCondition({ index: conditionIndex }))}
        >
          <SvgIcon>
            <XMarkIcon />
          </SvgIcon>
        </IconButton>
      </div>
      <Stack direction="row" spacing={2.5} sx={{ marginTop: '16px' }}>
        <CustomSelect
          sx={{ maxWidth: '140px', minWidth: '140px' }}
          key="sensor-select"
          id="sensor-select"
          value={sensorType1}
          fullWidth
          onChange={(e) => {
            e.stopPropagation()
            handleChange1(e.target.value)
          }}
        >
          {sensorTypes[selectedBuilding?.id]?.map((sensor) => (
            <MenuItem value={sensor} key={sensor}>
              {t(`sensor.${sensor}`)}
            </MenuItem>
          ))}
        </CustomSelect>
        <CustomSelect
          fullWidth
          value={logical}
          onChange={(e) => {
            e.stopPropagation()
            handleLogicalOperatorChange(e.target.value)
          }}
        >
          {ModifiedConstants.LOGICAL_OPERATORS.map((operator) => (
            <MenuItem value={operator} key={operator}>
              {t(`logical.${operator}`)}
            </MenuItem>
          ))}
        </CustomSelect>
        <CustomTextField
          fullWidth
          type="number"
          value={setPoint}
          placeholder="Set Point..."
          onChange={(e) => handleChangeSetPoint(e.target.value)}
          endAdornment={
            <InputAdornment position="start">
              <Typography variant="p" sx={{ opacity: "0.6" }}>
                {getSensorUnit(sensorType1)}
              </Typography>
            </InputAdornment>
          }
        />
      </Stack>
    </>
  )
}
