import React from 'react'
import { changeAlertSelection } from '../../../redux/slices/alertsSlice'
import { useDispatch } from 'react-redux'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid'
import { t } from 'i18next'
import { format, parseISO } from 'date-fns'
import { formatNumber } from '../../../helpers/functions'

export default function AlertsGrid({ processedAlerts, columns }) {
  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{width: "100%"}}>
        <GridToolbarExport sx={{marginLeft: "auto", color: "inherit !important"}} />
      </GridToolbarContainer>
    );
  }

  const dispatch = useDispatch()
  const handleSelectionChange = (newRowSelectionModel) => {
    const selectedRow = processedAlerts.filter(
      (item) => item.id === newRowSelectionModel[0],
    )
    dispatch(changeAlertSelection(selectedRow))
  }
  console.log(processedAlerts)
  const processedAlertsTranslated = processedAlerts?.map((alert) => {
    return {
      ...alert,
      alertValue: formatNumber(alert.alertValue),
      startDate: format(parseISO(alert.startDate), 'dd MMM yy'),
      sensor: t(`sensor.${alert.sensor}`),
      sensor2: alert.sensor2 ? t(`sensor.${alert.sensor2}`) : '-',
      severity: t(`severities.${alert.severity}`),
      setPoint: alert.setPoint ? alert.setPoint : '-',
      status: t(`alerts.${alert.status}`),
    }
  })
  return processedAlerts.length > 0 ? (
    <DataGrid
      rows={processedAlertsTranslated}
      columns={columns}
      editMode="row"
      slots={{
        toolbar: CustomToolbar,
      }}
      onRowSelectionModelChange={(newRowSelectionModel) => {
        handleSelectionChange(newRowSelectionModel)
      }}
      pageSizeOptions={[5]}
    />
  ) : null
}
