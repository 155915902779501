import {
  Box,
  Checkbox,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Typography
} from "@mui/material";
import {
  ActionsGrid,
  ContentGrid,
  CustomAutocomplete,
  CustomCircularProgress,
  CustomDialogContent,
  CustomTextField,
  TextFieldGrid,
  TitleGrid
} from "../../ActionDialog/Forms/Forms.styled";

import {
  LockClosedIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { changeActionDialogType, openActionDialog } from "../../../redux/slices/actionDialogSlice";
import { isEmailValid } from "../../ActionDialog/Forms/validators";
import {
  useDeleteUserBuildingMutation,
  useEditUserMutation,
  useGetTenantsWithBuildingProfilesQuery,
  useGetUserMetaDataByIdQuery
} from "../../../redux/metaDataApi";
import { useEffect, useState } from "react";
import { closeDetailsDialog } from "../../../redux/slices/detailsDialogSlice";
import { getBuildingsForUser } from "../../../helpers/userSettingsFunctions";
import {
  changeAdminPanelTenantSelection,
  changeBuildingSelection
} from "../../../redux/slices/adminPanelSlice";
import { openSnack } from "../../../redux/slices/snackSlice";
import { StyledButton } from "../../../App.styled";
import { t } from "i18next";

export default function EditUserForm() {
  const dispatch = useDispatch();
  const sessionRole = useSelector((state) => state.session.role);
  const user = useSelector((state) => state.adminPanel.userSelected);
  const { data: userSelected } = useGetUserMetaDataByIdQuery(user.auth0Id, {
    skip: !user
  });
  const [firstName, setFirstName] = useState(null); // Or any other default value
  const [lastName, setLastName] = useState(null);
  const [role, setRole] = useState(null);
  const [email, setEmail] = useState(null);
  const [tenant, setTenant] = useState(null);
  const [building, setBuilding] = useState([]);
  const [selectedTenants, setSelectedTenants] = useState({});
  const { data: tenants } = useGetTenantsWithBuildingProfilesQuery();
  const [updateUser, { isLoading }] = useEditUserMutation();
  const [deleteUserBuilding] = useDeleteUserBuildingMutation();

  const closeForm = () => {
    dispatch(closeDetailsDialog());
  };

  const handleToggleTenant = (value) => () => {
    setSelectedTenants((prevState) => {
      const newState = !prevState.current || prevState.current !== value ? { current: value } : {};
      dispatch(changeAdminPanelTenantSelection(newState));
      return newState;
    });
  };

  useEffect(() => {
    if (userSelected) {
      setFirstName(userSelected.firstName);
      setLastName(userSelected.lastName);
      setRole(userSelected.role);
      setEmail(userSelected.email);
      setTenant(userSelected.userTenants?.map((item) => item.tenant));
    }
  }, [userSelected]);

  useEffect(() => {
    if (userSelected) {
      if (selectedTenants.current) {
        setBuilding(getBuildingsForUser(selectedTenants.current, tenants, userSelected));
      }
    }
  }, [dispatch, tenants, selectedTenants, userSelected, tenant, user]);

  const handleConfirm = async () => {
    const payload = {
      id: userSelected.id,
      firstName: firstName,
      lastName: lastName,
      role: role,
      email: email
    };
    await updateUser(payload)
      .unwrap()
      .then(() => {
        dispatch(openSnack({ message: t("toast.userUpdated"), type: "success" }));
        dispatch(closeDetailsDialog());
      })
      .catch((error) => {
        dispatch(
          openSnack({
            message: t("toast.errorMessage"),
            type: "error"
          })
        );
        console.log(`Error:${error}`);
      });
  };

  const deleteBuilding = async (value) => {
    const payload = {
      userId: userSelected.id,
      buildingId: value.id
    };

    await deleteUserBuilding(payload)
      .unwrap()
      .then(() => {
        dispatch(openSnack({ message: "Building deleted for user", type: "success" }));
      })
      .catch((error) => {
        dispatch(openSnack({ message: "Building could not be deleted", type: "error" }));
        console.log(`Error:${error}`);
      });
  };

  return (
    userSelected &&
    role !== "" && (
      <>
        <DialogTitle>
          <TitleGrid container>
            <Grid item xs>
              <Grow in timeout={800}>
                <Typography variant="h3">{t("form.editUser")}</Typography>
              </Grow>
            </Grid>
            <Grid item xs textAlign="right">
              <IconButton onClick={closeForm}>
                <SvgIcon>
                  <XMarkIcon />
                </SvgIcon>
              </IconButton>
            </Grid>
          </TitleGrid>
        </DialogTitle>
        <CustomDialogContent>
          <ContentGrid container rowGap={2}>
            <TextFieldGrid item xs={12} md={6}>
              <Typography variant="p">{t("form.firstName")}</Typography>
              <CustomTextField
                size="small"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              ></CustomTextField>
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={6}>
              <Typography variant="p">{t("form.lastName")}</Typography>
              <CustomTextField
                size="small"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              ></CustomTextField>
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={6}>
              <Typography variant="p">{t("form.email")}</Typography>
              <CustomTextField
                disabled
                size="small"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!isEmailValid(email)}
                helperText={!isEmailValid(email) && "Invalid email address"}
              ></CustomTextField>
            </TextFieldGrid>
            <TextFieldGrid item xs={12} md={6}>
              <Typography variant="p">{t("form.role")}</Typography>
              <CustomAutocomplete
                size="small"
                disableClearable
                disabled={role === "Partner" && sessionRole !== "Admin"}
                value={t(`role.${role}`)}
                onChange={(_, value) => setRole(value)} // Using value directly
                options={
                  sessionRole === "Admin"
                    ? ["Admin", "User", "Manager", "Partner"]
                    : ["User", "Manager"]
                }
                renderInput={(params) => <CustomTextField {...params} />}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    <Typography variant="p">{t(`role.${option}`)}</Typography>
                  </li>
                )}
              ></CustomAutocomplete>
            </TextFieldGrid>
            {sessionRole === "Admin" && (
              <>
                <TextFieldGrid item xs={12} md={12}>
                  <TitleGrid container>
                    <Grid item xs>
                      <Typography variant="h3">{t("form.tenants")}</Typography>
                    </Grid>
                    <Grid item xs textAlign="right">
                      <IconButton
                        onClick={() => {
                          dispatch(openActionDialog());
                          dispatch(changeActionDialogType("tenants"));
                        }}
                      >
                        <SvgIcon>
                          <PencilSquareIcon />
                        </SvgIcon>
                      </IconButton>
                    </Grid>
                  </TitleGrid>
                  <Divider />
                  <List>
                    {[...userSelected?.userTenants]
                      ?.sort((a, b) => a.tenant.description.localeCompare(b.tenant.description))
                      .map((userTenant) => {
                        return (
                          <ListItem key={userTenant.tenant.id} disablePadding>
                            <ListItemButton dense>
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  checked={selectedTenants.current === userTenant.tenant}
                                  onClick={handleToggleTenant(userTenant.tenant)}
                                  tabIndex={-1}
                                  disableRipple
                                />
                              </ListItemIcon>
                              <ListItemText primary={userTenant.tenant.description} />
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                  </List>
                </TextFieldGrid>
                {selectedTenants.current && (
                  <TextFieldGrid item xs={12} md={12}>
                    <TitleGrid container>
                      <Grid item xs>
                        <Typography variant="h3">{t("form.buildings")}</Typography>
                      </Grid>
                      <Grid item xs textAlign="right">
                        <IconButton
                          onClick={() => {
                            dispatch(openActionDialog());
                            dispatch(changeActionDialogType("buildings"));
                          }}
                        >
                          <SvgIcon>
                            <PlusIcon />
                          </SvgIcon>
                        </IconButton>
                      </Grid>
                    </TitleGrid>
                    <Divider />
                    <List>
                      {building
                        ?.sort((a, b) => a.description.localeCompare(b.description))
                        .map((building, index) => {
                          return (
                            <ListItem key={index} disablePadding>
                              <ListItemButton dense>
                                {building.isDefault && (
                                  <ListItemIcon>
                                    <SvgIcon>
                                      <LockClosedIcon />
                                    </SvgIcon>
                                  </ListItemIcon>
                                )}
                                <ListItemText primary={building.description} />
                                <SvgIcon
                                  onClick={() => {
                                    dispatch(changeBuildingSelection(building));
                                    dispatch(openActionDialog());
                                    dispatch(changeActionDialogType("editBuildings"));
                                  }}
                                >
                                  <PencilSquareIcon />
                                </SvgIcon>
                                <SvgIcon onClick={() => deleteBuilding(building)}>
                                  <TrashIcon />
                                </SvgIcon>
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                    </List>
                  </TextFieldGrid>
                )}
              </>
            )}
          </ContentGrid>
        </CustomDialogContent>
        <DialogActions>
          <ActionsGrid container>
            <StyledButton onClick={closeForm}> {t("button.cancel")}</StyledButton>
            <Box sx={{ position: "relative" }}>
              <StyledButton selected onClick={handleConfirm}>
                {t("button.confirm")}
              </StyledButton>
              {isLoading && <CustomCircularProgress size={24} />}
            </Box>
          </ActionsGrid>
        </DialogActions>
      </>
    )
  );
}
