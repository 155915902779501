import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const initialState = {
  indexSelected: Cookies.get('drawerIndex') ?  Cookies.get('drawerIndex') :  'Multisense',
  AccountIndexSelected: Cookies.get('drawerAccountIndex') ? parseInt(Cookies.get('drawerAccountIndex')) : 0,
  openDrawer: Cookies.get('openDrawer') === 'true', // Parse boolean from string,
};

export const sideMenuSlice = createSlice({
  name: 'sideMenu',
  initialState,
  reducers: {
    changeIndexSelected: (state, action) => {
      state.indexSelected = action.payload;
      Cookies.set('drawerIndex', action.payload, { expires: 7 }); // Set cookie with 7 days expiry
    },
    changeIndexAccount: (state, action) => {
      state.indexSelected = action.payload;
      Cookies.set('drawerAccountIndex', action.payload, { expires: 7 });  // Optionally sync with local storage
    },
    openDrawer: (state, action) => {
      state.openDrawer = action.payload;
      Cookies.set('openDrawer',  action.payload.toString(), { expires: 7 });  // Optionally sync with local storage
    }
  },
});

export const {
  changeIndexSelected,
  changeIndexAccount,
  openDrawer
} = sideMenuSlice.actions;

export default sideMenuSlice.reducer;