import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectBuilding } from "../../redux/slices/buildingsSlice";
import { changeSelectedProfile } from "../../redux/slices/currentSessionSlice";
import { toggleDrawer } from "../../redux/slices/mobileDrawer";

export default function BuildingSelection() {
  const dispatch = useDispatch();
  const profiles = useSelector((state) => state.session.profiles);
  const { buildings } = useSelector((state) => state.buildings);

  const handleBuildingSelection = (building) => {
    const selectedProfile = profiles.find((obj) => obj.buildingId === building.id);
    dispatch(selectBuilding({ id: building.id, description: building.description }));
    dispatch(changeSelectedProfile(selectedProfile));
    dispatch(toggleDrawer());
  };


  return (
    <List>
      {buildings.map((building) => (
        <ListItem key={building.id} disablePadding onClick={() => handleBuildingSelection(building)}>
          <ListItemButton>
            <ListItemText primary={building.description} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}
