import React, { useEffect } from "react";
import { Box, DialogActions, DialogTitle, Divider, FormControl, FormControlLabel, Grid, Grow, IconButton, Radio, RadioGroup, Stack, SvgIcon, Typography } from "@mui/material";
import { useState } from "react";
import { ActionsGrid, ContentGrid, CustomDialogContent, CustomTextField, TextFieldGrid, TitleGrid } from "./Forms.styled";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { closeActionDialog } from "../../../redux/slices/actionDialogSlice";
import { useDispatch, useSelector } from "react-redux";
import { StyledButton } from "../../../App.styled";
import LocationDropdown from "../../MultiSense/ChartTitle/LocationFilter/LocationDropdown/LocationDropdown";
import DataDropdown from "../../MultiSense/ChartTitle/DataFilter/DataDropdown/DataDropdown";
import { addSeries, cancelSeries, editSeries } from "../../../redux/slices/multisenseSlice";
import { Constants } from "../../../helpers/constants";
import { t } from "i18next";

export default function AddNewSeriesForm(props) {
  const dispatch = useDispatch();
  
  const { sensor, location, locationIds, locationLevel } = useSelector((state) => state.multisense);
  const series = useSelector((state) => state.multisense.series);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [seriesForm, setSeriesForm] = useState({
    seriesName: "",
    sensor: "",
    location: "",
    locationId: "",
    color: "",
    type: "",
    locationLevel: "",
  });

  // New state to track form validity
  const [isFormValid, setIsFormValid] = useState(false);

  const closeForm = () => {
    dispatch(closeActionDialog());
  };

  const handleConfirm = () => {
    if (isFormValid) {
      if (props.index !== undefined) {
        dispatch(editSeries({ ...seriesForm, index: props.index }));
      } else {
        dispatch(addSeries(seriesForm));
      }
      dispatch(closeActionDialog());
    } else {
      setShowErrorMessage(true); // Show error message if form is not valid
    }
  };

  const handleSelectionChange = (e) => {
    const { name, value } = e.target;
    setSeriesForm({ ...seriesForm, [name]: value });
  };

  useEffect(() => {
    setSeriesForm({
      ...seriesForm,
      sensor: sensor[props.index !== undefined ? props.index : series.length],
      location: location[props.index !== undefined ? props.index : series.length],
      locationId: locationIds[props.index !== undefined ? props.index : series.length],
      locationLevel: locationLevel[props.index !== undefined ? props.index : series.length],
    });
  }, [sensor, location, locationIds, props.index, series.length, seriesForm, locationLevel]);

  useEffect(() => {
    if (props.index !== undefined) {
      setSeriesForm({
        seriesName: series[props.index].seriesName,
        sensor: series[props.index].sensor,
        location: series[props.index].location,
        locationId: series[props.index].locationId,
        color: series[props.index].color,
        type: series[props.index].type,
        locationLevel: series[props.index].locationLevel,
      });
    }
  }, []);

  // Check if form is valid whenever seriesForm changes
  useEffect(() => {
    const isValid = Object.values(seriesForm).every((value) => value !== "");
    setIsFormValid(isValid);
    if (isValid) {
      setShowErrorMessage(false);
    }
  }, [seriesForm]);

  const colorPalette = [
    { colorHex: "#1C7AD4", name: "blue" },
    { colorHex: "#aa46bE", name: "purple" },
    { colorHex: "#28b4c8", name: "lightBlue" },
    { colorHex: "#78d237", name: "green" },
    { colorHex: "#ffd246", name: "yellow" },
  ];

  return (
    <>
      <DialogTitle>
        <TitleGrid container>
          <Grid item xs>
            <Grow in timeout={800}>
              <Typography variant="h3"> {t(`multisense.addSeries`)}</Typography>
            </Grow>
          </Grid>
          <Grid item xs textAlign="right">
            <IconButton onClick={closeForm}>
              <SvgIcon>
                <XMarkIcon />
              </SvgIcon>
            </IconButton>
          </Grid>
        </TitleGrid>
      </DialogTitle>
      <CustomDialogContent>
        <ContentGrid container rowGap={2}>
          <TextFieldGrid item xs={12} md={12}>
            <Typography variant="p">{t("multisense.sense-filter.seriesName")}</Typography>
            <CustomTextField name="seriesName" size="small" value={seriesForm.seriesName} onChange={handleSelectionChange}></CustomTextField>
          </TextFieldGrid>
          <TextFieldGrid item xs={12} md={12}>
            <Typography variant="p">{t("multisense.sense-filter.location")}</Typography>
            <LocationDropdown index={props.index !== undefined ? props.index : series.length} />
          </TextFieldGrid>
          <TextFieldGrid item xs={12} md={12}>
            <Typography variant="p">{t("multisense.sense-filter.dataType")}</Typography>
            <DataDropdown index={props.index !== undefined ? props.index : series.length} />
          </TextFieldGrid>
          <TextFieldGrid item xs={12} md={12}>
            <Divider flexItem orientation="horizontal" />
          </TextFieldGrid>
          <TextFieldGrid item xs={12} md={12}>
            <Typography variant="p">{t("multisense.sense-filter.seriesColor")}</Typography>
            <FormControl>
              <RadioGroup row name="color" value={seriesForm.color} onChange={handleSelectionChange} sx={{ width: "100%", justifyContent: "center" }}>
                {colorPalette.map((item) => (
                  <FormControlLabel
                    key={item.name}
                    value={item.colorHex}
                    control={
                      <Radio
                        sx={{
                          color: `${item.colorHex}`,
                          "&.Mui-checked": {
                            color: `${item.colorHex}`,
                          },
                        }}
                      />
                    }
                    label={<Typography variant="p"> {t(`chart.${item.name}`)}</Typography>}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </TextFieldGrid>
          <TextFieldGrid item xs={12} md={12}>
            <Typography variant="p">{t("multisense.sense-filter.seriesType")}</Typography>
            <Stack direction="row" spacing={1} sx={{ width: "100%", justifyContent: "center" }}>
              {Constants.CHART_TYPES.map((item) => (
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignContent: "center" }} key={item.name}>
                  <IconButton
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                        color: "whiteSmoke",
                      },
                      backgroundColor: item.value === seriesForm.type ? "rgba(0, 0, 0, 0.3)" : "none",
                      color: item.value === seriesForm.type ? "whiteSmoke" : "rgba(0, 0, 0, 0.3)",
                    }}
                    onClick={() => setSeriesForm({ ...seriesForm, type: item.value })}
                  >
                    {item.icon}
                  </IconButton>
                  <Typography variant="p" sx={{ display: "block", textAlign: "center", alignContent: "center", marginLeft: "4px" }}>
                    {t(`chart.${item.name}`)}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </TextFieldGrid>
        </ContentGrid>
        {showErrorMessage && (
          <Box>
            <Typography variant="p" sx={{ color: "red !important", textAlign: "center !important", width: "100%", justifyContent: "center" }}>
              {t("multisense.sense-filter.error")}
            </Typography>
          </Box>
        )}
      </CustomDialogContent>
      <DialogActions>
        <ActionsGrid container>
          <StyledButton
            onClick={() => {
              dispatch(closeActionDialog());
              dispatch(cancelSeries());
            }}
          >
            {t(`button.cancel`)}
          </StyledButton>
          <Box sx={{ position: "relative" }}>
            <StyledButton selected onClick={handleConfirm}>
              {t(`button.apply`)}
            </StyledButton>
          </Box>
        </ActionsGrid>
      </DialogActions>
    </>
  );
}
