import {
  format,
  startOfWeek,
  subDays,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subWeeks,
  subMonths,
} from "date-fns";
import { updateCalendar } from "../redux/slices/calendarSlice";

export const selectPeriod = (period, dispatch) => {
  const currentDate = new Date();

  let dateTimeStart, dateTimeEnd;

  switch (period) {
    case "Current":
      dateTimeStart = format(
        new Date(currentDate.setHours(0, 0, 0, 0)),
        "yyyy-MM-dd HH:mm:ss"
      );
      dateTimeEnd = format(new Date(), "yyyy-MM-dd HH:mm:ss");
      break;

    case "Day":
      const yesterday = subDays(currentDate, 1);
      dateTimeStart = format(
        new Date(yesterday.setHours(0, 0, 0, 0)),
        "yyyy-MM-dd HH:mm:ss"
      );
      dateTimeEnd = format(
        new Date(yesterday.setHours(0, 0, 0, 0)),
        "yyyy-MM-dd HH:mm:ss"
      );
      break;

    case "Week":
      const startOfLastWeek = startOfWeek(subWeeks(currentDate, 1), {
        weekStartsOn: 1,
      });
      const endOfLastWeek = endOfWeek(subWeeks(currentDate, 1), {
        weekStartsOn: 1,
      });
      dateTimeStart = format(
        new Date(startOfLastWeek.setHours(0, 0, 0, 0)),
        "yyyy-MM-dd HH:mm:ss"
      );
      dateTimeEnd = format(
        new Date(endOfLastWeek.setHours(0, 0, 0, 0)),
        "yyyy-MM-dd HH:mm:ss"
      );
      break;

    case "Month":
      const lastMonth = subMonths(currentDate, 1);
      const startOfLastMonth = startOfMonth(lastMonth);
      const endOfLastMonth = endOfMonth(lastMonth);
      dateTimeStart = format(
        new Date(startOfLastMonth.setHours(0, 0, 0, 0)),
        "yyyy-MM-dd HH:mm:ss"
      );
      dateTimeEnd = format(
        new Date(endOfLastMonth.setHours(0, 0, 0, 0)),
        "yyyy-MM-dd HH:mm:ss"
      );
      break;

    default:
      break;
  }

  const dateStart = getDate(dateTimeStart);
  const timeStart = getTime(dateTimeStart);

  const dateEnd = getDate(dateTimeEnd);
  const timeEnd = getTime(dateTimeEnd);

  dispatch(updateCalendar({ period, dateStart, dateEnd, timeStart, timeEnd }));
};

export const timeStringToDate = (timeString) => {
  const currentDate = new Date();
  const [hours] = timeString?.split(":")?.map(Number);

  currentDate.setHours(hours, 0);

  return currentDate;
};

const getDate = (dateTimeString) => {
  return dateTimeString?.split(" ")[0];
};

const getTime = (dateTimeString) => {
  return dateTimeString?.split(" ")[1];
};
