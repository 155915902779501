import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, CircularProgress, Grid, Typography, Tooltip, tooltipClasses } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { CustomCard } from "../../App.styled";
import TotalSavings from "./TotalSavings/TotalSavings";
import Trend from "./Trend/Trend";
import { useDispatch, useSelector } from "react-redux";
import {
  adjustToPreviousYear,
  adjustToPreviousPeriod,
  getBucketTimeDynamically,
  getFilter
} from "../../helpers/functions";
import { differenceInDays, format, parse, parseISO } from "date-fns";
import { useGetDataCostsByFilterWithBenchMarkQuery } from "../../redux/dataApi";
import CostsChart from "./CostsChart/CostsChart";
import { CustomAutocomplete, CustomTextField } from "./Costs.styled";
import LocationDropdown from "../MultiSense/ChartTitle/LocationFilter/LocationDropdown/LocationDropdown";
import { t } from "i18next";
import { initMultisense } from "../../redux/slices/multisenseSlice";
import CostBenchmark from "./CostsBenchmark/CostBenchmark";
import { selectPeriod } from "../../helpers/calendarFunctions";
import { changeCalendarSelection } from "../../redux/slices/calendarSlice";

export default function Costs() {
  const dispatch = useDispatch();
  const {
    customBuckets,
    calendarSelection,
    buildings,
    selectedBuilding,
    sensorTypes,
    locationIds,
    location,
    locationLevel,
    tenantId
  } = useSelector((state) => ({
    customBuckets: state.multisense.customBucket,
    calendarSelection: state.calendar.calendarSelection,
    buildings: state.buildings.buildings,
    selectedBuilding: state.buildings.selectedBuilding,
    sensorTypes: state.buildings.sensorTypes,
    locationIds: state.multisense.locationIds,
    location: state.multisense.location,
    locationLevel: state.multisense.locationLevel,
    tenantId: state.session.selectedTenant.id
  }));

  const InfoTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "rgba(239, 196, 77, 0.5)",
      color: "rgba(0, 0, 0, 0.60)",
      boxShadow: theme.shadows[1],
      fontSize: 14,
      maxWidth: "none"
    }
  }));

  const bucket = useMemo(
    () =>
      customBuckets ||
      getBucketTimeDynamically(calendarSelection.dateStart, calendarSelection.dateEnd),
    [customBuckets, calendarSelection]
  );

  const [seriesQueries, setSeriesQueries] = useState([]);
  const selectedBuildingSensorTypes = sensorTypes[selectedBuilding.id];
  const costsSensors = useMemo(() => {
    return [
      { value: "CentralMeterEnergy", description: t("costs.CentralMeterEnergy") },
      { value: "Energy", description: t("costs.Energy") },
      { value: "Gas", description: t("costs.Gas") },
      { value: "LightingEnergy", description: t("costs.LightingEnergy") },
      // { value: "SolarPanelEnergy", description: t("costs.SolarPanelEnergy") },
      { value: "UtilityEnergy", description: t("costs.UtilityEnergy") },
      { value: "UtilityGas", description: t("costs.UtilityGas") },
      { value: "UtilityWater", description: t("costs.UtilityWater") },
      { value: "Water", description: t("costs.Water") }
    ].filter((sensor) => selectedBuildingSensorTypes.includes(sensor.value));
  }, [selectedBuildingSensorTypes]);
  const [selectedSensor, setSelectedSensor] = useState(costsSensors[0]);

  const currentPeriodFilter = getFilter(
    selectedSensor?.value,
    calendarSelection,
    locationLevel[0],
    customBuckets
  );

  const { data: currentPeriod, isFetching: isFetchingData } =
    useGetDataCostsByFilterWithBenchMarkQuery(
      {
        tenantId: tenantId,
        id: locationIds[0],
        queryParams: {
          sensorType: currentPeriodFilter.sensorType,
          startDate: currentPeriodFilter.dateStart,
          endDate: currentPeriodFilter.dateEnd,
          startTime: currentPeriodFilter.timeStart,
          endTime: currentPeriodFilter.timeEnd,
          weekDays: currentPeriodFilter.weekDays,
          bucketTimeWindow: currentPeriodFilter.bucketTimeWindow,
          objectType: currentPeriodFilter.objectType
        }
      },
      { skip: !currentPeriodFilter || !tenantId || !locationIds[0] }
    );

  const previousPeriodFilter = getFilter(
    selectedSensor?.value,
    adjustToPreviousPeriod(calendarSelection),
    locationLevel[0],
    customBuckets
  );

  const { data: previousPeriod } = useGetDataCostsByFilterWithBenchMarkQuery(
    {
      tenantId: tenantId,
      id: locationIds[0],
      queryParams: {
        sensorType: previousPeriodFilter.sensorType,
        startDate: previousPeriodFilter.dateStart,
        endDate: previousPeriodFilter.dateEnd,
        startTime: previousPeriodFilter.timeStart,
        endTime: previousPeriodFilter.timeEnd,
        weekDays: previousPeriodFilter.weekDays,
        bucketTimeWindow: previousPeriodFilter.bucketTimeWindow,
        objectType: currentPeriodFilter.objectType
      }
    },
    { skip: !previousPeriodFilter || !tenantId || !locationIds[0] }
  );

  const previousYearFilter = getFilter(
    selectedSensor?.value,
    adjustToPreviousYear(calendarSelection),
    locationLevel[0],
    customBuckets
  );

  const { data: previousYear } = useGetDataCostsByFilterWithBenchMarkQuery(
    {
      tenantId: tenantId,
      id: locationIds[0],
      queryParams: {
        sensorType: previousYearFilter.sensorType,
        startDate: previousYearFilter.dateStart,
        endDate: previousYearFilter.dateEnd,
        startTime: previousYearFilter.timeStart,
        endTime: previousYearFilter.timeEnd,
        weekDays: previousYearFilter.weekDays,
        bucketTimeWindow: previousYearFilter.bucketTimeWindow,
        objectType: currentPeriodFilter.objectType
      }
    },
    { skip: !previousYearFilter || !tenantId || !locationIds[0] }
  );

  const startDate = parse(calendarSelection.dateStart, "yyyy-MM-dd", new Date());
  const endDate = parse(calendarSelection.dateEnd, "yyyy-MM-dd", new Date());

  const daysDifference = differenceInDays(endDate, startDate);

  useEffect(() => {
    if (currentPeriod && previousPeriod && previousYear) {
      const queries = [];

      let currentPeriodSerieName = format(parseISO(currentPeriodFilter.dateEnd), "yyyy");
      let previousPeriodSerieName = "Previous period";
      let previousYearSerieName = format(parseISO(previousYearFilter.dateEnd), "yyyy");

      if (
        calendarSelection.selectedPeriod === "Current" ||
        calendarSelection.selectedPeriod === "Day" ||
        (calendarSelection.selectedPeriod === "Custom" && daysDifference === 0)
      ) {
        currentPeriodSerieName = format(parseISO(currentPeriodFilter.dateEnd), "dd MMM yyyy");
        previousPeriodSerieName = format(parseISO(previousPeriodFilter.dateEnd), "dd MMM yyyy");
        previousYearSerieName = format(parseISO(previousYearFilter.dateEnd), "dd MMM yyyy");
      } else if (calendarSelection.selectedPeriod === "Week") {
        // In this case, we can't generate the previous period serie name
      } else if (calendarSelection.selectedPeriod === "Month") {
        currentPeriodSerieName = format(parseISO(currentPeriodFilter.dateEnd), "MMM yyyy");
        previousPeriodSerieName = format(parseISO(previousPeriodFilter.dateEnd), "MMM yyyy");
        previousYearSerieName = format(parseISO(previousYearFilter.dateEnd), "MMM yyyy");
      }

      queries.push(
        currentPeriod.dataBuckets?.map((item) => ({
          ...item,
          name: currentPeriodSerieName,
          sensor: selectedSensor?.value,
          location: location[0],
          color: "#1c7ad4",
          type: "spline"
        }))
      );

      if (
        calendarSelection.selectedPeriod === "Current" ||
        calendarSelection.selectedPeriod === "Day" ||
        calendarSelection.selectedPeriod === "Month" ||
        (calendarSelection.selectedPeriod === "Custom" && daysDifference === 0)
      ) {
        queries.push(
          previousPeriod.dataBuckets?.map((item) => ({
            ...item,
            name: previousPeriodSerieName,
            sensor: selectedSensor?.value,
            location: location[0],
            color: "#28b4c8",
            type: "spline"
          }))
        );
      } else if (calendarSelection.selectedPeriod === "Week") {
        // We cannot generate the previous period serie name for a week
      } else if (calendarSelection.selectedPeriod === "Custom") {
        // We can generate the previous period serie name for custom just like for day
      }

      queries.push(
        previousYear.dataBuckets?.map((item) => ({
          ...item,
          name: previousYearSerieName,
          sensor: selectedSensor?.value,
          location: location[0],
          color: "#aa46bE",
          type: "spline"
        }))
      );

      setSeriesQueries(queries);
    }
  }, [
    currentPeriod,
    calendarSelection,
    tenantId,
    customBuckets,
    previousPeriod,
    previousYear,
    selectedSensor?.value,
    location
  ]);

  useEffect(() => {
    if (buildings.length > 0) {
      if (calendarSelection.selectedPeriod === "Current") {
        selectPeriod("Day", dispatch);
        dispatch(changeCalendarSelection());
      }
      const index = buildings.findIndex((item) => item.id === selectedBuilding.id);
      if (index !== -1) {
        const newBuildings = [
          buildings[index],
          ...buildings.slice(0, index),
          ...buildings.slice(index + 1)
        ];
        dispatch(initMultisense(newBuildings));
      } else {
        dispatch(initMultisense(buildings));
      }
    }
  }, [buildings, selectedBuilding]);

  return (
    <Grid container spacing={2} sx={{ height: "90%" }}>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={4}>
          <CostBenchmark
            sensorType={currentPeriod?.sensorType}
            bucketTimeWindow={currentPeriod?.bucketTimeWindow}
            consumptionPerM2={currentPeriod?.consumptionPerM2}
            costPerM2={currentPeriod?.costPerM2}
            benchmarkConsumptionPerM2={currentPeriod?.benchmarkConsumptionPerM2}
            benchmarkCostPerM2={currentPeriod?.benchmarkCostPerM2}
            isFetching={isFetchingData}
          />
        </Grid>
        <Grid item xs={4}>
          <TotalSavings sensor={selectedSensor} />
        </Grid>
        <Grid item xs={4}>
          <Trend
            allSeries={seriesQueries}
            isFetching={isFetchingData}
            sensor={selectedSensor?.value}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ height: "75%" }}>
        <CustomCard variant="outlined" smaller={true} sx={{ padding: "20px" }}>
          <Grid container flexDirection="row" alignItems="center">
            <Grid item container flexDirection="row" xs={6} alignItems="flex-start">
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "8px",
                  marginBottom: "8px",
                  width: "70%"
                }}
              >
                <CustomAutocomplete
                  options={costsSensors}
                  freeSolo
                  disableClearable
                  getOptionLabel={(option) => option.description}
                  value={selectedSensor}
                  onChange={(e, newValue) => {
                    setSelectedSensor(newValue);
                  }}
                  renderInput={(params) => <CustomTextField {...params} />}
                />
                <Typography>in</Typography>
                <LocationDropdown index={0} hideAreas={true} />
              </Box>
            </Grid>
            <Grid
              item
              container
              xs={6}
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              paddingRight="60px"
            >
              <InfoTooltip title={t("costs.PeiodComparisonToolTip")} placement="left" arrow>
                <InfoIcon sx={{ color: "rgba(239, 196, 77, 1)" }} />
              </InfoTooltip>
            </Grid>
          </Grid>
          <Grid sx={{ height: "100%", width: "100%" }} paddingX={2}>
            {isFetchingData ? (
              <Box
                style={{
                  display: "flex",
                  height: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: " center",
                  gap: "18px"
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <CostsChart
                bucket={bucket}
                allSeries={seriesQueries}
                seriesNames={[selectedSensor]}
                selectedSensor={selectedSensor}
                selectedLocation={location}
                selectedPeriod={calendarSelection.selectedPeriod}
              />
            )}
          </Grid>
        </CustomCard>
      </Grid>
    </Grid>
  );
}
