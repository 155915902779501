import AdminPanel from '../components/AdminPanel/AdminPanel'
import Multisense from '../components/MultiSense/Multisense'
import loader from '../resources/lotties/loader.json'
import firstLoader from '../resources/lotties/first_loader.json'
import noData from '../resources/lotties/no_data.json'
import {
  PresentationChartBarIcon,
  MapPinIcon,
  UserIcon,
  SunIcon,
  LightBulbIcon,
  CursorArrowRippleIcon,
  Cog6ToothIcon,
  CurrencyEuroIcon
} from '@heroicons/react/24/outline'
import LightnShades from '../components/LightnShades/LightnShades'
import Utilization from '../components/Utilization/Utilization'
import AssetTracking from '../components/AssetTracking/AssetTracking'
import Impact from '../components/Impact/Impact'
import DigitalFloorPlanLight from '../components/LightnShades/DigitalFloorPlanLight'
import LightsInsights from '../components/LightnShades/LightsInsights'
import UtilizationInsights from '../components/Utilization/UtilizationInsights'
import DigitalFloorPlan from '../components/Utilization/DigitalFloorPlan'
import PartnerPanel from '../components/PartnerPanel/PartnerPanel'
import MultisenseMobile from '../mobileComponents/MultisenseMobile/MultisenseMobile'
import Account from '../components/Account/Account'
import EqualizerIcon from '@mui/icons-material/Equalizer';
import TimelineIcon from '@mui/icons-material/Timeline';
import TerrainRoundedIcon from '@mui/icons-material/TerrainRounded';
import Costs from '../components/Costs/Costs'

export const Constants = {
  WEEK_DAYS: [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
    'All',
  ],
  OBJECT_TYPE: {
    BUILDING: 0,
    FLOOR: 1,
    AREA: 2,
  },
  MEASUREMENT_TYPE: {
    AVG: 0,
    SUM: 1,
  },
  BUCKET_TIME: {
    MINUTE: 0,
    HOUR: 1,
    DAY: 2,
    WEEK: 3,
    MONTH: 4,
    QUARTER: 5,
    YEAR: 6,
    TEN_MINUTES: 7,
  },
  CURRENCY:[{value:"EUR", label:"EUR"}, {value:"MXN", label:"MXN"},{value:"GBP", label:"GBP"}, {value:"USD", label:"USD"}],
  PERIOD: ['Custom', 'Month', 'Week', 'Day', 'Current'],

  SENSOR_TYPE: {
    ENERGY: 'Energy',
    OCCUPANCY: 'Occupancy',
    LIGHT_LEVEL: 'LightLevel',
    SOUND: 'Sound',
    TEMPERATURE: 'Temperature',
    CO2: 'CO2',
    HUMIDITY: 'Humidity',
    GAS: 'Gas',
    COOLING: 'Cooling',
    HEATING: 'Heating',
    WIND_SPEED: 'WindSpeed',
    WATER: 'Water',
    LIGHTING_ENERGY: 'LightingEnergy',
    CEILING_TEMPERATURE: 'CeilingTemperature',
    VOC: 'VOC',
    TEMPERATURE_COLLECTOR:"TemperatureCollector",
    COOLING_POWER:"CoolingPower",
    HEATING_POWER:"HeatingPower",
    BUFFER_MAX_TEMPERATURE:"BufferMaxTemperature",
    BUFFER_MIN_TEMPERATURE:"BufferMinTemperature",
    NOISE:"Noise",
    PRESENCE: "Presence",
    UTILITY_ENERGY: "UtilityEnergy",
    UTILITY_GAS: "UtilityGas",
    UTILITY_WATER: "UtilityWater",
    CENTRAL_METER_ENERGY: "CentralMeterEnergy",
    SOLAR_PANEL_ENERGY: "SolarPanelEnergy",
  },

  LOADER: {
    animationData: loader,
    loop: true,
    style: {
      height: '100%',
      justifyContent: 'center',
      maxWidth: '3em',
      margin: '0 auto',
    },
  },

  FIRST_LOADER: {
    animationData: firstLoader,
    loop: true,
    style: {
      height: '100%',
      maxWidth: '600px',
      justifyContent: 'center',
      margin: '0 auto',
    },
  },

  NO_DATA_LOADER :{
    animationData: noData,
    loop: true,
    style: {
      height: '100%',
      maxWidth: '600px',
      justifyContent: 'center',
      margin: '0 auto',
    },
  },
  
  CSS:{
    DRAWER_WIDTH: {
      OPEN: 95,
    }
  },
  MODULES: [
    {
      name: 'Multisense',
      icon: <PresentationChartBarIcon />,
      component: <Multisense />,
      route: '/',
    },
    {
      name: 'EnergyCosts',
      icon: <CurrencyEuroIcon />,
      component: <Costs />,
      route: '/energy-costs',
    },
    {
      name: 'Impact',
      icon: <CursorArrowRippleIcon />,
      component: <Impact />,
      route: '/impact',
    },
    {
      name: 'Utilization',
      icon: <UserIcon />,
      component: <Utilization />,
      route: '/utilization',
      subRoutes: [
        {
          name: 'UtilizationMap',
          route: 'map',
          component: <DigitalFloorPlan/>,
        },
        {
          name: 'UtilizationInsights',
          route: 'insights',
          component: <UtilizationInsights/>,
        },
      ]
    },
    {
      name: 'Lighting',
      icon: <LightBulbIcon />,
      component: <LightnShades/>,
      route: '/lighting',
      subRoutes: [
        {
          name: 'LightingMap',
          route: 'map',
          component: <DigitalFloorPlanLight/>,
        },
        {
          name: 'LightingInsights',
          route: 'insights',
          component: <LightsInsights/>,
        },
      ]
    },
    {
      name: 'Environment',
      icon: <SunIcon />,
      route: '/environment',
    },
    {
      name: 'AssetTracking',
      icon: <MapPinIcon />,
      component: <AssetTracking />,
      route: '/asset-tracking',
    },
    {
      name: 'AdminPanel',
      icon: <Cog6ToothIcon />,
      roles: ['Admin'],
      component: <AdminPanel />,
      route: '/admin-panel',
    },
    {
      name: 'PartnerPanel',
      icon: <Cog6ToothIcon />,
      roles: ['Partner'],
      component: <PartnerPanel />,
      route: '/partner-panel',
    },
  ],

  MODULE_CATALOG: [
    'Multisense',
    'MultisenseAlertsLite',
    'MultisenseAlertsPro',
    'MultisenseSmartAlerts',
    'MultisenseDataCrossReference',
    'MultisenseBuildingCrossReference',
    'DataExport',
    'DigitalFloorPlan',
    'Utilization',
    'Impact',
    'Lighting',
    'ESGQuarterlyReports',
    'ESGMonthlyReports',
    'MobileApp',
    'Environment',
    'AssetTracking',
    "UtilizationInsights",
    "LightingInsights",
    "UtilizationMap",
    "LightingMap",
    "EnergyCosts"
  ],

  MOBILE:{
    MODULES: [
      {
        name: 'Multisense',
        icon: <PresentationChartBarIcon />,
        component: <MultisenseMobile />,
        route: '/',
      },
      {
        name: 'Lighting',
        icon: <LightBulbIcon />,
        component: <DigitalFloorPlanLight/>,
        route: '/lighting/map',
      },
      {
        name: 'Account',
        icon: <UserIcon />,
        component: <Account />,
        route: '/account',
      },
    ],
  },

  SEVERITY: ['Low', 'Medium', 'High'],
  CHANNEL: ['Email'],
  ALL_DAYS: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  LOGICAL_OPERATORS: ['LowerThan', 'LowerThanOrEqualTo', 'EqualTo', 'HigherThanOrEqualTo', 'HigherThan'],
  ARITHMETIC_OPERATORS: ['Add', 'Subtract', 'Multiply', 'Divide'],
  CHART_TYPES:[{
    name: 'line',
    value: "spline",
    icon: <TimelineIcon />,
  },{
    name: 'bar',
    value: "bar",
    icon: <EqualizerIcon  />,
  },
  {
    name: 'area',
    value: "splinearea",
    icon: <TerrainRoundedIcon  />,
  }, {
    name: 'stackedArea',
    value: "stackedsplinearea",
    icon: <TerrainRoundedIcon  />,
  }],
  
  CHAT_TEMPLATE: {
    cardsV2: [
      {
        cardId: 'unique-card-id',
        card: {
          header: {
            title: '',
            subtitle: '',
            imageUrl:
              'https://developers.google.com/chat/images/quickstart-app-avatar.png',
            imageType: 'CIRCLE',
            imageAltText: 'Avatar for Sasha',
          },
          sections: [
            {
              header: 'Contact Info',
              collapsible: true,
              uncollapsibleWidgetsCount: 1,
              widgets: [
                {
                  decoratedText: {
                    startIcon: {
                      knownIcon: 'EMAIL',
                    },
                    text: '',
                  },
                },
                {
                  decoratedText: {
                    startIcon: {
                      knownIcon: 'PERSON',
                    },
                    text: '',
                  },
                },
                {
                  decoratedText: {
                    startIcon: {
                      knownIcon: 'PHONE',
                    },
                    text: '+1 (555) 555-1234',
                  },
                },
                {
                  buttonList: {
                    buttons: [
                      {
                        text: 'Create Ticket',
                        onClick: {
                          openLink: {
                            url: 'https://enappgy.atlassian.net/jira/core/projects/EN/board',
                          },
                        },
                      },
                    ],
                  },
                },
              ],
            },
          ],
        },
      },
    ],
  }
}
