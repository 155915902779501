import { SvgIcon } from '@mui/material'
import { useEffect, useState } from 'react'
import {
  PencilSquareIcon,
} from '@heroicons/react/24/outline'
import { CustomCard } from '../../../App.styled'
import { useDispatch } from 'react-redux'
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton
} from '@mui/x-data-grid'
import {
  changeDetailsDialogType,
  openDetailsDialog,
} from '../../../redux/slices/detailsDialogSlice'
import { useEditBuildingMutation } from '../../../redux/metaDataApi'
import { openSnack } from '../../../redux/slices/snackSlice'
import { selectBuilding } from '../../../redux/slices/buildingsSlice'
import { changeBuildingSelection } from '../../../redux/slices/adminPanelSlice'

export default function CompactBuildingSettings({ buildings }) {
  const dispatch = useDispatch()
  const [rows, setRows] = useState([])

  useEffect(() => {
    if (buildings && buildings.length > 0) {
      setRows(buildings)
    }
  }, [buildings, rows])



  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ width: "100%" }}>
        <GridToolbarFilterButton sx={{ marginLeft: "auto", color: "inherit !important" }} />
        <GridToolbarColumnsButton sx={{ marginRight: "10px", color: "inherit !important" }} />
      </GridToolbarContainer>
    );
  }

  const handleDetailsClick = (id, description) => {
    const selectedBuilding = { id, description }
    dispatch(selectBuilding(selectedBuilding))
    dispatch(changeBuildingSelection(id))
    dispatch(openDetailsDialog())
    dispatch(changeDetailsDialogType('building'))
  }

  const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    {
      field: 'description',
      headerName: 'Description',

      flex: 1,
    },
    {
      field: 'address1',
      headerName: 'Street',

      flex: 1,
    },
    {
      field: 'city',
      headerName: 'City',
      flex: 1,
    },
    {
      field: 'country',
      headerName: 'Country',
      flex: 1,
    },
    {
      field: 'zip',
      headerName: 'Zip Code',
      flex: 1,
    },
    {
      field: 'currency',
      headerName: 'Currency',
      type: 'singleSelect',
      valueOptions: ["GBP", "EUR", "MXN"],
      flex: 1,
    },
    {
      field: 'workingDays',
      headerName: 'Working Days',
      flex: 1,
    },
    {
      field: 'workingStartHour',
      headerName: 'Working Start Hours',
      type: 'time',
      flex: 1,
    },
    {
      field: 'workingEndHour',
      headerName: 'Working End Hours',
      type: 'time',
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      getActions: ({ id, description }) => {
        return [
          <GridActionsCellItem
            icon={
              <SvgIcon>
                <PencilSquareIcon />
              </SvgIcon>
            }
            label="Edit"
            className="textPrimary"
            onClick={() => handleDetailsClick(id, description)}
            color="inherit"
          />,
        ]
      },
      flex: 1,
    },
  ]


  return (
    <CustomCard sx={{ height: '500px' }}>
      <DataGrid
        style={{ width: '100%' }}
        rows={rows}
        columns={columns}
        editMode="row"
        slots={{
          toolbar: CustomToolbar,
        }}
        slotProps={{
          align: "right"
        }}
      />
    </CustomCard>
  )
}
