import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useDeleteFavoriteMutation, useGetFavoritesByUserIdQuery } from "../../../redux/metaDataApi";
import { addFavorites, applySelection, cleanFavorites, selectFavorites } from "../../../redux/slices/multisenseSlice";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Typography, IconButton, Menu, MenuItem, Tooltip, Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { changeDetailsDialogType, openDetailsDialog } from "../../../redux/slices/detailsDialogSlice";
import { openSnack } from "../../../redux/slices/snackSlice";
import { t } from "i18next";
import Delete from "../../ActionDialog/Delete/Delete";

export default function Favorites() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const userId = useSelector((state) => state.session.userId);
  const {id: tenantId} = useSelector((state) => state.session.selectedTenant);
  const [isOpen, setIsOpen] = useState(false); // State to manage the toggle
  const { data: favorites } = useGetFavoritesByUserIdQuery({userId, tenantId}, { skip: !userId && !tenantId });
  const [deleteFavorite] = useDeleteFavoriteMutation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(!isOpen); // Toggle the open state
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(!isOpen);
  };
  const handleSelection = (favorite) => {
    let series = JSON.parse(favorite.series);
    dispatch(addFavorites(favorite));
    dispatch(selectFavorites(series));
    dispatch(applySelection());
    setAnchorEl(null);
  };

  const handleEdit = (favorite) => {
    let series = JSON.parse(favorite.series);
    dispatch(addFavorites(favorite));
    dispatch(selectFavorites(series));
    dispatch(openDetailsDialog());
    dispatch(changeDetailsDialogType("editSeries"));
    setAnchorEl(null);
  };

  const handleDelete = async (favorite) => {
    await deleteFavorite(favorite.id)
      .unwrap()
      .then(() => {
        dispatch(cleanFavorites());
        dispatch(openSnack({ message: t("snack.favDelete", { description: favorite.description }), type: "success" }));
      })
      .catch((error) => {
        dispatch(openSnack({ message: t("snack.error"), type: "error" }));
      });
  };

  useEffect(() => {
    if (favorites?.length === 0) {
      dispatch(cleanFavorites());
      setAnchorEl(null);
    }
  }, [dispatch, favorites]);

  return (
    <div>
      <Tooltip title={t("favorites.showFav")}>
        <Button
          disabled={favorites?.length === 0}
          id="basic-button"
          onClick={handleClick}
          sx={{
            backgroundColor: "transparent",
            borderColor: "transparent",
            padding: 0, // Removes all padding
            minWidth: 0,
            color: "white",
            "&.Mui-disabled": {
              backgroundColor: "transparent",
              borderColor: "transparent",
            },
          }}
        >
          {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {favorites
          ?.slice()
          .sort((a, b) => a.description.localeCompare(b.description))
          .map((item) => {
            return (
              <MenuItem key={item.id} disableRipple sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant="body1" sx={{ flexGrow: 1, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                  {item.description}
                </Typography>
                <div style={{ marginLeft: "40px" }}>
                  <Tooltip title={t("tooltip.view")}>
                    <IconButton onClick={() => handleSelection(item)} size="small">
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("tooltip.edit")}>
                    <IconButton onClick={() => handleEdit(item)} size="small">
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("tooltip.delete")}>
                    <>
                      <Delete onDelete={handleDelete} item={item} />
                    </>
                  </Tooltip>
                </div>
              </MenuItem>
            );
          })}
      </Menu>
    </div>
  );
}
