import React from "react";
import top from '../../resources/images/Top.png'
import bottom from '../../resources/images/Bottom.png'
export default function UtilizationInsights() {
  return (
    <div>
            <img
              src={top}
              alt="Utilization_top"
              style={{ width: "100%", height: "30vh", objectFit: "contain" }}
            />
            <img
              src={bottom}
              alt="Utilization_bottom"
              style={{ width: "100%", height: "42.5vh", objectFit: "contain" }}
            />
    </div>
  );
}
