import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { AppContainer, RouterOutlet, theme } from "../App.styled";
import LabelBottomNavigation from "./NavigationMobile/NavigationMobile";
import { Route, Routes } from "react-router-dom";
import HeaderMobile from "./HeaderMobile/HeaderMobile";
import { Constants } from "../helpers/constants";
import AnchorTemporaryDrawer from "./DrawerMobile/DrawerMobile";
import AlertsMobile from "./AlertsMobile/AlertsMobile";
import GuardedMobile from "../security/GuardedMobile";
import LockedService from "../components/LockedService/LockedService";
import { useSelector } from "react-redux";
import LockedMobile from "./LockedMobile/LockedMobile";

export default function MobileApp() {
  const profile = useSelector((state) => state.session.selectedProfile);
  const modules = profile?.profileModules?.map((item) => item.module) || [];

  if (modules.includes("MobileApp")) {
    return (
      <AppContainer container>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <HeaderMobile />
          <RouterOutlet>
            <Routes>
              {Constants.MOBILE.MODULES.map((module, index) => (
                <Route key={index} path={module.route} element={<GuardedMobile allowedModule={module.name}>{module.component}</GuardedMobile>}></Route>
              ))}
              <Route path="/alertsMobile" element={<AlertsMobile />} />
            </Routes>
          </RouterOutlet>
          <AnchorTemporaryDrawer />
          <LabelBottomNavigation />
        </ThemeProvider>
      </AppContainer>
    );
  } else {
    return (
      <AppContainer container>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <HeaderMobile />
          <RouterOutlet>
            <LockedMobile />
          </RouterOutlet>
          <AnchorTemporaryDrawer />
          <LabelBottomNavigation />
        </ThemeProvider>
      </AppContainer>
    );
  }
}
