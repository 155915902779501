import React from "react";
import { CustomDialogContent, StyledNoDataLottie } from "../../ActionDialog/Forms/Forms.styled";
import { Box, Typography } from "@mui/material";
import { ModifiedConstants } from "../../../App";
import { t } from "i18next";

export default function NoSensor() {
  return (
    <CustomDialogContent sx={{ p: "4px 12px" }}>
      <Box
        sx={{
          p: 0.5,
          m: 0.5,
          height: "450px",
          width: "700px",
          display: "flex",
          marginTop: "60px",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <StyledNoDataLottie
          sx={{ width: "45%", height: "40%" }}
          animationData={ModifiedConstants.NO_DATA_LOADER.animationData}
          loop={false}
        />
        <Typography variant="h3">{t("flexibleRates.noSensor")}</Typography>
      </Box>
    </CustomDialogContent>
  );
}
