import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomTextField, StyledAutocomplete } from "./DataDropdown.styled";
import { deleteSensor, selectSensor } from "../../../../../redux/slices/multisenseSlice";
import CloseIcon from "@mui/icons-material/Close";
import { getSensorUnit } from "../../../../../helpers/functions";
import { Typography } from "@mui/material";
import { t } from "i18next";

export default function DataDropdown(props) {
  const dispatch = useDispatch();
  const selectedBuilding = useSelector((state) => state.multisense.selectedBuilding);
  const isCentralInstallation = useSelector((state) => state.buildings.isCentralInstallation);
  const { sensor, locationIds, locationLevel } = useSelector((state) => state.multisense);
  const { selectedLocationIds, selectedSensor } = useSelector(
    (state) => state.multisense.selectedValues
  );
  const buildings = useSelector((state) => state.multisense.buildings);
  const sensorTypes = useSelector((state) => state.buildings.sensorTypes);
  const centralSensorTypes = useSelector((state) => state.buildings.centralSensorTypes);
  const [sensorOptions, setSensorOptions] = useState([]);
  const [autocompleteSensor, setAutocompleteSensor] = useState(
    sensor[props.index] ? t(`sensor.${sensor[props.index]}`) : ""
  );

  const handleDelete = (index) => {
    setAutocompleteSensor(undefined);
    dispatch(deleteSensor({ sensor: undefined, index: index }));
  };

  useLayoutEffect(() => {
    if (!selectedBuilding) return;

    let sensorTypesToUse = [];
    const isLocationCentral = isCentralInstallation.includes(locationIds[props.index]);

    if (isLocationCentral) {
      sensorTypesToUse = centralSensorTypes[selectedBuilding.id] || [];
    } else {
      const sensorTypesWithoutCentral = sensorTypes[selectedBuilding.id]?.filter(
        (sensor) => !centralSensorTypes[selectedBuilding.id]?.includes(sensor)
      );
      sensorTypesToUse =
        locationLevel[props.index] === "Building"
          ? sensorTypes[selectedBuilding.id]
          : sensorTypesWithoutCentral;
    }

    let options = [];
    if (selectedLocationIds.length > 1 && buildings.length !== 1) {
      let commonSensors = sensorTypesToUse;

      for (let i = 1; i < buildings.length; i++) {
        const currentBuildingSensors = isCentralInstallation.includes(selectedLocationIds[i])
          ? centralSensorTypes[buildings[i].id] || []
          : (sensorTypes[buildings[i].id] || []).filter(
              (sensor) => !centralSensorTypes[buildings[i].id]?.includes(sensor)
            );
        commonSensors = commonSensors.filter((sensor) => currentBuildingSensors.includes(sensor));
      }

      options = commonSensors;
    } else {
      options = sensorTypesToUse;
    }

    setSensorOptions(options);
  }, [
    selectedBuilding,
    props.index,
    sensorTypes,
    centralSensorTypes,
    isCentralInstallation,
    sensor,
    selectedLocationIds.length,
    buildings,
    locationIds,
    selectedLocationIds,
    locationLevel
  ]);

  const translatedSensorTypes = sensorOptions.map((sensorType) => ({
    sensorType,
    translation: t(`sensor.${sensorType}`)
  }));

  return (
    <>
      {sensorOptions.length > 0 ? (
        <StyledAutocomplete
          id="free-solo-demo"
          freeSolo
          disableClearable
          onChange={(event, value) => {
            const selectedSensor = sensorTypes[selectedBuilding?.id].find(
              (option) => t(`sensor.${option}`) === value
            );
            setAutocompleteSensor(value);
            dispatch(selectSensor({ sensor: selectedSensor, index: props.index }));
          }}
          value={autocompleteSensor}
          options={[...translatedSensorTypes]
            .sort((a, b) => a.translation.localeCompare(b.translation))
            .map((translatedSensorType) => t(`sensor.${translatedSensorType.sensorType}`))}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {sensor[props.index] && (
                      <CloseIcon
                        sx={{ cursor: "pointer" }}
                        fontSize="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(props.index);
                        }}
                      />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      ) : (
        <Typography
          variant="p"
          sx={{ marginTop: "10px", color: "red !important", textAlign: "center" }}
        >
          {t("multisense.sense-filter.noSensors")}
        </Typography>
      )}
    </>
  );
}
