import { createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";

const currentDate = format(new Date(), "yyyy-MM-dd");
const currentTime = format(new Date(), "HH:00:00");

const previousDate = format(
  new Date(new Date().setDate(new Date().getDate() - 1)),
  "yyyy-MM-dd"
);

const initialState = {
  open: false,
  dateStart: null,
  timeStart: null,
  timeEnd: null,
  dateEnd: null,
  selectedWeekDay: [],
  selectedPeriod: null,
  calendarSelection: {
    selectedPeriod: "Current",
    dateStart: currentDate,
    timeStart: "00:00:00",
    timeEnd: "00:00:00",
    dateEnd: currentDate,
    weekDays: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
  },
};

export const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    toggleCalendar: (state) => {
      state.open = !state.open;
    },
    cancelCalendar: (state) => {
      state.selectedPeriod = state.calendarSelection.selectedPeriod;
      state.selectedWeekDay = [...state.calendarSelection.weekDays];
      state.timeStart = state.calendarSelection.timeStart;
      state.timeEnd = state.calendarSelection.timeEnd;
      state.dateStart = state.calendarSelection.dateStart;
      state.dateEnd = state.calendarSelection.dateEnd;
    },
    updateCalendar: (state, action) => {
      state.timeStart = action.payload.timeStart;
      state.timeEnd = action.payload.timeEnd;
      state.dateStart = action.payload.dateStart;
      state.dateEnd = action.payload.dateEnd;
      state.selectedPeriod = action.payload.period;
    },
    initCalendar: (state) => {
      state.calendarSelection.selectedPeriod = "Current";
      state.calendarSelection.weekDays = ["All"];
      state.calendarSelection.timeStart = "00:00:00";
      state.calendarSelection.timeEnd = currentTime;
      state.calendarSelection.dateStart = currentDate;
      state.calendarSelection.dateEnd = currentDate;
      state.selectedPeriod = "Current";
      state.selectedWeekDay = ["All"];
      state.timeStart = "00:00:00";
      state.timeEnd = currentTime;
      state.dateStart = currentDate;
      state.dateEnd = currentDate;
    },
    resetCalendar: (state, action) => {
      if (action.payload.currentModule === "EnergyCosts") {
        state.calendarSelection.selectedPeriod = "Day";
        state.calendarSelection.weekDays = ["All"];
        state.calendarSelection.timeStart = "00:00:00";
        state.calendarSelection.timeEnd = "00:00:00";
        state.calendarSelection.dateStart = previousDate;
        state.calendarSelection.dateEnd = previousDate;
        state.selectedPeriod = "Day";
        state.selectedWeekDay = ["All"];
        state.timeStart = "00:00:00";
        state.timeEnd = "00:00:00";
        state.dateStart = previousDate;
        state.dateEnd = previousDate;
      } else {
        state.calendarSelection.selectedPeriod = "Current";
        state.calendarSelection.weekDays = ["All"];
        state.calendarSelection.timeStart = "00:00:00";
        state.calendarSelection.timeEnd = currentTime;
        state.calendarSelection.dateStart = currentDate;
        state.calendarSelection.dateEnd = currentDate;
        state.selectedPeriod = "Current";
        state.selectedWeekDay = ["All"];
        state.timeStart = "00:00:00";
        state.timeEnd = currentTime;
        state.dateStart = currentDate;
        state.dateEnd = currentDate;
      }
    },
    changePeriod: (state, action) => {
      state.selectedPeriod = action.payload;
    },
    changeWeekDay: (state, action) => {
      state.selectedPeriod = "Custom";
      if (action.payload === "All") {
        state.selectedWeekDay = ["All"];
      } else {
        if (state.selectedWeekDay.includes("All")) {
          state.selectedWeekDay = state.selectedWeekDay.filter(
            (item) => item !== "All"
          );
        }

        if (state.selectedWeekDay.includes(action.payload)) {
          state.selectedWeekDay = state.selectedWeekDay.filter(
            (item) => item !== action.payload
          );
        } else {
          state.selectedWeekDay.push(action.payload);
        }
      }
    },
    changeTime: (state, action) => {
      state.timeStart = action.payload.timeStart;
      state.timeEnd = action.payload.timeEnd;
    },
    changeDate: (state, action) => {
      state.dateStart = action.payload.dateStart;
      state.dateEnd = action.payload.dateEnd;
    },
    changeCalendarSelection: (state) => {
      state.calendarSelection.selectedPeriod = state.selectedPeriod;
      state.calendarSelection.weekDays = [...state.selectedWeekDay];
      state.calendarSelection.timeStart = state.timeStart;
      state.calendarSelection.timeEnd = state.timeEnd;
      state.calendarSelection.dateStart = state.dateStart;
      state.calendarSelection.dateEnd = state.dateEnd;
    },
  },
});

export const {
  updateCalendar,
  changePeriod,
  changeWeekDay,
  changeTime,
  cancelCalendar,
  changeDate,
  initCalendar,
  resetCalendar,
  changeCalendarSelection,
  toggleCalendar,
} = calendarSlice.actions;

export default calendarSlice.reducer;
