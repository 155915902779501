import {
  addHours,
  differenceInDays,
  differenceInHours,
  eachDayOfInterval,
  format,
  parse,
  parseISO,
  subHours,
  subMonths
} from "date-fns";
import { changeAlertSettingDaysOfWeek } from "../redux/slices/alertSettingsSlice";
import { ModifiedConstants } from "../App";
import Cookies from "js-cookie";
import i18n from "../services/i18n";

export const formatNumber = (value) => {
  let suffix = "";
  let number = value;

  if (Math.abs(number) >= 1000000) {
    suffix = "M";
    number = number / 1000000;
  } else if (Math.abs(number) >= 1000) {
    suffix = "K";
    number = number / 1000;
  }

  // Determine the number of decimal places add if needed
  let decimalPlaces = 2;
  if (Math.abs(number) > -1 && Math.abs(number) < 1 && Math.abs(number) !== 0) {
    decimalPlaces = 4;
  }

  const currentLocale = i18n.language || "en";

  const formattedNumber = new Intl.NumberFormat(currentLocale, {
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: decimalPlaces
  }).format(number);

  return `${formattedNumber}${suffix}`;
};

export const formatNumberCustom = (value, applySuffix, minDecimalPlaces, maxDecimalPlaces) => {
  let suffix = "";
  let number = value;

  if (applySuffix) {
    if (Math.abs(number) >= 1000000) {
      suffix = "M";
      number = number / 1000000;
    } else if (Math.abs(number) >= 1000) {
      suffix = "K";
      number = number / 1000;
    }
  }

  // Determine the number of decimal places add if needed
  let decimalPlaces = minDecimalPlaces || 0;
  if (Math.abs(number) > -1 && Math.abs(number) < 1 && Math.abs(number) !== 0) {
    decimalPlaces = maxDecimalPlaces || 4;
  }

  const currentLocale = i18n.language || "en";

  const formattedNumber = new Intl.NumberFormat(currentLocale, {
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: decimalPlaces
  }).format(number);

  return `${formattedNumber}${suffix}`;
};

export const getFilter = (sensor, calendarSelection, location, customBucket, previous) => {
  const filter = {
    objectType: getObjectType(location),
    sensorType: sensor,
    dateStart: calendarSelection.dateStart,
    dateEnd: calendarSelection.dateEnd,
    timeStart: calendarSelection.timeStart,
    timeEnd: calendarSelection.timeEnd,
    weekDays:
      calendarSelection.weekDays[0] === "All"
        ? ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].join(",")
        : calendarSelection.weekDays.join(","),
    measurementType: getMeasurementType(sensor),
    bucketTimeWindow: getBucketTimeDynamically(
      calendarSelection.dateStart,
      calendarSelection.dateEnd,
      customBucket
    )
  };
  if (previous) {
    let startDate = parse(calendarSelection.dateStart, "yyyy-MM-dd", new Date());
    let endDate = parse(calendarSelection.dateEnd, "yyyy-MM-dd", new Date());

    const daysDifference = differenceInDays(endDate, startDate);

    startDate.setDate(startDate.getDate() - (daysDifference + 1));
    endDate.setDate(endDate.getDate() - (daysDifference + 1));

    filter.dateStart = formatDate(startDate);
    filter.dateEnd = formatDate(endDate);
  }

  return filter;
};

export const getNormalFilter = (sensor, from, to, location, bucket) => {
  const fromDate = new Date(from); // Replace with your actual 'from' date
  const toDate = new Date(to); // Replace with your actual 'to' date
  const filter = {
    objectType: getObjectType(location),
    sensorType: sensor,
    dateTimeStart: format(subHours(fromDate, 3), "yyyy-MM-dd HH:mm:ss"),
    dateTimeEnd: format(addHours(toDate, 3), "yyyy-MM-dd HH:mm:ss"),
    measurementType: getMeasurementType(sensor),
    bucketTimeWindow: getBucketTimeDynamically(from, to)
  };
  return filter;
};

export const getBucketTimeDynamically = (from, to, custom) => {
  const fromDate = new Date(from);
  const toDate = new Date(to);
  const daysDifference = differenceInDays(toDate, fromDate);
  let bucketTimeWindow;
  if (custom === undefined || custom === "") {
    bucketTimeWindow =
      daysDifference < 1 ? ModifiedConstants.BUCKET_TIME.HOUR : ModifiedConstants.BUCKET_TIME.DAY;
  } else {
    bucketTimeWindow = custom;
  }

  return bucketTimeWindow;
};

export const getSensorUnit = (sensor) => {
  const key = sensor?.toUpperCase().replace(/ /g, "_");
  const sensorInfo = ModifiedConstants.SENSOR_TYPE[key];
  return sensorInfo ? sensorInfo.unit : "N/A";
};

export const getMeasurementType = (sensor) => {
  switch (sensor) {
    case ModifiedConstants.SENSOR_TYPE.ENERGY?.description:
    case ModifiedConstants.SENSOR_TYPE.GAS?.description:
    case ModifiedConstants.SENSOR_TYPE.COOLING?.description:
    case ModifiedConstants.SENSOR_TYPE.HEATING?.description:
    case ModifiedConstants.SENSOR_TYPE.WATER?.description:
    case ModifiedConstants.SENSOR_TYPE.LIGHTINGENERGY?.description:
    case ModifiedConstants.SENSOR_TYPE.COOLINGPOWER?.description:
    case ModifiedConstants.SENSOR_TYPE.HEATINGPOWER?.description:
    case ModifiedConstants.SENSOR_TYPE.UTILITYENERGY?.description:
    case ModifiedConstants.SENSOR_TYPE.UTILITYGAS?.description:
    case ModifiedConstants.SENSOR_TYPE.UTILITYWATER?.description:
    case ModifiedConstants.SENSOR_TYPE.CENTRALMETERENERGY?.description:
    case ModifiedConstants.SENSOR_TYPE.SOLARPANELENERGY?.description:
      return ModifiedConstants.MEASUREMENT_TYPE.SUM;
    default:
      return ModifiedConstants.MEASUREMENT_TYPE.AVG;
  }
};

export const calculateMinMax = (data) => {
  const values = data
    .map((entry) => (entry.avg !== 0 ? entry.avg : entry.sum))
    .filter((value) => value !== undefined && !isNaN(value));
  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);
  return { min: minValue, max: maxValue };
};

export const getBucketTimeWindow = (period) => {
  if (period === ModifiedConstants.PERIOD[3] || period === ModifiedConstants.PERIOD[4]) {
    return ModifiedConstants.BUCKET_TIME.HOUR;
  }
  return ModifiedConstants.BUCKET_TIME.DAY;
};

export const getObjectType = (location) => {
  switch (location) {
    case "Building":
      return ModifiedConstants.OBJECT_TYPE.BUILDING;
    case "Floor":
      return ModifiedConstants.OBJECT_TYPE.FLOOR;
    case "Area":
      return ModifiedConstants.OBJECT_TYPE.AREA;
    default:
      return ModifiedConstants.OBJECT_TYPE.BUILDING; // or return some default object type
  }
};

export const validateDays = (selectedDaysOfWeek, dispatch) => {
  const sortedSelectedDays = [...selectedDaysOfWeek].sort().toString();
  const sortedAllDays = ModifiedConstants.ALL_DAYS.sort().toString();

  if (sortedSelectedDays === sortedAllDays) {
    return dispatch(changeAlertSettingDaysOfWeek("All"));
  }

  return selectedDaysOfWeek;
};

export const processChartData = (series1, series3, operation) => {
  if (!series1 || !series3 || series1.length !== series3.length) {
    // Handle error or return null if the data is not available or not matching
    return null;
  }

  return series1?.map((dataPoint, index) => {
    const avg3 = series3[index] ? series3[index].avg : 0;
    const sum3 = series3[index] ? series3[index].sum : 0;
    if (operation === "Add") {
      return {
        ...dataPoint,
        sum: dataPoint.sum + sum3,
        avg: dataPoint.avg + avg3
      };
    } else if (operation === "Subtract") {
      return {
        ...dataPoint,
        sum: dataPoint.sum - sum3,
        avg: dataPoint.avg - avg3
      };
    } else if (operation === "Multiply") {
      return {
        ...dataPoint,
        sum: dataPoint.sum * sum3,
        avg: dataPoint.avg * avg3
      };
    } else if (operation === "Divide") {
      return {
        ...dataPoint,
        sum: dataPoint.sum / sum3,
        avg: dataPoint.avg / avg3
      };
    } else {
      return dataPoint;
    }
  });
};

export const transformSensorTypes = (sensorData) => {
  const SENSOR_TYPE = {};

  sensorData.forEach((sensor) => {
    const key = sensor.description.toUpperCase().replace(/ /g, "_");
    SENSOR_TYPE[key] = { description: sensor.description, unit: sensor.unit };
  });

  return SENSOR_TYPE;
};

export const colorizeAreaByPowerLevel = (spaceId, powerLevel, floorPlan) => {
  let node = null;
  floorPlan?.resources.spaces.forEach((element) => {
    if (element.node?.id === spaceId) {
      node = element.node.id === spaceId ? element.node : null;
      switch (powerLevel) {
        case "0.0":
          node.setHighlight({ fill: [253, 253, 253] });
          break;
        case "25.0":
          node.setHighlight({ fill: [240, 255, 255] });
          break;
        case "50.0":
          node.setHighlight({ fill: [137, 207, 240] });
          break;
        case "100.0":
          node.setHighlight({ fill: [100, 149, 237] });
          break;
        default:
          node.setHighlight({ fill: [253, 253, 253] });
          return;
      }
    }
  });
};

export const getColorForArea = (value) => {
  let color = "grey";
  if (value < 19) {
    color = "green";
  } else if (20 && value < 40) {
    color = "yellow";
  } else if (value >= 40 && value < 60) {
    color = "orange";
  } else if (value >= 60 && value < 80) {
    color = "orangered";
  } else if (value >= 80 && value <= 100) {
    color = "red";
  }
  return color;
};

export const filterNotifications = (notifications) => {
  const currentDate = new Date();
  // Retrieve shown "NewRelease" notification IDs from cookies
  const shownNewReleaseIds = Cookies.get("shownNewReleaseNotifications")
    ? JSON.parse(Cookies.get("shownNewReleaseNotifications"))
    : [];

  // Filter "NewRelease" notifications that haven't been shown and are active
  const newReleaseNotifications = notifications.filter((notification) => {
    const endDate = new Date(notification.endDate);
    const extendedEndDate = new Date(endDate.getTime() + 24 * 60 * 60 * 1000);
    const isNotShownYet = !shownNewReleaseIds.includes(notification.id);
    return (
      notification.appNotificationType === "NewRelease" &&
      notification.enabled &&
      extendedEndDate > currentDate &&
      isNotShownYet
    );
  });

  // Filter other notifications that are active, regardless of type
  const otherNotifications = notifications.filter((notification) => {
    return notification.appNotificationType !== "NewRelease" && notification.enabled;
  });

  // Return both sets of filtered notifications in an object
  return {
    newReleaseNotifications,
    otherNotifications
  };
};

export const areArraysEqual = (array1, array2) => {
  // Check if either array is undefined or null
  if (array1 == null || array2 == null) {
    return array1 === array2;
  }

  // Check if arrays have the same length
  if (array1.length !== array2.length) {
    return false;
  }

  // Check each object in the arrays for equality
  for (let i = 0; i < array1.length; i++) {
    const elementKeys = Object.keys(array1[i]);
    const array2Keys = Object.keys(array2[i]);

    if (elementKeys.length !== array2Keys.length) {
      return false;
    }

    for (const key of elementKeys) {
      if (array1[i][key] !== array2[i][key]) {
        return false;
      }
    }
  }

  return true;
};

export const getMinMaxForSeries = (data, seriesNames) => {
  // Initialize an object to store min and max values for each series
  const seriesStats = {};

  // Loop through each series name provided
  seriesNames.forEach((series) => {
    // Extract values for the current series from the data
    const values = data.map((item) => item[series]);

    // Calculate min and max for the current series
    seriesStats[series] = {
      min: Math.min(...values),
      max: Math.max(...values)
    };
  });

  return seriesStats;
};

export const doesSequentiallyCoverFullDay = (rates) => {
  const weekdayRates = rates.filter((rate) => rate.isWeekdayRate);

  const [firstRate, secondRate] = weekdayRates;

  // Convert times to strings if they are Date objects or assume they are already strings
  const firstToHour =
    firstRate.toHour instanceof Date ? format(firstRate.toHour, "HH:mm") : firstRate.toHour;
  const secondFromHour =
    secondRate.fromHour instanceof Date
      ? format(secondRate.fromHour, "HH:mm")
      : secondRate.fromHour;

  // Check if the end of the first matches the start of the second
  if (firstToHour !== secondFromHour) {
    console.error(
      "The end time of the first weekday rate does not match the start time of the second."
    );
    return false;
  }

  // Validate that the first starts at 00:00 and the second ends at 23:59 or 24:00
  const firstFromHour =
    firstRate.fromHour instanceof Date ? format(firstRate.fromHour, "HH:mm") : firstRate.fromHour;
  const secondToHour =
    secondRate.toHour instanceof Date ? format(secondRate.toHour, "HH:mm") : secondRate.toHour;

  if (firstFromHour !== secondToHour) {
    console.error(
      "The end time of the first weekday rate does not match the start time of the second."
    );
    return false;
  }
  return true;
};

export const segregateWeekendRates = (rates) => {
  const weekdayRates = rates.filter((rate) => !rate.isWeekendRate);
  const weekendRates = rates.filter((rate) => rate.isWeekendRate);
  return [...weekdayRates, ...weekendRates]; // Concatenate weekday first, then weekend
};

export function isLastDayOfMonth(date) {
  var test = parse(formatDate(date), "yyyy-MM-dd", new Date());
  test.setDate(test.getDate() + 1);
  return test.getDate() === 1;
}

function formatDate(date) {
  // This works, but I think it's not the best way to do it
  // const year = date.getFullYear();
  // var month = "" + (date.getMonth() + 1);
  // var day = "" + date.getDate();

  // if (month.length < 2) month = "0" + month;
  // if (day.length < 2) day = "0" + day;

  // return [year, month, day].join("-");

  // I think this is a better way to do it
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split("T")[0]; // Returns date in yyyy-MM-dd format
}

export const adjustToPreviousYear = (calendar) => {
  const startDate = parse(calendar.dateStart, "yyyy-MM-dd", new Date());
  const endDate = parse(calendar.dateEnd, "yyyy-MM-dd", new Date());

  const adjustDate = (date) => {
    date.setFullYear(date.getFullYear() - 1);

    return formatDate(date); // Returns date in yyyy-MM-dd format
  };

  return {
    ...calendar,
    dateStart: adjustDate(startDate),
    dateEnd: adjustDate(endDate)
  };
};

export const adjustToPreviousPeriod = (calendar) => {
  const startDate = parse(calendar.dateStart, "yyyy-MM-dd", new Date());
  const endDate = parse(calendar.dateEnd, "yyyy-MM-dd", new Date());

  const daysDifference = differenceInDays(endDate, startDate);

  const adjustDate = (date) => {
    if (
      calendar.selectedPeriod === "Current" ||
      calendar.selectedPeriod === "Day" ||
      (calendar.selectedPeriod === "Custom" && daysDifference === 0)
    ) {
      date.setDate(date.getDate() - 1);
    } else if (calendar.selectedPeriod === "Week") {
      date.setDate(date.getDate() - 7);
    } else if (calendar.selectedPeriod === "Month") {
      if (date.getDate() === 1) {
        date.setMonth(date.getMonth() - 1);
      } else if (isLastDayOfMonth(date)) {
        date.setDate(0);
      }
    } else if (calendar.selectedPeriod === "Custom") {
      date.setDate(date.getDate() - (daysDifference + 1));
    }

    return formatDate(date); // Returns date in yyyy-MM-dd format
  };

  return {
    ...calendar,
    dateStart: adjustDate(startDate),
    dateEnd: adjustDate(endDate)
  };
};

export const analyzeSeries = (data) => {
  if (data) {
    if (data?.length === 0) {
      return {
        total: 0,
        overallPeak: null,
        overallValley: null,
        average: 0
      };
    }

    let total = 0;
    let overallPeak = { ...data[0], position: 0 }; // Initialize with the first data point
    let overallValley = { ...data[0], position: 0 }; // Initialize with the first data point

    data?.forEach((entry, index) => {
      total += entry.sum;

      // Check for overall peak
      if (entry.sum > overallPeak.sum) {
        overallPeak = { ...entry, position: index };
      }

      // Check for overall valley
      if (entry.sum < overallValley.sum) {
        overallValley = { ...entry, position: index };
      }
    });

    let average = total / data?.length;

    return {
      total,
      overallPeak,
      overallValley,
      average
    };
  }
};

export const generateRates = (values, sensorType, selectedBuilding) => {
  return [
    {
      isPeakHour: false,
      buildingId: selectedBuilding?.id,
      sensorType: sensorType,
      isWeekdayRate: true,
      isWeekendRate: false,
      fromHour: "00:00",
      toHour: format(values.peakFromHour, "HH:mm"),
      rate: values.offPeakRate
    },
    {
      isPeakHour: true,
      buildingId: selectedBuilding?.id,
      sensorType: sensorType,
      isWeekdayRate: true,
      isWeekendRate: false,
      fromHour: format(values.peakFromHour, "HH:mm"),
      toHour: format(values.peakToHour, "HH:mm"),
      rate: values.peakRate
    },
    {
      isPeakHour: false,
      buildingId: selectedBuilding?.id,
      sensorType: sensorType,
      isWeekdayRate: true,
      isWeekendRate: false,
      fromHour: format(values.peakToHour, "HH:mm"),
      toHour: "00:00",
      rate: values.offPeakRate
    },
    {
      isPeakHour: false,
      buildingId: selectedBuilding?.id,
      sensorType: sensorType,
      isWeekdayRate: false,
      isWeekendRate: true,
      fromHour: "00:00",
      toHour: "00:00",
      rate: values.weekendRate
    }
  ];
};

export function getHoursWithinPeriod(calendarSelection) {
  const { dateStart, dateEnd, timeStart, timeEnd, weekDays } = calendarSelection;

  // Parse input parameters
  const startDate = parseISO(dateStart);
  const endDate = parseISO(dateEnd);
  const timeStartParsed = parseISO(`${dateStart}T${timeStart}`);
  const timeEndParsed = parseISO(`${dateStart}T${timeEnd}`);
  const weekDaysArray =
    weekDays[0] === "All"
      ? ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
      : weekDays;

  // Generate all dates within the interval
  const allDates = eachDayOfInterval({ start: startDate, end: endDate });

  // Filter dates by the specified weekdays
  const validDates = allDates.filter((date) => weekDaysArray.includes(format(date, "EEEE")));

  let totalHours = 0;

  validDates.forEach((date) => {
    const startDatetime = parseISO(
      format(date, "yyyy-MM-dd") + "T" + format(timeStartParsed, "HH:mm:ss")
    );
    const endDatetime = parseISO(
      format(date, "yyyy-MM-dd") + "T" + format(timeEndParsed, "HH:mm:ss")
    );

    // Calculate the hours difference
    const hours = differenceInHours(endDatetime, startDatetime);
    totalHours += hours;
  });
  return totalHours;
}
