import React, { useEffect, useState } from "react";
import { ActionsGrid, CustomCircularProgress, CustomDialogContent, CustomTextField, TitleGrid } from "../../ActionDialog/Forms/Forms.styled";
import { Box, DialogActions, DialogTitle, Grid, Grow, IconButton, Typography, SvgIcon, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import { changeActionDialogType, openActionDialog, openEditSeries, openNewSeries } from "../../../redux/slices/actionDialogSlice";
import { StyledButton } from "../../../App.styled";
import { closeDetailsDialog } from "../../../redux/slices/detailsDialogSlice";
import { addLocationPlaceholder, addSensorPlaceholder, applySelection, cancelSeries, deleteSensor, deleteSeries, modifyLocation } from "../../../redux/slices/multisenseSlice";
import { Constants } from "../../../helpers/constants";
import { t } from "i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { BookmarkIcon } from "@heroicons/react/24/outline";
import { BookmarkIcon as SolidBookmarkIcon } from "@heroicons/react/24/solid";
import { areArraysEqual } from "../../../helpers/functions";
import { useGetFavoritesByUserIdQuery, useUpdateFavoritesMutation } from "../../../redux/metaDataApi";
import { openSnack } from "../../../redux/slices/snackSlice";

export default function MultisenseForm(props) {
  const dispatch = useDispatch();
  const series = useSelector((state) => state.multisense.series);
  const userId = useSelector((state) => state.session.userId);
  const favorite = useSelector((state) => state.multisense.favorites);
  const profile = useSelector((state) => state.session.selectedProfile);
  const modules = profile?.profileModules?.map((item) => item.module) || [];
  const [areEqual, setAreEqual] = useState(false);
  const [description, setDescription] = useState("");
  const [button, setButton] = useState("apply");
  const [updateFavorite, { isLoading }] = useUpdateFavoritesMutation();
  const { id: tenantId } = useSelector((state) => state.session.selectedTenant);
  const { data: favorites, isFetching } = useGetFavoritesByUserIdQuery({userId, tenantId}, { skip: !userId && !tenantId });
  const cancel = () => {
    dispatch(cancelSeries());
    dispatch(closeDetailsDialog());
  };
  const handleConfirm = async () => {
    if (props.isEdit) {
      const payload = {
        id: favorite.id,
        userId: favorite.userId,
        tenantId: favorite.tenantId,
        description: description,
        series: JSON.stringify(series),
      };

      await updateFavorite(payload)
        .unwrap()
        .then(() => {
          dispatch(openSnack({ message: t("snack.favUpdate", { description: description }), type: "success" }));
          dispatch(applySelection());
          dispatch(closeDetailsDialog());
        })
        .catch((error) => {
          dispatch(openSnack({ message: t("snack.error"), type: "error" }));
        });
    } else {
      dispatch(applySelection());
      dispatch(closeDetailsDialog());
    }
  };

  const addNewFavorite = () => {
    dispatch(openActionDialog());
    dispatch(changeActionDialogType("confirmFavorite"));
  };

  useEffect(() => {
    if (favorite && series) {
      setAreEqual(areArraysEqual(favorite?.series, series));
      setDescription(favorite.description);
    }
    if (props.isEdit) {
      setButton("update");
    }
  }, [favorite, series, props.isEdit]);

  const disableBookmarkIcon = props.isEdit || (favorites?.length >= 10 && !isFetching) || areEqual;

  return (
    <>
      <DialogTitle>
        <TitleGrid container>
          <Grid item xs>
            {props.isEdit ? (
              <CustomTextField fullWidth value={description} onChange={(e) => setDescription(e.target.value)} inputProps={{ maxLength: 50 }}></CustomTextField>
            ) : (
              <Grow in timeout={800}>
                <Typography variant="h3">{t(`multisense.multisenseSeries`)}</Typography>
              </Grow>
            )}
          </Grid>

          <Grid item xs textAlign="right">
            <Tooltip title={t(`tooltip.addFavorite`)}>
              <IconButton onClick={addNewFavorite} disabled={disableBookmarkIcon}>
                <SvgIcon>{disableBookmarkIcon ? <SolidBookmarkIcon /> : <BookmarkIcon />}</SvgIcon>
              </IconButton>
            </Tooltip>

            <Tooltip title={t(`multisense.addSeries`)}>
              <IconButton
                sx={{
                  display: series.length === 5 ? "none" : "inline-block",
                }}
                onClick={() => {
                  if (modules.includes("MultisenseDataCrossReference") && modules.includes("MultisenseBuildingCrossReference")) {
                    dispatch(addSensorPlaceholder());
                    dispatch(addLocationPlaceholder());
                    dispatch(openNewSeries());
                  } else {
                    dispatch(changeActionDialogType("upgradeAlerts"));
                    dispatch(openActionDialog());
                  }
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </TitleGrid>
      </DialogTitle>
      <CustomDialogContent>
        <List sx={{ width: "100%", maxWidth: "100%", bgcolor: "background.paper" }}>
          {series?.map((item, index) => (
            <ListItem
              key={item.seriesName}
              secondaryAction={
                <>
                  <IconButton
                    disabled={index === 0}
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      dispatch(deleteSensor({ sensor: undefined, index: index }));
                      dispatch(modifyLocation(index));
                      dispatch(deleteSeries(index));
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => {
                      dispatch(openEditSeries(index));
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </>
              }
            >
              <ListItemAvatar>
                {Constants.CHART_TYPES.map((chart) => {
                  if (item.type === chart.value) {
                    return <div key={chart.value}>{chart.icon}</div>;
                  } else {
                    return null;
                  }
                })}
              </ListItemAvatar>
              <ListItemText
                primary={item.seriesName}
                secondary={`${t(`sensor.${item.sensor}`)}  ${t(`multisense.sense-title.in`)} ${t(`multisense.${item.locationLevel}`).toLocaleLowerCase()}: ${item.location}`}
              />
            </ListItem>
          ))}
        </List>
      </CustomDialogContent>
      <DialogActions>
        <ActionsGrid container>
          <StyledButton onClick={cancel}> {t(`button.cancel`)}</StyledButton>
          <Box sx={{ position: "relative" }}>
            <StyledButton selected onClick={handleConfirm} disabled={isLoading}>
              {t(`button.${button}`)}
            </StyledButton>
            {isLoading && <CustomCircularProgress size={24} />}
          </Box>
        </ActionsGrid>
      </DialogActions>
    </>
  );
}
