import { Box, Grid} from '@mui/material'
import styled from '@emotion/styled'

export const CustomFilterContainer = styled(Grid)(({ theme }) => ({
  flexDirection: 'column',
  width: '300px',
  rowGap: theme.spacing(3)
}))

export const CustomTitleContainer = styled(Box)(() => ({
  justifyContent: 'flex-start',
  width: '100%',
}))

export const CustomItemContainer = styled(Grid)(() => ({
  justifyContent:"center",
  alignItems:"center"
}))
