import Chart from 'devextreme-react/chart'
import styled from '@emotion/styled'

export const CustomChart = styled(Chart)(({ theme, hideDetail }) => ({
  width: '97%',
  height:  hideDetail ? '90%':'95%',
  marginTop: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    width: '100%',
  },
}))
