import {  Menu} from '@mui/material'
import { TreeView } from '@mui/x-tree-view'
import styled from 'styled-components'


  export const StyledMenu = styled(Menu)(() => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: '8px', // adjusted from theme.spacing(1)
      minWidth: 180,
      color: 'rgb(55, 65, 81)', // removed conditional theme dependency
      boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: 'grey', // removed theme dependency, replace with a suitable color
          marginRight: '24px', // adjusted from theme.spacing(1.5)
        },
        '& .MuiButtonBase-root': {
          disableRipple: true, // disabling the ripple effect here
        },
        '&:hover, &:focus, &:active': {
          backgroundColor: 'transparent !important',
        },
      },
    },
  }));


  export const StyledTree = styled(TreeView)(() => ({
    maxHeight: 250,
    minHeight: 250, 
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    
  }));


  

  